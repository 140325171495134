var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "800",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "light": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-text', {
    staticClass: "py-5"
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.types,
      "label": "유형"
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "#",
      "disabled": _vm.isCreate
    },
    model: {
      value: _vm.form.index,
      callback: function ($$v) {
        _vm.$set(_vm.form, "index", $$v);
      },
      expression: "form.index"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.form.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('naver-smarteditor', {
    staticClass: "mb-n2",
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }