<template>
    <v-currency-field v-model="form.limit" label="사용금액제한" v-bind="props__limit" @input="emit" />
</template>

<script>
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

import { attrs_input__console } from "@/assets/variables/attrs";
import { initShopCoupon } from "@/assets/variables/inits";
import { SHOP_COUPON_UNITS } from "@/assets/variables/constants";

export default {
    components: {
        AutocompleteUser,
    },
    props: {
        value: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        form: initShopCoupon(),
    }),
    computed: {
        props__limit() {
            return {
                ...attrs_input__console,

                prefix: "최대",
                suffix: "원",
                messages: "※ 값이 0일 때, 사용금액제한 미적용",

                class: "text-align-right-field",
                disabled: this.form.unit == SHOP_COUPON_UNITS.AMOUNT.value,
            };
        },
    },
    methods: {
        sync() {
            this.form = initShopCoupon(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>