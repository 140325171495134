var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-currency-field', _vm._b({
    attrs: {
      "label": "사용금액제한"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.limit,
      callback: function ($$v) {
        _vm.$set(_vm.form, "limit", $$v);
      },
      expression: "form.limit"
    }
  }, 'v-currency-field', _vm.props__limit, false));

}
var staticRenderFns = []

export { render, staticRenderFns }