var render = function render(){
  var _vm$category;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": 1080
    }
  }, [_c('list-heading', {
    attrs: {
      "title": (_vm$category = _vm.category) === null || _vm$category === void 0 ? void 0 : _vm$category.name
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('v-row', {
          staticClass: "ma-n2"
        }, [_c('v-spacer'), _c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "auto"
          }
        }, [_c('analytics-period-filter', {
          on: {
            "input": _vm.sync
          },
          model: {
            value: _vm.params,
            callback: function ($$v) {
              _vm.params = $$v;
            },
            expression: "params"
          }
        })], 1), _c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "3"
          }
        }, [_c('sales-analytics-sort-field', {
          on: {
            "input": _vm.sync
          },
          model: {
            value: _vm.params,
            callback: function ($$v) {
              _vm.params = $$v;
            },
            expression: "params"
          }
        })], 1), _c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "3"
          }
        }, [_c('sales-analytics-sort', {
          on: {
            "input": _vm.sync
          },
          model: {
            value: _vm.params,
            callback: function ($$v) {
              _vm.params = $$v;
            },
            expression: "params"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('sales-analytics-search', _vm._b({
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.params,
      callback: function ($$v) {
        _vm.params = $$v;
      },
      expression: "params"
    }
  }, 'sales-analytics-search', {
    showsSearch: _vm.showsSearch
  }, false)), _c('v-row', {
    staticClass: "mt-n3"
  }), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            attrs: {
              "title": header.withTitle ? header.formatter.bind(item)(value, item) : undefined
            },
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value, item))
            }
          })];
        }
      };
    }), {
      key: `item.product`,
      fn: function (_ref2) {
        var _item$thumb;
        var item = _ref2.item;
        return [item ? _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [item.thumb ? _c('image-popup', {
          attrs: {
            "src": (_item$thumb = item.thumb) === null || _item$thumb === void 0 ? void 0 : _item$thumb.url,
            "attrs_avatar": {
              size: '40',
              rounded: true
            }
          }
        }) : _c('v-responsive', {
          attrs: {
            "width": "56",
            "height": "56"
          }
        }, [_c('v-row', {
          staticClass: "fill-height ma-0 grey lighten-3",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('div', {
          staticClass: "text-start cursor-pointer",
          on: {
            "click": function ($event) {
              var _item$product;
              return _vm.$router.push(`/console/shop/products/${item === null || item === void 0 ? void 0 : (_item$product = item.product) === null || _item$product === void 0 ? void 0 : _item$product._id}`);
            }
          }
        }, [_vm._v(_vm._s(item.product.name))])])], 1) : _vm._e()];
      }
    }, {
      key: `item.status`,
      fn: function (_ref3) {
        var _item$product2, _item$product3;
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item !== null && item !== void 0 && (_item$product2 = item.product) !== null && _item$product2 !== void 0 && _item$product2.isDeleted || !(item !== null && item !== void 0 && (_item$product3 = item.product) !== null && _item$product3 !== void 0 && _item$product3.shows) ? "종료" : "판매중") + " ")];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    loading: _vm.loading
  }, false)), _c('v-pagination', {
    staticClass: "my-2",
    attrs: {
      "value": _vm.params.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.params = Object.assign({}, _vm.params, {
          page
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }