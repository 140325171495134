var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-field-card', {
    attrs: {
      "containerAttrs": {
        class: 'pa-0 rounded-lg',
        style: 'overflow:hidden'
      }
    }
  }, [_c('vertical-form-table', _vm._b({
    attrs: {
      "hideDivider": ""
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "책번호",
      fn: function () {
        return [_c('v-row', {
          attrs: {
            "no-gutters": "",
            "align": "stretch"
          }
        }, [_c('v-col', [_c('v-text-field', _vm._b({
          staticClass: "text-caption",
          attrs: {
            "suffix": "권",
            "dense": "",
            "readonly": ""
          },
          model: {
            value: _vm.form.information.kwon,
            callback: function ($$v) {
              _vm.$set(_vm.form.information, "kwon", $$v);
            },
            expression: "form.information.kwon"
          }
        }, 'v-text-field', _vm.attrs_input__verticalTable, false))], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-divider', {
          attrs: {
            "vertical": ""
          }
        })], 1), _c('v-col', [_c('v-text-field', _vm._b({
          staticClass: "text-caption",
          attrs: {
            "suffix": "호",
            "dense": "",
            "readonly": ""
          },
          model: {
            value: _vm.form.information.ho,
            callback: function ($$v) {
              _vm.$set(_vm.form.information, "ho", $$v);
            },
            expression: "form.information.ho"
          }
        }, 'v-text-field', _vm.attrs_input__verticalTable, false))], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }