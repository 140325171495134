var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": "",
      "fill-height": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1160",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": _vm.isCreate ? '서비스 등록' : '서비스 상세'
    }
  }), _c('v-row', {
    staticClass: "mx-n2 my-2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "서비스정보"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "서비스 유형",
      "items": _vm.serviceTypes,
      "disabled": !_vm.isCreate
    },
    model: {
      value: _vm.service.type,
      callback: function ($$v) {
        _vm.$set(_vm.service, "type", $$v);
      },
      expression: "service.type"
    }
  }, 'v-select', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "서비스명"
    },
    model: {
      value: _vm.service.name,
      callback: function ($$v) {
        _vm.$set(_vm.service, "name", $$v);
      },
      expression: "service.name"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "서비스 설명"
    },
    model: {
      value: _vm.service.description,
      callback: function ($$v) {
        _vm.$set(_vm.service, "description", $$v);
      },
      expression: "service.description"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-currency-field', _vm._b({
    attrs: {
      "label": "판매가",
      "suffix": "원"
    },
    model: {
      value: _vm.service.price,
      callback: function ($$v) {
        _vm.$set(_vm.service, "price", $$v);
      },
      expression: "service.price"
    }
  }, 'v-currency-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "auto": ""
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "진열순서",
      "type": "number"
    },
    model: {
      value: _vm.service.sequence,
      callback: function ($$v) {
        _vm.$set(_vm.service, "sequence", $$v);
      },
      expression: "service.sequence"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "hideTitle": "",
      "hideDivider": ""
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('view-section-title', {
    attrs: {
      "title": "전시여부"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          model: {
            value: _vm.service.shows,
            callback: function ($$v) {
              _vm.$set(_vm.service, "shows", $$v);
            },
            expression: "service.shows"
          }
        }, 'v-switch', _vm.attrs_switch__console, false))];
      },
      proxy: true
    }])
  }), _c('v-divider', {
    staticStyle: {
      "margin-bottom": "-1px"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('view-section-title', {
    attrs: {
      "title": "판매여부"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          model: {
            value: _vm.service.sells,
            callback: function ($$v) {
              _vm.$set(_vm.service, "sells", $$v);
            },
            expression: "service.sells"
          }
        }, 'v-switch', _vm.attrs_switch__console, false))];
      },
      proxy: true
    }])
  }), _c('v-divider', {
    staticStyle: {
      "margin-bottom": "-1px"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-btn', _vm._b({
    attrs: {
      "fixed": "",
      "right": "",
      "bottom": "",
      "fab": "",
      "color": "primary",
      "elevation": "1"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('v-icon', [_vm._v("mdi-content-save")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }