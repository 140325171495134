<template>
    <v-dialog v-model="shows" width="560" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-5">
                <v-row class="ma-n2">
                    <v-col cols="12" class="pa-2" v-if="!code">
                        <v-select v-model="form.code" label="위치" :items="codes" v-bind="{ ...attrs_input__console, loading }" :disabled="isCreate" @input="emit" />
                    </v-col>
                    <v-col cols="12" md="2" class="pa-2">
                        <v-text-field v-model="form.index" label="#" v-bind="{ ...attrs_input__console, loading }" :disabled="isCreate" @input="emit" />
                    </v-col>
                    <v-col cols="12" md="10" class="pa-2">
                        <autocomplete-board v-model="form.board" label="스토리" v-bind="{ ...attrs_input__console, loading }" returnObject @input="emit" />
                    </v-col>
                    <v-col cols="12" class="pa-0">
                        <v-expand-transition>
                            <v-row class="ma-0" v-show="!!form.board">
                                <v-col cols="12" class="pa-2">
                                    <form-image v-model="form" v-bind="{ attrs_input__console, loading }" @input="emit" />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="form.subject" label="제목" :placeholder="form.board?.subject" persistent-placeholder clearable clear-icon="mdi-refresh" v-bind="{ ...attrs_input__console, loading, disabled: !form.board }" @input="emit" />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-textarea v-model="form.content" label="내용" :placeholder="form.computedContent" persistent-placeholder v-bind="{ ...attrs_input__console, loading, disabled: !form.board }" clearable clear-icon="mdi-refresh" @input="emit" />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <form-products v-model="form" v-bind="{ attrs_input__console, loading }" @input="emit" />
                                </v-col>
                            </v-row>
                        </v-expand-transition>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-datetime-field v-model="form.startsAt" label="전시시작" class="pa-0" v-bind="{ ...attrs_input__console, loading }" @input="emit" />
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-datetime-field v-model="form.endsAt" label="전시종료" class="pa-0" v-bind="{ ...attrs_input__console, loading }" @input="emit" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-switch v-model="form.shows" dense v-bind="attrs_switch__console">
                        <span slot="label" class="subtitle-2"> {{ form.shows ? "노출" : "미노출" }} </span>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { initStoryBoardDisplay, STORY_DISPLAY_CODES } from "@/store/story";
import { attrs_input__console, attrs_switch__console } from "@/assets/variables";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import AutocompleteBoard from "@/components/dumb/autocomplete-board.vue";
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";

import FormImage from "./form/form-image.vue";
import FormProducts from "./form/form-products.vue";

const codes = Object.values(STORY_DISPLAY_CODES);

export default {
    components: {
        VDatetimeField,
        AutocompleteBoard,
        AutocompleteProduct,

        FormImage,
        FormProducts,
    },
    props: {
        _id: { type: String, default: null },
        code: { type: String, default: null },
    },
    data: () => ({
        form: initStoryBoardDisplay(),

        attrs_input__console,
        attrs_switch__console,

        codes,

        shows: false,
        loading: false,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "스토리 카드";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initStoryBoardDisplay({ code: this.code });
                } else {
                    let { _id } = this;
                    this.form = initStoryBoardDisplay((await api.console.boards.displays.get({ _id }))?.display);
                    this.$emit("input", { ...this.form });
                }
            } finally {
                this.loading = false;
            }
        },
        validates() {
            try {
                let { code, board, thumb } = this.form;

                if (!code) throw new Error("위치를 선택하세요");
                if (!board) throw new Error("스토리를 선택하세요");
                if (!thumb) throw new Error("이미지를 선택하세요");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (!this.validates()) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                let { post, put } = api.console.boards.displays;
                let { _image, image, ...form } = this.form;

                form._board = form?.board?._id;
                if (!form._board) delete form._board;
                delete form.board;

                form._stage = form?.stage?._id;
                if (!form._stage) delete form._stage;
                delete form.stage;

                delete form.thumb;
                delete form.thumbSrc;

                let display = (await (this.isCreate ? post : put)(form))?.display;

                let needsUpdate = false;

                if (image instanceof File) {
                    needsUpdate = true;
                    image = (await api.console.files.post({ _boardDisplay: display._id }, image))?.file;
                    if (!!_image) {
                        await api.console.files.delete({ _id: _image });
                    }
                }
                _image = image?._id || null;

                if (needsUpdate) {
                    display = (await put({ _id: display._id, _image }))?.display;
                }

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", display);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },

        emit() {
            delete this.form.thumb;
            delete this.form.thumbSrc;

            this.$nextTick(() => (this.form = initStoryBoardDisplay(this.form)));
        },
    },
};
</script>
