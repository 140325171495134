<template>
    <view-field-card :containerAttrs="{ class: 'pa-0 rounded-lg', style: 'overflow:hidden' }">
        <vertical-form-table v-model="form" v-bind="{ items }" hideDivider @input="emit" />
    </view-field-card>
</template>

<script>
import { attrs_input__verticalTable, initShopTaxinvoice } from "@/assets/variables";

import ViewFieldCard from "@/components/console/dumb/view-field-card.vue";
import VerticalFormTable from "@/components/console/dumb/vertical-form-table.vue";

const items = [
    [
        {
            key: "base.writeDate",
            term: "작성일자",
            type: "text",
            readonly: true,
        },
        {
            key: "information.supplyCostTotal",
            term: "공급가액",
            type: "currency",
            class: "text-align-right-field text-caption",
            suffix: "원",
            readonly: true,
        },
        {
            key: "information.taxTotal",
            term: "세액",
            type: "currency",
            class: "text-align-right-field text-caption",
            suffix: "원",
            readonly: true,
        },
        {
            key: "amendment.modifyCode",
            term: "수정사유",
            type: "select",
            items: [
                { text: "", value: null },
                { text: "기재사항 착오정정", value: 1 },
                { text: "공급가액 변동", value: 2 },
                { text: "환입", value: 3 },
                { text: "계약의 해제", value: 4 },
                { text: "내국신용장 사후개설", value: 5 },
                { text: "착오에 의한 이중발급", value: 6 },
            ],
            appendIcon: "",

            readonly: true,
        },
    ],
    {
        key: "information.remark1",
        term: "비고",
        type: "text",
        readonly: true,
    },
].map(function mapItem(item, index, array) {
    const hideDivider = item.hideDivider ?? index == array.length - 1;
    if (Array.isArray(item)) return item.map((item, index, array) => mapItem({ ...item, hideDivider }, index, array));

    return {
        ...attrs_input__verticalTable,

        divider: true,
        hideDivider,

        class: "text-caption",

        ...item,

        rowProps: {
            class: "ma-0 text-caption",
            align: "center",
            noGutters: true,
        },

        termProps: {
            class: "px-4",
            style: "max-width:120px",
            ...(item.termProps || {}),
        },
    };
});

export default {
    components: {
        VerticalFormTable,
        ViewFieldCard,
    },
    props: {
        value: { type: Object, default: initShopTaxinvoice },
    },
    data: () => ({
        form: initShopTaxinvoice(),

        items,
    }),
    methods: {
        sync() {
            this.form = initShopTaxinvoice(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>