var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({}, 'v-card', _vm.$attrs, false), [_c('v-card-text', {
    staticClass: "caption"
  }, [_c('v-row', {
    staticClass: "ma-n4",
    staticStyle: {
      "word-break": "keep-all"
    }
  }, [_vm._l(_vm.items, function (_ref, index) {
    var term = _ref.term,
      desc = _ref.desc;
    return [_c('v-col', {
      key: term,
      staticClass: "py-3 px-4",
      class: {
        'pt-0': index
      },
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "grey--text text--lighten-1"
    }, [_vm._v(_vm._s(term))]), _c('div', {
      staticClass: "font-weight-bold white-space-pre-line"
    }, [_vm._v(_vm._s(desc))])])];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }