var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": 1440
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "상품태그"
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('v-row', {
          staticClass: "ma-n2"
        }, [_c('v-spacer'), _c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "auto"
          }
        }, [_c('analytics-period-filter', {
          on: {
            "input": _vm.sync
          },
          model: {
            value: _vm.params,
            callback: function ($$v) {
              _vm.params = $$v;
            },
            expression: "params"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('tags-analytics-search', _vm._b({
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.params,
      callback: function ($$v) {
        _vm.params = $$v;
      },
      expression: "params"
    }
  }, 'tags-analytics-search', {
    showsSearch: _vm.showsSearch
  }, false)), _c('v-row', {
    staticClass: "mt-n3"
  }), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            attrs: {
              "title": header.withTitle ? header.formatter.bind(item)(value, item) : undefined
            },
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value, item))
            }
          })];
        }
      };
    }), {
      key: `item.status`,
      fn: function (_ref2) {
        var _item$product, _item$product2;
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item !== null && item !== void 0 && (_item$product = item.product) !== null && _item$product !== void 0 && _item$product.isDeleted || !(item !== null && item !== void 0 && (_item$product2 = item.product) !== null && _item$product2 !== void 0 && _item$product2.shows) || !(item !== null && item !== void 0 && item.totalCount) ? "종료" : "게시중") + " ")];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    loading: _vm.loading
  }, false)), _c('v-pagination', {
    staticClass: "my-2",
    attrs: {
      "value": _vm.params.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.params = Object.assign({}, _vm.params, {
          page
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }