<template>
    <v-data-table v-bind="{ items, headers }" disable-sort fixed-header>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
    </v-data-table>
</template>

<script>
import { initDataTableHeaders, initShopBulkUploadData } from "@/assets/variables";

const varName = "relateds";

export default {
    props: {
        value: { type: Object, default: initShopBulkUploadData },
        isError: { type: Boolean, default: false },
    },
    computed: {
        items() {
            return this.value?.[varName] || [];
        },
        headers() {
            const headers = [
                { width: 240, value: "parent", text: "상품코드" },
                { value: "custom.name", text: "추가옵션명" },
                { width: 240, value: "target", text: "추가옵션코드" },
                { width: +80, value: "enabled", text: "사용여부", formatter: (value) => (value ? "Y" : "N"), align: "center", class: "pa-0" },
                { width: 120, value: "custom.salePrice", text: "추가가격", formatter: (value) => value?.format?.() || "기본가격" },
            ];

            if (this.isError) headers.push({ width: 480, value: "errors", text: "오류", formatter: (errors) => errors.map((error) => error.message).join("\r\n") });

            return initDataTableHeaders(headers.map((header) => ({ ...header, class: (header.class || "") + " word-break-keep-all", cellClass: (header.cellClass || "") + " text-truncate max-width-0 py-3", withTitle: true })));
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .word-break-keep-all {
        word-break: keep-all;
    }
    .max-width-0 {
        max-width: 0px;
    }
}
</style>
