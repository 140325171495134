<template>
    <list-search v-bind="{ showsSearch }" @search="setQuery">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" md="4" lg="" class="pa-2">
                <v-select label="검색대상" v-model="query.searchKey" :items="searchKeys" placeholder="전체" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="" class="pa-2">
                <v-text-field label="검색어" v-model="query.searchValue" clearable v-bind="attrs_input__console" @keydown.enter="setQuery" />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="" class="pa-2">
                <!-- <v-select label="회원유형" v-model="query.type" :items="types" placeholder="전체" clearable v-bind="attrs_input__console" @change="setQuery" /> -->
                <v-select label="활성화여부" v-model="query.enabled" :items="types" placeholder="전체" clearable v-bind="attrs_input__console" @change="setQuery" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input__console, attrs_switch__console, USER_TYPES } from "@/assets/variables";
import ListSearch from "@/components/console/dumb/list-search.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["searchKey"]: query?.["searchKey"] ?? null,
    ["searchValue"]: query?.["searchValue"] ?? null,
    
    ["enabled"]: query?.["enabled"] ?? null,
    // ["type"]: query?.["type"] ?? null,
    // ["isWarning"]: JSON.parse(query?.["isWarning"] ?? "null"),
    // ["isApproved"]: JSON.parse(query?.["isApproved"] ?? "null"),
});

const types = [
    { value: "true", text: "활성화" },
    { value: "false", text: "비활성화" },
]

const searchKeys = [
    { text: "이름", value: "name" },
    { text: "연락처", value: "phone" },
    { text: "아이디", value: "username" },
    { text: "닉네임", value: "nickname" },
];

export default {
    components: {
        ListSearch,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        types,
        searchKeys,

        attrs_input__console,
        attrs_switch__console,
    }),
    mounted() {
        this.sync();
    },

    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        setQuery() {
            const { ...query } = this.query || {};

            if (!query?.["searchKey"]) delete query["searchKey"];
            if (!query?.["searchValue"]) delete query["searchValue"];
            if (!query?.["enabled"]) delete query["enabled"];

            // if (!query?.["type"]) delete query["type"];

            // if (query?.["isWarning"] == null) delete query["isWarning"];
            // if (query?.["isApproved"] == null) delete query["isApproved"];

            delete query.page;

            this.$router.push({ query });
        },
    },
    watch: {
        "$route"() {
            this.sync();
        },
    }
};
</script>

<style></style>
s