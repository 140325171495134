<template>
    <view-field-card :containerAttrs="{ class: 'pa-0 rounded-lg', style: 'overflow:hidden' }">
        <v-row no-gutters align="center">
            <v-col>
                <v-data-table v-bind="{ headers, items }" hide-default-footer disable-filtering disable-pagination disable-sort>
                    <template #[`item.information.totalAmount`]="{ item }">
                        <v-currency-field v-model="item.information.totalAmount" v-bind="attrs_input__verticalTable" readonly class="text-caption text-align-right-field" suffix="원" />
                    </template>
                    <template #[`item.information.cash`]="{ item }">
                        <v-currency-field v-model="item.information.cash" v-bind="attrs_input__verticalTable" readonly class="text-caption text-align-right-field" suffix="원" />
                    </template>
                    <template #[`item.information.chkBill`]="{ item }">
                        <v-currency-field v-model="item.information.chkBill" v-bind="attrs_input__verticalTable" readonly class="text-caption text-align-right-field" suffix="원" />
                    </template>
                    <template #[`item.information.note`]="{ item }">
                        <v-currency-field v-model="item.information.note" v-bind="attrs_input__verticalTable" readonly class="text-caption text-align-right-field" suffix="원" />
                    </template>
                    <template #[`item.information.credit`]="{ item }">
                        <v-currency-field v-model="item.information.credit" v-bind="attrs_input__verticalTable" readonly class="text-caption text-align-right-field" suffix="원" />
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="auto" align-self="stretch"> <v-divider vertical /> </v-col>
            <v-col cols="12" md="4" class="pa-3 text-caption text-center"> 이 금액을 { <v-select v-model="form.base.purposeType" :items="purposeTypes" class="d-inline-block text-caption" style="width: 120px" v-bind="attrs_input__verticalTable" /> } 함 </v-col>
        </v-row>
    </view-field-card>
</template>

<script>
import { attrs_input__verticalTable } from "@/assets/variables/attrs";
import { initDataTableHeaders, initShopTaxinvoice } from "@/assets/variables/inits";

import ViewFieldCard from "@/components/console/dumb/view-field-card.vue";
import VerticalFormTable from "@/components/console/dumb/vertical-form-table.vue";

const headers = initDataTableHeaders([
    {
        text: "합계금액",
        value: "information.totalAmount",
        cellClass: "pa-0",
        width: 100,
    },
    {
        text: "합계금액",
        value: "information.cash",
        cellClass: "pa-0",
        width: 100,
    },
    {
        text: "수표",
        value: "information.chkBill",
        cellClass: "pa-0",
        width: 100,
    },
    {
        text: "어음",
        value: "information.note",
        cellClass: "pa-0",
        width: 100,
    },
    {
        text: "어음",
        value: "information.credit",
        cellClass: "pa-0",
        width: 100,
    },
]);

const purposeTypes = ["영수", "청구", "없음"];

export default {
    components: {
        VerticalFormTable,
        ViewFieldCard,
    },
    props: {
        value: { type: Object, default: initShopTaxinvoice },
    },
    setup: () => ({
        headers,
        attrs_input__verticalTable,
        purposeTypes,
    }),
    data: () => ({
        form: initShopTaxinvoice(),
    }),
    computed: {
        items() {
            return [this.form];
        },
    },
    methods: {
        sync() {
            this.form = initShopTaxinvoice(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>