var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": "",
      "fill-height": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1160",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "게시글 목록",
      "showsFilterButton": ""
    },
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }), _c('list-search-default', _vm._b({}, 'list-search-default', {
    showsSearch: _vm.showsSearch,
    loading: _vm.loading
  }, false)), _vm.codes.length ? [_c('v-row', {
    staticClass: "mt-n3"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('board-list-codes', _vm._b({}, 'board-list-codes', {
    codes: _vm.codes
  }, false))], 1)], 1)] : _vm._e(), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.storyThumbSrc`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-img', {
          staticClass: "mx-auto grey lighten-2",
          attrs: {
            "src": item.storyThumbSrc,
            "aspect-ratio": 1 / 1,
            "height": "40",
            "width": "40",
            "contain": ""
          },
          scopedSlots: _vm._u([{
            key: "placeholder",
            fn: function () {
              return [_c('v-overlay', {
                attrs: {
                  "absolute": "",
                  "color": "transparent"
                }
              }, [_c('v-icon', [_vm._v(" mdi-image-broken-variant ")])], 1)];
            },
            proxy: true
          }], null, true)
        })];
      }
    }, {
      key: `item.isNotice`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-switch', _vm._b({
          staticClass: "mt-0",
          attrs: {
            "hide-details": ""
          },
          on: {
            "change": function ($event) {
              return _vm.update(item);
            }
          },
          model: {
            value: item.isNotice,
            callback: function ($$v) {
              _vm.$set(item, "isNotice", $$v);
            },
            expression: "item.isNotice"
          }
        }, 'v-switch', Object.assign({}, _vm.attrs_switch__console), false))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-icon', {
          attrs: {
            "small": "",
            "title": "수정"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), false ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "title": "복사"
          },
          on: {
            "click": function ($event) {
              return _vm.copy(item);
            }
          }
        }, [_vm._v("mdi-content-copy")]) : _vm._e(), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "title": "삭제"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "my-2",
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 11,
            "color": "primary"
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false)), _c('v-btn', {
    attrs: {
      "bottom": "",
      "color": "accent",
      "fab": "",
      "fixed": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        return _vm.create();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }