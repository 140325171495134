<template>
    <v-layout justify-center>
        <v-responsive max-width="1400" width="100%" height="100%">
            <list-heading title="차단회원" v-model="showsSearch" showsFilterButton >
                <template #add-button>
                    <user-block-form @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n2" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </user-block-form>
                </template>
            </list-heading>
            <user-block-search v-bind="{ showsSearch }" />
            <v-row class="mt-n3">
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white" class="caption" v-bind="{ loading }">
                            <v-layout align-baseline>
                                <span> 검색결과: </span>
                                <b class="mx-1"> {{ summary.totalCount?.format?.() }} </b> 건
                            </v-layout>
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>
            <v-data-table :headers="headers" :items="users" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
                <template #[`item.enabled`]="{ item }">
                    <v-switch v-model="item.enabled" v-bind="attrs_switch__console" @change="update(item)"/>
                </template>
                <template #[`item.actions`]="{ item }">
                    <user-block-form v-bind="{ _id: item._id }" @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="attrs" v-on="on"> <v-icon small>mdi-pencil</v-icon> </v-btn>
                        </template>
                    </user-block-form>
                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch__console, initDataTableHeaders, USER_TYPES } from "@/assets/variables";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import UserBlockSearch from "@/components/console/users/block/user-block-search.vue";
import UserBlockForm from "@/components/console/users/block/user-block-form.vue";

export default {
    components: {
        ListHeading,
        UserBlockSearch,
        UserBlockForm,
    },
    data: () => ({
        users: [],
        USER_TYPES,

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,

        attrs_switch__console,
    }),
    computed: {
        items() {
            return this.users.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        headers() {
            return initDataTableHeaders([
                { text: "해제일자", value: "unblockedAt", formatter: (value) => value?.toDate?.() || value || "-", width: 60 },
                { text: "유형", value: "user.type", formatter: (key) => USER_TYPES[key]?.text || "-", width: 60 },
                { text: "아이디/닉네임", value: "user", width: 80, formatter: ({ username, nickname }) => `${username}\r\n${nickname}` },
                { text: "이름", value: "user.name", width: 60 },
                { text: "연락처", value: "user.phone", formatter: (value) => value?.phoneNumberFormat?.() || value || "-", width: 100 },
                { text: "메모", value: "memo", width: +160 },
                { text: "활성화여부", value: "enabled", align: "center", class: "px-1", align: "center", formatter: (value) => (value ? "Y" : "N"), width: 80 },
                { text: "차단일시", value: "blockedAt", formatter: (value) => value?.toDateTime?.() || value || "-", width: 0 },
                { text: "", value: "actions", align: "center", width: 60 },
            ]);
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            delete query.page;

            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.init().then(this.search);
        },
    },
    methods: {
        async init() {
            try {
                this.users = [];
                this.summary = { totalCount: 0 };
            } catch (error) {
                this.$handleError(error);
            }
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                const { summary, users } = await api.console.users.blocks.gets({
                    headers: { skip, limit },
                    params,
                });

                this.users = users;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(user) {
            try {
                await api.console.users.blocks.put(user);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        // updateItem(user) {
        //     const index = this.users.findIndex(({ _id }) => _id == user._id);
        //     if (0 <= index) this.users.splice(index, 1, user);
        //     else {
        //         this.users = [user, ...this.users];
        //         this.summary.totalCount += 1;
        //     }
        //     this.search();
        // },

        async remove(item) {
            try{
                if(confirm("해당 차단 정보를 삭제하시겠습니까?")){
                    await api.console.users.blocks.delete(item)
                    alert("삭제되었습니다.");
                    this.search();
                } else return;
            } catch(error) {
                this.$handleError(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
