<template>
  <v-row>
    <v-col cols="12">
        <visits-daily-weekly-view v-bind="{ type: ANALYTICS_VISITS_TYPES.DAILY_WEEKLY.value }"/>
    </v-col>
    <v-col cols="12">
        <visits-monthly-yearly-view v-bind="{ type: ANALYTICS_VISITS_TYPES.MONTHLY_YEARLY.value }"/>
    </v-col>
  </v-row>
</template>

<script>
import { ANALYTICS_VISITS_TYPES } from "@/assets/variables";
import VisitsDailyWeeklyView from "@/components/console/analytics/visits/visits-daily-weekly-view.vue";
import VisitsMonthlyYearlyView from "@/components/console/analytics/visits/visits-monthly-yearly-view.vue";

export default {
    components: {
        VisitsDailyWeeklyView,
        VisitsMonthlyYearlyView
    },
    data: () => ({
        ANALYTICS_VISITS_TYPES,
    }),
}
</script>

<style>

</style>