<template>
  <v-layout justify-center fill-height>
    <v-responsive max-width="1200" width="100%" height="100%">
        <list-heading v-bind="{ title }" />
        <v-row>
            <v-col cols="5">
                <view-section-card title="메시지 템플릿">
                    <v-card flat>
                        <v-card-text>
                            <span v-if="isMessage">
                                * 90 Bytes 초과 입력 시, LMS로 전환됩니다.<br/>
                                * "제목"은 LMS 메신저에서만 적용됩니다.<br/>
                                * LMS는 최대 2000 Bytes까지 전송하실 수 있습니다.
                            </span>
                            <v-row>
                                <v-col cols="12" v-if="isMessage && previewBytes > 90">
                                    <v-text-field v-model="form.subject" label="LMS 제목" v-bind="{ ...attrs_input__console }"/>
                                </v-col>
                                <v-col cols="12" v-if="!isMessage">
                                    <v-text-field v-model="form.title" label="알림 제목" v-bind="{ ...attrs_input__console }" clearable/>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="form.message" label="내용" v-bind="{ ...attrs_input__console }" clearable/>
                                </v-col>
                                <v-col cols="12" v-if="isMessage">
                                    <v-textarea v-model="form.message" label="미리보기" 
                                    v-bind="{ ...attrs_input__console }" 
                                    readonly 
                                    counter
                                    :counter-value="countValue"/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </view-section-card>
            </v-col>
            <v-col cols="7">
                <view-section-card title="수신자 명단">
                    <!-- <template #actions>
                        <v-btn color="white" @click="$refs.excel.$refs.input.click()" class="green--text px-2"><v-icon class="mr-2">mdi-microsoft-excel</v-icon>엑셀업로드</v-btn>
                        <v-btn :href="file.pdf" color="white" class="green--text px-2"><v-icon class="mr-2">mdi-microsoft-excel</v-icon>샘플 다운로드</v-btn>
                        <v-file-input ref="excel" accept=".xls, .xlsx" class="d-none" @change="upload"></v-file-input>
                    </template> -->
                    <v-card flat>
                        <v-card-text>
                            <v-row>
                                <v-col cols="4">
                                    <v-select v-model="type" :items="msg_types_items" label="발송 유형" v-bind="{...attrs_input__console}"></v-select>
                                </v-col>
                                <v-col cols="8">
                                    <autocomplete-user 
                                        v-model="receivers" 
                                        label="수신자"
                                        :itemText="({ name, username, nickname }) => `${name} (${username}/${nickname})`" 
                                        :returnObject="true"
                                        v-bind="{ ...attrs_input__console }"
                                        clearable 
                                        multiple/>
                                </v-col>
                                <v-col cols="4" v-if="isMessage">
                                    <v-text-field v-model="receiver.name" label="이름" v-bind="{ ...attrs_input__console }" clearable @keydown.enter="addNumber"/>
                                </v-col>
                                <v-col cols="6" v-if="isMessage">
                                    <v-text-field v-model="receiver.phone" label="전화번호 수동입력" v-bind="{ ...attrs_input__console }" clearable placeholder="000-0000-0000" @keydown.enter="addNumber"/>
                                </v-col>
                                <v-col cols="2" v-if="isMessage">
                                    <v-btn v-bind="{ ...btn_primary, loading }" @click="addNumber">입력</v-btn>
                                </v-col>
                                <v-col cols="12" v-if="!isMessage">
                                    <v-text-field v-model="form.url" label="이동 URL" placeholder="/mypage/dashboard (도메인명 제거)" v-bind="{ ...attrs_input__console }"/>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table
                                        :headers="headers"
                                        :items="receivers"
                                        disable-sort
                                        class="v-sheet--outlined"
                                    >
                                    <template #[`item.actions`]="{ item }">
                                        <v-btn small text icon tile @click="remove(item)">
                                            <v-icon small> mdi-delete </v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-row class="row--xxs">
                                <v-spacer/>
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_primary, loading }" class="v-btn--width-fixed" @click="send">발송</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </view-section-card>
            </v-col>
        </v-row>
    </v-responsive>
  </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input__console, btn_primary } from "@/assets/variables/attrs";
import { ALARM_TYPES, initDataTableHeaders, MSG_TYPES, ALARM_CATEGORIES } from '@/assets/variables';
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

const initMessage = (form = {}) => ({
    ...form,

    type: form?.type || MSG_TYPES.SMS.value,
    subject: form?.subject || "",
    message: form?.message || "",
    receivers: form?.receivers || [],
});

const initReceiver = (form = {}) => ({
    _user: form?._user || form?._id || null,
    name: form?.name || "",
    phone: form?.phone || "",
});

const initNoti = (form = {}) => ({
    _user: form?._user || "",
    _alarm: null,

    type: ALARM_TYPES.NOTI.value,
    category: ALARM_CATEGORIES.ETC.value,
    title: form?.title || "",
    content: form?.message || "",
    url: form?.url || "",
})

const msg_types = {
    MESSAGE: { text: "메시지", value: "MESSAGE" },
    NOTI: { text: "플랫폼 알림", value: "NOTI" },
};

export default {
    components: {
        AutocompleteUser,
        ListHeading,
        ViewSectionCard
    },
    data: () => ({
        attrs_input__console,
        btn_primary,
        loading: false,
        msg_types_items: Object.values(msg_types),
        type: msg_types.MESSAGE.value,

        form: initMessage(),
        receiver: initReceiver(),
        receivers: [],
    }),
    computed: {
        title() {
            return "메시지 발송"
        },
        headers() {
            return initDataTableHeaders([
                // { text: "유형", value: "type", formatter: (value)=> USER_TYPES[value].text || "" },
                { text: "이름", value: "name"},
                { text: "아이디", value: "username" },
                { text: "닉네임", value: "nickname" },
                { text: "전화번호", value: "phone" },
                { text: "actions", value: "actions", width: 40 },
            ])
        },
        previewBytes(){
            return this.countBytes(this.form.message);
        },
        isMessage(){
            return this.type === msg_types.MESSAGE.value;
        },
    },
    methods:{
        async send(){
            if(this.loading) return;
            else this.loading = true;
            try{
                switch (this.type) {
                    case msg_types.MESSAGE.value:
                        this.form.receivers = this.receivers.map((receiver)=> initReceiver(receiver));
                        this.form.type = this.previewBytes > 90 ? MSG_TYPES.LMS.value : MSG_TYPES.SMS.value;
                        await api.console.messages.post(this.form);
                        
                        this.form = initMessage();
                        break;
                    case msg_types.NOTI.value:
                        Promise.all(this.receivers.map(async (receiver)=> {
                            const form = initNoti({...this.form, _user: receiver._id});
                            return await api.console.users.alarms.post(form);
                        }));

                        this.form = initNoti();
                        break;
                    };
                alert("성공적으로 발송되었습니다.");
                this.receivers = [];
                this.loading = false;
            } catch(error){
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        countBytes(string){
            // 10 : '\n' (2bytes), 128 이상: 2bytes
            return string?.split('')?.map(char => char?.charCodeAt(0))?.reduce((acc, cur)=> acc + (cur === 10 ? 2 : cur < 128 ? 1 : 2), 0) || 0;
        },
        countValue(){
            return `${this.previewBytes > 90 ? MSG_TYPES.LMS.value : MSG_TYPES.SMS.value } ${this.previewBytes} Bytes`;
        },
        addNumber(){
            const{ name, phone } = this.receiver;
            if(name || phone) this.receivers.push({
                ...this.receiver,
            })
            this.receiver = initReceiver();
        },
        remove(item){
            this.receivers = this.receivers.filter(receiver => receiver !== item);
        }
    },
    watch: {
        type(){
            switch(this.type){
                case msg_types.MESSAGE.value:
                    this.form = initMessage();
                    break;
                case msg_types.NOTI.value:
                    this.form = initNoti();
                    break;
            }
        }
    }
}
</script>

<style>

</style>