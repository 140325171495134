<template>
    <v-btn small outlined color="primary" class="mr-2" v-bind="{ loading }" @click="setOrderStatusCancel">{{ title }}</v-btn>
</template>

<script>
import api from "@/api";
import { PURCHASE_CLAIM_STATUSES, PURCHASE_ORDER_STATUSES, PURCHASE_PURCHASE_STATUSES } from "@/assets/variables";

export default {
    props: {
        title: { type: String, default: "주문취소" },
        loading: { type: Boolean, default: false },
        selected: { type: Array, default: () => [] },
    },
    methods: {
        async setOrderStatusCancel() {
            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                if (!this.selected.length) throw new Error(`${this.title} 처리할 구매건을 선택해주세요`);

                for (const { _id } of this.selected) {
                    await api.console.shop.purchases.putPurchase({ _id, orderStatus: PURCHASE_ORDER_STATUSES.ORDER_CANCELED.value, claimStatus: PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value, purchaseStatus: PURCHASE_PURCHASE_STATUSES.PURCHASE_CANCELED.value, rejectedAt: null, canceledAt: Date.now(), returnedAt: null, exchangedAt: null, exchangeShippedAt: null });
                };

                const orders = [...new Set(this.selected.map(({ _order }) => _order))];
                for (const _id of orders) {
                    await api.console.shop.orders.cancel({ _id });
                }

                alert(`${this.title} 처리 되었습니다. (가상계좌 결제의 경우, 수동 환불 처리가 필요합니다.)`);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.$emit("loading", false);
                this.$emit("search");
            }
        },
    },
};
</script>

<style></style>
