<template>
    <list-search v-bind="{ showsSearch }" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query.createdAt[0]" type="date" label="신청일(시작)" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query.createdAt[1]" type="date" label="신청일(종료)" clearable v-bind="attrs_input__console" />
            </v-col>
            <template v-if="hasConsoleScope">
                <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                    <autocomplete-user v-model="query['_user']" label="회원" placeholder="전체" clearable v-bind="{ ...attrs_input__console, ...props__autocompleteUser }" v-on="{ emit }" />
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                    <v-select v-model="query['searchKey']" :items="searchKeys" label="검색대상" placeholder="전체" clearable v-bind="attrs_input__console" />
                </v-col>
            </template>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query['searchValue']" label="검색어" clearable v-bind="attrs_input__console" @keydown.enter="emit" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { mapGetters } from "vuex";
import { USER_TYPES } from "@/assets/variables/constants";
import { attrs_input__console } from "@/assets/variables/attrs";

import ListSearch from "@/components/console/dumb/list-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["createdAt"]: query?.["createdAt"] || [null, null],

    ["_user"]: query?.["_user"] ?? null,
    ["searchKey"]: query?.["searchKey"] ?? null,
    ["searchValue"]: query?.["searchValue"] ?? null,
});

const searchKeys = [
    { text: "관리번호", value: "code" },
    { text: "관리메모", value: "memo" },
    { text: "출금비고", value: "remark" },
];

const props__autocompleteUser = {
    sort: { nickname: 1, username: 1, _id: 1 },
    params: {
        type: USER_TYPES.COMPANY.value,
    },
    itemText: ({ nickname, username }) => `${nickname || "-"} (${username})`,
};


export default {
    components: {
        ListSearch,
        AutocompleteUser,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        searchKeys,
        attrs_input__console,
        props__autocompleteUser,
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
    },
    mounted() {
        this.sync();
    },

    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            if (!query["createdAt"]) delete query["createdAt"];
            if (!query["createdAt"]?.[0] && !query?.createdAt?.[1]) delete query["createdAt"];

            if (!query["_user"]) delete query["_user"];
            if (!query["searchKey"]) delete query["searchKey"];
            if (!query["searchValue"]) delete query["searchValue"];

            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
