<template>
    <v-dialog v-model="dialog" width="800" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-5">
                <v-row class="ma-n2">
                    <v-col cols="12" md="6" class="pa-2"> <v-select v-model="form.type" :items="types" label="유형" v-bind="{ ...attrs_input__console, loading }" /> </v-col>
                    <v-col cols="12" md="6" class="pa-2"> <v-text-field v-model="form.index" label="#" v-bind="{ ...attrs_input__console, loading }" :disabled="isCreate" /> </v-col>
                    <v-col cols="12" class="pa-2"> <v-text-field v-model="form.subject" label="제목" v-bind="{ ...attrs_input__console, loading }" /> </v-col>
                    <v-col cols="12" class="pa-2"> <naver-smarteditor v-model="form.content" class="mb-n2" /> </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input__console, FAQ_TYPES } from "@/assets/variables";

import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

const types = Object.values(FAQ_TYPES);

const initFaq = (faq = {}) => ({
    type: faq.type || null,
    index: faq.index || null,
    subject: faq.subject || null,
    content: faq.content || null,
});

export default {
    components: {
        NaverSmarteditor,
    },
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        form: initFaq(),

        types,
        attrs_input__console,

        dialog: false,
        loading: false,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "FAQ";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initFaq();
                } else {
                    let { _id } = this;
                    this.form = (await api.console.center.faqs.get({ _id }))?.faq;
                    this.$emit("input", { ...this.form });
                }
            } finally {
                this.loading = false;
            }
        },
        validates() {
            try {
                let { type, subject, content } = this.form;

                if (!type) throw new Error("유형을 입력하세요");
                if (!subject) throw new Error("제목을 입력하세요");
                if (!content) throw new Error("내용을 입력하세요");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (!this.validates()) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                let { post, put } = api.console.center.faqs;
                let { ...form } = this.form;
                let faq = (await (this.isCreate ? post : put)(form))?.faq;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", faq);
                this.dialog = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
