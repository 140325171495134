<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1600px" width="100%" height="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">탈퇴회원 목록</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <v-card v-show="showsSearch" class="elevation-1 mx-auto">
                        <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                        <v-card-text class="py-0">
                            <v-row class="mt-4" align="center">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="filter.name" dense hide-details type="text" label="사용자이름" persistent-placeholder />
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="filter.phone" dense hide-details type="text" label="연락처" persistent-placeholder/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" rounded text @click="search"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expand-transition>

                <v-data-table :headers="usersHeaders" :items="users" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template v-slot:item.createdAt="{ item }">
                        {{ item.createdAt ? item.createdAt.toDateTime() : null }}
                    </template>
                    <template v-slot:item.withdrawnAt="{ item }">
                        {{ item.withdrawnAt ? item.withdrawnAt.toDateTime() : null }}
                    </template>
                    <template v-slot:item.phone="{ item }">
                        {{ item.phone ? item.phone.phoneNumberFormat() : null }}
                    </template>
                    <template v-slot:item.level="{ item }">
                        {{ item.level ? item.level.name : '-' }}
                    </template>
                    <template v-slot:item.type="{ item }">
                        {{ USER_TYPES[item.type].text }}
                    </template>
                    <!-- <template v-slot:item.isWarning="{ item }">
                        <v-row justify="center" align="center">
                            <v-col cols="auto" class="pa-0">
                                <v-switch v-model="item.isWarning" color="red lighten-1" hide-details dens class="mt-0"></v-switch>
                            </v-col>
                        </v-row>
                    </template> -->
                    <template v-slot:item.actions="{ item }">
                        <v-btn small text icon tile @click="edit(item)">
                            <v-icon small> mdi-pencil </v-icon>
                        </v-btn>
                        <v-btn v-if="item.type === USER_TYPES.PERSON.value" small text icon tile @click="restore(item)">
                            <v-icon small> mdi-delete </v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12 mx-4" @input="search" />
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import { USER_TYPES } from "@/assets/variables";
export default {
    data() {
        return {
            showsSearch: true,
            USER_TYPES,
            page: 1,
            pageCount: 0,
            limit: 10,
            filter: {
                name: this.$route.query.name,
                phone: this.$route.query.phone,
                _level: this.$route.query._level,
                isWarning: undefined,
                isWithdrawn: true,
                isDeleted: JSON.stringify({ $ne: true })
            },

            users: [],
            usersHeaders: [
                {
                    text: '유형',
                    align: 'center',
                    width: 120,
                    value: 'type',
                },
                {
                    text: '이름',
                    align: 'center',
                    width: 120,
                    value: 'name',
                },
                {
                    text: '닉네임',
                    align: 'center',
                    width: 160,
                    value: 'nickname'
                },
                {
                    text: '연락처',
                    align: 'center',
                    width: 128,
                    value: 'phone'
                },
                {
                    text: '이메일',
                    align: 'center',
                    width: 128,
                    value: 'email'
                },
                {
                    text: '등급',
                    align: 'center',
                    width: 140,
                    value: 'level'
                },
                // {
                //     text: '주의회원',
                //     width: 100,
                //     align: 'center',
                //     value: 'isWarning'
                // },
                {
                    text: "탈퇴사유",
                    align: "center",
                    value: "withdrawalReason"
                },
                {
                    text: "남기신말",
                    align: "center",
                    value: "withdrawalMessage"
                },
                {
                    text: '가입일자',
                    align: 'center',
                    width: 160,
                    value: 'createdAt'
                },
                {
                    text: '탈퇴일자',
                    align: 'center',
                    width: 160,
                    value: 'withdrawnAt'
                },
                {
                    align: 'center',
                    width: 100,
                    value: 'actions'
                },
            ],

            // isWarningItems: [
            //     { text: "전체", value: undefined },
            //     { text: "주의회원", value: true },
            //     { text: "미주의회원", value: false }
            // ]

        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                this.search();
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async search(){
            try{
                var { summary, users } = await api.console.users.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                        sort: JSON.stringify({ withdrawnAt: -1 })
                    },
                    params: this.filter
                });

                this.users = users;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        edit(user){
            this.$router.push(`./users/${user._id}`);
        },

        async restore(user){
            try{
                if(confirm("탈퇴회원을 제거하시겠습니까?\r회원에 대한 모든 정보가 삭제됩니다.")){
                    if(user._thumb) await api.console.files.delete({ _id: user._thumb });
                    if(user._banner) await api.console.files.delete({ _id: user._banner });

                    await api.console.users.delete({ _id: user._id });
                    alert("탈퇴회원이 제거되었습니다");
                    this.search();
                } else{
                    return
                }
            } catch(error) {
                this.$handleError(error);
            }
        }
    }
}
</script>
