<template>
    <v-dialog v-model="shows" width="320" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-card-text class="py-5">
                <v-row class="ma-n2">
                    <v-col cols="12" class="pa-2"> <v-select v-model="form.type" label="회원유형" :items="types" v-bind="attrs_input__console" /> </v-col>
                    <v-col cols="12" class="pa-2"> <v-text-field v-model="form.username" :disabled="!isCreate" label="아이디" v-bind="attrs_input__console" /> </v-col>
                    <v-col cols="12" class="pa-2"> <v-text-field v-model="form.password" label="비밀번호" v-bind="attrs_input__console" /> </v-col>
                    <v-col cols="12" class="pa-2"> <v-text-field v-model="form.name" label="이름" v-bind="attrs_input__console" /> </v-col>
                    <v-col cols="12" class="pa-2"> <v-text-field v-model="form.phone" label="연락처" v-bind="attrs_input__console" /> </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" v-bind="{ loading }" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { attrs_input__console, attrs_switch__console, initUser, USER_TYPES } from "@/assets/variables";

const types = Object.values(USER_TYPES);

export default {
    components: {},
    props: {
        _id: { type: String, default: null },
        // value: { type: Object, default: () => ({}) }, // user
    },
    data: () => ({
        form: initUser({ type: USER_TYPES.OPERATOR.value }),

        shows: false,
        loading: false,

        types,
        attrs_input__console,
        attrs_switch__console,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "회원";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) this.form = initUser({ type: USER_TYPES.OPERATOR.value });
                else {
                    let { _id } = this;
                    let { user } = await api.console.users.get({ _id });
                    this.form = initUser(user);
                }
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { password, ...form } = this.form;
                if (password) form.password = CryptoAES.encrypt(password);
                else delete form.password;

                const { post, put } = api.console.users;
                const { user } = await (this.isCreate ? post : put)(form);

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", user);
                this.shows = false;
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>
