var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.thumbSrc && _vm.isLoaded__image,
      expression: "form.thumbSrc && isLoaded__image"
    }]
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var hover = _ref.hover;
        return [_c('v-img', {
          key: _vm.key__image,
          staticClass: "mb-4",
          attrs: {
            "src": _vm.form.thumbSrc,
            "contain": "",
            "eager": ""
          },
          on: {
            "load": function ($event) {
              _vm.isLoaded__image = true;
            }
          },
          scopedSlots: _vm._u([{
            key: "placeholder",
            fn: function () {
              return [_c('v-icon', [_vm._v(" mdi-image-broke ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-fade-transition', [_c('v-overlay', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: hover,
            expression: "hover"
          }],
          attrs: {
            "absolute": ""
          }
        }, [_c('v-file-btn', {
          attrs: {
            "small": "",
            "fab": "",
            "icon": ""
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.form.image,
            callback: function ($$v) {
              _vm.$set(_vm.form, "image", $$v);
            },
            expression: "form.image"
          }
        }, [_c('v-icon', [_vm._v(" mdi-file ")])], 1), _vm.form.image ? _c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "fab": "",
            "icon": ""
          },
          on: {
            "click": _vm.reset
          }
        }, [_c('v-icon', [_vm._v(" mdi-delete ")])], 1) : _vm._e()], 1)], 1)], 1)];
      }
    }])
  })], 1)]), _c('v-select', _vm._b({
    attrs: {
      "label": "이미지"
    },
    on: {
      "change": function ($event) {
        _vm.form.thumb != _vm.form.image ? _vm.reset() : null;
      }
    },
    model: {
      value: _vm.form.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.form, "thumb", $$v);
      },
      expression: "form.thumb"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading,
    disabled: !_vm.form.board,
    items: _vm.items__thumb
  }), false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }