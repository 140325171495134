<template>
    <div>
        <template v-if="type == 'address'">
            <v-address-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" :btnAttrs="{ ...(props?.btnAttrs || {}), className: `${props?.btnAttrs?.className || ''} primary` }" @input="emit" />
        </template>
        <template v-if="type == 'bank'">
            <v-bank-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" @input="emit" />
        </template>
        <template v-if="type == 'currency'">
            <v-currency-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" @input="emit" />
        </template>
        <template v-if="type == 'date'">
            <v-text-field v-model="findData(keys)[lastKey]" type="date" v-bind="props" hide-details="auto" :key="key" @input="emit" />
        </template>
        <template v-if="type == 'dates'">
            <v-row align="center" class="row--xs" :key="key">
                <v-col> <v-text-field v-model="findData(keys)[lastKey]['startsAt']" type="date" v-bind="props" hide-details="auto" @input="emit" /> </v-col>
                <v-col cols="auto"> ~ </v-col>
                <v-col> <v-text-field v-model="findData(keys)[lastKey]['endsAt']" type="date" v-bind="props" hide-details="auto" @input="emit" /> </v-col>
            </v-row>
        </template>
        <template v-if="type == 'editor'">
            <naver-smarteditor v-model="findData(keys)[lastKey]" v-bind="props" :key="key" @input="emit" />
        </template>
        <template v-if="type == 'email'">
            <v-email-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" @input="emit" />
        </template>
        <template v-if="type == 'file'">
            <v-file-input v-model="findData(keys)[lastKey]" v-bind="props" hide-details="auto" :key="key" @change="emit" />
        </template>
        <template v-if="type == 'password'">
            <v-password-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" @input="emit" />
        </template>
        <template v-if="type == 'phone'">
            <v-phone-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" @input="emit" @certification="(value) => $emit('certification', value)" />
        </template>
        <template v-if="type == 'rrn'">
            <v-rrn-field v-model="findData(keys)[lastKey]" v-bind="props" :key="key" @input="emit" />
        </template>
        <template v-if="type == 'select'">
            <v-select v-model="findData(keys)[lastKey]" v-bind="props" hide-details="auto" :key="key" @input="emit" />
        </template>
        <template v-if="type == 'switch'">
            <v-switch v-model="findData(keys)[lastKey]" v-bind="props" inset dense hide-details="auto" class="my-10 pl-4" :key="key" @input="emit" />
        </template>
        <template v-if="type == 'text'">
            <v-text-field v-model="findData(keys)[lastKey]" v-bind="props" hide-details="auto" :key="key" @input="emit" />
        </template>
        <template v-if="type == 'textarea'">
            <v-textarea v-model="findData(keys)[lastKey]" v-bind="props" hide-details="auto" :key="key" @input="emit" />
        </template>
    </div>
</template>

<script>
import VRrnField from "@/components/plugins/vuetify/v-rrn-field.vue";
import VBankField from "@/components/plugins/vuetify/v-bank-field.vue";
import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";
import VEmailField from "@/components/plugins/vuetify/v-email-field.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    name: "vertical-form-table-item",

    components: {
        VRrnField,
        VBankField,
        VPhoneField,
        VEmailField,
        VAddressField,
        VPasswordField,
        NaverSmarteditor,
    },
    props: {
        mode: { type: String, default: "form" },
        item: { type: Object, default: () => ({}) },
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        form: {},
    }),
    computed: {
        term() {
            return this.item.term;
        },
        type() {
            return this.item.type;
        },
        key() {
            return this.item.key;
        },
        keys() {
            return this.item.keys;
        },
        lastKey() {
            return this.item.lastKey;
        },
        props() {
            return this.item.props;
        },
    },
    methods: {
        sync() {
            this.form = { ...this.value };
        },
        emit() {
            this.$emit("input", this.form);
        },
        findData(keys) {
            return keys.reduce((o, key) => o[key], this.form) || this.form;
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>