<template>
    <view-section-card title="기본정보">
        <v-card flat>
            <v-card-text>
                <v-row class="ma-n2">
                    <template v-if="hasConsoleScope">
                        <v-col cols="12" md="8" class="pa-2">
                            <basic-seller v-model="form" @input="emit" />
                        </v-col>
                        <v-col cols="12" md="4" class="pa-2">
                            <basic-share v-model="form" @input="emit" />
                        </v-col>
                    </template>
                    <v-col cols="12" md="8" class="pa-2">
                        <v-text-field v-model="form.name" label="쿠폰명" v-bind="{ ...attrs_input__console }" @input="emit" />
                    </v-col>
                    <v-col cols="12" md="4" class="pa-2">
                        <v-currency-field v-model="form.count" label="발급수량제한" suffix="개" class="text-align-right-field" v-bind="{ ...attrs_input__console }" @input="emit" />
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <basic-value v-model="form" @input="emit" />
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <basic-limit v-model="form" @input="emit" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </view-section-card>
</template>

<script>
import { mapGetters } from "vuex";

import { attrs_input__console } from "@/assets/variables/attrs";
import { initShopCoupon } from "@/assets/variables/inits";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

import BasicLimit from "./basic/basic-limit.vue";
import BasicSeller from "./basic/basic-seller.vue";
import BasicShare from "./basic/basic-share.vue";
import BasicValue from "./basic/basic-value.vue";

export default {
    components: {
        AutocompleteUser,
        ViewSectionCard,

        BasicLimit,
        BasicSeller,
        BasicShare,
        BasicValue,
    },
    props: {
        value: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        form: initShopCoupon(),

        attrs_input__console,
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopCoupon(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
