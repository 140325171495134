var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": "1080",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', _vm._b({
    scopedSlots: _vm._u([{
      key: "add-button",
      fn: function () {
        return [_c('tag-form', _vm._b({
          on: {
            "input": _vm.updateItem
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "my-n2",
                attrs: {
                  "small": "",
                  "fab": "",
                  "tile": "",
                  "color": "transparent primary--text"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            }
          }])
        }, 'tag-form', {
          code: _vm.code
        }, false))];
      },
      proxy: true
    }])
  }, 'list-heading', {
    title: _vm.title
  }, false)), _c('v-data-table', _vm._b({
    ref: "data-table",
    staticClass: "v-sheet--outlined",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "items-per-page": -1,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (props) {
        return [_vm.$refs['data-table'] ? _c('draggable', {
          attrs: {
            "tag": "tbody",
            "list": props.items,
            "disabled": !_vm.code || _vm.loading
          },
          on: {
            "change": _vm.setNewIndex
          }
        }, [_c('v-nodes', {
          attrs: {
            "vnodes": _vm.$refs['data-table'].genItems(props.items, props)
          }
        })], 1) : _vm._e()];
      }
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value,
            item = _ref2.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.product`,
      fn: function (_ref3) {
        var _value$thumb, _value$category;
        var value = _ref3.value;
        return [_c('v-list-item', {
          attrs: {
            "to": `/console/shop/products/${value === null || value === void 0 ? void 0 : value._id}`,
            "draggable": false
          }
        }, [_c('v-list-item-avatar', {
          attrs: {
            "rounded": ""
          }
        }, [_c('image-popup', {
          attrs: {
            "src": value === null || value === void 0 ? void 0 : (_value$thumb = value.thumb) === null || _value$thumb === void 0 ? void 0 : _value$thumb.url,
            "attrs_avatar": {
              rounded: true
            }
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', {
          staticClass: "caption text-truncate"
        }, [_c('span', [_vm._v(" " + _vm._s(value === null || value === void 0 ? void 0 : (_value$category = value.category) === null || _value$category === void 0 ? void 0 : _value$category.name) + " ")])]), _c('v-list-item-title', {
          staticClass: "caption text-truncate"
        }, [_c('span', [_vm._v(" " + _vm._s(value === null || value === void 0 ? void 0 : value.name) + " ")])])], 1)], 1)];
      }
    }, {
      key: `item.author.value`,
      fn: function (_ref4) {
        var _item$author, _item$author2;
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$author = item.author) === null || _item$author === void 0 ? void 0 : _item$author.value) + _vm._s(_vm.formatUnit(item === null || item === void 0 ? void 0 : (_item$author2 = item.author) === null || _item$author2 === void 0 ? void 0 : _item$author2.unit)) + " ")];
      }
    }, {
      key: `item.visitor.value`,
      fn: function (_ref5) {
        var _item$visitor, _item$visitor2;
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$visitor = item.visitor) === null || _item$visitor === void 0 ? void 0 : _item$visitor.value) + _vm._s(_vm.formatUnit(item === null || item === void 0 ? void 0 : (_item$visitor2 = item.visitor) === null || _item$visitor2 === void 0 ? void 0 : _item$visitor2.unit)) + " ")];
      }
    }, {
      key: `item.author.buy_value`,
      fn: function (_ref6) {
        var _item$author3, _item$author4;
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$author3 = item.author) === null || _item$author3 === void 0 ? void 0 : _item$author3.buy_value) + _vm._s(_vm.formatUnit(item === null || item === void 0 ? void 0 : (_item$author4 = item.author) === null || _item$author4 === void 0 ? void 0 : _item$author4.buy_unit)) + " ")];
      }
    }, {
      key: `item.duration`,
      fn: function (_ref7) {
        var _item$startsAt, _item$endsAt;
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$startsAt = item.startsAt) === null || _item$startsAt === void 0 ? void 0 : _item$startsAt.toDate()) + " ~ " + _vm._s(item === null || item === void 0 ? void 0 : (_item$endsAt = item.endsAt) === null || _item$endsAt === void 0 ? void 0 : _item$endsAt.toDate()) + " ")];
      }
    }, {
      key: `item.shows`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('v-switch', _vm._b({
          on: {
            "change": function (shows) {
              return _vm.update({
                _id: item._id,
                shows
              });
            }
          },
          model: {
            value: item.shows,
            callback: function ($$v) {
              _vm.$set(item, "shows", $$v);
            },
            expression: "item.shows"
          }
        }, 'v-switch', _vm.attrs_switch__console, false))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('tag-form', _vm._b({
          on: {
            "input": _vm.updateItem
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref10) {
              var attrs = _ref10.attrs,
                on = _ref10.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "text": "",
                  "icon": "",
                  "tile": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" mdi-pencil ")])], 1)];
            }
          }], null, true)
        }, 'tag-form', {
          _id: item._id,
          code: _vm.code
        }, false)), _c('v-btn', {
          attrs: {
            "text": "",
            "icon": "",
            "tile": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-delete ")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "my-2",
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 11,
            "color": "primary"
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    loading: _vm.loading,
    headers: _vm.headers,
    items: _vm.items
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }