<template>
    <v-card outlined flat v-bind="{ loading }">
        <v-card-title class="subtitle-1">카테고리 {{ isCreate ? "생성하기" : "수정하기" }}</v-card-title>
        <v-card-text>
            <v-row class="ma-n2">
                <v-col cols="12" class="pa-2">
                    <shop-category-select v-if="categories" v-model="form._parent" v-bind="{ ...attrs_input__console, categories, _disableds: [form._id] }" dense hideDetails />
                </v-col>
                <v-col cols="12" class="pa-2"> <v-text-field v-model="form.name" label="카테고리 이름" v-bind="attrs_input__console" /> </v-col>
                <v-col cols="12" class="pa-2"> <v-text-field v-model="form.code" label="카테고리 코드" v-bind="attrs_input__console" /> </v-col>
                <v-col cols="12" class="pa-2" v-if="isRoot">
                    <span class="caption">카테고리 이미지</span>
                    <image-input v-model="form.visual" accept="image/*" outlined :aspect-ratio="1 / 1" contain />
                </v-col>
                <!-- <v-col cols="12" class="pa-2">
                    <v-text-field v-model="form.color" label="카테고리 색상" v-bind="attrs_input__console">
                        <template #append>
                            <v-card outlined rounded="xl"> <v-avatar :color="form.color" size="24" /> </v-card>
                        </template>
                    </v-text-field>
                </v-col> -->
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-fade-transition>
                <v-btn v-show="!isCreate" :disabled="!form._id || form.root ? true : false" color="grey darken-1" class="white--text" @click="remove">삭제</v-btn>
            </v-fade-transition>
            <v-spacer />
            <v-btn color="primary" @click="save">저장</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import api from "@/api";
import { attrs_input__console, initShopCategory } from "@/assets/variables";

import ShopCategorySelect from "./shop-category-select.vue";
import ImageInput from "../../dumb/image-input.vue";

export default {
    components: {
        ShopCategorySelect,
        ImageInput,
    },
    props: {
        value: { type: Object, default: initShopCategory }, // category
        categories: { type: Array, default: null },

        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initShopCategory(),

        attrs_input__console,
    }),

    computed: {
        _category() {
            return this.form._id;
        },
        isCreate() {
            return !this._category;
        },
        isRoot() {
            return this.form.depth == 1;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopCategory(this.value);
        },
        async save() {
            if (this.loading) return;
            else this.$emit("loading", true);

            const { post, put } = api.console.shop.categories;
            try {
                let { _visual, visual, ...form } = this.form;

                delete form._brands;
                delete form.brands;
                delete form.children;

                delete form.sequence;
                delete form.createdAt;

                form = (await (this.isCreate ? post : put)(form))?.category;
                if (visual instanceof File) {
                    visual = (await api.console.files.post({ _category: form?._id, index: "visual" }, visual))?.file;
                }
                if (!!_visual && _visual != visual?._id) {
                    await api.console.files.delete({ _id: _visual });
                }
                _visual = visual?._id || null;
                await put({ ...form, _visual });

                this.loading = false;
                this.$emit("loading", false);
                this.$emit("search");
            } finally {
                if (this.loading) this.$emit("loading", false);
            }
        },
        async remove() {
            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                await api.console.shop.categories.delete(this.form);
                this.$emit("loading", false);
                this.$emit("search");
            } finally {
                if (this.loading) this.$emit("loading", false);
            }
        },
    },
};
</script>

<style></style>
