var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1440",
      "width": "100%"
    }
  }, [_c('list-heading', _vm._b({
    attrs: {
      "showsFilterButton": ""
    },
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }, 'list-heading', {
    title: _vm.title
  }, false)), _c('question-list-search', _vm._b({}, 'question-list-search', {
    showsSearch: _vm.showsSearch,
    loading: _vm.loading
  }, false)), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "items-per-page": -1,
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": "",
      "show-expand": "",
      "item-key": "_id"
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value, item))
            }
          })];
        }
      };
    }), {
      key: `item.reply`,
      fn: function (_ref2) {
        var item = _ref2.item,
          value = _ref2.value;
        return [_c('item-reply-edit', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_c('v-sheet', {
          attrs: {
            "flat": "",
            "tile": "",
            "color": "transparent"
          }
        }, [_c('v-card-text', {
          staticClass: "caption white-space-pre-line"
        }, [_vm._v(" " + _vm._s(value || "-") + " ")])], 1)], 1)];
      }
    }, {
      key: `item.repliedAt`,
      fn: function (_ref3) {
        var _value$toDateTime;
        var item = _ref3.item,
          value = _ref3.value;
        return [_c('item-replied-at-edit', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_c('v-card', {
          staticClass: "px-4 py-3 fill-height",
          attrs: {
            "flat": "",
            "tile": "",
            "color": "transparent"
          }
        }, [_vm._v(" " + _vm._s((value === null || value === void 0 ? void 0 : (_value$toDateTime = value.toDateTime) === null || _value$toDateTime === void 0 ? void 0 : _value$toDateTime.call(value)) || value || "-") + " ")])], 1)];
      }
    }, {
      key: "expanded-item",
      fn: function (_ref4) {
        var _item$files;
        var item = _ref4.item,
          headers = _ref4.headers;
        return [_c('td'), _c('td', {
          staticClass: "px-0",
          attrs: {
            "colspan": headers.length - 1
          }
        }, [_c('v-sheet', {
          staticClass: "fill-height"
        }, [_c('v-card-subtitle', {
          staticClass: "caption"
        }, [_vm._v("문의내용")]), _c('v-card-text', {
          staticClass: "white-space-pre-line"
        }, [_vm._v(" " + _vm._s(item.content) + " ")]), _c('v-card-actions', {
          staticClass: "caption px-4"
        }, [_vm._v(" 첨부파일: "), (_item$files = item.files) !== null && _item$files !== void 0 && _item$files.length ? _vm._l(item.files, function (file, index) {
          return _c('v-col', {
            key: index,
            attrs: {
              "cols": "auto"
            }
          }, [_c('a', {
            staticClass: "d-inline-block",
            attrs: {
              "href": file.src,
              "download": ""
            }
          }, [_c('v-card', {
            staticClass: "attachment-img",
            attrs: {
              "tile": ""
            }
          }, [_c('v-img', {
            attrs: {
              "src": file.src,
              "aspect-ratio": 1 / 1,
              "width": "72"
            },
            scopedSlots: _vm._u([{
              key: "placeholder",
              fn: function () {
                return [_c('v-overlay', {
                  attrs: {
                    "absolute": "",
                    "opacity": "0.1"
                  }
                }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
              },
              proxy: true
            }], null, true)
          })], 1)], 1)]);
        }) : _vm._e()], 2)], 1)], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false)), _c('v-pagination', {
    staticClass: "my-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }