<template>
    <list-search v-bind="{ loading, showsSearch }" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" lg="" class="pa-2">
                <v-text-field v-model="query.searchDateValue[0]" label="작성일(시작)" clearable v-bind="attrs_input__date__console" @input="emit" />
            </v-col>
            <v-col cols="12" sm="6" lg="" class="pa-2">
                <v-text-field v-model="query.searchDateValue[1]" label="작성일(종료)" clearable v-bind="attrs_input__date__console" @input="emit" />
            </v-col>
            <v-col cols="12" sm="6" lg="" class="pa-2" v-if="hasConsoleScope">
                <autocomplete-user label="발급주체" v-model="query._seller" placeholder="전체" clearable v-bind="props__autocompleteUser" :items="[{ _id: 'null', nickname: '플랫폼' }]" @input="emit" />
            </v-col>
            <!-- <v-col cols="12" sm="6" lg="" class="pa-2">
                <v-select v-model="query['searchKey']" :items="searchKeys" label="검색대상" placeholder="전체" clearable v-bind="attrs_input__console" />
            </v-col> -->
            <v-col cols="12" sm="6" lg="" class="pa-2">
                <v-text-field v-model="query['searchValue']" label="쿠폰명" clearable v-bind="attrs_input__console" @keydown.enter="emit" />
            </v-col>
            <!-- <v-col cols="12" sm="6" lg="3">
                <autocomplete-product label="상품" v-model="query._product" placeholder="전체" clearable v-bind="attrs_input__console" />
            </v-col> -->
        </v-row>
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-select v-model="query['usage.isActive']" label="사용여부" :items="isActiveItems" v-bind="attrs_input__console" @input="emit" />
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-select v-model="query['onDisplay']" label="숨김여부" :items="onDisplayItems" v-bind="attrs_input__console" @input="emit" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { mapGetters } from "vuex";
import { attrs_input__console, attrs_input__date__console } from "@/assets/variables/attrs";
import { USER_TYPES } from "@/assets/variables/constants";

import ListSearch from "@/components/console/dumb/list-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";

let initQuery = (query = {}) => ({
    ...query,
    ["_seller"]: query?.["_seller"] || null,
    ["_product"]: query?.["_product"] || null,
    ["searchKey"]: query?.["searchKey"] || null,
    ["searchValue"]: query?.["searchValue"] || null,
    ["searchDateValue"]: [...(query?.["searchDateValue"] || [null, null])],
    ["usage.isActive"]: JSON.parse(query?.["usage.isActive"] || "null"),
    ["onDisplay"]: JSON.parse(query?.["onDisplay"] || "null"),
});

const props__autocompleteUser = {
    ...attrs_input__console,
    params: {
        type: USER_TYPES.COMPANY.value,
    },
    itemText: ({ nickname, username }) => `${nickname}` + (username ? ` (${username})` : ""),
};

const searchKeys = [
    { text: "쿠폰이름", value: "name" },
    { text: "쿠폰코드", value: "code" },
];

const isActiveItems = [
    { text: "전체", value: null },
    { text: "사용 ", value: true },
    { text: "미사용", value: false },
];
const onDisplayItems = [
    { text: "전체", value: null },
    { text: "숨김 ", value: true },
    { text: "미숨김", value: false },
]

export default {
    components: {
        ListSearch,
        AutocompleteUser,
        AutocompleteProduct,
    },
    props: {
        loading: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
    setup: () => ({
        searchKeys,

        attrs_input__console,
        attrs_input__date__console,

        props__autocompleteUser,
    }),
    data: () => ({
        query: initQuery(),
        isActiveItems,
        onDisplayItems
    }),
    computed: {
        ...mapGetters(["hasCompanyScope", "hasConsoleScope"]),
    },
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            delete query.page;

            if (!query?.["searchDateValue"]) delete query["searchDateValue"];
            if (!query?.["searchDateValue"]?.[0] && !query?.["searchDateValue"]?.[1]) delete query["searchDateValue"];

            if (!query?.["_seller"]) delete query["_seller"];

            if (!query?.["searchKey"]) delete query["searchKey"];
            if (!query?.["searchValue"]) delete query["searchValue"];

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
