var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-field-card', {
    attrs: {
      "label": "공급받는자",
      "containerAttrs": {
        class: 'pa-0 rounded-lg',
        style: 'overflow:hidden'
      }
    }
  }, [_c('vertical-form-table', _vm._b({
    attrs: {
      "hideDivider": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }