<template>
    <v-card v-bind="$attrs">
        <v-card-text class="caption">
            <v-row class="ma-n4" style="word-break: keep-all">
                <template v-if="!items.length">
                    <v-col> 제한없음 </v-col>
                </template>
                <template v-for="({ term, desc, prefix, suffix }, index) in items">
                    <v-col cols="12" class="py-3 px-4" :class="{ 'pt-0': index }" :key="term">
                        <div class="font-weight-bold grey--text">{{ term }}</div>
                        {{ prefix || "" }} {{ desc }} {{ suffix || "" }}
                    </v-col>
                </template>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { initUsage } from "@/assets/variables";

export default {
    props: {
        usage: { type: Object, default: initUsage },
    },
    computed: {
        items() {
            let items = [];

            const { shop, user, target } = this.usage.limit;

            items.push({
                term: "전체사용수량",
                desc: shop?.count__sum,
                prefix: "최대",
                suffix: "개",
            });
            items.push({
                term: "전체사용금액",
                desc: shop?.price__sum,
                prefix: "최대",
                suffix: "원",
            });
            items.push({
                term: "회원사용수량",
                desc: user?.count__sum,
                prefix: "최대",
                suffix: "개",
            });
            items.push({
                term: "회원사용금액",
                desc: user?.price__sum,
                prefix: "최대",
                suffix: "원",
            });

            items = items.filter(({ desc }) => desc).map((item) => ({ ...item, desc: item?.desc?.format?.() }));

            if (target?.price__min || target?.price__max) {
                items.push({
                    term: "대상상품금액",
                    desc: `최소 ${target?.price__min?.() || 0} 원 ~ 최대 ${target?.price__max?.() || 0} 원`,
                });
            }

            return items;
        },
    },
};
</script>

<style>
</style>