<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%" class="px-3 mx-n3">
            <list-heading title="보너스 관리" showsFilterButton v-model="showsSearch" />
            <point-list-search v-bind="{ showsSearch }" />

            <v-data-table v-bind="{ items, headers, loading }" :items-per-page="-1" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined mt-3">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.promoter`]="{ item }">
                    <div v-if="item.promoter">{{ item.promoter.username }}</div>
                    <div v-if="item.promoter">{{ item.promoter.name }}</div>
                    <div v-if="item.promoter">{{ (item.promoter.phone || "").phoneNumberFormat() }}</div>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />

            <v-card outlined>
                <v-card-title class="subtitle-1">
                    <v-row align="center" no-gutters>
                        <v-col cols="auto" class="pr-3">보너스 적립</v-col>
                        <v-col cols="12" md="" class="caption primary--text"> + 금액 입력시 적립, - 금액 입력시 차감됩니다. </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row align="center" class="mx-n2 mb-n2">
                        <v-col cols="6" md="3" class="pa-2">
                            <autocomplete-user v-model="bonus._promoter" label="회원" :params="{ type: USER_TYPES.PROMOTER.value }" v-bind="attrs_input__console" />
                        </v-col>
                        <v-col cols="6" md="3" class="pa-2">
                            <v-currency-field v-model="bonus.amount" label="보너스" suffix="원" v-bind="attrs_input__console" />
                        </v-col>
                        <v-col cols="12" md="" class="pa-2">
                            <v-text-field v-model="bonus.remark" label="메모입력" v-bind="attrs_input__console" />
                        </v-col>
                        <v-col cols="12" md="auto" class="pa-2">
                            <v-btn block color="primary" @click="save">적립하기</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input__console, BONUS_TYPES, USER_TYPES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import PointListSearch from "@/components/console/users/points/user-point-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

const headers = [
    { width: 164, text: "지급/사용일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 180, text: "사용자", value: "promoter", cellClass: "caption" },
    { width: 480, text: "비고", value: "remark" },
    { width: 115, text: "보너스", value: "amount", formatter: (value) => value?.format?.() || 0 || "-", align: "end" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1 }));

export default {
    components: {
        ListHeading,
        PointListSearch,
        AutocompleteUser,
    },
    data: () => ({
        bonuses: [],

        limit: 20,
        summary: { totalCount: 0 },

        headers,

        loading: false,
        showsSearch: true,

        attrs_input__console,

        bonus: {
            _promoter: null,
            type: BONUS_TYPES.BONUS__ADMIN.value,
            amount: 0,
            remark: null,
        },
        USER_TYPES,
    }),
    computed: {
        items() {
            return [...this.bonuses];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                await this.search(false);
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, bonuses } = await api.console.users.bonuses.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.bonuses = bonuses;
            } finally {
                this.loading = false;
            }
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (!this.bonus._promoter) throw new Error("회원을 선택해주세요");
                if (!this.bonus.amount) throw new Error("보너스 지급량을 입력하세요");

                await api.console.users.bonuses.post(this.bonus);

                alert(+this.bonus.amount < 0 ? "보너스가 차감되었습니다" : "보너스가 지급되었습니다");
            } finally {
                this.loading = false;
                this.search();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
