var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip-group', {
    attrs: {
      "multiple": "",
      "column": "",
      "dense": "",
      "color": "primary"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.days,
      callback: function ($$v) {
        _vm.days = $$v;
      },
      expression: "days"
    }
  }, [_vm._l(_vm.items, function (_ref) {
    var text = _ref.text,
      value = _ref.value;
    return [_c('v-chip', {
      key: text,
      staticClass: "rounded-lg",
      attrs: {
        "value": value,
        "outlined": "",
        "label": ""
      }
    }, [_vm._v(" " + _vm._s(text) + " ")])];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }