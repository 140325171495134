<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">
            <list-heading title="상품문의" v-model="showsSearch" showsFilterButton />

            <inquire-list-search v-bind="{ loading, showsSearch }" />

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.product`]="{ value }">
                    <item-product :product="value" isLink />
                </template>

                <template #[`item.content`]="{ item }">
                    <v-card flat color="transparent" class="fill-height d-flex flex-column">
                        <v-card-subtitle class="caption font-weight-bold">
                            제목:
                            {{ item.subject || "-" }}
                        </v-card-subtitle>
                        <v-card-text class="caption white-space-pre-line" style="flex: 1 0 auto">
                            {{ item.content }}
                            <template v-if="item.images?.length">
                                <image-tiles :images="(item.images || [])" tilesOnly class="pt-4" />
                            </template>
                        </v-card-text>
                    </v-card>
                </template>

                <template #[`item.reply`]="{ item, value }">
                    <inquire-reply-edit :value="item" v-on="{ update }">
                        <!-- <v-card flat tile color="transparent" class="px-4 py-3 fill-height white-space-pre-line"> {{ value || "-" }} </v-card> -->
                        <div flat tile color="transparent" class="px-4 py-3 fill-height white-space-pre-line" v-html="value"/> 
                    </inquire-reply-edit>
                </template>

                <template #[`item.repliedAt`]="{ item, value }">
                    <inquire-replied-at-edit :value="item" v-on="{ update }">
                        <v-card flat tile color="transparent" class="px-4 py-3 fill-height"> {{ value?.toDateTime?.() || value || "-" }} </v-card>
                    </inquire-replied-at-edit>
                </template>

                <template #[`item.hides`]="{ item }">
                    <v-switch v-model="item.hides" v-bind="attrs_switch__console" @change="(hides) => update({ _id: item._id, hides })" class="d-inline-block ml-n1" />
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch__console, initDataTableHeaders } from "@/assets/variables";

import ImageTiles from "@/components/dumb/image-tiles/image-tiles.vue";
import ItemProduct from "@/components/console/dumb/item-product.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import InquireReplyEdit from "@/components/console/shop/inquires/inquire-reply-edit.vue";
import InquireListSearch from "@/components/console/shop/inquires/inquire-list-search.vue";
import InquireRepliedAtEdit from "@/components/console/shop/inquires/inquire-replied-at-edit.vue";

const headers = initDataTableHeaders([
    { width: 100, text: "작성일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 140, text: "작성자 / 아이디", value: "user", formatter: ({ name, username, phone } = {}) => [name, username, phone].filter((item) => item).join("\n") },
    {
        text: "입점사",
        value: "product.seller",
        formatter: ({ nickname, username } = {}) => `${nickname} (${username})`,
        width: 140,
        test: (scope) => scope.includes("console"),
    },
    { width: 240, text: "상품", value: "product", cellClass: "pa-0 max-width-0" },
    { width: 320, text: "내용", value: "content", cellClass: "pa-0" },
    { width: 320, text: "답변", value: "reply", cellClass: "pa-0" },
    { width: 100, text: "답변일시", value: "repliedAt", cellClass: "pa-0" },
    { width: +80, text: "블라인드", value: "hides", align: "center" },
]);

export default {
    components: {
        ImageTiles,
        ItemProduct,
        ListHeading,
        InquireReplyEdit,
        InquireListSearch,
        InquireRepliedAtEdit,
    },
    data: () => ({
        inquires: [],

        limit: 20,
        summary: { totalCount: 0 },

        headers,
        loading: false,
        showsSearch: true,

        attrs_switch__console,
    }),
    computed: {
        items() {
            return this.inquires.map((item, index) => ({ ...item, index: index + 1 }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            if (query.searchDateValue) query.searchDateKey = "createdAt";

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.inquires = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, inquires } = await api.console.shop.inquires.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.inquires = inquires;
            } finally {
                this.loading = false;
            }
        },

        async update(inquire) {
            if (this.loading) return;
            else this.loading = true;
            try {
                inquire = (await api.console.shop.inquires.put(inquire))?.inquire;
                this.updateItem(inquire);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(inquire) {
            const index = this.inquires.findIndex(({ _id }) => _id == inquire._id);
            if (0 <= index) this.inquires.splice(index, 1, inquire);
            else {
                this.inquires = [inquire, ...this.inquires];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
