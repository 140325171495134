var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [!_vm.hideDivider ? _c('v-divider') : _vm._e(), _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.items, function (item, index) {
    var _item$cols;
    return _c('v-col', {
      key: index,
      staticClass: "d-flex flex-column",
      attrs: {
        "cols": "12",
        "lg": (_item$cols = item === null || item === void 0 ? void 0 : item.cols) !== null && _item$cols !== void 0 ? _item$cols : _vm.defaultCols
      }
    }, [_vm.isArray(item) ? [_c('v-row', {
      staticClass: "ma-0",
      attrs: {
        "no-gutters": "",
        "align": "stretch"
      }
    }, [_vm._l(item, function (child, index) {
      return [_c('v-col', {
        key: child.term
      }, [_c('vertical-table-row', _vm._b({}, 'vertical-table-row', child, false), [child.data ? [_vm._v(" " + _vm._s(child.data) + " ")] : _vm.$slots[child.term] ? _vm._t(child.term) : child.node ? _c('v-node', {
        attrs: {
          "node": child.node
        }
      }) : _vm._e()], 2)], 1), index != item.length - 1 ? _c('v-col', {
        key: `divider=${index}`,
        attrs: {
          "cols": "auto"
        }
      }, [_c('v-divider', {
        attrs: {
          "vertical": ""
        }
      })], 1) : _vm._e()];
    })], 2)] : [_c('vertical-table-row', _vm._b({}, 'vertical-table-row', item, false), [item.data ? [_vm._v(" " + _vm._s(item.data) + " ")] : _vm.$slots[item.term] ? _vm._t(item.term) : item.node ? _c('v-node', {
      attrs: {
        "node": item.node
      }
    }) : _vm._e()], 2)]], 2);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }