<template>
    <v-data-table v-bind="{ items, headers, loading }" disable-filtering disable-pagination disable-sort hide-default-footer :items-per-page="-1" :class="{ 'v-sheet--outlined': mode == 'form', 'view-mode': mode != 'form' }" class="shipping-form-options-table" ref="data-table">
        <template #top v-if="mode == 'form'">
            <view-section-title title="옵션정보">
                <template #actions>
                    <v-btn text icon color="primary" @click="push()"> <v-icon>mdi-plus</v-icon> </v-btn>
                </template>
            </view-section-title>
            <v-divider />
        </template>

        <template #[`header._region`]="{ header }" v-if="mode == 'form'">
            <shop-shipping-regions-form v-bind="{ seller }" @updated="initRegions">
                <template #activator="{ attrs, on }">
                    <v-card v-bind="attrs" v-on="on" flat tile class="d-table-cell vertical-align-middle px-4" height="48" width="100%" style="color: rgba(0, 0, 0, 0.6)">
                        <span> {{ header.text }} </span>
                        <v-icon small> mdi-cog </v-icon>
                    </v-card>
                </template>
            </shop-shipping-regions-form>
        </template>

        <template #[`header._method`]="{ header }" v-if="mode == 'form'">
            <shop-shipping-methods-form v-bind="{ seller }" @updated="initMethods">
                <template #activator="{ attrs, on }">
                    <v-card v-bind="attrs" v-on="on" flat tile class="d-table-cell vertical-align-middle px-4" height="48" width="100%" style="color: rgba(0, 0, 0, 0.6)">
                        <span> {{ header.text }} </span>
                        <v-icon small> mdi-cog </v-icon>
                    </v-card>
                </template>
            </shop-shipping-methods-form>
        </template>

        <template #body="props" v-if="mode == 'form'">
            <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" @change="setNewIndex" :sort="!loading">
                <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
            </draggable>
        </template>

        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

        <template #[`item.index`]="{ value }">
            <div style="margin-top: 15px">{{ value }}</div>
        </template>
        <template #[`item._region`]="{ item, index }">
            <item-region :value="item" v-bind="{ mode, seller }" @input="updateItem" :ref="`shop-shipping-regions-${index}`" />
        </template>
        <template #[`item._method`]="{ item, index }">
            <item-method :value="item" v-bind="{ mode, seller }" @input="updateItem" :ref="`shop-shipping-methods-${index}`" />
        </template>
        <template #[`item.bundle.type`]="{ item }">
            <item-bundle-type :value="item" v-bind="{ mode }" @input="updateItem" />
        </template>
        <template #[`item.charge.type`]="{ item }">
            <item-charge-type :value="item" v-bind="{ mode }" @input="updateItem" />
        </template>
        <template #[`item.charge`]="{ item }">
            <item-charge :value="item" v-bind="{ mode }" @input="updateItem" />
        </template>
        <template #[`item.island`]="{ item }">
            <item-island :value="item" v-bind="{ mode }" @input="updateItem" />
        </template>
        <template #[`item.service`]="{ item }">
            <item-service :value="item" v-bind="{ mode }" @input="updateItem" />
        </template>

        <template #[`item.areas__available`]="{ item }">
            <item-areas-available :value="item" @input="updateItem" />
        </template>

        <template #[`item.actions`]="{ item }">
            <v-btn text icon color="red" style="margin-top: 6px" @click="pull(item)">
                <v-icon> mdi-minus </v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { initDataTableHeaders, initShopShipping, initShopShippingOption } from "@/assets/variables/inits";

import Draggable from "vuedraggable";

import ViewSectionTitle from "@/components/console/dumb/view-section-title.vue";

import ItemAreasAvailable from "./options/item-areas-available.vue";
import ItemBundleType from "./options/item-bundle-type.vue";
import ItemChargeType from "./options/item-charge-type.vue";
import ItemCharge from "./options/item-charge.vue";
import ItemIsland from "./options/item-island.vue";
import ItemMethod from "./options/item-method.vue";
import ItemRegion from "./options/item-region.vue";
import ItemService from "./options/item-service.vue";

import ShopShippingMethodsForm from "@/components/console/shop/shippings/methods/shop-shipping-methods-form.vue";
import ShopShippingRegionsForm from "@/components/console/shop/shippings/regions/shop-shipping-regions-form.vue";

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },

        Draggable,

        ViewSectionTitle,

        ItemAreasAvailable,
        ItemBundleType,
        ItemChargeType,
        ItemCharge,
        ItemIsland,
        ItemMethod,
        ItemRegion,
        ItemService,

        ShopShippingMethodsForm,
        ShopShippingRegionsForm,
    },
    props: {
        value: { type: Object, default: initShopShipping },

        mode: { type: String, default: "form" },
    },
    data: () => ({
        form: initShopShipping(),

        loading: false,
    }),
    computed: {
        ...mapState(["user"]),
        ...mapGetters(["hasCompanyScope"]),
        seller() {
            return this.form.seller || {};
        },
        items() {
            return this.form.options.map((item, index) => ({ ...item, index: index + 1 }));
        },
        headers() {
            return initDataTableHeaders(
                [
                    {
                        value: "index",
                        text: "#",
                        align: "end",
                        width: +50,
                        cellClass: "px-4",
                        hides: this.mode != "form",
                    },
                    {
                        value: "_region",
                        text: "배송그룹",
                        width: 160,
                        class: this.mode == "form" ? "pa-0" : undefined,
                    },
                    {
                        value: "_method",
                        text: "배송수단",
                        width: 160,
                        class: this.mode == "form" ? "pa-0" : undefined,
                    },
                    {
                        value: "bundle.type",
                        text: "묶음방식",
                        width: 120,
                    },
                    {
                        value: "charge.type",
                        text: "요금유형",
                        width: 120,
                    },
                    {
                        value: "charge",
                        text: "요금상세",
                        width: 310,
                    },
                    {
                        value: "island",
                        text: "도서산간\n추가요금",
                        width: 160,
                    },
                    // {
                    //     value: "service",
                    //     text: "설치서비스\n추가요금",
                    //     width: 160,
                    // },
                    // {
                    //     value: "areas__available",
                    //     text: "배송가능지역",
                    //     width: 160,
                    // },
                    {
                        value: "actions",
                        text: "",
                        cellClass: "px-4",
                        width: 68,
                        align: "end",
                        shows: this.mode == "form",
                    },
                ].map((item) => ({ ...item, cellClass: `${item.cellClass || "py-0 px-1"} vertical-align-top` }))
            ).filter(({ shows }) => shows ?? true);
        },
    },
    methods: {
        sync() {
            this.form = initShopShipping(this.value);
        },
        emit() {
            this.$emit("input", initShopShipping(this.form));
        },
        push(item = initShopShippingOption()) {
            this.form.options.push(item);
            this.emit();
        },
        pull(item) {
            const index = this.form.options.findIndex(({ tempId }) => tempId == item.tempId);
            if (0 <= index) {
                this.form.options.splice(index, 1);
            }
            this.emit();
        },
        updateItem(item) {
            const index = this.form.options.findIndex(({ tempId }) => tempId == item.tempId);
            if (0 <= index) {
                this.form.options.splice(index, 1, item);
            } else {
                this.form.options.push(item);
            }
            this.emit();
        },

        async setNewIndex({ moved = {} }) {
            if (this.loading) return;
            this.loading = true;

            try {
                let { oldIndex, newIndex } = moved;
                this.form.options.splice(newIndex, 0, ...this.form.options.splice(oldIndex, 1));

                this.emit();
            } finally {
                this.loading = false;
            }
        },

        async initRegions() {
            for (const [index] of this.items.entries()) {
                await this.$refs[`shop-shipping-regions-${index}`]?.init?.();
            }
        },
        async initMethods() {
            for (const [index] of this.items.entries()) {
                await this.$refs[`shop-shipping-methods-${index}`]?.init?.();
            }
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style lang="scss" scoped>
.shipping-form-options-table.view-mode {
    height: 100%;
    background-color: transparent;

    ::v-deep {
        .v-data-table__wrapper,
        .v-data-table__wrapper > table {
            height: 100% !important;
        }
    }
}
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    th:last-child,
    td:last-child {
        border-right: none !important;
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
    .vertical-align-middle {
        vertical-align: middle !important;
    }

    .v-input__slot {
        background: transparent !important;
    }

    input[inputmode="decimal"] {
        text-align: right;
    }
}
</style>
