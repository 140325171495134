<template>
    <v-dialog v-model="dialog" width="560" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-5">
                <v-row class="ma-n2">
                    <v-col cols="12" class="pa-2" v-if="!code">
                        <v-select v-model="form.code" label="위치" :items="types" v-bind="{ ...attrs_input__console, loading }" :disabled="isCreate" />
                    </v-col>
                    <v-col cols="12" md="2" class="pa-2">
                        <v-text-field v-model="form.index" label="#" v-bind="{ ...attrs_input__console, loading }" :disabled="isCreate" />
                    </v-col>
                    <v-col cols="12" md="10" class="pa-2">
                        <autocomplete-product v-model="form._product" label="상품" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-datetime-field v-model="form.startsAt" label="전시시작" class="pa-0" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-datetime-field v-model="form.endsAt" label="전시종료" class="pa-0" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-switch v-model="form.shows" dense v-bind="attrs_switch__console">
                        <span slot="label" class="subtitle-2"> {{ form.shows ? "노출" : "미노출" }} </span>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input__console, attrs_switch__console, DISPLAY_CODES } from "@/assets/variables";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";

const types = Object.values(DISPLAY_CODES);

const initDisplay = (display = {}) => ({
    ...display,

    _product: display._product || null,

    index: display.index || null,
    shows: display.shows || false,

    startsAt: display.startsAt || null,
    endsAt: display.endsAt || null,
});

export default {
    components: {
        VDatetimeField,
        AutocompleteProduct,
    },
    props: {
        _id: { type: String, default: null },
        code: { type: String, default: null },
    },
    data: () => ({
        form: initDisplay(),

        attrs_input__console,
        attrs_switch__console,

        types,
        dialog: false,
        loading: false,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "상품전시";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initDisplay({ code: this.code });
                } else {
                    let { _id } = this;
                    this.form = (await api.console.shop.displays.get({ _id }))?.display;
                    this.$emit("input", { ...this.form });
                }
            } finally {
                this.loading = false;
            }
        },
        validates() {
            try {
                let { code, _product } = this.form;

                if (!code) throw new Error("위치를 선택하세요");
                if (!_product) throw new Error("상품을 선택하세요");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (!this.validates()) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                let { post, put } = api.console.shop.displays;
                let { ...form } = this.form;
                let display = (await (this.isCreate ? post : put)(form))?.display;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", display);
                this.dialog = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
