<template>
    <v-sheet :class="attachClass" style="position: relative">
        <v-menu ref="menu" :close-on-content-click="false" :return-value.sync="time" transition="scale-transition" min-width="290px" max-width="290px" offset-y :attach="attach ? `.${attachClass}` : false">
            <template #activator="{ on }">
                <v-text-field :value="time" append-icon="mdi-clock-outline" readonly v-bind="$attrs" v-on="on" @click:append="on.click" class="pt-0" />
            </template>
            <v-time-picker v-model="time" full-width @click:minute="$refs.menu.save(time)" />
        </v-menu>
    </v-sheet>
</template>

<script>
export default {
    props: {
        value: { type: String, default: "00:00" },
        attach: { type: Boolean, default: false },
    },
    data() {
        return {
            time: this.$props.value,
            attachClass: `v-time-field-activator-${Math.random().toString().slice(2)}`,
        };
    },
    watch: {
        value() {
            this.time = this.value;
        },
        time(){
            this.$emit('input',this.time)
        }
    },
    methods: {
    },
};
</script>