<template>
    <v-sheet>
        <v-expand-transition>
            <div v-show="form.thumbSrc && isLoaded__image">
                <v-hover v-slot="{ hover }">
                    <v-img :src="form.thumbSrc" contain eager class="mb-4" :key="key__image" @load="isLoaded__image = true">
                        <template #placeholder>
                            <v-icon> mdi-image-broke </v-icon>
                        </template>
                        <v-fade-transition>
                            <v-overlay v-show="hover" absolute>
                                <v-file-btn v-model="form.image" small fab icon @input="emit">
                                    <v-icon> mdi-file </v-icon>
                                </v-file-btn>
                                <v-btn v-if="form.image" small fab icon class="ml-2" @click="reset">
                                    <v-icon> mdi-delete </v-icon>
                                </v-btn>
                            </v-overlay>
                        </v-fade-transition>
                    </v-img>
                </v-hover>
            </div>
        </v-expand-transition>
        <v-select
            v-model="form.thumb"
            label="이미지"
            v-bind="{
                ...attrs_input__console,

                loading,
                disabled: !form.board,

                items: items__thumb,
            }"
            @change="form.thumb != form.image ? reset() : null"
        />
    </v-sheet>
</template>

<script>
import { attrs_input__console } from "@/assets/variables";
import { initStoryBoardDisplay } from "@/store/story";

import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";

export default {
    components: {
        VFileBtn,
    },
    props: {
        value: { type: Object, default: initStoryBoardDisplay },

        loading: { type: Boolean, default: false },

        attrs_input__console: { type: Object, default: () => attrs_input__console },
    },
    data: () => ({
        form: initStoryBoardDisplay(),

        key__image: 1,
        isLoaded__image: false,
    }),
    computed: {
        items__thumb() {
            const items = [];
            if (this.form.image) {
                items.push({
                    text: "0. 커스텀 이미지",
                    value: this.form?.image,
                });
            }

            return items.concat(
                this.form.board?.showcases?.map((showcase, index) => ({
                    text: `${index + 1}. ${showcase?.image?.name}`,
                    value: showcase?.image,
                    _stage: showcase?._id,
                }))
            );
        },
    },
    methods: {
        sync() {
            this.form = initStoryBoardDisplay(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
        reset() {
            delete this.form.image;
            this.emit();
        },
    },
    watch: {
        value() {
            this.sync();
        },
        "value.thumbSrc"() {
            this.isLoaded__image = false;
            this.key__image += 1;
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>