<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%">
            <list-heading v-bind="{ title }" v-model="showsSearch" showsFilterButton />

            <form-list-search v-bind="{ showsSearch, loading }" />

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer show-expand single-expand item-key="_id" class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value, item)" /> </template>
                
                <template #[`item.state`]="{ item, value }">
                    <form-state-edit :value="item" v-on="{ update }">
                        <v-card flat tile color="transparent" class="pa-4">
                            {{ FORM_STATES[value]?.text || "-" }}
                        </v-card>
                    </form-state-edit>
                </template>

                <template #[`item.memo`]="{ item, value }">
                    <form-memo-edit :value="item" v-on="{ update }">
                        <v-card flat tile color="transparent" class="px-4 py-2 my-n2 fill-height"> {{ value || "-" }} </v-card>
                    </form-memo-edit>
                </template>

                <template #expanded-item="{ item, headers }">
                    <td />
                    <td :colspan="headers.length - 1" >
                        <v-sheet>
                            <v-row>
                                <v-col cols="auto">
                                    <v-card-subtitle class="caption">홈페이지 주소</v-card-subtitle>
                                </v-col>
                                <v-divider vertical />
                                <v-col>
                                    <v-card-text class="white-space-pre-line">
                                        {{ item.homepage || "-" }}
                                    </v-card-text>
                                </v-col>
                                <v-divider vertical />
                                <v-col cols="auto">
                                    <v-card-subtitle class="caption">이메일</v-card-subtitle>
                                </v-col>
                                <v-divider vertical />
                                <v-col>
                                    <v-card-text class="white-space-pre-line">
                                        {{ item.writer.email || "-" }}
                                    </v-card-text>
                                </v-col>
                                <v-divider vertical />
                                <v-col cols="auto">
                                    <v-card-subtitle class="caption">전화번호</v-card-subtitle>
                                </v-col>
                                <v-divider vertical />
                                <v-col>
                                    <v-card-text class="white-space-pre-line">
                                        {{ item.writer.tel || "-" }}
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </td>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { initDataTableHeaders, FORM_STATES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import FormMemoEdit from "@/components/console/forms/form-memo-edit.vue";
import FormStateEdit from "@/components/console/forms/form-state-edit.vue";
import FormListSearch from "@/components/console/forms/form-list-search.vue";

const headers = initDataTableHeaders([
    { width: 100, align: "center", text: "작성일자", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-"  },
    { width: 160, align: "center",  text: "업체명", value: "company"},
    { width: 200, align: "center", text: "담당자명 / 연락처", value: "writer", formatter: ({ name, phone } = {}) => `${name} / ${phone}`},
    { width: +90, align: "center", text: "처리상태", value: "state", cellClass: "px-0" },
    { width: 320, align: "center", text: "내용", value: "content", cellClass: "pa-0"  },
    { width: 220, align: "center", text: "메모", value: "memo", cellClass: "px-0" },
    // { width: 160, align: "center", text: "이메일", value: "writer.email"  },
    // { width: 160, align: "center", text: "전화번호", value: "writer.tel"  },
    // { width: 160, align: "center", text: "홈페이지주소", value: "homepage"  },
]);

export default {
    components: {
        ListHeading,
        FormListSearch,
        FormStateEdit,
        FormMemoEdit,
    },
    data: () => ({
        FORM_STATES,
        contacts: [],
        expanded: [],

        limit: 20,
        summary: { totalCount: 0 },

        headers,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        title() {
            return "Contact Us";
        },
        items() {
            return this.contacts;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            if (query.searchDateValue) query.searchDateKey = "createdAt";

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.contacts = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, contacts } = await api.console.center.contacts.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.contacts = contacts;
            } finally {
                this.loading = false;
            }
        },

        async update(contact) {
            if (this.loading) return;
            else this.loading = true;

            try {
                contact = (await api.console.center.contacts.put(contact))?.contact;
                alert("저장되었습니다");
                this.updateItem(contact);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(contact) {
            const index = this.contacts.findIndex(({ _id }) => _id == contact._id);
            if (0 <= index) this.contacts.splice(index, 1, contact);
            else {
                this.contacts = [contact, ...this.contacts];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
