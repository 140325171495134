<template>
    <list-search v-bind="{ showsSearch }" @search="setQuery">
        <v-row align="center">
            <v-col cols="12" sm="6" md="3" lg="">
                <v-text-field v-model="query.createdAt[0]" type="date" label="주문일자(시작)" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="">
                <v-text-field v-model="query.createdAt[1]" type="date" label="주문일자(종료)" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col v-if="hasConsoleScope" cols="12" sm="6" md="3" lg="">
                <autocomplete-company label="검색대상" v-model="query._seller" :itemText="(value)=> `${value.companyName} (${value.username})`" placeholder="전체" clearable v-bind="attrs_input__console" @input="setQuery"/>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="">
                <v-select label="검색대상" v-model="query.searchKey" :items="searchKeys" placeholder="전체" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="">
                <v-text-field label="검색어" v-model="query.searchValue" clearable v-bind="attrs_input__console" @keydown.enter="setQuery" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { mapGetters } from "vuex";
import { attrs_input__console } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";
import AutocompleteCompany from "@/components/dumb/autocomplete-company.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["createdAt"]: query?.["createdAt"] || [null, null],

    ["searchKey"]: query?.["searchKey"] || null,
    ["searchValue"]: query?.["searchValue"] || null,
});

const searchKeys = [
    { text: "수취인명", value: "receiver.name" },
    { text: "구매자명", value: "sender.name" },
    { text: "구매자연락처", value: "sender.phone" },
    { text: "구매자ID", value: "sender.username" },
    { text: "주문번호", value: "orderNo" },
    { text: "상품주문번호", value: "purchaseNo" },
];

export default {
    components: {
        ListSearch,
        AutocompleteCompany
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        searchKeys,

        attrs_input__console,
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
    },
    mounted() {
        this.sync();
    },

    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        setQuery() {
            const { ...query } = this.query || {};

            if (!query?.["createdAt"]) delete query.createdAt;
            if (!query?.["createdAt"]?.[0] && !query?.createdAt?.[1]) delete query.createdAt;

            if (!query?.["searchKey"]) delete query.searchKey;
            if (!query?.["searchValue"]) delete query.searchValue;

            query.page = 1;

            console.log(query);

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
