var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "기본정보"
    }
  }, [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_vm.hasConsoleScope ? _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('autocomplete-user', _vm._b({
    attrs: {
      "label": "입점사",
      "returnObject": "",
      "disabled": !!_vm.form._id
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.seller,
      callback: function ($$v) {
        _vm.$set(_vm.form, "seller", $$v);
      },
      expression: "form.seller"
    }
  }, 'autocomplete-user', _vm.props__autocompleteUser, false))], 1) : _vm._e(), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "정책명"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": _vm.hasConsoleScope ? 6 : 12
    }
  }, [_c('v-datetime-field', _vm._b({
    attrs: {
      "label": "사용시작",
      "placeholder": "시작일시 없음",
      "padding": "0"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.startsAt,
      callback: function ($$v) {
        _vm.$set(_vm.form, "startsAt", $$v);
      },
      expression: "form.startsAt"
    }
  }, 'v-datetime-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": _vm.hasConsoleScope ? 6 : 12
    }
  }, [_c('v-datetime-field', _vm._b({
    attrs: {
      "label": "사용종료",
      "placeholder": "종료일시 없음",
      "padding": "0"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.endsAt,
      callback: function ($$v) {
        _vm.$set(_vm.form, "endsAt", $$v);
      },
      expression: "form.endsAt"
    }
  }, 'v-datetime-field', _vm.attrs_input__console, false))], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }