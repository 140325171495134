var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "유효기간 설정"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          attrs: {
            "disabled": _vm.disabled
          },
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.expire.isActive,
            callback: function ($$v) {
              _vm.$set(_vm.form.expire, "isActive", $$v);
            },
            expression: "form.expire.isActive"
          }
        }, 'v-switch', _vm.attrs_switch__console, false))];
      },
      proxy: true
    }])
  }, [_c('v-card', {
    attrs: {
      "disabled": !_vm.form.expire.isActive,
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "결제기간(시작)",
      "clearable": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.expire.startsAt,
      callback: function ($$v) {
        _vm.$set(_vm.form.expire, "startsAt", $$v);
      },
      expression: "form.expire.startsAt"
    }
  }, 'v-text-field', _vm.attrs_input__date__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "결제기간(종료)",
      "clearable": "",
      "messages": "※ 미입력 시 상시 사용"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.expire.endsAt,
      callback: function ($$v) {
        _vm.$set(_vm.form.expire, "endsAt", $$v);
      },
      expression: "form.expire.endsAt"
    }
  }, 'v-text-field', _vm.attrs_input__date__console, false))], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }