<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%" class="px-3 mx-n3">
            <list-heading :title="ANALYTICS_VISITS_TYPES[type].text"/>
            <visits-analytics-search v-model="params" v-bind="{ showsSearch, loading, hasConsoleScope }" @input="sync" />

            <v-row class="mt-n3"> </v-row>

            <v-card class="my-4">
                <v-card-title>
                    <v-row>
                        <v-col cols="auto" class="subtitle-1">월별 총합</v-col>
                    </v-row>
                </v-card-title>
                <v-divider class="mx-3"></v-divider>
                <v-card-text class="pa-0">
                    <v-responsive width="100%" height="400">
                        <v-chart :options="dailyWeeklyOptions" />
                    </v-responsive>
                </v-card-text>
            </v-card>

            <v-data-table v-bind="{ items, headers, loading }" :items-per-page="-1" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value, item)" /> </template>

                <template v-for="(month, index) in Object.keys(ANALYTICS_MONTHS_OF_YEAR)" #[`item.${month}`]="{ item }">
                    {{ countOfMonths(item.months, index + 1) }}
                </template>
            </v-data-table>

            <!-- <v-pagination :value="params.page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => (params = { ...params, page })" /> -->
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import ECharts from "vue-echarts";
import dayjs from "@/plugins/vue-dayjs";
import { ANALYTICS_MONTHS_OF_YEAR, ANALYTICS_VISITS_TYPES } from "@/assets/variables";
import { initDataTableHeaders } from "@/assets/variables/inits";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import AnalyticsPeriodFilter from "@/components/console/analytics/analytics-period-filter.vue";
import VisitsAnalyticsSearch from "@/components/console/analytics/visits/visits-analytics-search.vue";

export default {
    components: {
        "v-chart": ECharts,
        ListHeading,
        AnalyticsPeriodFilter,
        VisitsAnalyticsSearch,
    },
    props: {
        type: { type: String, default: null },
    },
    data: () => ({
        loading: false,
        showsSearch: true,
        ANALYTICS_MONTHS_OF_YEAR,
        ANALYTICS_VISITS_TYPES,

        analytics: [],
        summary: {
            totalCount: 0,
        },
        params: {
            _seller: null,
            // page: 1,
        },
        // limit: 5,
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
        items() {
            return [...this.analytics];
        },
        headers() {
            return initDataTableHeaders([
                { text: "일자", class: "white-space-pre-line", value: "year", width: "100px" },
                ...Object.values(ANALYTICS_MONTHS_OF_YEAR).map(({text, value}) => ({ text, value })),
                { text: "평균", value: "average", formatter: (value) => value?.format() },
                { text: "전체", value: "total", formatter: (value) => value?.format() },
                
            ]);
        },
        // skip() {
        //     return (this.params.page - 1) * this.limit;
        // },
        // pageCount() {
        //     return Math.ceil(this.summary.totalCount / this.limit);
        // },
        dailyWeeklyOptions() {
            return {
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                xAxis: {
                    type: 'category',
                    data: Object.values(ANALYTICS_MONTHS_OF_YEAR).map(({ text }) => text),
                },
                yAxis: {
                    type: 'value',
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [{
                    data: this.analytics.flatMap(({ months }) => months).reduce((acc, { month, count }) => {
                        acc[month - 1] = (acc[month - 1] || 0) + count;
                        return acc;
                    }, Array(7).fill(0)),
                    type: 'line',
                    areaStyle: {}
                }],
            }
        },
    },
    methods: {
        async init(){
            const { params } = this;
            if(!this.hasConsoleScope) params._seller = this.$store.state.payload._user;

            this.search();
        },
        async search() {
            if (this.loading) return;
            this.loading = true;
            try {
                const { params, skip, limit, type } = this;
                const { summary, analytics } = await api.console.analytics.getVisits({
                    // headers: { skip, limit },
                    params: {
                        ...params,
                        type
                    },
                });
                this.summary = summary;
                this.analytics = analytics;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        sync(value) {
            this.params = value;
        },
        countOfMonths(months, monthOfYear){
            return months.find(({ month })=>month == monthOfYear)?.count || 0;
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.init();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
.echarts {
  width: 100%;
  height: 100%;
}
</style>