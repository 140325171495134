<template>
    <v-btn-toggle v-model="status" @change="push" dense tile>
        <template v-for="{ text, value } in statuses">
            <v-btn :key="value" v-bind="{ value }">{{ text }}</v-btn>
        </template>
    </v-btn-toggle>
</template>

<script>
import { EVENT_STATUS } from "@/assets/variables";

const statuses = Object.values(EVENT_STATUS);

export default {
    data: () => ({
        statuses,
        status: statuses[0].value,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query.group"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.status = this.$route.query.status || statuses[0].value;
        },
        push() {
            const { ...query } = this.$route.query;

            delete query.page;
            query.status = this.status;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
