<template>
    <list-search v-bind="{ loading, showsSearch }" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-text-field v-model="query.searchDateValue[0]" type="date" max="2999-12-31" label="작성일(시작)" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-text-field v-model="query.searchDateValue[1]" type="date" max="2999-12-31" label="작성일(종료)" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <autocomplete-board label="게시글" v-model="query['_board']" :sort="{ subject: 1, _id: -1 }" placeholder="전체" clearable v-bind="{ ...attrs_input__console, params: boardParams }" v-on="{ emit }" />
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <autocomplete-user label="작성자" v-model="query['writer._id']" :itemText="({ name, username }) => `${name} (${username})`" :sort="{ name: 1, username: 1, _id: -1 }" placeholder="전체" clearable v-bind="{ ...attrs_input__console }" v-on="{ emit }" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input__console } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteBoard from "@/components/dumb/autocomplete-board.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["searchDateValue"]: [...(query?.["searchDateValue"] || [null, null])],

    ["_board"]: query?.["_board"] || null,
    ["writer._id"]: query?.["writer._id"] || null,
});

export default {
    components: {
        ListSearch,
        AutocompleteUser,
        AutocompleteBoard,
    },
    props: {
        loading: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
        boardCode: { type: String, default: null },
        boardCategory: { type: String, default: null },
    },
    data: () => ({
        query: initQuery(),

        attrs_input__console,
    }),
    computed: {
        boardParams() {
            const { boardCode, boardCategory } = this;

            const params = { code: boardCode, category: boardCategory };

            if (!boardCode) delete params.code;
            if (!boardCategory) delete params.category;

            return params;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            delete query.page;

            if (!query?.["searchDateValue"]) delete query["searchDateValue"];
            if (!query?.["searchDateValue"]?.[0] && !query?.["searchDateValue"]?.[1]) delete query["searchDateValue"];

            if (!query?.["_board"]) delete query["_board"];
            if (!query?.["writer._id"]) delete query["writer._id"];

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
