<template>
    <v-select
        v-model="form._products"
        label="상품"
        v-bind="{
            ...attrs_input__console,

            items: items__products,
            itemText: 'name',
            itemValue: '_id',

            multiple: true,

            smallChips: true,
            deletableChips: true,

            loading,
            disabled: !form.board,
        }"
        @change="emit"
    >
        <template #selection="{ item, index }">
            <draggable
                :list="form._products"
                group="form-products-chips"
                v-bind="{
                    id: index,
                    move,
                }"
                v-on="{
                    change,
                }"
            >
                <v-chip :key="item._id" draggable close @click:close="pull(index)" @mousedown.stop @click.stop>
                    {{ item.name }}
                </v-chip>
            </draggable>
        </template></v-select
    >
</template>

<script>
import { attrs_input__console } from "@/assets/variables";
import { initStoryBoardDisplay } from "@/store/story";

import Draggable from "vuedraggable";

const initTemp = (temp = {}) => ({
    ...temp,
    oldIndex: temp.oldIndex ?? null,
    newIndex: temp.newIndex ?? null,
});

export default {
    components: {
        Draggable,
    },
    props: {
        value: { type: Object, default: initStoryBoardDisplay },

        loading: { type: Boolean, default: false },

        attrs_input__console: { type: Object, default: () => attrs_input__console },
    },
    data: () => ({
        form: initStoryBoardDisplay(),

        temp: initTemp(),
    }),
    computed: {
        items__products() {
            return this.form.board?.showcases?.reduce(
                (items, { tags }) =>
                    (tags || [])?.reduce((items, { product }) => {
                        if (!items.some((item) => item._id == product._id)) {
                            items.push(product);
                        }
                        return items;
                    }, items),
                []
            );
        },
    },
    methods: {
        sync() {
            this.form = initStoryBoardDisplay(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
        pull(index) {
            this.form._products.splice(index, 1);
            this.emit();
        },
        move({ from, to }) {
            this.temp = initTemp({
                oldIndex: from.id,
                newIndex: to.id,
            });
        },
        change({ removed }) {
            if (!removed) return;

            this.form._products.splice(this.temp.newIndex, 0, ...this.form._products.splice(this.temp.oldIndex, 1));

            this.emit();
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>