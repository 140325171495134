var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.form ? _c('vertical-table', _vm._b({
    attrs: {
      "items": _vm.computedItems
    }
  }, 'vertical-table', {
    itemsPerRow: _vm.itemsPerRow,
    hideDivider: _vm.hideDivider
  }, false), [_vm._l(_vm.computedItems, function (item, i) {
    return [_vm.isArray(item) ? [_vm._l(item, function (child, j) {
      return [_vm.$slots[child.term] ? [_vm._t(child.term, null, {
        "slot": child.term,
        "value": _vm.findData(child.keys)[child.lastKey]
      })] : _vm.mode == 'form' ? [_c('vertical-form-table-item', _vm._b({
        key: `item-${i}-${j}`,
        attrs: {
          "slot": child.term
        },
        on: {
          "input": _vm.emit
        },
        slot: child.term,
        model: {
          value: _vm.$data.form,
          callback: function ($$v) {
            _vm.$set(_vm.$data, "form", $$v);
          },
          expression: "$data.form"
        }
      }, 'vertical-form-table-item', {
        mode: _vm.mode,
        item: child
      }, false))] : _vm._e()];
    })] : [_vm.$slots[item.term] ? [_vm._t(item.term, null, {
      "slot": item.term,
      "value": _vm.findData(item.keys)[item.lastKey]
    })] : _vm.mode == 'form' ? [_c('vertical-form-table-item', _vm._b({
      key: `item-${i}`,
      attrs: {
        "slot": item.term
      },
      on: {
        "input": _vm.emit
      },
      slot: item.term,
      model: {
        value: _vm.$data.form,
        callback: function ($$v) {
          _vm.$set(_vm.$data, "form", $$v);
        },
        expression: "$data.form"
      }
    }, 'vertical-form-table-item', {
      mode: _vm.mode,
      item
    }, false))] : _vm._e()]];
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }