<template>
    <v-layout justify-center>
        <v-responsive :max-width="hasConsoleScope ? 2160 : 1080" width="100%">
            <list-heading v-bind="{ title }" v-model="showsSearch" showsFilterButton>
                <template #add-button v-if="hasCompanyScope">
                    <money-withdrawal-form @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </money-withdrawal-form>
                </template>
            </list-heading>

            <money-withdrawal-search v-bind="{ showsSearch, loading }" />

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer item-key="_id" class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value, item)" /> </template>

                <template #[`item.user`]="{ item, value, header }">
                    <v-row no-gutters>
                        <v-col class="pr-4 text-truncate">{{ header.formatter(value) }} </v-col>
                        <v-col cols="auto">
                            <v-btn x-small text icon color="primary" :to="{ query: { _user: item._user } }">
                                <v-icon>mdi-filter-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>

                <template #[`item.memo`]="{ item, value }">
                    <form-memo-edit :value="item" v-on="{ update }">
                        <v-card flat tile color="transparent" class="fill-height d-flex align-center justify-center"> {{ value || "-" }} </v-card>
                    </form-memo-edit>
                </template>

                <template #[`item.isCanceled`]="{ item }">
                    <v-switch v-model="item.isCanceled" v-bind="attrs_switch__console" :disabled="hasCompanyScope && item.isWithdrawn" @change="(isCanceled) => update({ _id: item._id, isCanceled })" />
                </template>
                <template #[`item.isWithdrawn`]="{ item }">
                    <v-switch v-model="item.isWithdrawn" v-bind="attrs_switch__console" @change="(isWithdrawn) => update({ _id: item._id, isWithdrawn })" />
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import { initDataTableHeaders } from "@/assets/variables/inits";
import { attrs_switch__console } from "@/assets/variables/attrs";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import FormMemoEdit from "@/components/console/forms/form-memo-edit.vue";
import MoneyWithdrawalForm from "@/components/console/users/money-withdrawals/money-withdrawal-form.vue";
import MoneyWithdrawalSearch from "@/components/console/users/money-withdrawals/money-withdrawal-search.vue";

export default {
    components: {
        ListHeading,

        FormMemoEdit,
        MoneyWithdrawalForm,
        MoneyWithdrawalSearch,
    },
    data: () => ({
        withdrawals: [],

        limit: 20,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,

        attrs_switch__console,
    }),
    computed: {
        ...mapGetters(["hasCompanyScope", "hasConsoleScope"]),
        title() {
            return "예치금 출금 신청 목록";
        },
        items() {
            return this.withdrawals;
        },
        headers() {
            const items = initDataTableHeaders([
                {
                    text: "#",
                    value: "code",
                    width: 160,
                },
                {
                    text: "회원",
                    value: "user",
                    width: 300,
                    cellClass: "caption text-truncate max-width-0",
                    withTitle: true,
                    formatter: (value) => {
                        const { nickname, username } = value;
                        return `${nickname || "-"} (${username})`;
                    },
                    shows: this.hasConsoleScope,
                },
                {
                    text: "비고",
                    value: "remark",
                },
                {
                    text: "금액",
                    value: "amount",
                    width: 160,
                    align: "right",
                    formatter: (value) => `${(value || 0)?.format?.()}원`,
                },
                {
                    text: "신청일시",
                    value: "createdAt",
                    width: 110,
                    formatter: (value) => value?.toDateTime?.() || value || "-",
                },
                {
                    text: "처리일시",
                    value: "processedAt",
                    width: 110,
                    formatter: (value) => value?.toDateTime?.() || value || "-",
                },
                {
                    text: "처리상태",
                    value: "state",
                    width: 100,
                },
                {
                    text: "취소하기",
                    value: "isCanceled",
                    width: 100,
                },
                {
                    text: "승인하기",
                    value: "isWithdrawn",
                    width: 100,
                    shows: this.hasConsoleScope,
                },
                {
                    text: "메모",
                    value: "memo",
                    width: 180,
                    cellClass: "px-0",
                    shows: this.hasConsoleScope,
                },
            ]);
            return items.filter(({ shows }) => shows ?? true);
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            if (this.hasCompanyScope) {
                query.searchKey = "remark";
            }

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.withdrawals = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, withdrawals } = await api.console.users.moneys.withdrawals.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.withdrawals = withdrawals;
            } finally {
                this.loading = false;
            }
        },

        async update(withdrawal) {
            if (this.loading) return;
            else this.loading = true;

            try {
                withdrawal = (await api.console.users.moneys.withdrawals.put(withdrawal))?.withdrawal;
                alert("저장되었습니다");
                this.updateItem(withdrawal);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(withdrawal) {
            const index = this.withdrawals.findIndex(({ _id }) => _id == withdrawal._id);
            if (0 <= index) this.withdrawals.splice(index, 1, withdrawal);
            else {
                this.withdrawals = [withdrawal, ...this.withdrawals];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
        height: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    th:last-child,
    td:last-child {
        border-right: none !important;
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
