var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', _vm._l(_vm.categories, function (category, index) {
    return _c('v-row', {
      key: index
    }, [_c('v-col', [_c('sales-analytics-view', _vm._b({}, 'sales-analytics-view', {
      category
    }, false))], 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }