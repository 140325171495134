var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold grey--text text--darken-2"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', _vm._b({
    staticClass: "text-truncate pr-3"
  }, 'v-col', {
    title: _vm.title
  }, false), [_vm._v(_vm._s(_vm.title))]), _c('v-col', {
    staticClass: "my-n3",
    attrs: {
      "cols": _vm.actionCols
    }
  }, [_vm._t("actions", null, null, {
    title: _vm.title
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }