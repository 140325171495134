<template>
    <v-card outlined>
        <v-card-text class="white pa-0">
            <v-select v-model="query['isPending']" v-bind="{ ...attrs_input__console, items }" solo flat :outlined="false" class="caption" @change="emit" />
        </v-card-text>
    </v-card>
</template>

<script>
import { attrs_input__console } from "@/assets/variables";

const initQuery = (query = {}) => ({
    ...query,

    ["isPending"]: JSON.parse(query["isPending"] || "null"),
});

const items = [
    { text: "전체 예치금", value: null },
    { text: "예정 예치금", value: true },
    { text: "확정 예치금", value: false },
];

export default {
    setup: () => ({
        items,
        attrs_input__console,
    }),
    data: () => ({
        query: initQuery(),
    }),
    methods: {
        sync() {
            this.query = initQuery({ ...this.$route.query });
        },
        emit() {
            const { ...query } = this.query;

            delete query["page"];

            if (query["isPending"] == null) delete query["isPending"];

            this.$router.push({ query });
        },
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>