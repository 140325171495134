var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', _vm._b({
    attrs: {
      "title": "검색엔진최적화(SEO)",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.seoEnabled,
            callback: function ($$v) {
              _vm.$set(_vm.form, "seoEnabled", $$v);
            },
            expression: "form.seoEnabled"
          }
        }, 'v-switch', _vm.attrs_switch__console, false))];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.seoEnabled,
      expression: "form.seoEnabled"
    }]
  }, [_c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "타이틀: Title"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.seo.title,
      callback: function ($$v) {
        _vm.$set(_vm.form.seo, "title", $$v);
      },
      expression: "form.seo.title"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "메타태그: Author"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.author.content,
      callback: function ($$v) {
        _vm.$set(_vm.author, "content", $$v);
      },
      expression: "author.content"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "label": "메타태그: Description"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.decription.content,
      callback: function ($$v) {
        _vm.$set(_vm.decription, "content", $$v);
      },
      expression: "decription.content"
    }
  }, 'v-textarea', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "label": "메타태그: Keywords"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.keywords.content,
      callback: function ($$v) {
        _vm.$set(_vm.keywords, "content", $$v);
      },
      expression: "keywords.content"
    }
  }, 'v-textarea', _vm.attrs_input__console, false))], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }