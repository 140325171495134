<template></template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(["payload", "scope"]),
    },
    methods: {
        scopeIncludes(criterion) {
            return this.payload?.scope?.includes?.(criterion);
        },

        redirect() {
            if (this.scopeIncludes("console")) this.$router.replace({ path: "/console/dashboard" });
            if (this.scopeIncludes("company")) this.$router.replace({ path: "/console/dashboard" });
        },
    },
    watch: {
        scope() {
            this.redirect();
        },
    },
    mounted() {
        this.redirect();
    },
};
</script>
