var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1660",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "상품리뷰",
      "showsFilterButton": ""
    },
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }), _c('review-list-search', _vm._b({}, 'review-list-search', {
    showsSearch: _vm.showsSearch,
    loading: _vm.loading
  }, false)), _c('v-data-table', _vm._b({
    ref: "data-table",
    staticClass: "v-sheet--outlined",
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.product`,
      fn: function (_ref2) {
        var value = _ref2.value;
        return [_c('item-product', {
          attrs: {
            "product": value,
            "isLink": ""
          }
        })];
      }
    }, {
      key: `item.content`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('item-review', {
          staticClass: "fill-height d-flex flex-column",
          attrs: {
            "review": item,
            "flat": "",
            "color": "transparent"
          }
        })];
      }
    }, {
      key: `item.reply`,
      fn: function (_ref4) {
        var item = _ref4.item,
          value = _ref4.value;
        return [_c('item-reply-edit', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_c('v-card', {
          staticClass: "px-4 py-3 fill-height white-space-pre-line",
          attrs: {
            "flat": "",
            "tile": "",
            "color": "transparent"
          }
        }, [_vm._v(" " + _vm._s(value || "-") + " ")])], 1)];
      }
    }, {
      key: `item.repliedAt`,
      fn: function (_ref5) {
        var _value$toDateTime;
        var item = _ref5.item,
          value = _ref5.value;
        return [_c('item-replied-at-edit', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_c('v-card', {
          staticClass: "px-4 py-3 fill-height",
          attrs: {
            "flat": "",
            "tile": "",
            "color": "transparent"
          }
        }, [_vm._v(" " + _vm._s((value === null || value === void 0 ? void 0 : (_value$toDateTime = value.toDateTime) === null || _value$toDateTime === void 0 ? void 0 : _value$toDateTime.call(value)) || value || "-") + " ")])], 1)];
      }
    }, {
      key: `item.isFixed`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('v-switch', _vm._b({
          on: {
            "change": function (isFixed) {
              return _vm.update({
                _id: item._id,
                isFixed
              });
            }
          },
          model: {
            value: item.isFixed,
            callback: function ($$v) {
              _vm.$set(item, "isFixed", $$v);
            },
            expression: "item.isFixed"
          }
        }, 'v-switch', _vm.attrs_switch__console, false))];
      }
    }, {
      key: `item.fixedIndex`,
      fn: function (_ref7) {
        var _value$format;
        var item = _ref7.item,
          value = _ref7.value;
        return [_c('review-fixed-index-edit', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_c('v-card', {
          staticClass: "px-4 py-3 fill-height",
          attrs: {
            "flat": "",
            "tile": "",
            "color": "transparent"
          }
        }, [_vm._v(" " + _vm._s((value === null || value === void 0 ? void 0 : (_value$format = value.format) === null || _value$format === void 0 ? void 0 : _value$format.call(value)) || value || "-") + " ")])], 1)];
      }
    }, {
      key: `item.hides`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('v-switch', _vm._b({
          staticClass: "d-inline-block ml-n1",
          on: {
            "change": function (hides) {
              return _vm.update({
                _id: item._id,
                hides
              });
            }
          },
          model: {
            value: item.hides,
            callback: function ($$v) {
              _vm.$set(item, "hides", $$v);
            },
            expression: "item.hides"
          }
        }, 'v-switch', _vm.attrs_switch__console, false))];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "my-2",
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 11,
            "color": "primary"
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    loading: _vm.loading,
    headers: _vm.headers,
    items: _vm.items
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }