var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": "1080",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "사이트 관리"
    }
  }), _c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "outlined": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "py-0 subtitle-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("SEO(검색어 최적화 설정)")])], 1)], 1), _c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "타이틀: Title"
    },
    on: {
      "input": function ($event) {
        _vm.meta('title').content = _vm.setting.seo.title;
      }
    },
    model: {
      value: _vm.setting.seo.title,
      callback: function ($$v) {
        _vm.$set(_vm.setting.seo, "title", $$v);
      },
      expression: "setting.seo.title"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "메타태그: Title",
      "disabled": ""
    },
    model: {
      value: _vm.meta('title').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('title'), "content", $$v);
      },
      expression: "meta('title').content"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "메타태그: Author"
    },
    model: {
      value: _vm.meta('author').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('author'), "content", $$v);
      },
      expression: "meta('author').content"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "메타태그: Subject"
    },
    model: {
      value: _vm.meta('subject').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('subject'), "content", $$v);
      },
      expression: "meta('subject').content"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "메타태그: Copyright"
    },
    model: {
      value: _vm.meta('copyright').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('copyright'), "content", $$v);
      },
      expression: "meta('copyright').content"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "label": "메타태그: Description"
    },
    model: {
      value: _vm.meta('description').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('description'), "content", $$v);
      },
      expression: "meta('description').content"
    }
  }, 'v-textarea', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "label": "메타태그: Keywords"
    },
    model: {
      value: _vm.meta('keywords').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('keywords'), "content", $$v);
      },
      expression: "meta('keywords').content"
    }
  }, 'v-textarea', _vm.attrs_input__console, false))], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "무통장입금 계좌연동"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          on: {
            "change": _vm.save
          },
          model: {
            value: _vm.setting.shop.cashReceipt.enabled,
            callback: function ($$v) {
              _vm.$set(_vm.setting.shop.cashReceipt, "enabled", $$v);
            },
            expression: "setting.shop.cashReceipt.enabled"
          }
        }, 'v-switch', _vm.attrs_switch__console, false))];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "은행",
      "items": _vm.banks,
      "item-text": "name",
      "item-value": "code"
    },
    model: {
      value: _vm.setting.shop.accountBankCode,
      callback: function ($$v) {
        _vm.$set(_vm.setting.shop, "accountBankCode", $$v);
      },
      expression: "setting.shop.accountBankCode"
    }
  }, 'v-select', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "예금주"
    },
    model: {
      value: _vm.setting.shop.accountHolder,
      callback: function ($$v) {
        _vm.$set(_vm.setting.shop, "accountHolder", $$v);
      },
      expression: "setting.shop.accountHolder"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "계좌번호"
    },
    model: {
      value: _vm.setting.shop.accountNumber,
      callback: function ($$v) {
        _vm.$set(_vm.setting.shop, "accountNumber", $$v);
      },
      expression: "setting.shop.accountNumber"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1)], 1)], 1)], 1), _c('view-section-card', {
    staticClass: "mt-4",
    attrs: {
      "title": "관리자 비밀번호 변경"
    }
  }, [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "type": "password",
      "label": "비밀번호",
      "autocomplete": "new-password"
    },
    model: {
      value: _vm.admin.password,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "password", $$v);
      },
      expression: "admin.password"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "type": "password",
      "label": "비밀번호 확인",
      "autocomplete": "new-password"
    },
    model: {
      value: _vm.admin.passwordRepeat,
      callback: function ($$v) {
        _vm.$set(_vm.admin, "passwordRepeat", $$v);
      },
      expression: "admin.passwordRepeat"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.saveAdmin
    }
  }, [_vm._v("저장")])], 1)], 1), _c('view-section-card', {
    staticClass: "mt-4",
    attrs: {
      "title": "금지 키워드 설정"
    }
  }, [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "label": "금지 키워드",
      "placeholder": ",(쉼표)로 구분해주세요"
    },
    model: {
      value: _vm.setting.bannedKeywords,
      callback: function ($$v) {
        _vm.$set(_vm.setting, "bannedKeywords", $$v);
      },
      expression: "setting.bannedKeywords"
    }
  }, 'v-textarea', _vm.attrs_input__console, false))], 1)], 1)], 1)], 1)], 1)], 1), _c('v-btn', _vm._b({
    attrs: {
      "fixed": "",
      "right": "",
      "bottom": "",
      "fab": "",
      "color": "primary",
      "elevation": "1"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('v-icon', [_vm._v("mdi-content-save")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }