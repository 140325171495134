var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "480",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "light": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "명칭"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "코드"
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "URL"
    },
    model: {
      value: _vm.form.href,
      callback: function ($$v) {
        _vm.$set(_vm.form, "href", $$v);
      },
      expression: "form.href"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', _vm._b({
    attrs: {
      "label": "노출",
      "dense": "",
      "color": "secondary"
    },
    model: {
      value: _vm.form.display.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.form.display, "enabled", $$v);
      },
      expression: "form.display.enabled"
    }
  }, 'v-switch', _vm.attrs_switch__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', _vm._b({
    attrs: {
      "label": "기간",
      "dense": "",
      "color": "secondary"
    },
    model: {
      value: _vm.form.display.hasPeriod,
      callback: function ($$v) {
        _vm.$set(_vm.form.display, "hasPeriod", $$v);
      },
      expression: "form.display.hasPeriod"
    }
  }, 'v-switch', _vm.attrs_switch__console, false))], 1)], 1), _c('v-expand-transition', {
    model: {
      value: _vm.form.display.hasPeriod,
      callback: function ($$v) {
        _vm.$set(_vm.form.display, "hasPeriod", $$v);
      },
      expression: "form.display.hasPeriod"
    }
  }, [_c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.display.hasPeriod,
      expression: "form.display.hasPeriod"
    }],
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-datetime-field', _vm._b({
    attrs: {
      "label": "시작일",
      "padding": "0"
    },
    model: {
      value: _vm.form.display.startedAt,
      callback: function ($$v) {
        _vm.$set(_vm.form.display, "startedAt", $$v);
      },
      expression: "form.display.startedAt"
    }
  }, 'v-datetime-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-datetime-field', _vm._b({
    attrs: {
      "label": "종료일",
      "padding": "0"
    },
    model: {
      value: _vm.form.display.endedAt,
      callback: function ($$v) {
        _vm.$set(_vm.form.display, "endedAt", $$v);
      },
      expression: "form.display.endedAt"
    }
  }, 'v-datetime-field', _vm.attrs_input__console, false))], 1)], 1)], 1), _c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('span', {
    staticClass: "caption"
  }, [_vm._v("이미지(PC)")]), _c('image-input', {
    attrs: {
      "outlined": "",
      "flat": "",
      "width": "100%",
      "aspect-ratio": 1 / 1
    },
    model: {
      value: _vm.form.imagePc,
      callback: function ($$v) {
        _vm.$set(_vm.form, "imagePc", $$v);
      },
      expression: "form.imagePc"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('span', {
    staticClass: "caption"
  }, [_vm._v("이미지(모바일)")]), _c('image-input', {
    attrs: {
      "outlined": "",
      "flat": "",
      "width": "100%",
      "aspect-ratio": 1 / 1
    },
    model: {
      value: _vm.form.imageMb,
      callback: function ($$v) {
        _vm.$set(_vm.form, "imageMb", $$v);
      },
      expression: "form.imageMb"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }