<template>
    <view-field-card label="공급자" :containerAttrs="{ class: 'pa-0 rounded-lg', style: 'overflow:hidden' }">
        <vertical-form-table v-model="form" v-bind="{ items }" hideDivider @input="emit" />
    </view-field-card>
</template>

<script>
import { attrs_input__verticalTable, initShopTaxinvoice } from "@/assets/variables";

import ViewFieldCard from "@/components/console/dumb/view-field-card.vue";
import VerticalFormTable from "@/components/console/dumb/vertical-form-table.vue";

const items = [
    [
        {
            key: "invoicer.invoicerCorpNum",
            term: "등록번호",
            type: "text",
            readonly: true,
        },
        {
            key: "invoicer.invoicerTaxRegID",
            term: "종사업장번호",
            type: "text",
            readonly: true,
        },
    ],
    [
        {
            key: "invoicer.invoicerCorpName",
            term: "상호 (법인명)",
            type: "text",
            readonly: true,
        },
        {
            key: "invoicer.invoicerCEOName",
            term: "성명",
            type: "text",
            readonly: true,
        },
    ],
    {
        key: "invoicer.invoicerAddr",
        term: "사업장",
        type: "text",
        readonly: true,
    },
    [
        {
            key: "invoicer.invoicerBizType",
            term: "업태",
            type: "text",
            readonly: true,
        },
        {
            key: "invoicer.invoicerBizClass",
            term: "종목",
            type: "text",
            readonly: true,
        },
    ],
    {
        key: "invoicer.invoicerEmail",
        term: "이메일",
        type: "text",
        readonly: true,
    },
].map(function mapItem(item, index, array) {
    const hideDivider = item.hideDivider ?? index == array.length - 1;
    if (Array.isArray(item)) return item.map((item, index, array) => mapItem({ ...item, hideDivider }, index, array));

    return {
        ...attrs_input__verticalTable,

        dense: true,
        divider: true,
        hideDivider,

        class: "text-caption",

        ...item,

        rowProps: {
            class: "ma-0 text-caption",
            align: "center",
            noGutters: true,
        },

        termProps: {
            class: "px-3",
            style: "max-width:120px",
            ...(item.termProps || {}),
        },
    };
});

export default {
    components: {
        VerticalFormTable,
        ViewFieldCard,
    },
    props: {
        value: { type: Object, default: initShopTaxinvoice },
    },
    data: () => ({
        form: initShopTaxinvoice(),

        items,
    }),
    methods: {
        sync() {
            this.form = initShopTaxinvoice(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>