<template>
    <view-section-card title="알람 템플릿">
        <v-card flat>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-text-field v-model="form.name" label="알람 이름" v-bind="{ ...attrs_input__console }" />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="form.code" label="알람 코드" v-bind="{ ...attrs_input__console }" />
                    </v-col>
                    <v-col cols="4">
                        <v-select v-model="form.type" :items="alarmTypeItems" label="알람 유형" v-bind="{ ...attrs_input__console }" />
                    </v-col>
                    <v-col cols="4">
                        <v-select v-model="form.category" :items="alarmCategoryItems" label="알람 구분" v-bind="{ ...attrs_input__console }" />
                    </v-col>
                    <v-col cols="4">
                        <v-select v-model="form.target" :items="alarmTargetItems" label="알람 대상" v-bind="{ ...attrs_input__console }" />
                    </v-col>
                    <v-col v-if="!isTalk" cols="12">
                        <v-text-field v-model="form.url" label="URL" placeholder="/mypage/dashboard (도메인명 제거)" v-bind="{ ...attrs_input__console }" />
                    </v-col>
                    <v-col v-if="isTalk" cols="4">
                        <v-text-field v-model="form.templateCode" label="템플릿 코드" v-bind="{ ...attrs_input__console }" />
                    </v-col>
                    <v-col v-if="isTalk" cols="4">
                        <v-text-field v-model="form.receiverName" label="수신자명" v-bind="{ ...attrs_input__console }" />
                    </v-col>
                    <v-col v-if="isTalk" cols="4">
                        <v-text-field v-model="form.receiverNumber" label="수신자 번호" v-bind="{ ...attrs_input__console }" />
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox v-if="isTalk" v-model="form.sendAtFail" class="ma-0" label="발송실패 시 문자발송"/>
                        <v-textarea v-model="form.template" label="내용" v-bind="{ ...attrs_input__console }" rows="12" clearable />
                    </v-col>
                    <v-col cols="6">
                        <v-data-table :headers="headers" :items="dataItems" :items-per-page=5 disable-sort class="v-sheet--outlined">
                            <template #[`item.text`]="{ item }">
                                <span>{{ item.text }}</span>
                                <v-icon x-small class="ml-2" @click="copy($event)">mdi-content-copy</v-icon>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-row class="row--xxs">
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn v-bind="{ ...btn_primary, loading }" :disabled="isCreate" class="v-btn--width-fixed" @click="save">{{ isCreate ? "저장" : "수정" }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </view-section-card>
</template>

<script>
import api from "@/api";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import { attrs_input__console, btn_primary } from "@/assets/variables/attrs";
import { initDataTableHeaders, ALARM_CATEGORIES, ALARM_TYPES, ALARM_TARGETS, ALARM_VARIABLES_COMMUNITY, ALARM_VARIABLES_PRODUCT, ALARM_VARIABLES_POINT, ALARM_VARIABLES_MONEY, ALARM_VARIABLES_EVENT } from "@/assets/variables";
import { initAlarmTemplate } from '@/pages/console/shop/alarms/AlarmList.vue';

const alarmCategoryItems = Object.values(ALARM_CATEGORIES);
const alarmTypeItems = Object.values(ALARM_TYPES);
const alarmTargetItems = Object.values(ALARM_TARGETS);

export default {
    components: {
        ViewSectionCard,
    },
    props:{
        value: { type: Object, default: ()=> initAlarmTemplate() }
    },
    data: () => ({
        attrs_input__console,
        btn_primary,
        loading: false,
        alarmCategoryItems,
        alarmTypeItems,
        alarmTargetItems,

        form: initAlarmTemplate(),
    }),
    computed: {
        headers() {
            return initDataTableHeaders([
                { text: "변수명", value: "value" },
                { text: "설명", value: "text" },
            ]);
        },
        isCreate() {
            return !this.form._id;
        },
        dataItems(){
            switch(this.form.category){
                case ALARM_CATEGORIES.PRODUCT.value:
                    return ALARM_VARIABLES_PRODUCT;
                case ALARM_CATEGORIES.COMMUNITY.value:
                    return ALARM_VARIABLES_COMMUNITY;
                case ALARM_CATEGORIES.EVENT.value:
                    return ALARM_VARIABLES_EVENT;
                case ALARM_CATEGORIES.POINT.value:
                    return ALARM_VARIABLES_POINT;
                case ALARM_CATEGORIES.MONEY.value:
                    return ALARM_VARIABLES_MONEY;
            }
        },
        isTalk() {
            return this.form.type === ALARM_TYPES.TALK.value;
        },
    },
    methods: {
        async save() {
            if(this.loading) return;
            else this.loading = true;
            try {
                const { put, post } = api.console.shop.alarms;
                const { alarm } = await (this.isCreate ? post: put)(this.form);

                this.form = initAlarmTemplate(alarm);
                this.$emit("save");
                this.loading = false;
            } catch(error){
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        copy(event) {
            var element = event.target.previousSibling;
            var range;
            if (document.selection) {
                // IE
                range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (window.getSelection) {
                range = document.createRange();
                range.selectNode(element);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
            document.execCommand("copy");

            if (document.selection) document.selection.empty();
            else if (window.getSelection) window.getSelection().removeAllRanges();
        },
    },
    watch: {
        value() {
            this.form = this.value;
        }
    }
}
</script>

<style>

</style>