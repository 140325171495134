<template>
    <v-layout justify-center>
        <v-responsive :max-width="1440" class="px-3 mx-n3">
            <list-heading :title="title">
                <template #buttons>
                    <v-row class="ma-n2">
                        <v-spacer />
                        <v-col class="pa-2" cols="auto">
                            <analytics-period-filter v-model="params" @input="sync" />
                        </v-col>
                        <!-- <v-col class="pa-2" cols="3">
                        <sales-analytics-sort-field v-model="params" @input="sync" />
                    </v-col>
                    <v-col class="pa-2" cols="3">
                        <sales-analytics-sort v-model="params" @input="sync" />
                    </v-col> -->
                    </v-row>
                </template>
            </list-heading>
            <banners-analytics-search v-model="params" v-bind="{ showsSearch }" @input="sync" />

            <v-row class="mt-n3"> </v-row>

            <v-data-table v-bind="{ items, headers, loading }" :items-per-page="-1" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value, item)" /> </template>
                <template #[`item.popup`]="{ item }">
                    <v-row v-if="item" align="center">
                        <v-col md="auto">
                            <image-popup v-if="item.image" :src="item.image" :attrs_avatar="{ size: '40', rounded: true }"/>
                            <v-responsive v-else width="40" height="40">
                                <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                    <v-icon>mdi-image-broken-variant</v-icon>
                                </v-row>
                            </v-responsive>
                        </v-col>
                        <v-col>
                            <div class="white-space-pre-line cursor-pointer" @click="$router.push(`/console/popups`)">
                                {{ `${item?.name || "-"}` }}
                            </div>
                        </v-col>
                    </v-row>
                </template>
                <template #[`item.link`]="{ item }">
                    <a :href="item.url" target="_blank">{{ item.url }}</a>
                </template>
                <template #[`item.status`]="{ item }">
                    {{ getStatus(item) }}
                </template>
            </v-data-table>

            <v-pagination :value="params.page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => (params = { ...params, page })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import dayjs from "@/plugins/vue-dayjs";
import { ANALYTICS_PERIOD_FILTER } from "@/assets/variables";
import { initDataTableHeaders } from "@/assets/variables/inits";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import AnalyticsPeriodFilter from "../analytics-period-filter.vue";
import BannersAnalyticsSearch from "./banners-analytics-search.vue";

export default {
    components: {
        ImagePopup,
        ListHeading,
        AnalyticsPeriodFilter,
        BannersAnalyticsSearch,
    },
    data: () => ({
        loading: false,
        showsSearch: true,

        analytics: [],
        summary: {
            totalCount: 0,
        },
        params: {
            period: ANALYTICS_PERIOD_FILTER.DAILY.value,
            searchKey: "name",
            searchValue: null,
            sortField: "viewCount",
            sortType: -1,
            page: 1,
        },
        limit: 5,
    }),
    computed: {
        items() {
            return [...this.analytics];
        },
        headers() {
            return initDataTableHeaders([
                // { text: "제목", class: "white-space-pre-line", value: "name", width: "240px" },
                { text: "제목", value: "popup", width: "240px" },
                { text: "URL", value: "link", width: "100px" },
                { text: "클릭수", value: "viewCount", width: "100px", align: "center" },
                { text: "ALL", value: "totalCount", width: "100px", align: "center" },
                { text: "등록일자", value: "createdAt", width: "100px", align: "center", formatter: (_, item) => `${item.createdAt.toDate()}` },
                { text: "상태", value: "status", width: "100px", align: "center" },
            ]);
        },
        skip() {
            return (this.params.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit);
        },
        sort() {
            const { sortField, sortType } = this.params;
            if (!!sortField && !!sortType) return JSON.stringify({ [sortField]: sortType });
            return null;
        },
        code() {
            return "POPUP";
        },
        title() {
            return "팝업";
        },
    },
    methods: {
        async search() {
            if (this.loading) return;
            this.loading = true;
            try {
                const { params, skip, limit, sort } = this;
                const { summary, analytics } = await api.console.analytics.getBanners({
                    headers: { skip, limit, sort },
                    params: {
                        ...params,
                        code: this.code,
                    },
                });
                this.summary = summary;
                this.analytics = analytics.map((item) => ({
                    ...item,
                    image: /<img [^\>]*src=["']([^\>\s]+)["'][^\>]*\/*>/.exec(item.content)?.[1] || null,
                }));
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        getStatus(item) {
            const { enabled, period, startDate, endDate } = item?.display;
            const isDeleted = item?.isDeleted;
            if (!enabled || isDeleted) return "종료";

            if (!period) return "게시중";

            const now = dayjs();
            const isBetween = now.isAfter(dayjs(startDate)) && now.isBefore(dayjs(endDate));

            return isBetween ? "게시중" : "종료";
        },
        sync(value) {
            this.params = value;
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        params() {
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .cursor-pointer {
        cursor: pointer;
    }
}
</style>