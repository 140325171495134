var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-combobox', _vm._g(_vm._b({
    attrs: {
      "multiple": "",
      "item-text": "name",
      "append-icon": "",
      "placeholder": "항목 작성 후 Enter키를 입력해주세요"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          parent = _ref.parent;
        return [_c('v-chip', {
          attrs: {
            "outlined": "",
            "label": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }]),
    model: {
      value: _vm.form.values,
      callback: function ($$v) {
        _vm.$set(_vm.form, "values", $$v);
      },
      expression: "form.values"
    }
  }, 'v-combobox', _vm.attrs_input__verticalTable, false), {
    input: _vm.input
  }));

}
var staticRenderFns = []

export { render, staticRenderFns }