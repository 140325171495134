<template>
    <v-dialog v-model="dialog" width="560" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-5">
                <v-row>
                    <v-col cols="12" md="2" class="pa-2">
                        <v-text-field v-model="form.index" label="#" v-bind="{ ...attrs_input__console, loading }" :disabled="isCreate" />
                    </v-col>
                    <v-col cols="12" md="10" class="pa-2">
                        <autocomplete-product v-model="form._product" label="상품" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-date-field v-model="form.startsAt" label="시작일" class="pa-0" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-date-field v-model="form.endsAt" label="종료일" class="pa-0" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                </v-row>
                <v-divider />
                * 비율은 소수점 한 자리까지만 저장됩니다.
                <v-row>
                    <v-col cols="12" md="8" class="pa-2">
                        <v-text-field v-model="form.author.value" label="작성자 포인트" type="number" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                    <v-col cols="12" md="4" class="pa-2">
                        <v-select v-model="form.author.unit" :items="units" label="단위" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                    <v-col cols="12" md="8" class="pa-2">
                        <v-text-field v-model="form.visitor.value" label="접속자 포인트" type="number" v-bind="{ ...attrs_input__console, loading }"/>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-2">
                        <v-select v-model="form.visitor.unit" :items="units" label="단위" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                    <v-col cols="12" md="8" class="pa-2">
                        <v-text-field v-model="form.author.buy_value" label="구매시 작성자 포인트" type="number" v-bind="{ ...attrs_input__console, loading }"/>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-2">
                        <v-select v-model="form.author.buy_unit" :items="units" label="단위" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-switch v-model="form.shows" dense v-bind="attrs_switch__console">
                        <span slot="label" class="subtitle-2"> {{ form.shows ? "노출" : "미노출" }} </span>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { attrs_input__console, attrs_switch__console, SHOP_POINT_UNITS } from "@/assets/variables";

import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";

const units = Object.values(SHOP_POINT_UNITS);

const initTags = (tag = {}) => ({
    ...tag,

    _product: tag._product || null,

    index: tag.index || null,
    shows: tag.shows || false,

    startsAt: tag.startsAt || null,
    endsAt: tag.endsAt || null,

    author:{
        value: tag.author?.value || "0",
        unit: tag.author?.unit || SHOP_POINT_UNITS.AMOUNT.value,
        buy_value: tag.author?.buy_value || "0",
        buy_unit: tag.author?.buy_unit || SHOP_POINT_UNITS.AMOUNT.value,
    },

    visitor:{
        value: tag.visitor?.value || "0",
        unit: tag.visitor?.unit || SHOP_POINT_UNITS.AMOUNT.value,
    },
});

export default {
    components: {
        VDateField,
        AutocompleteProduct,
    },
    props: {
        _id: { type: String, default: null },
        code: { type: String, default: null },
    },
    data: () => ({
        form: initTags(),

        attrs_input__console,
        attrs_switch__console,

        units,
        dialog: false,
        loading: false,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "태그상품";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initTags();
                } else {
                    let { _id } = this;
                    this.form = (await api.console.shop.tags.get({ _id }))?.tag;
                    this.form.startsAt = dayjs(this.form.startsAt).format("YYYY-MM-DD");
                    this.form.endsAt = dayjs(this.form.endsAt).format("YYYY-MM-DD");
                    
                    this.$emit("input", { ...this.form });
                }
            } finally {
                this.loading = false;
            }
        },
        validates() {
            try {
                let { _product, startsAt, endsAt } = this.form;
                if (!_product) throw new Error("상품을 선택하세요");
                if (!startsAt) throw new Error("시작일을 선택하세요");
                if (!endsAt) throw new Error("종료일을 선택하세요");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (!this.validates()) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                let { post, put } = api.console.shop.tags;
                let { ...form } = this.form;
                form = this.formatDecimal(form);

                let tag = (await (this.isCreate ? post : put)(form))?.tag;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", tag);
                this.dialog = false;
            } finally {
                this.loading = false;
            }
        },
        formatDecimal(form){
            if(form.author.unit === SHOP_POINT_UNITS.PERCENT.value){
                form.author.value = (+form.author.value).toFixed(1);
            }
            if(form.author.buy_unit === SHOP_POINT_UNITS.PERCENT.value){
                form.author.buy_value = (+form.author.buy_value).toFixed(1);
            }
            if(form.visitor.unit === SHOP_POINT_UNITS.PERCENT.value){
                form.visitor.value = (+form.visitor.value).toFixed(1);
            }
            return form;
        }
    },
};
</script>