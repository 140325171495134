<template>
    <v-dialog v-model="shows" width="400" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-0">
                <v-row>
                    <v-col class="py-2" cols="12">
                        <autocomplete-user v-model="form._user" label="회원" v-bind="{ ...props__autocompleteUser, loading }" @input="emit" />
                    </v-col>
                    <v-col class="py-2" cols="12">
                        <v-text-field v-model="form.code" label="코드" placeholder="미입력시 자동부여" v-bind="{ ...attrs_input__console, loading }" @input="emit" />
                    </v-col>
                    <v-col class="py-2" cols="12">
                        <v-text-field v-model="form.name" label="명칭" v-bind="{ ...attrs_input__console, loading }" @input="emit" />
                    </v-col>
                    <v-col class="py-2" cols="12">
                        <v-currency-field v-model="form.amount" label="금액" class="text-align-right-field" v-bind="{ ...attrs_input__console, loading }" @input="emit" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-switch v-model="form.isDisabled" label="출금금지" dense v-bind="attrs_switch__console" />
                </v-col>
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";

import { attrs_input__console, attrs_switch__console } from "@/assets/variables/attrs";
import { initUserMoney } from "@/assets/variables/inits";
import { USER_TYPES } from "@/assets/variables/constants";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

const props__autocompleteUser = {
    ...attrs_input__console,
    params: {
        type: USER_TYPES.COMPANY.value,
    },
    itemText: ({ name, username }) => `${name} (${username})`,
};

export default {
    components: {
        AutocompleteUser,
    },
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        form: initUserMoney(),

        shows: false,
        loading: false,

        attrs_input__console,
        attrs_switch__console,
        props__autocompleteUser,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "예치금";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initUserMoney({ usage: { isActive: true } });
                } else {
                    const { _id } = this;
                    const { money } = await api.console.users.moneys.get({ _id });
                    this.form = initUserMoney(money);
                    this.$emit("input", this.form);
                }
            } finally {
                this.loading = false;
            }
        },
        validates() {
            let { name, amount } = this.form;
            if (!name) throw new Error("이름을 등록하셔야 합니다");
            if (!amount) throw new Error("금액을 등록하셔야 합니다");
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();

                const { post, put } = api.console.users.moneys;
                let { ...form } = this.form;
                delete form.type;
                form = initUserMoney(form);
                const { money } = await (this.isCreate ? post : put)(form);
                this.form = initUserMoney(money);

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", this.money);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },

        emit() {
            this.form = initUserMoney(this.form);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .text-align-right-field {
    & > .v-input__control > .v-input__slot > .v-text-field__slot > input {
        text-align: right !important;
    }
}
::v-deep .amount-field {
    & > .v-input__control > .v-input__slot {
        padding-right: 0 !important;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner {
        margin: 1px 0 0;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner > .v-text-field--solo {
        width: 58px !important;

        .v-input__slot {
            padding-right: 4px;
        }
        .v-select__selections {
            justify-content: center;

            > .v-select__selection {
                margin-right: 0;
            }

            > input {
                display: none;
            }
        }

        .v-input__control,
        .v-input__slot {
            background-color: transparent;
        }
        .v-input__append-inner {
            margin-top: 0;
            padding-left: 0;
        }
    }
}

::v-deep .v-text-field__details {
    margin-bottom: 0 !important;
}

::v-deep .w-100-px {
    width: 100px;
}
</style>
