<template>
    <v-btn-toggle v-model="query['type']" color="primary" dense @change="emit">
        <template v-for="item in items">
            <v-btn :value="item.value" outlined :key="item.text">{{ item.text }}</v-btn>
        </template>
    </v-btn-toggle>
</template>

<script>
import { USER_MONEY_TYPES } from "@/assets/variables";

const initQuery = (query = {}) => ({
    ...query,

    type: query.type || null,
});

const items = [{ text: "전체", value: null }].concat(Object.values(USER_MONEY_TYPES));

export default {
    data: () => ({
        query: initQuery(),
        items,
    }),
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query;

            delete query["page"];

            if (!query["type"]) delete query["type"];

            this.$router.push({ query });
        },
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>