var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('autocomplete-product', _vm._b({
    attrs: {
      "height": "54",
      "placeholder": "연동할 상품을 선택하세요"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var _item$thumb, _item$flags, _item$flags2;
        var item = _ref.item,
          on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c('v-list-item-avatar', {
          attrs: {
            "tile": ""
          }
        }, [_c('v-img', {
          attrs: {
            "src": item === null || item === void 0 ? void 0 : (_item$thumb = item.thumb) === null || _item$thumb === void 0 ? void 0 : _item$thumb.url
          },
          scopedSlots: _vm._u([{
            key: "place-holder",
            fn: function () {
              return [_c('v-icon', [_vm._v("mdi-image-broken-variant")])];
            },
            proxy: true
          }], null, true)
        })], 1), _c('v-list-item-content', [_c('v-row', {
          attrs: {
            "no-gutters": ""
          }
        }, [item !== null && item !== void 0 && (_item$flags = item.flags) !== null && _item$flags !== void 0 && _item$flags.length ? _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-list-item-subtitle', {
          staticClass: "pr-1"
        }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$flags2 = item.flags) === null || _item$flags2 === void 0 ? void 0 : _item$flags2.map(function (text) {
          return `[${text}]`;
        }).join(" ")) + " ")])], 1) : _vm._e(), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-list-item-title', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])], 1)], 1), _c('span', [item.discountPrice ? _c('span', {
          staticClass: "text-decoration-line-through"
        }, [_vm._v(" " + _vm._s(item.price.format()) + " ")]) : _vm._e(), !item.sells || item.stock == 0 ? [_vm._v("품절")] : _c('div', [item.discountRate ? _c('span', [_vm._v(_vm._s(item.discountRate) + "%")]) : _vm._e(), _c('span', [_vm._v(_vm._s(item.salePrice.format()) + "원")])])], 2)], 1)], 1)];
      }
    }, {
      key: "append-outer",
      fn: function () {
        return [_c('v-divider', {
          attrs: {
            "vertical": ""
          }
        }), _c('v-btn', {
          attrs: {
            "text": "",
            "tile": "",
            "block": "",
            "width": "128",
            "height": "54",
            "color": "primary",
            "disabled": !_vm.selected
          },
          on: {
            "click": function ($event) {
              return _vm.emit(_vm.items);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-chevron-double-down")]), _c('span', [_vm._v(" 추가 ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'autocomplete-product', _vm.attrs_autocomplete, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }