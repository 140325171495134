var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-edit-dialog', _vm._g({
    attrs: {
      "return-value": _vm.item.custom.amount,
      "large": "",
      "cancel-text": "취소",
      "save-text": "저장"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.item.custom, "amount", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.item.custom, "amount", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-sheet', [_c('v-currency-field', _vm._b({
          staticClass: "mt-3",
          attrs: {
            "clearable": ""
          },
          model: {
            value: _vm.item.custom.amount,
            callback: function ($$v) {
              _vm.$set(_vm.item.custom, "amount", $$v);
            },
            expression: "item.custom.amount"
          }
        }, 'v-currency-field', Object.assign({}, _vm.attrs_input__console, {
          placeholder: _vm.placeholder
        }), false))], 1)];
      },
      proxy: true
    }])
  }, {
    save: _vm.save
  }), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }