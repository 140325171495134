<template>
    <v-sheet>
        <autocomplete-product v-model="selected" height="54" placeholder="연동할 상품을 선택하세요" v-bind="attrs_autocomplete">
            <template #item="{ item, on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-avatar tile>
                        <v-img :src="item?.thumb?.url">
                            <template #place-holder>
                                <v-icon>mdi-image-broken-variant</v-icon>
                            </template>
                        </v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-row no-gutters>
                            <v-col cols="auto" v-if="item?.flags?.length">
                                <v-list-item-subtitle class="pr-1">
                                    {{ item?.flags?.map((text) => `[${text}]`).join(" ") }}
                                </v-list-item-subtitle>
                            </v-col>
                            <v-col cols="auto">
                                <v-list-item-title>
                                    {{ item?.name }}
                                </v-list-item-title>
                            </v-col>
                        </v-row>
                        <span>
                            <span v-if="item.discountPrice" class="text-decoration-line-through">
                                {{ item.price.format() }}
                            </span>
                            <template v-if="!item.sells || item.stock == 0">품절</template>
                            <div v-else>
                                <span v-if="item.discountRate">{{ item.discountRate }}%</span>
                                <span>{{ item.salePrice.format() }}원</span>
                            </div>
                        </span>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template #append-outer>
                <v-divider vertical />
                <v-btn text tile block width="128" height="54" color="primary" :disabled="!selected" @click="emit(items)">
                    <v-icon>mdi-chevron-double-down</v-icon>
                    <span> 추가 </span>
                </v-btn>
            </template>
        </autocomplete-product>
        <!-- <v-expand-transition>
            <div v-show="selected">
                <v-divider />
                <v-autocomplete v-model="item" v-bind="{ ...attrs_autocomplete, items, itemText }">
                    <template #append-outer>
                        <v-divider vertical />
                        <v-btn text tile block width="128" height="54" color="primary" :disabled="!item" @click="emit([item])">
                            <v-icon>mdi-chevron-double-down</v-icon>
                            <span> 항목추가 </span>
                        </v-btn>
                    </template>
                </v-autocomplete>
            </div>
        </v-expand-transition> -->
    </v-sheet>
</template>

<script>
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";
import { attrs_input__verticalTable, initProduct, initShopProductRelated } from "@/assets/variables";

export default {
    components: {
        AutocompleteProduct,
    },
    props: {
        product: { type: Object, default: initProduct },
    },
    data: () => ({
        selected: null,
        item: null,
    }),
    computed: {
        attrs_autocomplete() {
            const attrs = {
                ...attrs_input__verticalTable,
                returnObject: true,
                params: { populate: JSON.stringify(["options", "supplies", "bundleds"]) },
            };

            if (this.product?._id) {
                attrs.params._id = { $ne: this.product?._id };
            }

            return { ...attrs };
        },
        items() {
            const items = [];
            if (this.selected) {
                const { _id: _product, options = [], supplies = [] } = this.selected || {};

                if (options.length) items.push(...options.map((option) => initShopProductRelated({ _product, _option: option?._id, product: this.selected, option })));

                if (supplies.length) items.push(...supplies.map((supply) => initShopProductRelated({ _product, _supply: supply?._id, product: this.selected, supply })));

                if (!options.length && !supplies.length) items.push(initShopProductRelated({ _product, product: this.selected }));
            }
            return items;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.selected = null;
        },
        emit(items) {
            this.$emit("push", items);
        },
        itemText(item) {
            return this.$decode__productOptionName(item?.custom?.name || item?.supply?.name || item?.option?.name || item?.product?.name);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-input__append-outer {
        margin: 0 !important;
    }
}
</style>