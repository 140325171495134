<template>
    <v-sheet class="d-flex flex-column fill-height" style="position: relative">
        <v-card-title class="subtitle-2 font-weight-bold" style="position: relative">
            <span>{{ label }}</span>
            <span class="pl-1 font-weight-medium grey--text">{{ sublabel }}</span>
            <v-file-btn absolute right text icon tile color="primary" multiple accept="image/*" @change="upload"> <v-icon>mdi-plus</v-icon> </v-file-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-0" style="flex-grow: 1; overflow-x: auto">
            <div class="mt-4 text-center" v-if="banner.slides < 1">슬라이드가 없습니다.</div>
            <draggable v-if="mode == 'horizontal'" v-model="banner.slides" class="d-flex flex-row ml-n3 mt-4">
                <v-hover v-for="(slide, index) in banner.slides" :key="`slide-${index}`" v-slot="{ hover }">
                    <v-card style="position: relative; overflow: hidden" class="ml-3">
                        <v-preview-input v-model="slide.image" contain width="288" height="512"></v-preview-input>
                        <v-fade-transition>
                            <v-overlay v-show="hover" absolute z-index="0">
                                <v-menu v-model="slide.showsModal_url" offset-y nudge-left="222" :close-on-content-click="false">
                                    <v-icon slot="activator" slot-scope="{ attrs, on }" v-bind="attrs" v-on="on" large> mdi-link-variant </v-icon>
                                    <v-card width="480" class="pa-3">
                                        <v-text-field v-model="slide.url" label="URL" placeholder="/products/621f2299052d6a062e7400bc" autofocus persistent-placeholder hide-details dense outlined @keydown.enter="slide.showsModal_url = false" />
                                    </v-card>
                                </v-menu>
                                <v-icon large class="ml-3" @click="remove(slide, index)"> mdi-delete </v-icon>
                            </v-overlay>
                        </v-fade-transition>
                    </v-card>
                </v-hover>
            </draggable>
            <draggable v-if="mode == 'vertical'" v-model="banner.slides">
                <v-hover v-for="(slide, index) in banner.slides" :key="`slide-${index}`" v-slot="{ hover }">
                    <v-card style="position: relative; overflow: hidden" class="mt-4">
                        <v-preview-input v-model="slide.image" contain></v-preview-input>
                        <v-fade-transition>
                            <v-overlay v-show="hover" absolute z-index="0">
                                <v-menu v-model="slide.showsModal_url" offset-y nudge-left="222" :close-on-content-click="false">
                                    <v-icon slot="activator" slot-scope="{ attrs, on }" v-bind="attrs" v-on="on" large> mdi-link-variant </v-icon>
                                    <banner-form :value="slide"/>
                                </v-menu>
                                <v-icon large class="ml-3" @click="remove(slide, index)"> mdi-delete </v-icon>
                            </v-overlay>
                        </v-fade-transition>
                    </v-card>
                </v-hover>
            </draggable>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-center">
            <v-btn plain text block color="primary" @click="save">
                <v-icon class="mr-2">mdi-content-save</v-icon>
                <span>저장</span>
            </v-btn>
        </v-card-actions>
        <v-fade-transition>
            <v-overlay v-show="loading" absolute>
                <v-progress-circular indeterminate size="200" width="10" />
            </v-overlay>
        </v-fade-transition>
    </v-sheet>
</template>

<script>
import api from "@/api";
import Draggable from "vuedraggable";
import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";
import VPreviewInput from "@/components/plugins/vuetify/v-preview-input.vue";
import BannerForm from "./banner-form.vue";

export default {
    components: {
        Draggable,
        VFileBtn,
        VPreviewInput,
        BannerForm
    },
    props: {
        mode: { type: String, default: "vertical" },
        name: { type: String },
        code: { type: String },
        label: { type: String },
        sublabel: { type: String },
    },
    data: () => ({
        banner: {
            _id: null,
            slides: [],
        },
        loading: false,
    }),
    mounted() {
        this.init();
    },
    watch: {
        code() {
            this.init();
        },
    },
    methods: {
        async init() {
            this.loading = false;
            this.banner = { _id: null, slides: [], _slides: []};
            this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { code } = this;
                let { banner } = await api.v1.banners.get({ code });
                this.banner = { ...this.banner, ...(banner || {}) };
            } finally {
                this.loading = false;
            }
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { code, name } = this;
                
                let { slides } = this.banner;

                let { put, post } = api.console.bannerSlides;

                let _slides = [];

                for (let [index, slide] of slides.entries()) {
                    const image = slide.image;

                    slide = { ...slide, code: this.$route.params.code };

                    slide = (await ((slide?._id) ? put : post)(slide))?.slide;

                    if(image instanceof File) {
                        slide = (await api.console.bannerSlides.images.post(slide._id, index, image))?.slide;
                    }

                    _slides = [..._slides, slide._id];
                }

                this.banner = { ...this.banner, _slides };

                this.banner._id ? await api.console.banners.put(this.banner) : await api.console.banners.post({ ...this.banner, code, name });

                alert("저장되었습니다");
                this.init();
            } finally {
                this.loading = false;
            }
        },

        upload(file) {
            this.banner.slides = [{ image: file, url: null, meta: {} }, ...this.banner.slides];
        },
        async remove(slide, index) {
            if (this.loading) return;
            else this.loading = true;
            try {
                if(!slide._id) {
                    this.banner.slides.splice(index, 1);
                    return;
                };

                if(confirm("해당 슬라이드를 삭제하시겠습니까?")){
                    let result = (await api.console.bannerSlides.delete(slide))?.slide;
                    const _slides = this.banner.slides.filter((slide) => slide._id !== result._id).map((slide) => slide._id);
                    await api.console.banners.put({ ...this.banner, _slides});
                }
                this.init();
            } catch(error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        }
    },
};
</script>

<style>
.editor.hidden {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}
</style>
