<template>
    <v-card outlined class="view-field-card" style="position: relative" v-bind="$attrs">
        <template v-if="label">
            <span class="d-inline-block white caption px-1" style="position: absolute; top: 0; left: 8px; transform: translateY(-50%)">
                {{ label }}
            </span>
        </template>
        <v-card-text v-bind="$attrs.containerAttrs">
            <slot />
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        label: { type: String, default: null },
    },
};
</script>


<style lang="scss" scoped>
.view-field-card {
    border-color: rgba(0, 0, 0, 0.38) !important;
}
</style>
