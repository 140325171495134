var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "알람 템플릿"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "알람 이름"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "알람 코드"
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.alarmTypeItems,
      "label": "알람 유형"
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input__console), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.alarmCategoryItems,
      "label": "알람 구분"
    },
    model: {
      value: _vm.form.category,
      callback: function ($$v) {
        _vm.$set(_vm.form, "category", $$v);
      },
      expression: "form.category"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input__console), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.alarmTargetItems,
      "label": "알람 대상"
    },
    model: {
      value: _vm.form.target,
      callback: function ($$v) {
        _vm.$set(_vm.form, "target", $$v);
      },
      expression: "form.target"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input__console), false))], 1), !_vm.isTalk ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "URL",
      "placeholder": "/mypage/dashboard (도메인명 제거)"
    },
    model: {
      value: _vm.form.url,
      callback: function ($$v) {
        _vm.$set(_vm.form, "url", $$v);
      },
      expression: "form.url"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console), false))], 1) : _vm._e(), _vm.isTalk ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "템플릿 코드"
    },
    model: {
      value: _vm.form.templateCode,
      callback: function ($$v) {
        _vm.$set(_vm.form, "templateCode", $$v);
      },
      expression: "form.templateCode"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console), false))], 1) : _vm._e(), _vm.isTalk ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "수신자명"
    },
    model: {
      value: _vm.form.receiverName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "receiverName", $$v);
      },
      expression: "form.receiverName"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console), false))], 1) : _vm._e(), _vm.isTalk ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "수신자 번호"
    },
    model: {
      value: _vm.form.receiverNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "receiverNumber", $$v);
      },
      expression: "form.receiverNumber"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console), false))], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.isTalk ? _c('v-checkbox', {
    staticClass: "ma-0",
    attrs: {
      "label": "발송실패 시 문자발송"
    },
    model: {
      value: _vm.form.sendAtFail,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sendAtFail", $$v);
      },
      expression: "form.sendAtFail"
    }
  }) : _vm._e(), _c('v-textarea', _vm._b({
    attrs: {
      "label": "내용",
      "rows": "12",
      "clearable": ""
    },
    model: {
      value: _vm.form.template,
      callback: function ($$v) {
        _vm.$set(_vm.form, "template", $$v);
      },
      expression: "form.template"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input__console), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-data-table', {
    staticClass: "v-sheet--outlined",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.dataItems,
      "items-per-page": 5,
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: `item.text`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(item.text))]), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "x-small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.copy($event);
            }
          }
        }, [_vm._v("mdi-content-copy")])];
      }
    }], null, true)
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    attrs: {
      "disabled": _vm.isCreate
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    loading: _vm.loading
  }), false), [_vm._v(_vm._s(_vm.isCreate ? "저장" : "수정"))])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }