<template>
    <view-section-card :title="title">
        <v-card-text v-if="isConsole">
            <v-textarea v-model="form.memo" dense hide-details outlined @input="emit" />
        </v-card-text>
        <v-card-text v-else>
            <v-textarea v-model="form.sellerMemo" dense hide-details outlined @input="emit" />
        </v-card-text>
    </view-section-card>
</template>

<script>
import { initProduct } from "@/assets/variables";
import viewSectionCard from "../../dumb/view-section-card.vue";
export default {
    components: { viewSectionCard },
    props: {
        hasConsoleScope: { type: Boolean, default: false },
        value: { type: Object, default: initProduct },
    },
    data: () => ({
        form: initProduct(),
    }),
    computed: {
        title() {
            return this.hasConsoleScope ? "최고관리자 메모" : "메모";
        },
        isConsole() {
            return this.hasConsoleScope;
        }
    },
    methods: {
        sync() {
            this.form = initProduct(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>