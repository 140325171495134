<template>
    <span v-if="readonly">
        <template v-if="loading">
            <v-progress-linear indeterminate />
        </template>
        <template v-else-if="multiple">
            {{ selected.map(itemText).join(", ") || placeholder }}
        </template>
        <template v-else>
            {{ itemText(selected) ?? placeholder }}
        </template>
    </span>
    <v-autocomplete
        v-else
        v-model="item"
        :search-input.sync="searchValue"
        v-bind="{
            ...$attrs,

            items,
            itemText,
            itemValue: '_id',

            loading,
            multiple,
            placeholder,
            returnObject,
        }"
        @click="alert"
        @keyup="onKeyup"
        @keyup.enter="search"
        @change="onChange"
    >
        <template #item="{ parent, item, on, attrs }" v-if="$scopedSlots['item']">
            <slot name="item" v-bind="{ parent, item, on, attrs, itemText }" />
        </template>
        <template #selection="{ parent, item, attrs }" v-if="$scopedSlots['selection']">
            <slot name="selection" v-bind="{ parent, item, attrs, itemText }" />
        </template>
        <template #append v-if="$scopedSlots['append']">
            <slot name="append" />
        </template>
        <template #append-outer v-if="$scopedSlots['append-outer']">
            <slot name="append-outer" />
        </template>
        <slot />
    </v-autocomplete>
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: [String, Object, Array], default: null },
        sort: { type: [String, Object], default: () => ({ createdAt: -1 }) },
        params: { type: Object, default: () => ({}) },
        itemText: { type: Function, default: ({ name }) => name },
        multiple: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        placeholder: { type: String, default: null },
        returnObject: { type: Boolean, default: false },
    },
    data: () => ({
        item: null,
        items: [],

        searchValue: null,

        loading: false,
    }),
    computed: {
        selected() {
            if (this.returnObject) {
                return this.item;
            }

            if (this.multiple) {
                return this.item.map((_id) => this.items.find((item) => item._id == _id));
            } else {
                return this.items.find((item) => item._id == this.item);
            }
        },
    },
    mounted() {
        this.sync();
        this.search();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            if (this.multiple) {
                this.item = [...(this.value || [])];
            } else {
                if (this.returnObject) {
                    this.item = this.value ? { ...this.value } : null;
                } else {
                    this.item = this.value || null;
                }
            }
        },
        async search() {
            this.loading = true;

            try {
                let sort = this.sort;
                if (typeof sort == "object") sort = JSON.stringify(sort);

                const { searchValue } = this;

                if (this.readonly) {
                    this.items = [];
                } else {
                    const { users: items } = await api.console.users.gets({
                        headers: { limit: 50, sort },
                        params: { ...this.params, searchValue },
                    });
                    this.items = items;
                }

                if (this.multiple) {
                    if (this.item?.length) {
                        const _id = this.item.map((item) => (this.returnObject ? item._id : item));
                        const { users: items } = await api.console.users.gets({
                            params: { _id },
                        });
                        this.items = [...items, ...this.items.filter((item) => !_id.includes(item._id))];
                    }
                } else {
                    if (this.item) {
                        const { user: item } = await api.console.users.get({
                            _id: this.returnObject ? this.item?._id : this.item,
                        });

                        if (item) {
                            const index = this.items.findIndex(({ _id }) => _id == item._id);

                            if (0 <= index) {
                                this.items.splice(index, 1, item);
                            } else {
                                this.items = [item, ...this.items];
                            }
                        }
                    }
                }
                this.items = (this.$attrs.items || []).concat(this.items);
            } finally {
                this.loading = false;
            }
        },
        alert() {
            if (this.readonly && this.$attrs.readonlyMessage) alert(this.$attrs.readonlyMessage);
            if (this.$attrs.disabled && this.$attrs.disabledMessage) alert(this.$attrs.readonlyMessage);
        },
        onKeyup(event) {
            switch (/^Arrow/.test(event.code)) {
                case false: {
                    this.search();
                    break;
                }
            }
        },
        onChange(value) {
            this.$emit("input", value);
            this.$nextTick(() => this.$emit("emit"));
        },
    },
};
</script>

<style></style>
