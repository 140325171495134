<template>
    <v-layout justify-center>
        <v-responsive width="100%" class="px-3 mx-n3">
            <list-heading title="회원쿠폰 목록" showsFilterButton v-model="showsSearch" />
            <coupon-list-search v-bind="{ showsSearch }" />

            <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined mt-3" style="overflow: hidden">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>

                <template #[`item.isDisabled`]="{ item }">
                    <v-switch v-model="item.isDisabled" v-bind="{ ...attrs_switch__console, disabled: item.isUsed }" @change="(isDisabled) => update({ _id: item._id, isDisabled })" />
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input__console, attrs_switch__console, SHOP_COUPON_UNITS, USER_TYPES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import CouponListSearch from "@/components/console/users/coupons/coupon-list-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

const headers = [
    { text: "#", value: "code", width: 160 },
    { text: "쿠폰명", value: "name" },
    {
        text: "할인내용",
        value: "value",
        align: "end",
        width: 100,
        formatter: (value, item) => `${value.format()}${SHOP_COUPON_UNITS[item.unit]?.suffix}`,
    },
    {
        width: 180,
        text: "회원",
        value: "user",
        cellClass: "caption text-truncate max-width-0",
        withTitle: true,
        formatter: (value) => {
            const { username, name, phone } = value || {};
            const items = [username, name, phone].filter((item) => item);
            return items.join(" / ");
        },
    },
    { width: 160, text: "발급일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 160, text: "사용일시", value: "usedAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 100, text: "사용여부", value: "isUsed", formatter: (value) => (value ? "사용완료" : "미사용") },
    { width: 100, text: "사용금지", value: "isDisabled" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, align: item.align ?? "start", class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "") + " caption line-height-1-5 white-space-pre-line" }));

const userTypes = [USER_TYPES.COMPANY.value, USER_TYPES.PERSON.value];

export default {
    components: {
        ListHeading,
        CouponListSearch,
        AutocompleteUser,
    },
    data: () => ({
        coupons: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,
        userTypes,
        attrs_input__console,
        attrs_switch__console,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        items() {
            return [...this.coupons];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                await this.search(false);
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, coupons } = await api.console.users.coupons.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.coupons = coupons;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { coupon } = await api.console.users.coupons.put(item);
                this.loading = false;
                this.updateItem(coupon);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(item) {
            const index = this.coupons.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.coupons.splice(index, 1, item);
            else {
                this.coupons = [item, ...this.coupons];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
