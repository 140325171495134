<template>
    <list-search v-bind="{ showsSearch }" @search="setQuery">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" md="4" lg="" class="pa-2">
                <v-select label="검색대상" v-model="query.searchKey" :items="searchKeys" placeholder="전체" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="" class="pa-2">
                <v-text-field label="검색어" v-model="query.searchValue" clearable v-bind="attrs_input__console" @keydown.enter="setQuery" />
            </v-col>
            <!-- <v-col cols="12" sm="6" md="4" lg="" class="pa-2">
                <v-select label="회원유형" v-model="query.type" :items="types" placeholder="전체" clearable v-bind="attrs_input__console" @change="setQuery" />
            </v-col> -->
            <v-col cols="12" sm="6" md="4" lg="" class="pa-2">
                <v-select label="회원권한" v-model="query['scope']" :items="scopeItems" v-bind="attrs_input__console" @change="setQuery" />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="" class="pa-2">
                <v-text-field label="주의회원" :value="query.isWarning ? '주의회원' : '일반회원'" v-bind="attrs_input__console" readonly @click="query.isWarning = !query.isWarning" @change="setQuery">
                    <template #prepend-inner> <v-switch v-model="query.isWarning" dense inset hide-details class="mt-0 mr-n2 mb-n1 pt-0" /> </template>
                </v-text-field>
            </v-col>
            <v-col v-if="isPerson" cols="12" sm="6" md="4" lg="" class="pa-2">
                <v-select label="카카오 연동여부" v-model="query['kakao']" :items="snsItems" v-bind="attrs_input__console" @change="setQuery" />
            </v-col>
            <v-col v-if="isPerson" cols="12" sm="6" md="4" lg="" class="pa-2">
                <v-select label="네이버 연동여부" v-model="query['naver']" :items="snsItems" v-bind="attrs_input__console" @change="setQuery" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input__console, attrs_switch__console, USER_TYPES } from "@/assets/variables";
import { scopes } from "../story/categories/story-category-form.vue";
import ListSearch from "@/components/console/dumb/list-search.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["searchKey"]: query?.["searchKey"] ?? null,
    ["searchValue"]: query?.["searchValue"] ?? null,

    ["type"]: query?.["type"] ?? null,
    ["scope"]: query?.["scope"] ?? null,
    ["kakao"]: query?.["kakao"] ?? null,
    ["naver"]: query?.["naver"] ?? null,
    ["isWarning"]: JSON.parse(query?.["isWarning"] ?? "null"),
    ["isApproved"]: JSON.parse(query?.["isApproved"] ?? "null"),
});

const types = Object.values(USER_TYPES);

const searchKeys = [
    { text: "이름", value: "name" },
    { text: "연락처", value: "phone" },
    { text: "이메일", value: "email" },
    { text: "추천인코드", value: "referrer" },
];

const snsItems = [
    { text: "전체", value: null },
    { text: "연동", value: "connect" },
    { text: "미연동", value: "disconnect" }
]

const scopeItems = [
    { text: "전체", value: null },
    ...Object.values(scopes)
];

export default {
    components: {
        ListSearch,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        types,
        searchKeys,

        attrs_input__console,
        attrs_switch__console,
        scopeItems,
        snsItems
    }),
    computed: {
        isPerson() {
            return this.$route.query.type === USER_TYPES.PERSON.value;
        }
    },
    mounted() {
        this.sync();
    },

    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        setQuery() {
            const { ...query } = this.query || {};

            if (!query?.["searchKey"]) delete query["searchKey"];
            if (!query?.["searchValue"]) delete query["searchValue"];

            if (!query?.["type"]) delete query["type"];
            if (!query?.["scope"]) delete query["scope"];
            if (!query?.["kakao"]) delete query["kakao"];
            if (!query?.["naver"]) delete query["naver"];

            if (query?.["isWarning"] == null) delete query["isWarning"];
            if (query?.["isApproved"] == null) delete query["isApproved"];

            delete query.page;

            this.$router.push({ query });
        },
    },
    watch: {
        "$route"() {
            this.sync();
        },
    }
};
</script>

<style></style>
