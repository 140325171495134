<template>
    <list-search v-bind="{ showsSearch }" dense hideLabel @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query.searchDateValue[0]" type="date" label="시작일" clearable v-bind="attrs_input__console" @input="emit" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query.searchDateValue[1]" type="date" label="종료일" clearable v-bind="attrs_input__console" @input="emit" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query.searchValue" label="검색어" v-bind="attrs_input__console" @keydown.enter="emit"/>
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input__console } from "@/assets/variables/attrs";

import ListSearch from "@/components/console/dumb/list-search.vue";
import DatesToggle from "@/components/console/dumb/dates-toggle.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["searchDateValue"]: JSON.parse(query?.["searchDateValue"] || JSON.stringify([null, null])),
});

export default {
    components: {
        ListSearch,
        AutocompleteUser,
        DatesToggle,
    },
    props: {
        value: { type: Object, default: () => ({}) },
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        attrs_input__console,
    }),
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.query = initQuery(this.value);
        },
        emit() {
            const { ...query } = this.query || {};

            if (!query["searchDateValue"]) delete query["searchDateValue"];
            if (!query["searchDateValue"]?.[0] && !query?.searchDateValue?.[1]) delete query["searchDateValue"];
            else {
                query["searchDateValue"] = JSON.stringify(query["searchDateValue"]);
                delete query.period;
            }

            if (!query.searchValue) delete query.searchValue;

            query.page = 1;

            this.$emit("input", query);
        },
    },
};
</script>

<style></style>
s