var render = function render(){
  var _vm$form$board;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "560",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "light": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-text', {
    staticClass: "py-5"
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [!_vm.code ? _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "위치",
      "items": _vm.codes,
      "disabled": _vm.isCreate
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading
  }), false))], 1) : _vm._e(), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "#",
      "disabled": _vm.isCreate
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.index,
      callback: function ($$v) {
        _vm.$set(_vm.form, "index", $$v);
      },
      expression: "form.index"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('autocomplete-board', _vm._b({
    attrs: {
      "label": "스토리",
      "returnObject": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.board,
      callback: function ($$v) {
        _vm.$set(_vm.form, "board", $$v);
      },
      expression: "form.board"
    }
  }, 'autocomplete-board', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-expand-transition', [_c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!_vm.form.board,
      expression: "!!form.board"
    }],
    staticClass: "ma-0"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('form-image', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'form-image', {
    attrs_input__console: _vm.attrs_input__console,
    loading: _vm.loading
  }, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제목",
      "placeholder": (_vm$form$board = _vm.form.board) === null || _vm$form$board === void 0 ? void 0 : _vm$form$board.subject,
      "persistent-placeholder": "",
      "clearable": "",
      "clear-icon": "mdi-refresh"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading,
    disabled: !_vm.form.board
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "label": "내용",
      "placeholder": _vm.form.computedContent,
      "persistent-placeholder": "",
      "clearable": "",
      "clear-icon": "mdi-refresh"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading,
    disabled: !_vm.form.board
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('form-products', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'form-products', {
    attrs_input__console: _vm.attrs_input__console,
    loading: _vm.loading
  }, false))], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-datetime-field', _vm._b({
    staticClass: "pa-0",
    attrs: {
      "label": "전시시작"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.startsAt,
      callback: function ($$v) {
        _vm.$set(_vm.form, "startsAt", $$v);
      },
      expression: "form.startsAt"
    }
  }, 'v-datetime-field', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-datetime-field', _vm._b({
    staticClass: "pa-0",
    attrs: {
      "label": "전시종료"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.endsAt,
      callback: function ($$v) {
        _vm.$set(_vm.form, "endsAt", $$v);
      },
      expression: "form.endsAt"
    }
  }, 'v-datetime-field', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading
  }), false))], 1)], 1)], 1), _c('v-card-actions', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-switch', _vm._b({
    attrs: {
      "dense": ""
    },
    model: {
      value: _vm.form.shows,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shows", $$v);
      },
      expression: "form.shows"
    }
  }, 'v-switch', _vm.attrs_switch__console, false), [_c('span', {
    staticClass: "subtitle-2",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" " + _vm._s(_vm.form.shows ? "노출" : "미노출") + " ")])])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }