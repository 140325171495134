var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": "",
      "fill-height": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1600",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', _vm._b({}, 'list-heading', {
    title: _vm.title
  }, false)), _c('alarm-list-types'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('alarm-list', _vm._b({
    on: {
      "show": _vm.sync
    }
  }, 'alarm-list', {
    alarms: _vm.alarms
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('alarm-form', _vm._b({
    on: {
      "save": _vm.search
    }
  }, 'alarm-form', {
    value: _vm.value
  }, false))], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }