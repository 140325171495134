<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <list-heading v-bind="{ title }" />

            <v-row class="ma-n2">
                <v-col cols="12" lg="3" class="pa-2">
                    <view-section-card title="썸네일">
                        <v-card-text>
                            <v-img :src="board.storyThumbSrc" :aspect-ratio="1 / 1" contain class="grey lighten-2">
                                <template #placeholder>
                                    <v-overlay absolute color="transparent">
                                        <v-icon> mdi-image-broken-variant </v-icon>
                                    </v-overlay>
                                </template>
                            </v-img>
                        </v-card-text>
                    </view-section-card>
                </v-col>
                <v-col cols="12" lg="9" class="pa-2">
                    <view-section-card title="기본정보" class="fill-height">
                        <v-card-text>
                            <v-row class="ma-n2">
                                <v-col cols="6" class="pa-2">
                                    <v-text-field :value="(board.writer || {}).nickname" label="작성자" v-bind="attrs_input__console" readonly disabled></v-text-field>
                                </v-col>
                                <v-col cols="6" class="pa-2">
                                    <v-datetime-field :value="board.createdAt ? board.createdAt.toDateTime() : undefined" label="작성일" v-bind="attrs_input__console" @input="(value) => (board.createdAt = value)" />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-select v-model="board.code" label="카테고리" :items="codes" v-bind="attrs_input__console" />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="board.subject" label="제목" v-bind="attrs_input__console" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </view-section-card>
                </v-col>
                <v-col cols="12" class="pa-2">
                    <view-section-card title="내용">
                        <v-card-text>
                            <naver-smarteditor v-model="board.content" bSkipXssFilter id="content" rows="10" />
                        </v-card-text>
                    </view-section-card>
                </v-col>
            </v-row>

            <v-btn v-if="board._id" fixed right bottom fab color="white" class="mb-16" v-bind="{ loading }" :href="`/story/${board.code}/${board._id}`" target="blank">
                <v-icon>mdi-link-variant</v-icon>
            </v-btn>
            <v-btn fixed right bottom fab color="primary" v-bind="{ loading }" @click="save">
                <v-icon>mdi-content-save</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import { attrs_input__console } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        ListHeading,
        VDatetimeField,
        ViewSectionCard,
        NaverSmarteditor,
    },
    data() {
        return {
            attrs_input__console,

            ready: false,
            loading: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            board: {
                code: this.$route.query.code,
                category: this.$route.query.category,

                subject: undefined,
                content: undefined,
                summary: undefined,
                period: undefined,
                createdAt: undefined,

                sequence: 1,

                storeName: undefined,
                storePhone: undefined,
                storeEmail: undefined,

                sido: undefined,
                gugun: undefined,
                area: undefined,
                postcode: undefined,
                address1: undefined,
                address2: undefined,
                lat: undefined,
                lng: undefined,

                writer: {
                    name: undefined,
                    phone: undefined,
                    email: undefined,
                },

                meta: {
                    buyEnabled: false,
                    buyURL: null,
                    youtube: null,
                },

                collections: [],
                relativeProducts: [],
                portfolios: [],

                thumb: undefined,
                files: [],
            },

            file: null,

            videoId: null,
        };
    },
    computed: {
        ...mapGetters("story", ["categories__flatMapped", "getCategory", "getCategoryWithId"]),

        title() {
            return !this.$route.params._board ? "게시글 등록" : "게시글 상세";
        },
        codes() {
            return this.categories__flatMapped.map((category) => {
                const codeTexts = (category?._parents || []).map((_parent) => this.getCategoryWithId(_parent)?.name).concat(category?.name);

                return {
                    text: codeTexts.join(" - "),
                    value: category.code,
                };
            });
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        file(file) {
            if (file) {
                this.board.files.push(file);
                this.file = null;
            }
        },
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._board) {
                    var { board } = await api.console.boards.get({ _id: this.$route.params._board });
                    board.files = await Promise.all((board.files || []).map(async ({ url }) => await api.getResource(url, true)));
                    // if (board.thumb) board.thumb = await api.getResource(board.thumb, true);
                    this.board = board;
                    if (this.board.code == "youtube") this.setVideoId();
                }

                if (!this.$route.params._board === undefined) {
                    var { summary } = await api.console.boards.gets({
                        headers: { skip: 0, limit: 1 },
                        params: {
                            code: this.$route.query.code,
                        },
                    });
                    this.board.sequence = summary.totalCount + 1;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        async save() {
            try {
                let { _files = [], files = [], thumb, ...board } = this.board;
                ////////////////////////////////////////////////////////////////
                // 0. 리소스 삭제
                ////////////////////////////////////////////////////////////////
                if (_files.length > 0) await Promise.all(_files.map(async (_id) => await api.console.boards.files.delete({ _board: board?._id, _id })));

                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                [{ board }] = [board._id ? await api.console.boards.put(board) : await api.console.boards.post(board)];

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                // if (thumb) await api.console.boards.postThumb(board, thumb);
                // if (files.length > 0) await Promise.all(files.map(async (file, index) => await api.console.boards.files.post({ _board: board?._id, index }, file)));

                alert(this.board._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>
