var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-field-card', {
    attrs: {
      "containerAttrs": {
        class: 'pa-0 rounded-lg',
        style: 'overflow:hidden'
      }
    }
  }, [_c('v-data-table', _vm._b({
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: `item.purchaseDT`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-text-field', _vm._b({
          staticClass: "text-caption",
          attrs: {
            "readonly": ""
          },
          model: {
            value: item.purchaseDT,
            callback: function ($$v) {
              _vm.$set(item, "purchaseDT", $$v);
            },
            expression: "item.purchaseDT"
          }
        }, 'v-text-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.itemName`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-text-field', _vm._b({
          staticClass: "text-caption",
          attrs: {
            "readonly": ""
          },
          model: {
            value: item.itemName,
            callback: function ($$v) {
              _vm.$set(item, "itemName", $$v);
            },
            expression: "item.itemName"
          }
        }, 'v-text-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.spec`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-text-field', _vm._b({
          staticClass: "text-caption",
          attrs: {
            "readonly": ""
          },
          model: {
            value: item.spec,
            callback: function ($$v) {
              _vm.$set(item, "spec", $$v);
            },
            expression: "item.spec"
          }
        }, 'v-text-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.qty`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-currency-field', _vm._b({
          staticClass: "text-caption text-align-right-field",
          attrs: {
            "readonly": "",
            "suffix": "원"
          },
          model: {
            value: item.qty,
            callback: function ($$v) {
              _vm.$set(item, "qty", $$v);
            },
            expression: "item.qty"
          }
        }, 'v-currency-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.unitCost`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-currency-field', _vm._b({
          staticClass: "text-caption text-align-right-field",
          attrs: {
            "readonly": "",
            "suffix": "원"
          },
          model: {
            value: item.unitCost,
            callback: function ($$v) {
              _vm.$set(item, "unitCost", $$v);
            },
            expression: "item.unitCost"
          }
        }, 'v-currency-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.supplyCost`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('v-currency-field', _vm._b({
          staticClass: "text-caption text-align-right-field",
          attrs: {
            "readonly": "",
            "suffix": "원"
          },
          model: {
            value: item.supplyCost,
            callback: function ($$v) {
              _vm.$set(item, "supplyCost", $$v);
            },
            expression: "item.supplyCost"
          }
        }, 'v-currency-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.tax`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-currency-field', _vm._b({
          staticClass: "text-caption text-align-right-field",
          attrs: {
            "readonly": "",
            "suffix": "원"
          },
          model: {
            value: item.tax,
            callback: function ($$v) {
              _vm.$set(item, "tax", $$v);
            },
            expression: "item.tax"
          }
        }, 'v-currency-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.remark`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('v-text-field', _vm._b({
          staticClass: "text-caption",
          attrs: {
            "readonly": ""
          },
          model: {
            value: item.remark,
            callback: function ($$v) {
              _vm.$set(item, "remark", $$v);
            },
            expression: "item.remark"
          }
        }, 'v-text-field', _vm.attrs_input__verticalTable, false))];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }