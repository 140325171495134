<template>
    <v-edit-dialog :return-value.sync="item.meta" large cancel-text="취소" save-text="변경" v-on="{ save }">
        <slot />
        <template #input> <v-text-field v-model="item.meta.sellCount" v-bind="attrs_input__console" type="number" class="mt-3" /> </template>
    </v-edit-dialog>
</template>

<script>
import { attrs_input__console } from "@/assets/variables";
export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        item: {
            meta: {
                sellCount: 0,
            },
        },
        attrs_input__console,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.item = JSON.parse(JSON.stringify(this.value));
        },
        save() {
            let { _id, meta } = this.item;
            let form = { _id, meta };

            this.$emit("update", form);
        },
    },
};
</script>

<style>
</style>