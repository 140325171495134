<template>
    <v-tabs v-bind="$attrs">
        <template v-for="{ text, value, to } in items">
            <v-tab :key="value" v-bind="{ value, to }" exact>{{ text }}</v-tab>
        </template>
        <v-spacer />
        <v-divider vertical />
        <showroom-list-codes-form v-model="setting" @input="init">
            <template #activator="{ attrs, on }">
                <v-btn text fab tile width="48" height="48" v-bind="attrs" v-on="on">
                    <v-icon dense>mdi-cog</v-icon>
                </v-btn>
            </template>
        </showroom-list-codes-form>
    </v-tabs>
</template>

<script>
import ShowroomListCodesForm from "./showroom-list-codes-form.vue";
import { mapActions, mapState } from "vuex";

const baseurl = "/console/shop/showrooms";

export default {
    components: {
        ShowroomListCodesForm,
    },
    data: () => ({
        loading: false,
    }),
    computed: {
        ...mapState(["setting"]),
        items() {
            return [{ text: "전체", value: null }, ...(this.setting.shop.showroomCodes || []).map((text) => ({ text, value: text }))].map((item) => {
                let to = baseurl;
                if (item.value) to = `${baseurl}/${item.value}`;
                return { ...item, to };
            });
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["getSetting__console"]),
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                await this.getSetting__console();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>