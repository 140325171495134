var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": "",
      "fill-height": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1200",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', _vm._b({}, 'list-heading', {
    title: _vm.title
  }, false)), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('view-section-card', {
    attrs: {
      "title": "메시지 템플릿"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_vm.isMessage ? _c('span', [_vm._v(" * 90 Bytes 초과 입력 시, LMS로 전환됩니다."), _c('br'), _vm._v(" * \"제목\"은 LMS 메신저에서만 적용됩니다."), _c('br'), _vm._v(" * LMS는 최대 2000 Bytes까지 전송하실 수 있습니다. ")]) : _vm._e(), _c('v-row', [_vm.isMessage && _vm.previewBytes > 90 ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "LMS 제목"
    },
    model: {
      value: _vm.form.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console), false))], 1) : _vm._e(), !_vm.isMessage ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "알림 제목",
      "clearable": ""
    },
    model: {
      value: _vm.form.title,
      callback: function ($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console), false))], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "label": "내용",
      "clearable": ""
    },
    model: {
      value: _vm.form.message,
      callback: function ($$v) {
        _vm.$set(_vm.form, "message", $$v);
      },
      expression: "form.message"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input__console), false))], 1), _vm.isMessage ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "label": "미리보기",
      "readonly": "",
      "counter": "",
      "counter-value": _vm.countValue
    },
    model: {
      value: _vm.form.message,
      callback: function ($$v) {
        _vm.$set(_vm.form, "message", $$v);
      },
      expression: "form.message"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input__console), false))], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('view-section-card', {
    attrs: {
      "title": "수신자 명단"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.msg_types_items,
      "label": "발송 유형"
    },
    model: {
      value: _vm.type,
      callback: function ($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input__console), false))], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('autocomplete-user', _vm._b({
    attrs: {
      "label": "수신자",
      "itemText": function (_ref) {
        var name = _ref.name,
          username = _ref.username,
          nickname = _ref.nickname;
        return `${name} (${username}/${nickname})`;
      },
      "returnObject": true,
      "clearable": "",
      "multiple": ""
    },
    model: {
      value: _vm.receivers,
      callback: function ($$v) {
        _vm.receivers = $$v;
      },
      expression: "receivers"
    }
  }, 'autocomplete-user', Object.assign({}, _vm.attrs_input__console), false))], 1), _vm.isMessage ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이름",
      "clearable": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addNumber.apply(null, arguments);
      }
    },
    model: {
      value: _vm.receiver.name,
      callback: function ($$v) {
        _vm.$set(_vm.receiver, "name", $$v);
      },
      expression: "receiver.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console), false))], 1) : _vm._e(), _vm.isMessage ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "전화번호 수동입력",
      "clearable": "",
      "placeholder": "000-0000-0000"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addNumber.apply(null, arguments);
      }
    },
    model: {
      value: _vm.receiver.phone,
      callback: function ($$v) {
        _vm.$set(_vm.receiver, "phone", $$v);
      },
      expression: "receiver.phone"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console), false))], 1) : _vm._e(), _vm.isMessage ? _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', _vm._b({
    on: {
      "click": _vm.addNumber
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    loading: _vm.loading
  }), false), [_vm._v("입력")])], 1) : _vm._e(), !_vm.isMessage ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이동 URL",
      "placeholder": "/mypage/dashboard (도메인명 제거)"
    },
    model: {
      value: _vm.form.url,
      callback: function ($$v) {
        _vm.$set(_vm.form, "url", $$v);
      },
      expression: "form.url"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console), false))], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "v-sheet--outlined",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.receivers,
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: `item.actions`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-delete ")])], 1)];
      }
    }], null, true)
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    on: {
      "click": _vm.send
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    loading: _vm.loading
  }), false), [_vm._v("발송")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }