<template>
    <v-sheet>
        <v-divider v-if="!hideDivider" />
        <v-row class="ma-0" no-gutters>
            <v-col v-for="(item, index) in items" :key="index" cols="12" :lg="item?.cols ?? defaultCols" class="d-flex flex-column">
                <template v-if="isArray(item)">
                    <v-row class="ma-0" no-gutters align="stretch">
                        <template v-for="(child, index) in item">
                            <v-col :key="child.term">
                                <vertical-table-row v-bind="child">
                                    <template v-if="child.data"> {{ child.data }} </template>
                                    <slot v-else-if="$slots[child.term]" :name="child.term" />
                                    <v-node v-else-if="child.node" :node="child.node" />
                                </vertical-table-row>
                            </v-col>
                            <v-col v-if="index != item.length - 1" cols="auto" :key="`divider=${index}`">
                                <v-divider vertical />
                            </v-col>
                        </template>
                    </v-row>
                </template>
                <template v-else>
                    <vertical-table-row v-bind="item">
                        <template v-if="item.data"> {{ item.data }} </template>
                        <slot v-else-if="$slots[item.term]" :name="item.term" />
                        <v-node v-else-if="item.node" :node="item.node" />
                    </vertical-table-row>
                </template>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import VerticalTableRow from "./vertical-table-row.vue";

export default {
    components: {
        vNode: { functional: true, render: (h, ctx) => ctx.props.node },
        VerticalTableRow,
    },
    props: {
        items: { type: Array, default: () => [] },
        itemsPerRow: { type: [Number, String], default: 1 },

        hideDivider: { type: Boolean, default: false },
    },
    computed: {
        defaultCols() {
            return 12 / this.itemsPerRow;
        },
        slots() {
            return this.$slots;
        },
    },
    methods: {
        isArray(item) {
            return Array.isArray(item);
        },
    },
};
</script>

<style></style>
