<template>
    <v-layout justify-center>
        <v-responsive width="100%" class="px-3 mx-n3">
            <list-heading v-model="showsSearch" showsFilterButton v-bind="{ title }">
                <template #add-button v-if="hasConsoleScope && mode == 'default'">
                    <user-money-form @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </user-money-form>
                </template>
            </list-heading>

            <user-money-search v-bind="{ mode, showsSearch }" />

            <template v-if="mode == 'default'">
                <user-money-types />
            </template>

            <v-row class="my-n3">
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white" class="caption" v-bind="{ loading }">
                            <v-layout align-baseline>
                                <span> 검색결과: </span>
                                <b class="mx-1"> {{ summary.totalCount?.format?.() }} </b> 건
                            </v-layout>
                        </v-btn>
                    </v-card>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white green--text" class="caption" v-bind="{ loading }" @click="excel">
                            <span> <v-icon>mdi-microsoft-excel</v-icon> 엑셀다운로드 </span>
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>

            <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined mt-3" style="overflow: hidden">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>

                <template #[`item.user`]="{ item, value, header }">
                    <v-row no-gutters>
                        <v-col class="pr-4 text-truncate">{{ header.formatter(value) }} </v-col>
                        <v-col cols="auto">
                            <v-btn x-small text icon color="primary" :to="{ query: { _user: item._user } }">
                                <v-icon>mdi-filter-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>

                <template #[`item.name`]="{ item, value, header }">
                    <v-row no-gutters>
                        <v-col class="pr-4 text-truncate">{{ header.formatter(value) }} </v-col>
                        <v-col cols="auto" v-show="!!getNameTo(item)">
                            <v-btn x-small text icon color="primary" :to="getNameTo(item)">
                                <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>

                <template #[`item.isDisabled`]="{ item }">
                    <v-switch v-model="item.isDisabled" v-bind="attrs_switch__console" @change="(isDisabled) => update({ _id: item._id, isDisabled })" />
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import { USER_MONEY_TYPES } from "@/assets/variables/constants";
import { initDataTableHeaders } from "@/assets/variables/inits";
import { attrs_input__console, attrs_switch__console } from "@/assets/variables/attrs";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import UserMoneyForm from "@/components/console/users/moneys/user-money-form.vue";
import UserMoneySearch from "@/components/console/users/moneys/user-money-search.vue";
import UserMoneyTypes from "@/components/console/users/moneys/user-money-types.vue";

export default {
    components: {
        ListHeading,

        UserMoneyForm,
        UserMoneySearch,
        UserMoneyTypes,
    },
    data: () => ({
        moneys: [],

        limit: 10,
        summary: { totalCount: 0 },

        attrs_input__console,
        attrs_switch__console,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        ...mapGetters(["hasCompanyScope", "hasConsoleScope"]),
        mode() {
            return this.$route.query["mode"] || "default";
        },
        title() {
            switch (this.mode) {
                case "pending": {
                    return "예정 예치금 목록";
                }
                case "default":
                default: {
                    return "확정 예치금 목록";
                }
            }
        },
        headers() {
            const items = [
                {
                    text: "#",
                    value: "code",
                    width: 150,
                },
                {
                    text: "분류",
                    value: "type",
                    width: 80,
                    formatter: (value) => USER_MONEY_TYPES[value]?.text,
                    hides: this.mode != "default",
                },
                {
                    text: "회원",
                    value: "user",
                    width: 160,
                    cellClass: "caption text-truncate max-width-0",
                    withTitle: true,
                    formatter: (value) => {
                        const { nickname, username } = value;
                        return `${nickname || "-"} (${username})`;
                    },
                    hides: this.hasCompanyScope,
                },
                {
                    text: "명칭",
                    value: "name",
                    cellClass: "max-width-0",
                    withTitle: true,
                    formatter: this.$decode__productOptionName,
                },
                {
                    text: "판매자\r\n판매금액",
                    value: "sales",
                    width: 110,
                    align: "end",
                    formatter: (value, item) => `${(item?.coupon_user + item?.charge + item?.amount)?.format?.()}원`,
                    excel: (value, item) => item?.coupon_user + item?.charge + item?.amount,
                },
                {
                    text: "쿠폰할인\r\n부담금액",
                    value: "coupon_user",
                    width: 100,
                    align: "end",
                    formatter: (value) => `${value?.format?.()}원`,
                    excel: (value) => value,
                },
                {
                    text: "판매장려\r\n지원금",
                    value: "grants",
                    width: 100,
                    align: "end",
                    formatter: (value) => `${value?.format?.()}원`,
                    excel: (value) => value,
                },
                {
                    text: "플랫폼\r\n수수료",
                    value: "charge",
                    width: 100,
                    align: "end",
                    formatter: (value) => `${value?.format?.()}원`,
                    excel: (value) => value,
                },
                {
                    text: "예치금액",
                    value: "amount",
                    width: 110,
                    align: "end",
                    formatter: (value) => `${value?.format?.()}원`,
                    excel: (value) => value,
                },
                // {
                //     text: "잔여/사용",
                //     value: "amount_left",
                //     width: 120,
                //     align: "end",
                //     formatter: (value, { type, amount_used }) => (type == USER_MONEY_TYPES.DECREASE.value ? "-" : `${value?.format?.()}/${amount_used?.format?.()}`),
                // },
                {
                    text: "총잔여\r\n예치금",
                    value: "remain",
                    width: 120,
                    align: "end",
                    formatter: (value) => `${value?.format?.()}원`,
                    excel: (value) => value,
                    hides: this.mode != "default",
                },
                {
                    text: "총잔여 (사이트)",
                    value: "remain_site",
                    width: 120,
                    align: "end",
                    formatter: (value) => `${value?.format?.()}원`,
                    excel: (value) => value,
                    hides: this.hasCompanyScope || this.mode != "default",
                },
                {
                    text: "입금일시 / 출금일시",
                    value: "transactedAt",
                    width: 100,
                    formatter: (value) => value?.toDateTime?.() || value || "-",
                    hides: this.mode != "default",
                },
                {
                    text: "정산일시",
                    value: "createdAt",
                    width: 160,
                    formatter: (value) => value?.toDateTime?.() || value || "-",
                    hides: this.mode != "pending",
                },
                {
                    text: "출금금지",
                    value: "isDisabled",
                    width: 90,
                    hides: this.hasCompanyScope || this.mode != "default",
                },
            ];

            return initDataTableHeaders(items.filter(({ hides }) => !(hides ?? false)));
        },
        items() {
            return [...this.moneys];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            if (query["searchDateValue"]) {
                try {
                    query["searchDateValue"] = JSON.parse(query["searchDateValue"]);
                } catch {
                    query["searchDateValue"] = query["searchDateValue"];
                }

                if (!query["searchDateValue"]) delete query["searchDateValue"];
                else {
                    query["searchDateKey"] = (() => {
                        switch (this.mode) {
                            case "pending": {
                                return "createdAt";
                            }
                            case "default":
                            default: {
                                return "transactedAt";
                            }
                        }
                    })();
                }
            }

            switch (this.mode) {
                case "pending": {
                    query["isPending"] = JSON.stringify({ $eq: true });
                    break;
                }
            }

            return { ...query };
        },
        sort() {
            switch (this.mode) {
                case "pending": {
                    return JSON.stringify({ createdAt: -1, _id: -1 });
                }
                case "default":
                default: {
                    return JSON.stringify({ transactedAt: -1, createdAt: -1, _id: -1 });
                }
            }
        },
    },
    mounted() {
        this.reroute().then(this.init).then(this.search);
    },
    watch: {
        mode() {
            this.reroute().then(this.init).then(this.search);
        },
        params() {
            this.reroute().then(this.search);
        },
    },
    methods: {
        async reroute() {
            const { mode } = this;
            if (this.$route.query["mode"] != mode) {
                this.$router.replace({
                    query: {
                        ...this.$route.query,
                        mode,
                    },
                });
            }
        },

        async init() {
            this.moenys = [];
            this.summary = { totalCount: 0 };
            this.loading = false;
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            let { sort, skip, limit, params, mode } = this;
            try {
                let { summary, moneys } = await api.console.users.moneys.gets({
                    headers: { sort, skip, limit },
                    params,
                });
                if (this.mode == mode) {
                    this.summary = summary;
                    this.moneys = moneys;
                }
            } finally {
                if (this.mode == mode) {
                    this.loading = false;
                }
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { money } = await api.console.users.moneys.put(item);
                this.loading = false;
                this.updateItem(money);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(item) {
            const index = this.moneys.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.moneys.splice(index, 1, item);
            else {
                this.moneys = [item, ...this.moneys];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        getNameTo(item) {
            if (item.shippingGroup) {
                return {
                    path: "/console/shop/purchases/all",
                    query: {
                        searchKey: "orderNo",
                        searchValue: item.shippingGroup.orderNo,
                    },
                };
            }

            if (item.purchase) {
                return {
                    path: "/console/shop/purchases/all",
                    query: {
                        searchKey: "purchaseNo",
                        searchValue: item.purchase.purchaseNo,
                    },
                };
            }
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { params } = this;
                let { moneys } = await api.console.users.moneys.gets({
                    headers: { sort: this.sort },
                    params,
                });

                let fileName = `${this.mode === 'default' ? '확정' : '예정'} 예치금`;

                this.$excel(moneys, this.headers, fileName);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
