var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-edit-dialog', _vm._g({
    attrs: {
      "return-value": _vm.item.state,
      "large": "",
      "cancel-text": "취소",
      "save-text": "변경"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.item, "state", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.item, "state", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-select', _vm._b({
          staticClass: "mt-3",
          attrs: {
            "items": _vm.states
          },
          model: {
            value: _vm.item.state,
            callback: function ($$v) {
              _vm.$set(_vm.item, "state", $$v);
            },
            expression: "item.state"
          }
        }, 'v-select', _vm.attrs_input__console, false))];
      },
      proxy: true
    }])
  }, {
    save: _vm.save
  }), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }