<template>
    <v-row>
        <v-col cols="12"> 
            <tags-recommendations-view/>
        </v-col>
        <v-col cols="12">
            <tags-products-view/>
        </v-col>
    </v-row>
</template>

<script>
import TagsRecommendationsView from "@/components/console/analytics/tags/tags-recommendations-view.vue";
import TagsProductsView from "@/components/console/analytics/tags/tags-products-view.vue";

export default {
    components: {
        TagsRecommendationsView,
        TagsProductsView,
    },
};
</script>

<style>
</style>