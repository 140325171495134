var render = function render(){
  var _vm$value, _vm$value2, _vm$value2$board, _vm$value3, _vm$value4, _vm$value5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-img', {
    attrs: {
      "src": _vm.value.thumbSrc
    }
  }), _c('v-card-title', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.subject) || ((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$board = _vm$value2.board) === null || _vm$value2$board === void 0 ? void 0 : _vm$value2$board.subject)))]), _c('v-card-text', [_vm._v(" " + _vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : _vm$value3.computedContent) + " ")]), _c('v-divider'), 0 < ((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : _vm$value4.products.length) ? [_c('v-list', [_vm._l((_vm$value5 = _vm.value) === null || _vm$value5 === void 0 ? void 0 : _vm$value5.products, function (product) {
    var _product$thumb, _product$salePrice, _product$salePrice$fo;
    return [_c('v-list-item', {
      key: product._id
    }, [_c('v-list-item-avatar', [_c('v-img', {
      attrs: {
        "src": product === null || product === void 0 ? void 0 : (_product$thumb = product.thumb) === null || _product$thumb === void 0 ? void 0 : _product$thumb.src
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-icon', [_vm._v(" mdi-image-broken ")])];
        },
        proxy: true
      }], null, true)
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(product === null || product === void 0 ? void 0 : product.name) + " ")]), _c('span', {
      staticClass: "font-weight-bold red--text text--darken-4"
    }, [_vm._v(" " + _vm._s(product === null || product === void 0 ? void 0 : (_product$salePrice = product.salePrice) === null || _product$salePrice === void 0 ? void 0 : (_product$salePrice$fo = _product$salePrice.format) === null || _product$salePrice$fo === void 0 ? void 0 : _product$salePrice$fo.call(_product$salePrice)) + "원 ")])], 1)], 1)];
  })], 2)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }