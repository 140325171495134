<template>
    <v-combobox v-model="form.values" multiple item-text="name" append-icon="" placeholder="항목 작성 후 Enter키를 입력해주세요" v-bind="attrs_input__verticalTable" v-on="{ input }">
        <template #selection="{ item, parent }">
            <v-chip outlined label close @click:close="parent.selectItem(item)"> {{ item.name }} </v-chip>
        </template>
    </v-combobox>
</template>

<script>
import { attrs_input__verticalTable } from "@/assets/variables/attrs";
import { initProductOptionCriterion, initProductOptionCriterionValue } from "@/assets/variables/inits";

export default {
    props: {
        value: { type: Object, default: initProductOptionCriterion },
    },
    setup: () => ({
        attrs_input__verticalTable,
    }),
    data: () => ({
        form: initProductOptionCriterion(),
    }),
    methods: {
        sync() {
            this.form = initProductOptionCriterion(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
        input(values = []) {
            this.form.values = values.map((item) => initProductOptionCriterionValue(typeof item == "object" ? item : { name: item }));
            this.emit();
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style></style>
