var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": "1440",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "배너관리"
    }
  }), _c('div', {
    staticClass: "caption primary--text"
  }, [_vm._v("* PC 배너 사이즈 1920*760(px) / 모바일 배너 사이즈 720*1180(px)")]), _c('v-data-table', _vm._b({
    staticClass: "elevation-1 mt-4",
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.createdAt`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.createdAt ? item.createdAt.toDate() : "") + " ")];
      }
    }, {
      key: `item.slides`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return _vm._l(item.slides.filter(function (slide) {
          return slide.image;
        }), function (slide, index) {
          return _c('v-img', {
            key: index,
            staticClass: "d-inline-block",
            attrs: {
              "src": slide.image,
              "max-width": "96",
              "max-height": "96",
              "contain": ""
            }
          });
        });
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "to": `/console/banners/${item === null || item === void 0 ? void 0 : item._id}`,
            "x-small": "",
            "icon": "",
            "text": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-pencil ")])], 1), _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    loading: _vm.loading
  }, false)), _c('v-pagination', {
    staticClass: "my-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1), _c('v-btn', {
    attrs: {
      "color": "primary",
      "fab": "",
      "fixed": "",
      "right": "",
      "bottom": "",
      "to": "/console/banners/create"
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }