var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "560",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "light": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이름"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "영문명"
    },
    model: {
      value: _vm.form.engName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "engName", $$v);
      },
      expression: "form.engName"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "코드"
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.korCharItems,
      "label": "초성(한글)"
    },
    model: {
      value: _vm.form.searchChar[0],
      callback: function ($$v) {
        _vm.$set(_vm.form.searchChar, 0, $$v);
      },
      expression: "form.searchChar[0]"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.engCharItems,
      "label": "초성(영문)"
    },
    model: {
      value: _vm.form.searchChar[1],
      callback: function ($$v) {
        _vm.$set(_vm.form.searchChar, 1, $$v);
      },
      expression: "form.searchChar[1]"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input__console, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('span', {
    staticClass: "caption"
  }, [_vm._v("라벨")]), _c('image-input', {
    attrs: {
      "accept": "image/*",
      "outlined": "",
      "aspect-ratio": 1 / 1,
      "contain": ""
    },
    model: {
      value: _vm.form.label,
      callback: function ($$v) {
        _vm.$set(_vm.form, "label", $$v);
      },
      expression: "form.label"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('span', {
    staticClass: "caption"
  }, [_vm._v("목록")]), _c('image-input', {
    attrs: {
      "accept": "image/*",
      "outlined": "",
      "aspect-ratio": 1 / 1,
      "contain": ""
    },
    model: {
      value: _vm.form.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.form, "thumb", $$v);
      },
      expression: "form.thumb"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2",
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('span', {
    staticClass: "caption"
  }, [_vm._v("상세")]), _c('image-input', {
    attrs: {
      "accept": "image/*",
      "outlined": "",
      "aspect-ratio": 1 / 1,
      "contain": ""
    },
    model: {
      value: _vm.form.image,
      callback: function ($$v) {
        _vm.$set(_vm.form, "image", $$v);
      },
      expression: "form.image"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-switch', _vm._b({
    attrs: {
      "dense": ""
    },
    model: {
      value: _vm.form.shows,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shows", $$v);
      },
      expression: "form.shows"
    }
  }, 'v-switch', _vm.attrs_switch__console, false), [_c('span', {
    staticClass: "caption white-space-pre-line",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" " + _vm._s(_vm.form.shows ? "목록\r\n표시" : "목록\r\n미표시") + " ")])])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }