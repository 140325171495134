<template>
    <v-row class="ma-n2">
        <v-col cols="12" md="12" class="pa-2">
            <v-select v-model="form.issue.target.type" label="발급대상" :items="targetTypes" v-bind="attrs_input__console" @input="emit" />
        </v-col>
        <v-col cols="12" md="12" class="pa-0">
            <v-expand-transition>
                <div v-show="form.issue.target.type == ISSUE_TARGET_TYPES.USERS.value">
                    <div class="pa-2">
                        <autocomplete-user v-model="form.issue.target._users" :label="'발급대상 ' + ISSUE_TARGET_TYPES.USERS.text" multiple :dense="false" :itemText="({ name, username }) => `${name} (${username})`" v-bind="attrs_input__console" @input="emit">
                            <template #selection="{ parent, item, attrs, itemText }">
                                <v-chip outlined label close v-bind="attrs" @click:close="parent.selectItem(item)" :key="item._id">{{ itemText(item) }}</v-chip>
                            </template>
                        </autocomplete-user>
                    </div>
                </div>
            </v-expand-transition>
            <v-expand-transition>
                <div v-show="form.issue.target.type == ISSUE_TARGET_TYPES.USER_TYPES.value">
                    <div class="pa-2">
                        <v-select v-model="form.issue.target.userTypes" :label="'발급대상 ' + ISSUE_TARGET_TYPES.USER_TYPES.text" :items="userTypes" multiple v-bind="attrs_input__console" @input="emit" />
                    </div>
                </div>
            </v-expand-transition>
            <v-expand-transition>
                <div v-show="form.issue.target.type == ISSUE_TARGET_TYPES.USER_LEVELS.value">
                    <div class="pa-2">
                        <autocomplete-user-level v-model="form.issue.target._levels" :label="'발급대상 ' + ISSUE_TARGET_TYPES.USER_LEVELS.text" multiple :dense="false" v-bind="attrs_input__console" @input="emit">
                            <template #selection="{ parent, item, attrs, itemText }">
                                <v-chip outlined label close v-bind="attrs" @click:close="parent.selectItem(item)" :key="item._id">{{ itemText(item) }}</v-chip>
                            </template>
                        </autocomplete-user-level>
                    </div>
                </div>
            </v-expand-transition>
        </v-col>
    </v-row>
</template>

<script>
import { attrs_input__console, initShopCoupon } from "@/assets/variables";
import { ISSUE_TARGET_TYPES, USER_TYPES } from "@/assets/variables/constants";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteUserLevel from "@/components/dumb/autocomplete-user-level.vue";

const userTypes = Object.values(USER_TYPES);
const targetTypes = Object.values(ISSUE_TARGET_TYPES);

export default {
    components: {
        AutocompleteUser,
        AutocompleteUserLevel,
    },
    props: {
        value: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        form: initShopCoupon(),

        userTypes,
        targetTypes,
        ISSUE_TARGET_TYPES,

        attrs_input__console,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopCoupon(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style>
</style>