<template>
    <v-row class="ma-n2">
        <v-col cols="12" md="12" class="pa-2">
            <v-select v-model="form.issue.target.type" label="부과대상" :items="targetTypes" v-bind="attrs_input__console" @input="emit" />
        </v-col>
        <v-col cols="12" md="12" class="pa-0">
            <v-expand-transition>
                <div v-show="form.issue.target.type == ISSUE_TARGET_TYPES.USERS.value">
                    <div class="pa-2">
                        <autocomplete-company v-model="form.issue.target._users" :label="'부과대상 ' + ISSUE_TARGET_TYPES.USERS.text" multiple :dense="false" v-bind="{ ...attrs_input__console, ...props__autocompleteUser }" @input="emit">
                            <template #selection="{ parent, item, attrs, itemText }">
                                <v-chip outlined label close v-bind="attrs" @click:close="parent.selectItem(item)" :key="item._id">{{ itemText(item) }}</v-chip>
                            </template>
                        </autocomplete-company>
                    </div>
                </div>
            </v-expand-transition>
            <!-- <v-expand-transition>
                <div v-show="form.issue.target.type == ISSUE_TARGET_TYPES.USER_TYPES.value">
                    <div class="pa-2">
                        <v-select v-model="form.issue.target.userTypes" :label="'발급대상 ' + ISSUE_TARGET_TYPES.USER_TYPES.text" :items="userTypes" multiple v-bind="attrs_input__console" @input="emit" />
                    </div>
                </div>
            </v-expand-transition> -->
            <!-- <v-expand-transition>
                <div v-show="form.issue.target.type == ISSUE_TARGET_TYPES.USER_LEVELS.value">
                    <div class="pa-2">
                        <autocomplete-user-level v-model="form.issue.target._levels" :label="'발급대상 ' + ISSUE_TARGET_TYPES.USER_LEVELS.text" multiple :dense="false" v-bind="attrs_input__console" @input="emit">
                            <template #selection="{ parent, item, attrs, itemText }">
                                <v-chip outlined label close v-bind="attrs" @click:close="parent.selectItem(item)" :key="item._id">{{ itemText(item) }}</v-chip>
                            </template>
                        </autocomplete-user-level>
                    </div>
                </div>
            </v-expand-transition> -->
        </v-col>
    </v-row>
</template>

<script>
import { initShopMoney__charge } from "@/assets/variables/inits";
import { attrs_input__console } from "@/assets/variables/attrs";
import { ISSUE_TARGET_TYPES, USER_TYPES } from "@/assets/variables/constants";

// import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteCompany from "@/components/dumb/autocomplete-company.vue";
import AutocompleteUserLevel from "@/components/dumb/autocomplete-user-level.vue";

// const userTypes = Object.values(USER_TYPES);
const targetTypes = [ISSUE_TARGET_TYPES.ALL, ISSUE_TARGET_TYPES.USERS];

const props__autocompleteUser = {
    params: {
        type: USER_TYPES.COMPANY.value,
    },
    itemText: ({ nickname, username }) => `${nickname} (${username})`,
};

export default {
    components: {
        // AutocompleteUser,
        AutocompleteCompany,
        AutocompleteUserLevel,
    },
    props: {
        value: { type: Object, default: initShopMoney__charge },
    },
    data: () => ({
        form: initShopMoney__charge(),

        // userTypes,
        targetTypes,
        ISSUE_TARGET_TYPES,

        attrs_input__console,
        props__autocompleteUser,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopMoney__charge(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style></style>
