<template>
    <v-layout justify-center>
        <v-responsive class="px-3 mx-n3">
            <list-heading title="세금계산서 목록" />
            <shop-taxinvoice-search v-bind="{ showsSearch }" />

            <v-row class="mt-n3">
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white" class="caption" v-bind="{ loading }">
                            <v-layout align-baseline>
                                <span> 검색결과: </span>
                                <b class="mx-1"> {{ summary.totalCount?.format?.() }} </b> 건
                            </v-layout>
                        </v-btn>
                    </v-card>
                </v-col>
                <v-spacer />
                <!-- <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white green--text" class="caption" v-bind="{ loading }" @click="excel">
                            <span> <v-icon>mdi-microsoft-excel</v-icon> 엑셀다운로드 </span>
                        </v-btn>
                    </v-card>
                </v-col> -->
            </v-row>

            <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined" style="overflow: hidden">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
                <template #[`item.user`]="{ header, item, value }">
                    <template v-if="loading">
                        <v-progress-linear indeterminate />
                    </template>
                    <template v-else-if="value">
                        {{ header.formatter(value, item) }}
                    </template>
                    <template v-else> - </template>
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-layout justify-center>
                        <shop-taxinvoice-form :value="item" @input="updateItem">
                            <template #activator="{ attrs, on }">
                                <v-btn small text icon tile v-bind="{ ...attrs, loading }" v-on="on">
                                    <v-icon small>mdi-receipt-text-outline</v-icon>
                                </v-btn>
                            </template>
                        </shop-taxinvoice-form>
                        <v-btn small text icon tile v-bind="{ loading, disabled: item.isIssued }" @click="issue(item)">
                            <v-icon small>mdi-receipt-text-send-outline</v-icon>
                        </v-btn>
                        <v-btn small text icon tile v-bind="{ loading, disabled: item.isIssued }" @click="remove(item)">
                            <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                    </v-layout>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";

import { attrs_input__console, attrs_switch__console } from "@/assets/variables/attrs";
import { initDataTableHeaders } from "@/assets/variables/inits";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import ShopTaxinvoiceForm from "@/components/console/shop/taxinvoices/shop-taxinvoice-form.vue";
import ShopTaxinvoiceSearch from "@/components/console/shop/taxinvoices/shop-taxinvoice-search.vue";

export default {
    components: {
        ListHeading,

        ShopTaxinvoiceForm,
        ShopTaxinvoiceSearch,
    },
    data: () => ({
        taxinvoices: [],

        limit: 20,
        summary: { totalCount: 0 },

        attrs_input__console,
        attrs_switch__console,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        ...mapGetters(["hasCompanyScope", "hasConsoleScope"]),
        headers() {
            const items = [
                {
                    text: "#",
                    value: "code",
                    width: 210,
                    align: "end",
                },
                {
                    text: "상호 (법인명)",
                    value: "invoicee.invoiceeCorpName",
                },
                {
                    text: "사업자등록번호",
                    value: "invoicee.invoiceeCorpNum",
                },
                {
                    text: "공급가액 합계",
                    value: "information.supplyCostTotal",
                    formatter: (value) => `${(+value)?.format?.()}원`,
                    width: 140,
                    align: "end",
                },
                {
                    text: "세액 합계",
                    value: "information.taxTotal",
                    formatter: (value) => `${(+value)?.format?.()}원`,
                    width: 140,
                    align: "end",
                },
                {
                    text: "합계",
                    value: "information.totalAmount",
                    formatter: (value) => `${(+value)?.format?.()}원`,
                    width: 140,
                    align: "end",
                },
                {
                    text: "회원",
                    value: "user",
                    formatter: (value) => `${value?.companyName || "-"} (${value?.username || "-"})`,
                    width: 160,
                },
                {
                    text: "발행여부",
                    value: "isIssued",
                    formatter: (value) => (value ? "발행" : "미발행"),
                    width: +90,
                },
                {
                    text: "",
                    value: "actions",
                    width: 120,
                },
            ];

            return initDataTableHeaders(items.filter(({ hides }) => !(hides ?? false)));
        },
        items() {
            return [...this.taxinvoices];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        sort() {
            let sort = { createdAt: -1, _id: -1 };

            switch (this.$route.query.sort) {
            }

            return JSON.stringify(sort);
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.sort;

            if (query["searchDateValue"]) {
                try {
                    query["searchDateValue"] = JSON.parse(query["searchDateValue"]);
                } catch {
                    query["searchDateValue"] = query["searchDateValue"];
                }

                if (!query["searchDateValue"]) delete query["searchDateValue"];
            }

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        mode() {
            this.taxinvoices = [];
            this.search();
        },
        params() {
            this.taxinvoices = [];
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                await this.search(false);
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { mode, sort, skip, limit, params } = this;
                let { summary, taxinvoices } = await api.console.shop.taxinvoices.gets({
                    headers: { mode, sort, skip, limit },
                    params,
                });

                this.summary = summary;
                this.taxinvoices = taxinvoices;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { taxinvoice } = await api.console.shop.taxinvoices.put(item);
                this.loading = false;
                this.updateItem(taxinvoice);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(item) {
            const index = this.taxinvoices.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.taxinvoices.splice(index, 1, item);
            else {
                this.taxinvoices = [item, ...this.taxinvoices];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async issue({ _id }) {
            const go = confirm("해당 세금계산서에 대한 발행 요청을 발송하시겠습니까?");
            if (!go) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                await api.console.shop.taxinvoices.postIssue({ _id });
                alert("세금계산서가 발행요청이 성공적으로 발송되었습니다");
                this.loading = false;
                await this.saerch();
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        async remove({ _id }) {
            const go = confirm("해당 세금계산서를 삭제하시겠습니까?");
            if (!go) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                await api.console.shop.taxinvoices.delete({ _id });
                alert("세금계산서가 성공적으로 삭제되었습니다");
                this.loading = false;
                await this.saerch();
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
