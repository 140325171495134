<template>
    <v-layout justify-center fill-height>
        <v-responsive max-width="1600" width="100%" height="100%">
            <list-heading v-bind="{ title }" />
            <alarm-list-types/>
            <v-row>
                <v-col cols="5">
                    <alarm-list v-bind="{ alarms }" @show="sync"/>
                </v-col>
                <v-col cols="7">
                    <alarm-form v-bind="{ value }" @save="search"/>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>
  
<script>
import api from "@/api";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import AlarmList from "@/components/console/shop/alarms/alarm-list.vue";
import AlarmForm from "@/components/console/shop/alarms/alarm-form.vue";
import AlarmListTypes from "@/components/console/shop/alarms/alarm-list-types.vue";

export const initAlarmTemplate = (form = {}) => ({
    ...form,

    _id: form?._id || null,
    
    name: form?.name || "",
    code: form?.code || "",
    category: form?.category || "",
    type: form?.type || "",
    template: form?.template || "",
    target: form?.target || "",
});

export default {
    components: {
        ListHeading,
        AlarmList,
        AlarmForm,
        AlarmListTypes
    },
    data: () => ({
        loading: false,
        alarms: [],

        value: initAlarmTemplate(),
    }),
    computed: {
        title() {
            return "알람 발송 목록";
        },
        params() {
            let { ...query } = this.$route.query;

            return query;
        }
    },
    methods: {
        async search() {
            if(this.loading) return;
            else this.loading = true;

            try {
                const { summary, alarms } = await api.console.shop.alarms.gets({
                    params: this.params
                });
                this.alarms = alarms;
                this.summary = summary;
            } catch(error){
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        sync(value) {
            this.value = initAlarmTemplate(value);
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        params() {
            this.search();
        }
    }
};
</script>