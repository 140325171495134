<template>
    <v-row class="ma-n2">
        <v-col cols="12" md="12" class="pa-2 subtitle-1">
            <v-dialog width="400">
                <template #activator="{ attrs, on }">
                    <span v-bind="attrs" v-on="on"> 사용제한 <v-icon small>mdi-information-outline</v-icon> </span>
                </template>
                <v-card>
                    <v-card-title class="subtitle-1">사용제한</v-card-title>
                    <v-card-text>
                        <v-list dense>
                            <v-list-item dense class="pa-0 my-n4 caption"> ※ 값이 0일 때 제한 미적용 </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-col>
        <v-col cols="12" md="6" class="pa-2">
            <v-currency-field v-model="form.usage.limit.shop.count__sum" label="전체사용수량제한" prefix="최대" suffix="개" class="text-align-right-field" v-bind="attrs_input__console" @input="emit" />
        </v-col>
        <v-col cols="12" md="6" class="pa-2">
            <v-currency-field v-model="form.usage.limit.shop.price__sum" label="전체사용금액제한" prefix="최대" suffix="원" class="text-align-right-field" v-bind="attrs_input__console" @input="emit" />
        </v-col>
        <v-col cols="12" md="6" class="pa-2">
            <v-currency-field v-model="form.usage.limit.target.price__min" label="대상상품금액제한" prefix="최소" suffix="원" class="text-align-right-field" v-bind="attrs_input__console" @input="emit" />
        </v-col>
        <v-col cols="12" md="6" class="pa-2">
            <v-currency-field v-model="form.usage.limit.target.price__max" label="대상상품금액제한" prefix="최대" suffix="원" class="text-align-right-field" v-bind="attrs_input__console" @input="emit" />
        </v-col>
    </v-row>
</template>

<script>
import { attrs_input__console, initShopCoupon } from "@/assets/variables";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteUserLevel from "@/components/dumb/autocomplete-user-level.vue";

export default {
    components: {
        AutocompleteUser,
        AutocompleteUserLevel,
    },
    props: {
        value: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        form: initShopCoupon(),

        attrs_input__console,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopCoupon(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style>
</style>