<template>
    <v-edit-dialog :return-value.sync="item.category" large cancel-text="취소" save-text="변경" v-on="{ save }">
        <slot />
        <template #input> <v-select v-model="item.category" :items="categories" multiple v-bind="attrs_input__console" class="mt-3" /> </template>
    </v-edit-dialog>
</template>

<script>
import { attrs_input__console, COMPANY_CATEGORY_TYPES } from "@/assets/variables";

const categories = Object.values(COMPANY_CATEGORY_TYPES);

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        item: {},
        categories,
        attrs_input__console,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.item = JSON.parse(JSON.stringify(this.value));
        },
        save() {
            let { _id, category } = this.item;
            let form = { _id, category };

            this.$emit("update", form);
        },
    },
};
</script>

<style>
</style>