<template>
    <view-section-card title="묶음상품" hideDivider v-bind="{ loading }">
        <template #actions v-if="form.type == PRODUCT_TYPES.NORMAL_PRODUCT.value">
            <v-switch v-model="form.variation.enabled" v-bind="attrs_switch__console" @change="emit" />
        </template>
        <v-expand-transition>
            <v-sheet v-show="form.type == PRODUCT_TYPES.VARIETY_BUNDLE.value">
                <v-divider />
                <variation-bundle v-model="form" @input="emit" />
            </v-sheet>
        </v-expand-transition>
        <v-expand-transition>
            <v-sheet v-show="form.type == PRODUCT_TYPES.NORMAL_PRODUCT.value && form.variation.enabled">
                <v-divider />
                <variation-normal v-model="form" @input="emit" />
            </v-sheet>
        </v-expand-transition>
    </view-section-card>
</template>

<script>
import { attrs_switch__console, initProduct, PRODUCT_TYPES } from "@/assets/variables";

import VariationBundle from "./variation-bundle.vue";
import VariationNormal from "./variation-normal.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        VariationBundle,
        VariationNormal,
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initProduct },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initProduct(),

        attrs_switch__console,
        PRODUCT_TYPES,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initProduct(this.value);
        },

        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
