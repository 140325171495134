<template>
    <v-card outlined>
        <v-card-text class="white pa-0">
            <v-select v-model="query['sortField']" v-bind="{ ...attrs_input__console, items }" solo flat :outlined="false" class="caption" @change="emit" />
        </v-card-text>
    </v-card>
</template>

<script>
import { attrs_input__console } from "@/assets/variables";

const initQuery = (query = {}) => ({
    ...query,

    ["sortField"] : query["sortField"] || "purchasePrice",
});

const items = [
    { text: "판매금액", value: "purchasePrice" },
    { text: "판매수량", value: "purchaseAmount" },
];

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        query: initQuery(),

        items,
        attrs_input__console,
    }),
    methods: {
        sync() {
            this.query = initQuery({ ...this.value});
        },
        emit() {
            const { ...query } = this.query;

            query["page"] = 1;

            if (query["sortField"] == null) query["sortField"] = "purchasePrice";

            this.$emit("input", query);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>