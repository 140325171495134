<template>
    <v-row class="ma-n2">
        <v-col cols="12" md="6" class="pa-2">
            <target-price v-model="form" @input="emit" />
        </v-col>
        <v-col cols="12" md="6" class="pa-2">
            <target-type v-model="form" @input="emit" />
        </v-col>
        <v-col cols="12" md="12" class="pa-0">
            <v-expand-transition>
                <div v-show="form.usage.target.type == USAGE_TARGET_TYPES.PRODUCTS.value">
                    <div class="pa-2">
                        <target-products v-model="form" @input="emit" />
                    </div>
                </div>
            </v-expand-transition>
            <v-expand-transition>
                <div v-show="form.usage.target.type == USAGE_TARGET_TYPES.EXHIBITS.value">
                    <div class="pa-2">
                        <autocomplete-exhibition v-model="form.usage.target._exhibitions" :label="`사용대상 ` + USAGE_TARGET_TYPES.EXHIBITS.text" multiple :dense="false" v-bind="attrs_input__console" @input="emit">
                            <template #selection="{ parent, item, attrs, itemText }">
                                <v-chip outlined label close v-bind="attrs" @click:close="parent.selectItem(item)" :key="item._id">{{ itemText(item) }}</v-chip>
                            </template>
                        </autocomplete-exhibition>
                    </div>
                </div>
            </v-expand-transition>
            <v-expand-transition>
                <div v-show="form.usage.target.type == USAGE_TARGET_TYPES.DISPLAYS.value">
                    <div class="pa-2">
                        <v-select v-model="form.usage.target.displayCodes" :label="'사용대상 ' + USAGE_TARGET_TYPES.DISPLAYS.text" :items="displayCodes" multiple v-bind="attrs_input__console" @input="emit" />
                    </div>
                </div>
            </v-expand-transition>
            <v-expand-transition>
                <div v-show="form.usage.target.type == USAGE_TARGET_TYPES.CATEGORY.value">
                    <div class="pa-2">
                        <autocomplete-category v-model="form.usage.target._categories" :label="'사용대상 ' + USAGE_TARGET_TYPES.CATEGORY.text" multiple :params="{ getFullName: true }" :dense="false" v-bind="attrs_input__console" @input="emit">
                            <template #selection="{ parent, item, attrs, itemText }">
                                <v-chip outlined label close v-bind="attrs" @click:close="parent.selectItem(item)" :key="item._id">{{ itemText(item) }}</v-chip>
                            </template>
                        </autocomplete-category>
                    </div>
                </div>
            </v-expand-transition>
        </v-col>
    </v-row>
</template>

<script>
import { attrs_input__console, initShopCoupon } from "@/assets/variables";
import { USAGE_TARGET_PRICES, USAGE_TARGET_TYPES } from "@/assets/variables/constants";

import AutocompleteCompany from "@/components/dumb/autocomplete-company.vue";
import AutocompleteCategory from "@/components/dumb/autocomplete-category.vue";
import AutocompleteExhibition from "@/components/dumb/autocomplete-exhibition.vue";

import TargetPrice from "./target/target-price.vue";
import TargetProducts from "./target/target-products.vue";
import TargetType from "./target/target-type.vue";

export default {
    components: {
        AutocompleteCompany,
        AutocompleteCategory,
        AutocompleteExhibition,

        TargetPrice,
        TargetProducts,
        TargetType,
    },
    props: {
        value: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        attrs_input__console,
        USAGE_TARGET_TYPES,

        form: initShopCoupon(),
    }),
    computed: {
        displayCodes() {
            return this.$store.getters.displayCodes?.map?.(({ text, value }) => ({ text, value }));
        },
        _seller() {
            return this.form._seller;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopCoupon(this.value);
        },
        emit() {
            if (this.form?.usage?.target?.price == USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value) {
                this.form.usage.target.type = USAGE_TARGET_TYPES.ALLITEMS.value;
            }

            this.$emit("input", this.form);
        },
    },
};
</script>

<style></style>
