<template>
    <v-container fluid fill-height class="console-notification">
        <v-layout justify-center>
            <v-responsive max-width="1400px" width="100%">
                <list-heading v-model="showsSearch" title="카테고리 공통 이미지 목록">
                    <template #add-button>
                        <category-detail-form @input="search">
                            <template #activator="{ attrs, on }">
                                <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                            </template>
                        </category-detail-form>
                    </template>
                </list-heading>

                <v-data-table v-bind="{ loading, headers: itemHeaders, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                    <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                    <template #[`item.no`]="{ item }">
                        {{ (page - 1) * limit + categoryDetails.indexOf(item) + 1 }}
                    </template>
                    <template #[`item._category`]="{ item }">
                        {{ categories.find((category) => category._id === item._category)?.name }}
                    </template>
                    <template #[`item.user`]="{ item }">
                        {{ `${item?.seller?.companyName || "최고관리자" }`}}
                    </template>
                    <template #[`item.body`]="{ item }">
                        <v-responsive>
                            <v-row @click="$set(item, 'show', !item.show)">
                                <!-- <v-col class="text-start">{{ item.body }}</v-col> -->
                                <v-spacer />
                                <v-col cols="auto">
                                    <v-icon v-if="!item.show">mdi-chevron-down</v-icon>
                                    <v-icon v-else>mdi-chevron-up</v-icon>
                                </v-col>
                            </v-row>
                            <v-expand-transition>
                                <v-row v-show="item.show">
                                    <v-col cols="12">
                                        <p v-html="item.body" class="category-content"></p>
                                    </v-col>
                                </v-row>
                            </v-expand-transition>
                        </v-responsive>
                    </template>
                    <template #[`item.enabled`]="{ item }">
                        <v-switch v-model="item.enabled" v-bind="{ ...attrs_switch__console }" class="mt-0" hide-details @change="update(item)"></v-switch>
                    </template>
                    <template #[`item.actions`]="{ item }">
                        <category-detail-form v-bind="{ _id: item._id}" @input="search">
                            <template #activator="{ attrs, on }">
                                <v-btn small text icon v-bind="attrs" v-on="on"> <v-icon small>mdi-pencil</v-icon> </v-btn>
                            </template>
                        </category-detail-form>
                        <v-icon small @click="remove(item)"> mdi-delete </v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search" class="mt-4 mb-12" />
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import { mapActions } from "vuex";
import { attrs_switch__console, CATEGORY_DETAILS_TYPES, initDataTableHeaders } from "@/assets/variables";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import CategoryDetailForm from "@/components/console/shop/category-details/category-detail-form.vue";

const headers = initDataTableHeaders(
    [
        { divider: true, width: 40, text: "카테고리", value: "_category" },
        { divider: true, width: 30, text: "유형", value: "type", formatter: (value) => CATEGORY_DETAILS_TYPES[value].text ?? "-" },
        // { divider: true, width: 60, text: "업체명", value: "user", test: (value) => value.includes("console") },
        { divider: true, width: 300, text: "내용", value: "body" },
        { divider: true, width: 20, text: "적용", value: "enabled" },
        { divider: true, width: 60, text: "생성일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() },
        { width: 40, text: "", value: "actions", align: "center" },
    ]
    .map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-") }))
);

export default {
    components: {
        ListHeading,
        CategoryDetailForm,
    },
    data: () => {
        return {
            showsSearch: false,
            headers,
            attrs_switch__console,
            loading: false,

            notificationDialog: false,

            categoryDetails: [],

            limit: 20,
            summary: { totalCount: 0 },
        };
    },
    computed: {
        itemHeaders() {
            return this.headers.filter((header)=> header?.test ? header.test(this.$store.state.scope) : true);
        },
        items() {
            return [...this.categoryDetails];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
        categories() {
            return this.$store.state.categories;
        },
    },
    methods: {
        ...mapActions(["getCategories"]),
        async init() {
            this.getCategories();
            this.search();
        },

        async search() {
            try {
                var { summary, categoryDetails } = await api.console.shop.categoryDetails.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                });

                this.categoryDetails = categoryDetails;
                this.summary = summary;
            } catch (error) {
                console.error(error);
            }
        },

        async update(item) {
            try {
                await api.console.shop.categoryDetails.put(item);
                await this.search();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        async remove(item) {
            try {
                if (confirm("해당 상세 이미지를 삭제하시겠습니까?")) {
                    await api.console.shop.categoryDetails.delete(item);
                    await this.search();
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.console-notification .v-data-table__mobile-row {
    height: auto !important;
}
.category-content {
    ::v-deep {
        img {
            max-width: 100%;
        }
    }
}
</style>
