<template>
    <list-search v-bind="{ showsSearch }" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query.createdAt[0]" type="date" label="결제 생성일시(시작)" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query.createdAt[1]" type="date" label="결제 생성일시(종료)" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-select v-model="query['searchKey']" :items="searchKeys" label="검색대상" placeholder="전체" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query['searchValue']" label="검색어" clearable v-bind="attrs_input__console" @keydown.enter="emit" />
            </v-col>
            <template>
                <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                    <v-select v-model="query['status']" :items="statuses" label="결제상태" placeholder="전체" clearable v-bind="attrs_input__console" />
                </v-col>
            </template>
        </v-row>
    </list-search>
</template>

<script>
import { mapGetters } from "vuex";
import { attrs_input__console, USER_PAYMENT_STATUS } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

const statuses = Object.values(USER_PAYMENT_STATUS);

let initQuery = (query = {}) => ({
    ...query,

    ["createdAt"]: query?.["createdAt"] || [null, null],

    ["user"]: query?.["user"] ?? null,
    ["seller"]: query?.["seller"] ?? null,
    ["product"]: query?.["product"] ?? null,
    ["status"]: query?.["status"] ?? null,
    ["searchKey"]: query?.["searchKey"] ?? null,
    ["searchValue"]: query?.["searchValue"] ?? null,
});

export default {
    components: {
        ListSearch,
        AutocompleteUser,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        statuses,
        attrs_input__console,
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
        searchKeys() {
            return [
                { text: "결제코드", value: "code" },
                { text: "상품명", value: "product" },
                { text: "결제자 아이디", value: "user" },
                { text: "요청자 아이디", value: "seller", test: (value) => value },
            ].filter((item) => (item?.test ? item.test(this.hasConsoleScope) : true));
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            if (!query["createdAt"]) delete query["createdAt"];
            if (!query["createdAt"]?.[0] && !query?.createdAt?.[1]) delete query["createdAt"];

            if (!query["user"]) delete query["user"];
            if (!query["seller"]) delete query["seller"];
            if (!query["product"]) delete query["product"];
            if (!query["status"]) delete query["status"];
            if (!query["searchKey"]) delete query["searchKey"];
            if (!query["searchValue"]) delete query["searchValue"];

            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
