<template>
    <v-dialog v-model="shows" width="600" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-0">
                <v-row>
                    <v-col v-if="!isCreate" class="py-2" cols="12">
                        <v-text-field v-model="status" disabled label="결제상태" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                    <v-col class="py-2" cols="6">
                        <autocomplete-user v-model="form._seller" :disabled="disabled" label="요청자" :itemText="({ name, username }) => `${name} (${username})`" v-bind="{ ...attrs_input__console, loading }" @input="emit" />
                    </v-col>
                    <v-col class="py-2" cols="6">
                        <autocomplete-user v-model="form._user" :disabled="disabled" label="결제자" :itemText="({ name, username }) => `${name} (${username})`" v-bind="{ ...attrs_input__console, loading }" @input="emit" />
                    </v-col>
                    <v-col class="py-2" cols="12">
                        <autocomplete-product v-model="form._product" :disabled="disabled" label="결제상품" v-bind="{ ...attrs_input__console, loading }" @input="emit" />
                    </v-col>
                    <v-col class="py-2" cols="6">
                        <v-text-field v-model="form.orderNo" :disabled="disabled" label="코드" placeholder="미입력시 자동부여" v-bind="{ ...attrs_input__console, loading }" @input="emit" />
                    </v-col>
                    <v-col class="py-2" cols="6">
                        <v-currency-field v-model="form.price" :disabled="disabled" label="주문금액" suffix="원" class="text-align-right-field" v-bind="{ ...attrs_input__console, loading }" @input="emit" />
                    </v-col>
                    <v-col class="py-2" cols="12"> <user-payment-expire v-model="form" :disabled="disabled" @input="emit" /> </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input__console, attrs_switch__console, initUserPayment, USER_PAYMENT_STATUS } from "@/assets/variables";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";
import UserPaymentExpire from './user-payment-expire.vue';

export default {
    components: {
        AutocompleteUser,
        AutocompleteProduct,
        UserPaymentExpire
    },
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        form: initUserPayment(),

        shows: false,
        loading: false,

        attrs_input__console,
        attrs_switch__console,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "개인결제";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
        status(){
            let { status } = this.form;
            return USER_PAYMENT_STATUS[status]?.text || "-";
        },
        disabled() {
            return this.form.status === USER_PAYMENT_STATUS.PAY_COMPLETED.value;
        }
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initUserPayment();
                } else {
                    const { _id } = this;
                    const { payment } = await api.console.users.payments.get({ _id });
                    this.form = initUserPayment(payment);
                    this.form.expire.startsAt = this.form.expire.startsAt.toDate();
                    this.form.expire.endsAt = this.form.expire.endsAt.toDate();
                    this.$emit("input", this.form);
                }
            } finally {
                this.loading = false;
            }
        },
        validates() {
            let { _user, _seller, _product, price, expire } = this.form;
            if (!_user) throw new Error("요청자를 지정해주세요.");
            if (!_seller) throw new Error("결제자를 지정해주세요.");
            if (!_product) throw new Error("결제상품를 지정해주세요.");
            if (!price) throw new Error("주문금액을 지정해주세요.");
            if (expire.isActive && ( !expire.startsAt || !expire.endsAt )) throw new Error("결제기간을 지정해주세요.");
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();
                let { orderNo, ...form } = this.form;
                // programmatic, manual
                orderNo = orderNo ? `M${orderNo}` : `P${this.$dayjs().format("YYMMDDHHmmssSSS")}`
                const { post, put } = api.console.users.payments;
                const { payment } = await (this.isCreate ? post : put)({...form, orderNo});
                this.payment = initUserPayment(payment);

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", this.payment);
                this.shows = false;
            } catch(error){
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        emit() {
            this.form = initUserPayment(this.form);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .text-align-right-field {
    & > .v-input__control > .v-input__slot > .v-text-field__slot > input {
        text-align: right !important;
    }
}
::v-deep .amount-field {
    & > .v-input__control > .v-input__slot {
        padding-right: 0 !important;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner {
        margin: 1px 0 0;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner > .v-text-field--solo {
        width: 58px !important;

        .v-input__slot {
            padding-right: 4px;
        }
        .v-select__selections {
            justify-content: center;

            > .v-select__selection {
                margin-right: 0;
            }

            > input {
                display: none;
            }
        }

        .v-input__control,
        .v-input__slot {
            background-color: transparent;
        }
        .v-input__append-inner {
            margin-top: 0;
            padding-left: 0;
        }
    }
}

::v-deep .v-text-field__details {
    margin-bottom: 0 !important;
}

::v-deep .w-100-px {
    width: 100px;
}
</style>
