<template>
    <v-chip outlined label close :ripple="false" v-bind="attrs" v-on="on" @click:close="$emit('remove')">
        <v-card flat outlined width="20" height="20" rounded="xl" :color="value.hexa" class="mr-1" style="overflow: hidden; border-color: #ddd">
            <v-img width="100%" height="100%" v-bind="{ src }" />
        </v-card>
        {{ value.name }}
    </v-chip>
</template>

<script>
import { initProductOptionCriterionValue } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initProductOptionCriterionValue },
        attrs: { type: Object, default: () => ({}) },
        on: { type: Object, default: () => ({}) },
    },
    computed: {
        src() {
            if (this.value.img instanceof File) return URL.createObjectURL(this.value.img);
            else return this.value.img?.url;
        },
    },
};
</script>

<style>
</style>