<template>
    <autocomplete-user v-model="form._seller" label="발급주체" v-bind="props__seller" @input="emit" />
</template>

<script>
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

import { attrs_input__console } from "@/assets/variables/attrs";
import { initShopCoupon } from "@/assets/variables/inits";
import { USER_TYPES } from "@/assets/variables/constants";

export default {
    components: {
        AutocompleteUser,
    },
    props: {
        value: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        form: initShopCoupon(),
    }),
    computed: {
        props__seller() {
            return {
                ...attrs_input__console,

                params: {
                    type: USER_TYPES.COMPANY.value,
                },

                itemText: ({ nickname, username }) => `${nickname} (${username})`,
                disabled: !!this.form._id,
                clearable: true,
            };
        },
    },
    methods: {
        sync() {
            this.form = initShopCoupon(this.value);
        },
        emit() {
            if (!this.form._seller && !!this.form.share) this.form.share = 0;
            if (!!this.form._seller && !this.form.share) this.form.share = 100;
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>