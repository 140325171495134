<template></template>

<script>
import XLSX from "xlsx";

import { initShopBulkUploadData } from "@/assets/variables";

const sheetName = "5.필수옵션(연동) 등록";

const BOOLEANS = { ["Y"]: true, ["N"]: false };

const nameData = (row) => ({
    rawData: row,

    ["상품코드"]: `${row?.A || ""}`?.trim?.() || null,
    ["필수옵션명"]: `${row?.B || ""}`?.trim?.() || null,
    ["필수옵션코드"]: `${row?.C || ""}`?.trim?.() || null,
    ["사용여부"]: row?.D || null,
    ["총 상품가격"]: row?.E || null,
});

const structureData = ({ rawData, ...nameData }) => ({
    parent: nameData["상품코드"],
    target: nameData["필수옵션코드"],
    custom: {
        name: nameData["필수옵션명"],
        salePrice: nameData["총 상품가격"] != null ? +nameData["총 상품가격"] : null,
    },
    enabled: BOOLEANS[nameData["사용여부"]] || false,

    rawData,
    nameData,
});

export default {
    props: {
        file: { type: File, default: null },
        value: { type: Object, default: initShopBulkUploadData },
    },
    mounted() {
        this.process();
    },
    watch: {
        file() {
            this.process();
        },
    },
    methods: {
        process() {
            if (!this.file) return;

            const reader = new FileReader();
            reader.onload = async () => {
                const workBook = XLSX.read(reader.result, { type: "binary", sheets: sheetName });
                const sheet = workBook.Sheets[sheetName];
                const rawData = XLSX.utils.sheet_to_json(sheet, { header: "A", range: 4 });

                const choicees = rawData.map(nameData).map(structureData);

                this.$emit("input", initShopBulkUploadData({ ...this.value, choicees }));
                this.$emit("loaded");
            };
            reader.readAsBinaryString(this.file);
        },
    },
};
</script>
