<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="정산 목록" />

            <v-data-table v-bind="{ loading, headers, items }" :items-per-page="-1" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn small text icon tile :to="`${$route.path}/${item._id}`">
                        <v-icon small> mdi-file </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch__console } from "@/assets/variables";

import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";

const headers = [
    { divider: true, width: 160, text: "관리번호", value: "settlementIndex" },
    { divider: true, width: 100, text: "회원직급", value: "promoter.title", formatter: (value) => value ?? "탈퇴회원" },
    { divider: true, width: 230, text: "회원", value: "promoter", formatter: ({ name, username } = {}) => (name && username ? `${name} / ${username}` : "탈퇴한 회원입니다") },
    { divider: true, width: 100, text: "매출\n(회원본인)", value: "meta.purchaseAmount.promoter", formatter: (value) => value?.format?.() ?? value ?? "-", align: "center", class: "white-space-pre-line", cellClass: "text-end" },
    { divider: true, width: 100, text: "매출\n(추천회원)", value: "meta.purchaseAmount.promotee", formatter: (value) => value?.format?.() ?? value ?? "-", align: "center", class: "white-space-pre-line", cellClass: "text-end" },
    { divider: true, width: 100, text: "기본보너스", value: "meta.bonusAmount.goods", formatter: (value) => value?.format?.() ?? value ?? "-", align: "center", class: "white-space-pre-line", cellClass: "text-end" },
    { divider: true, width: 100, text: "직급보너스", value: "meta.bonusAmount.grade", formatter: (value) => value?.format?.() ?? value ?? "-", align: "center", class: "white-space-pre-line", cellClass: "text-end" },
    { divider: true, width: 100, text: "장려보너스", value: "meta.bonusAmount.sales", formatter: (value) => value?.format?.() ?? value ?? "-", align: "center", class: "white-space-pre-line", cellClass: "text-end" },
    { width: +64, text: "", value: "actions", align: "center" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-") }));

export default {
    components: {
        ImagePopup,
        ListHeading,
    },
    data() {
        return {
            settlements: [],

            limit: 20,
            summary: { totalCount: 0 },

            headers,
            attrs_switch__console,
            loading: false,
        };
    },
    computed: {
        items() {
            return [...this.settlements];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, settlements } = await api.console.settlements.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.settlements = settlements.map((item, index) => ({ index: summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        updateItem(item) {
            const index = this.settlements.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.settlements.splice(index, 1, item);
            else {
                this.settlements = [item, ...this.settlements];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
