var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "mx-n1",
    attrs: {
      "no-gutters": ""
    }
  }, [_vm.mode == 'form' ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-switch', {
    staticClass: "pa-3 ma-0",
    attrs: {
      "dense": "",
      "inset": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.island.isActive,
      callback: function ($$v) {
        _vm.$set(_vm.form.island, "isActive", $$v);
      },
      expression: "form.island.isActive"
    }
  }, [_c('span', {
    staticClass: "caption",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm.form.island.isActive ? [_vm._v(" 사용 ")] : [_vm._v(" 미사용 ")]], 2)]), _vm.form.island.isActive ? _c('v-divider', {
    staticStyle: {
      "margin-bottom": "-1px"
    }
  }) : _vm._e()], 1) : _vm._e(), _c('v-expand-transition', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.island.isActive,
      expression: "form.island.isActive"
    }],
    attrs: {
      "cols": "12"
    }
  }, [_c('v-currency-field', _vm._b({
    staticClass: "caption",
    attrs: {
      "prefix": "기본요금",
      "suffix": "원"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.island.charge.amount__base,
      callback: function ($$v) {
        _vm.$set(_vm.form.island.charge, "amount__base", $$v);
      },
      expression: "form.island.charge.amount__base"
    }
  }, 'v-currency-field', Object.assign({}, _vm.attrs_input__verticalTable, {
    readonly: _vm.readonly
  }), false)), _c('v-divider', {
    staticStyle: {
      "margin-bottom": "-1px"
    }
  }), _c('v-currency-field', _vm._b({
    staticClass: "caption",
    attrs: {
      "prefix": "제주요금",
      "suffix": "원"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.island.charge.amount__jeju,
      callback: function ($$v) {
        _vm.$set(_vm.form.island.charge, "amount__jeju", $$v);
      },
      expression: "form.island.charge.amount__jeju"
    }
  }, 'v-currency-field', Object.assign({}, _vm.attrs_input__verticalTable, {
    readonly: _vm.readonly
  }), false))], 1)], 1), _vm.mode != 'form' ? [!_vm.form.island.isActive ? [_c('v-sheet', {
    staticClass: "pa-3 d-flex align-center",
    attrs: {
      "height": "48",
      "color": "transparent"
    }
  }, [_c('span', [_vm._v(" - ")])])] : _vm._e()] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }