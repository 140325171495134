<template>
    <v-responsive v-bind="$attrs" :class="{ 'grey lighten-2': !src }" class="text-center" @click="click">
        <v-img v-if="src" :src="src" v-bind="$attrs"></v-img>
        <v-layout v-else align-center justify-center fill-height><v-icon>mdi-image</v-icon></v-layout>
        <v-file-input v-model="fileInput" ref="fileInput" accept="image/*" class="d-none" @change="input"></v-file-input>
    </v-responsive>
</template>

<script>
export default {
    props: ["value"],
    data() {
        return {
            file: this.$props.value,
            fileInput: null,
        };
    },
    methods: {
        input(file) {
            this.file = file;
            this.fileInput = null;
            this.$emit("input", file);
        },
        click() {
            this.$refs.fileInput.$el.getElementsByTagName("input")[0].click();
        },
    },
    computed: {
        src() {
            if(typeof this.file === 'string') return this.file;
            if(this.file instanceof File) return URL.createObjectURL(this.file);
            return null;
        },
    },
    watch: {
        value() {
            this.file = this.value;
        },
    },
};
</script>