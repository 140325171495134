var render = function render(){
  var _vm$user, _vm$user$businessRegi;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1024",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "회원상세"
    }
  }), _c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": _vm.isCompany ? 8 : undefined
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "기본정보"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "4",
      "sm": "2"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "정렬",
      "disabled": ""
    },
    model: {
      value: _vm.user.index,
      callback: function ($$v) {
        _vm.$set(_vm.user, "index", $$v);
      },
      expression: "user.index"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "5"
    }
  }, [_c('autocomplete-user-level', _vm._b({
    attrs: {
      "label": "회원등급",
      "sort": {
        index: 1
      },
      "params": _vm.levelParams,
      "item-text": _vm.leveItemText,
      "item-value": "_id"
    },
    model: {
      value: _vm.user._level,
      callback: function ($$v) {
        _vm.$set(_vm.user, "_level", $$v);
      },
      expression: "user._level"
    }
  }, 'autocomplete-user-level', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "4",
      "sm": "5"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "닉네임"
    },
    model: {
      value: _vm.user.nickname,
      callback: function ($$v) {
        _vm.$set(_vm.user, "nickname", $$v);
      },
      expression: "user.nickname"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "아이디",
      "disabled": ""
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "비밀번호"
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이름"
    },
    model: {
      value: _vm.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "추천인코드"
    },
    model: {
      value: _vm.user.referrer,
      callback: function ($$v) {
        _vm.$set(_vm.user, "referrer", $$v);
      },
      expression: "user.referrer"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "연락처",
      "disabled": ""
    },
    model: {
      value: _vm.user.phone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone", $$v);
      },
      expression: "user.phone"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이메일"
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('autocomplete-bank', _vm._b({
    attrs: {
      "label": "계좌은행"
    },
    model: {
      value: _vm.user.bank.code,
      callback: function ($$v) {
        _vm.$set(_vm.user.bank, "code", $$v);
      },
      expression: "user.bank.code"
    }
  }, 'autocomplete-bank', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "예금주"
    },
    model: {
      value: _vm.user.bank.accountHolder,
      callback: function ($$v) {
        _vm.$set(_vm.user.bank, "accountHolder", $$v);
      },
      expression: "user.bank.accountHolder"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "계좌번호"
    },
    model: {
      value: _vm.user.bank.accountNumber,
      callback: function ($$v) {
        _vm.$set(_vm.user.bank, "accountNumber", $$v);
      },
      expression: "user.bank.accountNumber"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "우편번호",
      "disabled": ""
    },
    model: {
      value: _vm.user.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.user, "postcode", $$v);
      },
      expression: "user.postcode"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "기본주소",
      "disabled": ""
    },
    model: {
      value: _vm.user.address1,
      callback: function ($$v) {
        _vm.$set(_vm.user, "address1", $$v);
      },
      expression: "user.address1"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "상세주소",
      "disabled": ""
    },
    model: {
      value: _vm.user.address2,
      callback: function ($$v) {
        _vm.$set(_vm.user, "address2", $$v);
      },
      expression: "user.address2"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1)], 1)], 1)], 1)], 1), _vm.isCompany ? [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "4",
      "align-self": "stretch"
    }
  }, [_c('view-section-card', {
    attrs: {
      "title": "사업자등록증"
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "height": "calc(100% - 54px - 1px)"
    }
  }, [_c('v-layout', {
    staticClass: "grey lighten-2",
    attrs: {
      "justify-center": ""
    }
  }, [_c('image-popup', {
    attrs: {
      "src": (_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : (_vm$user$businessRegi = _vm$user.businessRegistration) === null || _vm$user$businessRegi === void 0 ? void 0 : _vm$user$businessRegi.src,
      "attrs_avatar": {
        tile: true,
        size: 225
      }
    }
  })], 1)], 1)], 1), _c('view-section-card', {
    attrs: {
      "title": "메모"
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "height": "calc(100% - 54px - 1px)"
    }
  }, [_c('v-textarea', {
    model: {
      value: _vm.user.memo,
      callback: function ($$v) {
        _vm.$set(_vm.user, "memo", $$v);
      },
      expression: "user.memo"
    }
  })], 1)], 1)], 1)] : _vm._e(), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "기업정보"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_vm.isCompany ? _c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "업체명"
    },
    model: {
      value: _vm.user.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.user, "companyName", $$v);
      },
      expression: "user.companyName"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "대표자명"
    },
    model: {
      value: _vm.user.ceoName,
      callback: function ($$v) {
        _vm.$set(_vm.user, "ceoName", $$v);
      },
      expression: "user.ceoName"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "담당자 부서명"
    },
    model: {
      value: _vm.user.department,
      callback: function ($$v) {
        _vm.$set(_vm.user, "department", $$v);
      },
      expression: "user.department"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "전화번호"
    },
    model: {
      value: _vm.user.tel,
      callback: function ($$v) {
        _vm.$set(_vm.user, "tel", $$v);
      },
      expression: "user.tel"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "사업자등록번호"
    },
    model: {
      value: _vm.user.registrationNumber,
      callback: function ($$v) {
        _vm.$set(_vm.user, "registrationNumber", $$v);
      },
      expression: "user.registrationNumber"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "통신판매업번호"
    },
    model: {
      value: _vm.user.licenseCode,
      callback: function ($$v) {
        _vm.$set(_vm.user, "licenseCode", $$v);
      },
      expression: "user.licenseCode"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', {
    attrs: {
      "title": "주의회원",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          attrs: {
            "color": "error"
          },
          model: {
            value: _vm.user.isWarning,
            callback: function ($$v) {
              _vm.$set(_vm.user, "isWarning", $$v);
            },
            expression: "user.isWarning"
          }
        }, 'v-switch', _vm.attrs_switch__console, false))];
      },
      proxy: true
    }])
  })], 1), !_vm.isCompany ? _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-card-title', {
          staticClass: "subtitle-2 font-weight-bold"
        }, [_vm._v("주문내역")]), _c('v-divider')];
      },
      proxy: true
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.numbers`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', [_c('order-view', {
          attrs: {
            "_order": item._order
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var _item$order;
              var attrs = _ref3.attrs,
                on = _ref3.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "pa-0 caption",
                attrs: {
                  "x-small": "",
                  "text": "",
                  "tile": ""
                }
              }, 'v-btn', attrs, false), on), [_vm._v(_vm._s((_item$order = item.order) === null || _item$order === void 0 ? void 0 : _item$order.orderNo))])];
            }
          }], null, true)
        })], 1), _c('div', [_vm._v(_vm._s(item.purchaseNo))])];
      }
    }, {
      key: `item.order.sender`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', [_vm._v(_vm._s(item.order.sender.name))]), _c('div', [_vm._v(_vm._s(item.order.sender.phone))]), _c('div', [_vm._v(_vm._s(item.order.sender.email))])];
      }
    }, {
      key: `item.order.receiver`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('div', [_vm._v(_vm._s(item.order.receiver.name))]), _c('div', [_vm._v(_vm._s(item.order.receiver.phone))]), _c('div', [_vm._v(_vm._s(item.order.receiver.email))])];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false))], 1) : _vm._e()], 2), _c('v-btn', _vm._b({
    attrs: {
      "fixed": "",
      "right": "",
      "bottom": "",
      "fab": "",
      "color": "primary",
      "elevation": "1"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('v-icon', [_vm._v("mdi-content-save")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }