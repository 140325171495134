var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": "880",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "직급 목록"
    }
  }), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.criteria`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('user-grade-criteria', {
          model: {
            value: item.criteria,
            callback: function ($$v) {
              _vm.$set(item, "criteria", $$v);
            },
            expression: "item.criteria"
          }
        })];
      }
    }, {
      key: `item.benefits`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('user-grade-benefits', {
          model: {
            value: item.benefits,
            callback: function ($$v) {
              _vm.$set(item, "benefits", $$v);
            },
            expression: "item.benefits"
          }
        })];
      }
    }, {
      key: `item.isActive`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-switch', _vm._b({
          attrs: {
            "disabled": "",
            "color": "grey darken-1"
          },
          model: {
            value: item.isActive,
            callback: function ($$v) {
              _vm.$set(item, "isActive", $$v);
            },
            expression: "item.isActive"
          }
        }, 'v-switch', _vm.attrs_switch__console, false))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-delete ")])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    loading: _vm.loading,
    headers: _vm.headers,
    items: _vm.items
  }, false)), _c('v-pagination', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }