<template>
    <v-select
        v-model="form._region"
        v-bind="{
            ...attrs_input__verticalTable,
            items,
            readonly,
            appendIcon,
            loading,
            error,
            placeholder,
        }"
        item-text="name"
        item-value="_id"
        class="item-select-input caption"
        color="transparent"
        @input="emit"
    />
</template>

<script>
import api from "@/api";
import { attrs_input__verticalTable } from "@/assets/variables/attrs";
import { initShopShippingOption, initShopShippingRegion, initUser } from "@/assets/variables/inits";

export default {
    props: {
        value: { type: Object, default: initShopShippingOption },
        seller: { type: Object, default: initUser },

        mode: { type: String, default: "form" },
    },
    data: () => ({
        regions: [],

        form: initShopShippingOption(),
        loading: false,

        attrs_input__verticalTable,
    }),
    computed: {
        items() {
            return this.regions.map(({ disabled, ...item }) => item);
        },
        readonly() {
            return this.mode != "form";
        },
        appendIcon() {
            return this.mode != "form" ? "" : undefined;
        },
        error() {
            return !this.form._region;
        },
        placeholder() {
            if (!this.form._region) return "항목을 선택하세요";
        },
    },
    methods: {
        async init() {
            const { _id: _seller } = this.seller;
            if (!_seller) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                let { regions } = await api.console.shop.shippings.regions.gets({
                    params: { _seller },
                });

                this.regions = regions.map(initShopShippingRegion);
            } finally {
                this.loading = false;
            }
        },
        sync() {
            this.form = initShopShippingOption(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        "seller._id"() {
            this.init();
        },
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.item-select-input.error--text {
    &::after {
        content: " ";
        position: absolute;
        top: 0;
        left: -4px;

        width: calc(100% + 8px);
        height: 100%;
        border: 4px solid #ff5252;
        pointer-events: none;
    }

    ::v-deep .v-select__selections > input::placeholder {
        color: #ff5252 !important;
    }
}
</style>