<template>
    <v-btn-toggle v-model="query.code" color="primary" @change="emit">
        <v-btn :value="null" color="white">전체</v-btn>
        <v-btn v-for="{ text, value } in codes" v-bind="{ value }" :key="value" color="white"> {{ text }} </v-btn>
    </v-btn-toggle>
</template>

<script>
const initQuery = (query = {}) => ({
    ...query,
    code: query?.code || null,
});
export default {
    props: {
        codes: { type: Array, default: () => [] },
    },
    data: () => ({
        query: initQuery(),
    }),
    computed: {
        code() {
            return this.$route.query.code || null;
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query;

            delete query.page;

            this.$router.push({ query });
        },
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style></style>
