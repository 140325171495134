<template>
    <v-layout justify-center>
        <v-responsive :max-width="1080" class="px-3 mx-n3">
            <list-heading title="정산 통계">
                <template #buttons>
                    <v-row class="ma-n2">
                        <v-col class="pa-2" cols="auto">
                            <user-money-statistics-modes />
                        </v-col>
                        <v-col class="pa-2" cols="auto" style="max-width: 160px">
                            <user-money-statistics-pends />
                        </v-col>
                        <v-col class="pa-2" cols="auto" style="max-width: 160px">
                            <user-money-statistics-sorts />
                        </v-col>
                    </v-row>
                </template>
            </list-heading>
            <user-money-statistics-search v-bind="{ showsSearch }" />

            <v-row class="mt-n3">
                <v-col cols="auto">
                    <v-card outlined v-bind="{ loading }">
                        <v-btn color="white" class="caption" v-bind="{ loading }">
                            <v-row>
                                <v-col>
                                    <v-layout align-baseline>
                                        <span> 검색결과: </span>
                                        <b class="mx-1"> {{ summary.totalCount?.format?.() }} </b> 건
                                    </v-layout>
                                </v-col>
                                <v-col class="px-0" cols="auto"> <v-divider vertical /> </v-col>
                                <v-col>
                                    <v-layout align-baseline>
                                        <span> 총 예치금: </span>
                                        <b class="mx-1"> {{ summary.currentRemain?.format?.() }} </b> 원
                                    </v-layout>
                                </v-col>
                                <v-col class="px-0" cols="auto"> <v-divider vertical /> </v-col>
                                <v-col>
                                    <v-layout align-baseline>
                                        <span> 총 출금금액: </span>
                                        <b class="mx-1"> {{ summary.totalAmount_decrease?.format?.() }} </b> 원
                                    </v-layout>
                                </v-col>
                            </v-row>
                        </v-btn>
                    </v-card>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white green--text" class="caption" v-bind="{ loading }" @click="excel">
                            <span> <v-icon>mdi-microsoft-excel</v-icon> 엑셀다운로드 </span>
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>

            <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined" style="overflow: hidden">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
                <template #[`item.user`]="{ header }">
                    <template v-if="loading">
                        <v-progress-linear indeterminate />
                    </template>
                    <template v-else-if="user._id">
                        {{ header.formatter() }}
                    </template>
                    <template v-else> - </template>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";

import { attrs_input__console, attrs_switch__console } from "@/assets/variables/attrs";
import { USER_MONEY_STATISTICS_MODES } from "@/assets/variables/constants";
import { initDataTableHeaders, initUser } from "@/assets/variables/inits";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

import UserMoneyStatisticsModes from "@/components/console/users/money-statistics/user-money-statistics-modes.vue";
import UserMoneyStatisticsPends from "@/components/console/users/money-statistics/user-money-statistics-pends.vue";
import UserMoneyStatisticsSorts from "@/components/console/users/money-statistics/user-money-statistics-sorts.vue";
import UserMoneyStatisticsSearch from "@/components/console/users/money-statistics/user-money-statistics-search.vue";

export default {
    components: {
        ListHeading,
        AutocompleteUser,

        UserMoneyStatisticsModes,
        UserMoneyStatisticsPends,
        UserMoneyStatisticsSorts,
        UserMoneyStatisticsSearch,
    },
    data: () => ({
        user: initUser(),
        statistics: [],

        limit: 20,
        summary: {
            totalCount: 0,
            currentRemain: 0,
            totalAmount_decrease: 0,
        },

        attrs_input__console,
        attrs_switch__console,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        ...mapGetters(["hasCompanyScope", "hasConsoleScope"]),
        headers() {
            const items = [
                {
                    text: (() => {
                        switch (this.mode) {
                            case USER_MONEY_STATISTICS_MODES.WEEKLY.value:
                            case USER_MONEY_STATISTICS_MODES.MONTHLY.value: {
                                return "기간";
                            }
                            case USER_MONEY_STATISTICS_MODES.DAILY.value:
                            default: {
                                return "날짜";
                            }
                        }
                    })(),
                    value: "_id",
                    width: (() => {
                        switch (this.mode) {
                            case USER_MONEY_STATISTICS_MODES.WEEKLY.value:
                            case USER_MONEY_STATISTICS_MODES.MONTHLY.value: {
                                return 200;
                            }
                            case USER_MONEY_STATISTICS_MODES.DAILY.value:
                            default: {
                                return 100;
                            }
                        }
                    })(),
                    formatter: ({ y, m, w, d }) => {
                        switch (this.mode) {
                            case USER_MONEY_STATISTICS_MODES.WEEKLY.value: {
                                let year = this.$dayjs().year(y);
                                let week = year.week(w + (year.startOf("year").day() == 0 ? 0 : 1));
                                return `${week.startOf("week").format("YYYY.MM.DD")} ~ ${week.endOf("week").format("YYYY.MM.DD")}`;
                            }
                            case USER_MONEY_STATISTICS_MODES.MONTHLY.value: {
                                let month = this.$dayjs().year(y).month(m);
                                return `${month.startOf("month").format("YYYY.MM.DD")} ~ ${month.endOf("month").format("YYYY.MM.DD")}`;
                            }
                            case USER_MONEY_STATISTICS_MODES.DAILY.value:
                            default: {
                                return `${y}.${m.toString().padStart(2, 0)}.${d.toString().padStart(2, 0)}`;
                            }
                        }
                    },
                },
                {
                    text: "회원",
                    value: "user",
                    width: 160,
                    formatter: () => `${this.user?.nickname || "-"} (${this.user?.username})`,
                    hides: this.hasCompanyScope || !this.params._user,
                },
                {
                    text: "판매자\r\n판매금액",
                    value: "sales",
                    align: "end",
                    formatter: (value, item) => `${(item?.charge + item?.amount)?.format?.()}원`,
                },
                {
                    text: "쿠폰할인\r\n부담금액",
                    value: "coupon_user",
                    align: "end",
                    formatter: (value) => `${value?.format?.()}원`,
                },
                {
                    text: "플랫폼\r\n수수료",
                    value: "charge",
                    align: "end",
                    formatter: (value) => `${value?.format?.()}원`,
                },
                {
                    text: "판매장려\r\n지원금",
                    value: "grants",
                    align: "end",
                    formatter: (value) => `${value?.format?.()}원`,
                },
                {
                    text: "정산금액",
                    value: "amount",
                    align: "end",
                    formatter: (value) => `${value?.format?.()}원`,
                },
                {
                    text: "수수료율",
                    value: "chargeAmount",
                    align: "end",
                    formatter: (value, item) => `${((item?.charge / (item?.charge + item?.amount)) * 100).toFixed(2)}%`,
                    hides: this.hasCompanyScope,
                },
            ];

            return initDataTableHeaders(items.filter(({ hides }) => !(hides ?? false)));
        },
        items() {
            return [...this.statistics];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        mode() {
            return this.$route.query.mode || USER_MONEY_STATISTICS_MODES.DAILY.value;
        },
        sort() {
            return JSON.stringify({ createdAt: this.$route.query.sort || "-1" });
        },
        mode() {
            return this.$route.query.mode || USER_MONEY_STATISTICS_MODES.DAILY.value;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.mode;
            delete query.sort;

            if (query["searchDateValue"]) {
                try {
                    query["searchDateValue"] = JSON.parse(query["searchDateValue"]);
                } catch {
                    query["searchDateValue"] = query["searchDateValue"];
                }

                if (!query["searchDateValue"]) delete query["searchDateValue"];
            }

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        mode() {
            this.statistics = [];
            this.search();
        },
        "params._user"() {
            this.user = initUser();
        },
        params() {
            this.statistics = [];
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                await this.search(false);
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { mode, sort, skip, limit, params } = this;
                let { summary, statistics } = await api.console.users.moneys.statistics.gets({
                    headers: { mode, sort, skip, limit },
                    params,
                });

                this.summary = summary;
                this.statistics = statistics;

                if (params._user && !this.user._id) {
                    this.user = (await api.console.users.get({ _id: params._user }))?.user;
                }
            } finally {
                this.loading = false;
            }
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { mode, sort, params } = this;
                let { statistics } = await api.console.users.moneys.statistics.gets({
                    headers: { mode, sort },
                    params,
                });

                let fileName = `정산통계(${USER_MONEY_STATISTICS_MODES[this.mode].text})`;
                if (this.user?._id) {
                    fileName += `_${(this.user?.nickname || "-").replace(/\s/g, "_")}(${this.user?.username})`;
                }

                this.$excel(statistics, this.headers, fileName);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
