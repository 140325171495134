<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">팝업관리</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <!-- <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn> -->
                    </v-col>
                </v-row>

                <v-data-table :headers="popupsHeaders" :items="popups" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template v-slot:item.no="{ item }">
                        {{ (page - 1) * limit + popups.indexOf(item) + 1 }}
                    </template>
                    <template v-slot:item.link="{ item }">
                        <v-row @click="$set(item, 'show', !item.show)">
                            <v-col cols="4" class="text-start">
                                <a :href="item.url" target="_blank">{{ item.url }}</a>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-icon v-if="!item.show">mdi-chevron-down</v-icon>
                                <v-icon v-else>mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-expand-transition>
                            <v-row v-show="item.show">
                                <v-col cols="12" class="text-start">
                                    <p v-html="item?.content?.replace(/\n/g, '<br>')"></p>
                                </v-col>
                            </v-row>>
                        </v-expand-transition>
                    </template>
                    <template #[`item.show`]="{ item }">
                        <v-switch v-model="item.display.enabled" class="mt-0" hide-details @change="update(item)"></v-switch>
                    </template>
                    <template #[`item.period`]="{ item }">
                        <div class="white-space-pre-line">
                            {{ `${item?.display?.startDate?.toDate?.() || ""}~\r\n${item?.display?.endDate?.toDate?.() || ""}` }}
                        </div>
                    </template>
                    <template v-slot:item.createdAt="{ item }">
                        {{ item.createdAt.toDate() }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
                        <v-icon small @click="remove(item)"> mdi-delete </v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" />

                <v-dialog v-model="popupDialog" max-width="960px">
                    <template v-slot:activator="{ on }">
                        <v-btn bottom color="accent" dark fab fixed right @click="create">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <v-card v-if="popupDialog">
                        <v-card-title>
                            <span v-if="editItem._id" class="subtitle-1 font-weight-bold">팝업수정</span>
                            <span v-else class="subtitle-1 font-weight-bold">팝업추가</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container class="pb-0">
                                <v-row align="center" class="mt-3">
                                    <v-col cols="12" class="pa-0">
                                        <v-text-field v-model="editItem.name" label="명칭" persistent-placeholder dense hide-details></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row align="center" class="mt-6">
                                    <v-col cols="12" class="pa-0">
                                        <v-text-field v-model="editItem.sequence" label="순서" persistent-placeholder dense hide-details></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-6" align="center">
                                    <v-col cols="12" class="pa-0">
                                        <v-text-field v-model="editItem.url" label="URL" persistent-placeholder dense hide-details></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-6" align="center">
                                    <v-col cols="6" class="py-0 pl-0"><v-text-field v-model="editItem.left" label="위치(x)" persistent-placeholder dense hide-details></v-text-field></v-col>
                                    <v-col cols="6" class="py-0 pr-0"><v-text-field v-model="editItem.top" label="위치(y)" persistent-placeholder dense hide-details></v-text-field></v-col>
                                </v-row>
                                <v-row class="mt-6" align="center">
                                    <v-col cols="6" class="py-0 pl-0"><v-text-field v-model="editItem.width" label="크기(가로)" persistent-placeholder dense hide-details></v-text-field></v-col>
                                    <v-col cols="6" class="py-0 pr-0"><v-text-field v-model="editItem.height" label="크기(세로)" persistent-placeholder dense hide-details></v-text-field></v-col>
                                </v-row>
                                <v-row class="mt-6" align="center">
                                    <v-col cols="auto" class="pa-0">전시여부</v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="auto" class="pa-0">
                                        <v-switch v-model="editItem.display.enabled" dense hide-details class="ma-0 pa-0"></v-switch>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-6" align="center">
                                    <v-col cols="auto" class="pa-0">전시기간</v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="auto" class="pa-0">
                                        <v-switch v-model="editItem.display.period" dense hide-details class="ma-0 pa-0"></v-switch>
                                    </v-col>
                                </v-row>
                                <v-row v-if="editItem.display.period" class="mt-6" align="center">
                                    <v-col cols="6" class="py-0 pl-0">
                                        <v-menu ref="startDateMenu" :close-on-content-click="false" :return-value.sync="editItem.display.startDate" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="editItem.display.startDate" label="주문일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="editItem.display.startDate" no-title scrollable @input="$refs.startDateMenu.save(editItem.display.startDate)"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6" class="py-0 pr-0">
                                        <v-menu ref="endDateMenu" :close-on-content-click="false" :return-value.sync="editItem.display.endDate" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="editItem.display.endDate" label="주문일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="editItem.display.endDate" no-title scrollable @input="$refs.endDateMenu.save(editItem.display.endDate)"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-6">
                                    <v-col class="pa-0">
                                        <naver-smarteditor v-model="editItem.content" bSkipXssFilter />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="close">취소</v-btn>
                            <v-btn text color="primary" @click="save">저장</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
export default {
    components: {
        NaverSmarteditor,
    },
    created() {
        this.init();
    },
    data: () => {
        return {
            showsSearch: true,

            page: 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            popups: [],
            popupsHeaders: [
                {
                    text: "no",
                    align: "center",
                    width: 50,
                    value: "no",  
                },
                {
                    text: "순서",
                    width: 80,
                    align: "center",
                    value: "sequence",
                },
                {
                    text: "명칭",
                    width: 200,
                    align: "center",
                    value: "name",
                },
                {
                    text: "URL",
                    width: 100,
                    align: "center",
                    value: "link",
                },
                {
                    text: "전시여부",
                    // align: "center",
                    width: 70,
                    value: "show",
                    // value: "display.enabled",
                },
                {
                    text: "전시기간",
                    align: "center",
                    width: 120,
                    value: "period"
                },
                {
                    text: "등록일자",
                    align: "center",
                    width: 120,
                    value: "createdAt",
                },
                {
                    text: "actions",
                    align: "center",
                    width: 80,
                    value: "actions",
                },
            ],

            popupDialog: false,
            editItem: null,
            defaultItem: {
                _id: null,
                code: null,
                subject: null,
                content: null,
                url: null,

                left: 0,
                top: 0,
                width: 0,
                height: 0,

                display: {
                    enabled: false,
                    period: false,
                    startDate: null,
                    endDate: null,
                },
            },

            image: null,
        };
    },
    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        async search() {
            try {
                var { summary, popups } = await api.console.popups.getPopups({
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                });

                this.popups = popups;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        close() {
            this.popupDialog = false;
        },

        create() {
            this.editItem = Object.assign({ sequence: this.summary.totalCount + 1 }, this.defaultItem);
            this.popupDialog = true;
        },

        edit(popup) {
            this.editItem = Object.assign({}, popup);
            this.popupDialog = true;
        },

        async update(item) {
            try {
                await api.console.popups.putPopup(item);
                this.search();
            } catch(error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        async save() {
            try {
                this.editItem._id ? await api.console.popups.putPopup(this.editItem) : await api.console.popups.postPopup(this.editItem);
                await this.search();
                this.close();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
            this.dialog = false;
        },

        async remove(popup) {
            try {
                if(confirm("팝업을 삭제하시겠습니까?")){
                    await api.console.popups.deletePopup(popup);
                    await this.search();
                };
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
    },
    watch: {
        dialog() {
            if (!this.dialog) {
                this.editedItem = Object.assign({}, this.defaultItem);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
}
</style>