<template>
    <list-search v-bind="{ loading, showsSearch }" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" class="pa-2">
                <!-- 카테고리 -->
                <shop-category-select v-model="query['_category']" v-bind="{ ...attrs_input__console, categories }" clearable @input="emit" />
            </v-col>
            <v-col cols="12" md="3" class="pa-2">
                <v-text-field v-model="query['name']" label="제품명" clearable v-bind="attrs_input__console" @keydown.enter="emit" />
            </v-col>
            <!-- <v-col cols="12" md="3" class="pa-2">
                <v-text-field v-model="query['code']" label="제품코드" clearable v-bind="attrs_input__console" @keydown.enter="emit" />
            </v-col> -->
            <!-- <v-col cols="12" md="3" class="pa-2">
                <v-text-field v-model="query['variation.code']" label="묶음상품코드" clearable v-bind="attrs_input__console" @keydown.enter="emit" />
            </v-col> -->
            <!-- <v-col cols="12" md="3" class="pa-2">
                <v-text-field v-model="query['relatedGroupCode']" label="연관제품코드" clearable v-bind="attrs_input__console" @keydown.enter="emit" />
            </v-col> -->
            <v-col cols="3" class="pa-2" v-if="hasConsoleScope">
                <autocomplete-user v-model="query['_seller']" label="입점사" clearable v-bind="props__autocompleteUser" @input="emit" />
            </v-col>
            <v-col cols="2" class="pa-2">
                <product-list-search-groups v-model="query[DISPLAY_CODES.RECOMMENDATION.value]" :code="DISPLAY_CODES.RECOMMENDATION.value" v-bind="attrs_input__console" @input="emit" />
            </v-col>
            <!-- <v-col cols="12" lg="6" class="pa-2">
                <v-row class="ma-n2">
                </v-row>
            </v-col> -->
            <v-col cols="2" class="pa-2">
                <v-select v-model="query['sells']" label="판매여부" :items="sellsItems" v-bind="attrs_input__console" @input="emit" />
            </v-col>
            <v-col cols="2" class="pa-2">
                <v-select v-model="query['shows']" label="전시여부" :items="showsItems" v-bind="attrs_input__console" @input="emit" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { mapGetters } from "vuex";

import api from "@/api";

import { attrs_input__console } from "@/assets/variables/attrs";
import { DISPLAY_CODES, USER_TYPES } from "@/assets/variables/constants";

import ListSearch from "@/components/console/dumb/list-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import ShopCategorySelect from "@/components/console/shop/categories/shop-category-select.vue";

import ProductListSearchGroups from "./product-list-search-groups.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["_seller"]: query?.["_seller"] || null,

    ["name"]: query?.["name"] || null,
    ["code"]: query?.["code"] || null,
    ["variation.code"]: query?.["variation.code"] || null,
    ["relatedGroupCode"]: query?.["relatedGroupCode"] || null,

    ["shows"]: JSON.parse(query?.["shows"] || "null"),
    ["sells"]: JSON.parse(query?.["sells"] || "null"),
    ["_category"]: query?.["_category"] || null,
    [DISPLAY_CODES.NEW_ARRIVAL.value]: JSON.parse(query?.[DISPLAY_CODES.NEW_ARRIVAL.value] || "null"),
    [DISPLAY_CODES.POPULAR.value]: JSON.parse(query?.[DISPLAY_CODES.POPULAR.value] || "null"),
    [DISPLAY_CODES.BEST_SELLER.value]: JSON.parse(query?.[DISPLAY_CODES.BEST_SELLER.value] || "null"),
    [DISPLAY_CODES.RECOMMENDATION.value]: JSON.parse(query?.[DISPLAY_CODES.RECOMMENDATION.value] || "null"),
});

const showsItems = [
    { text: "전체", value: null },
    { text: "전시", value: true },
    { text: "숨김 ", value: false },
];

const sellsItems = [
    { text: "전체", value: null },
    { text: "판매 ", value: true },
    { text: "품절", value: false },
];

const props__autocompleteUser = {
    ...attrs_input__console,
    params: {
        type: USER_TYPES.COMPANY.value,
    },
    itemText: ({ nickname, username }) => `${nickname} (${username})`,
};

export default {
    components: {
        ListSearch,
        AutocompleteUser,
        ShopCategorySelect,

        ProductListSearchGroups,
    },
    props: {
        loading: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        categories: [],

        showsItems,
        sellsItems,
        attrs_input__console,
        props__autocompleteUser,
        DISPLAY_CODES,
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
    },
    mounted() {
        this.init().then(this.sync);
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        async init() {
            const { categories } = await api.console.shop.categories.gets({ params: { depth: 1 } });
            this.categories = categories;
        },
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            delete query.page;

            if (!query?.["_category"]) delete query["_category"];
            if (!query?.["_seller"]) delete query["_seller"];

            if (!query?.["name"]) delete query["name"];
            if (!query?.["code"]) delete query["code"];
            if (!query?.["variation.code"]) delete query["variation.code"];
            if (!query?.["relatedGroupCode"]) delete query["relatedGroupCode"];

            if (query?.["shows"] == null) delete query["shows"];
            if (query?.["sells"] == null) delete query["sells"];
            if (query?.[DISPLAY_CODES.NEW_ARRIVAL.value] == null) delete query[DISPLAY_CODES.NEW_ARRIVAL.value];
            if (query?.[DISPLAY_CODES.POPULAR.value] == null) delete query[DISPLAY_CODES.POPULAR.value];
            if (query?.[DISPLAY_CODES.BEST_SELLER.value] == null) delete query[DISPLAY_CODES.BEST_SELLER.value];
            if (query?.[DISPLAY_CODES.RECOMMENDATION.value] == null) delete query[DISPLAY_CODES.RECOMMENDATION.value];

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
