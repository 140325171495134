<template>
    <v-select v-model="form.usage.target.price" label="사용대상금액" v-bind="{ ...attrs_input__console, items }" @input="emit" />
</template>

<script>
import { mapGetters } from "vuex";

import { attrs_input__console } from "@/assets/variables/attrs";
import { initShopCoupon } from "@/assets/variables/inits";
import { USAGE_TARGET_PRICES } from "@/assets/variables/constants";

export default {
    props: {
        value: { type: Object, default: initShopCoupon },
    },
    setup: () => ({
        attrs_input__console,
    }),
    data: () => ({
        form: initShopCoupon(),
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
        hasSeller() {
            return !!this.form._seller;
        },
        items() {
            if (this.hasConsoleScope) {
                if (this.hasSeller) {
                    return [USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE, USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE];
                } else {
                    // return [USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT, USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE];
                    return [USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT];
                }
            } else return [USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE, USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE];
        },
    },
    methods: {
        sync() {
            let form = { ...this.value };

            let needsEmit = false;

            if (this.hasConsoleScope) {
                let price = undefined;

                switch (form?.usage?.target?.price) {
                    case USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value: {
                        if (this.hasSeller) {
                            price = USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value;
                        }
                        break;
                    }

                    default: {
                        if (!this.hasSeller) {
                            price = USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value;
                        }
                        break;
                    }
                }

                if (price && !form?._id) {
                    needsEmit = true;
                    form = {
                        ...form,
                        usage: {
                            ...form.usage,
                            target: {
                                ...form.usage.target,
                                price,
                            },
                        },
                    };
                }
            }

            this.form = initShopCoupon(form);

            if (needsEmit) this.emit();
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
        hasSeller() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style></style>
