<template>
    <v-menu v-model="shows" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field v-model="date" v-on="on" :label="label" :placeholder="placeholder" :dense="dense" :outlined="outlined" :hide-details="hideDetails" append-icon="icon icon-calendar" readonly></v-text-field>
        </template>
        <v-date-picker v-model="date" :type="type" no-title scrollable @input="save"></v-date-picker>
    </v-menu>
</template>

<script>
export default {
    props: {
        value: { type: String, default: undefined },

        type: { type: String, default: "date" },
        label: { type: String, default: undefined },
        placeholder: { type: String, default: undefined },
        outlined: { type: Boolean, default: false },
        dense: { type: Boolean, default: false },
        hideDetails: { type: String, default: "auto" },
    },
    data() {
        return {
            date: this.$props.value,
            shows:false,
        };
    },
    methods: {
        sync() {
            this.date = this.value;
        },
        emit() {
            this.$emit("input", this.date);
        },
        save(){
            this.emit();
            this.shows = false;
        }
    },
    watch: {
        value() {
            this.sync();
        },
    },
};
</script>

<style lang="scss" scoped>
.v-text-field {
    --input-padding-dense: 0 12px;
    ::v-deep {
        .v-input__append-inner {
            margin-top: auto !important;
            margin-bottom: auto !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .v-text-field {
        --input-padding-dense: 0 12px 0 16px;
        width: 100%;
        min-width: 180px;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
