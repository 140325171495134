<template>
    <v-layout justify-center fill-height>
        <v-responsive max-width="1160" width="100%" height="100%">
            <list-heading v-model="showsSearch" title="게시글 목록" showsFilterButton />

            <list-search-default v-bind="{ showsSearch, loading }" />

            <template v-if="codes.length">
                <v-row class="mt-n3">
                    <v-col cols="auto">
                        <board-list-codes v-bind="{ codes }" />
                    </v-col>
                </v-row>
            </template>

            <v-data-table v-bind="{ headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.storyThumbSrc`]="{ item }">
                    <v-img :src="item.storyThumbSrc" :aspect-ratio="1 / 1" height="40" width="40" contain class="mx-auto grey lighten-2">
                        <template #placeholder>
                            <v-overlay absolute color="transparent">
                                <v-icon> mdi-image-broken-variant </v-icon>
                            </v-overlay>
                        </template>
                    </v-img>
                </template>

                <template #[`item.isNotice`]="{ item }">
                    <v-switch v-model="item.isNotice" v-bind="{ ...attrs_switch__console }" class="mt-0" hide-details @change="update(item)"></v-switch>
                </template>

                <template #[`item.actions`]="{ item }">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon v-if="false" small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>

            <v-btn bottom color="accent" fab fixed right @click="create()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapGetters, mapState } from "vuex";
import { initDataTableHeaders, attrs_switch__console } from "@/assets/variables";

import BoardListCodes from "@/components/console/boards/board-list-codes.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ListSearchDefault from "@/components/console/dumb/list-search-default.vue";

export default {
    components: {
        BoardListCodes,
        ListHeading,
        ListSearchDefault,
    },
    data: () => ({
        attrs_switch__console,
        boards: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,
    }),
    computed: {
        ...mapState({
            codes: (state) =>
                state.story.categories.map((item) => ({
                    ...item,

                    text: item.name,
                    value: item.code,
                })),
        }),
        ...mapGetters("story", ["getCategory", "getCategoryWithId"]),
        headers() {
            const headers = [
                { width: +80, text: "no", value: "index", align: "right" },
                { width: +80, text: "썸네일", value: "storyThumbSrc", align: "center" },
                { width: 160, text: "카테고리", value: "codeTexts", formatter: (value) => value?.join?.("\r\n\\ ") || "-" },
                { text: "제목", value: "subject" },
                { width: 160, text: "작성자", value: "writer.nickname" },
                { width: 80, text: "상단고정", value: "isNotice", align: "center" },
                { width: 100, text: "작성일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
                { width: +80, value: "actions", align: "right" },
            ].filter((header) => (header.test ? header.test(this.$route.query.code) : true));

            return initDataTableHeaders(headers);
        },
        items() {
            return this.boards.map((board, index) => {
                const category = this.getCategory(board.code);
                const codeTexts = (category?._parents || []).map((_parent) => this.getCategoryWithId(_parent)?.name).concat(category?.name || board.code);

                return {
                    ...board,
                    index: this.summary.totalCount - index - (this.page - 1) * this.limit,
                    codeTexts,
                };
            });
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            if (query.code) {
                query.code = this.getCategory(query.code)?.codes || query.code;
            }

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.boards = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, boards } = await api.console.boards.gets({
                    headers: { skip, limit },
                    params,
                });

                this.boards = boards;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        create() {
            const { code, category } = this.$route.query;
            const query = { code, category };

            if (!code) delete query.code;
            if (!category) delete query.category;

            this.$router.push({ path: "/console/boards/create", query });
        },

        edit(board) {
            this.$router.push(`/console/boards/${board._id}`);
        },

        async update(board){
            if (this.loading) return;
            else this.loading = true;
            
            try{
                await api.console.boards.put(board);
            } catch(error){
                this.$handleError(error);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        async remove(board) {
            if (!confirm("게시글을 삭제하시겠습니까?")) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                await api.console.boards.delete(board);
                alert("삭제되었습니다");
                this.init();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>

