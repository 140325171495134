<template>
    <v-row class="ma-n2">
        <v-col cols="12" md="6" class="pa-2">
            <v-text-field v-model="form.issue.startsAt" label="부과기간(시작)" clearable v-bind="attrs_input__date__console" messages="※ 미입력 시 바로 적용" @input="emit" />
        </v-col>
        <v-col cols="12" md="6" class="pa-2">
            <v-text-field v-model="form.issue.endsAt" label="부과기간(종료)" clearable v-bind="attrs_input__date__console" messages="※ 미입력 시 상시 적용" @input="emit" />
        </v-col>
    </v-row>
</template>

<script>
import { attrs_input__date__console, initShopPoint } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initShopPoint },
    },
    data: () => ({
        form: initShopPoint(),

        attrs_input__date__console,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopPoint(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style></style>
