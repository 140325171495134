var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-field-card', {
    attrs: {
      "containerAttrs": {
        class: 'pa-0 rounded-lg',
        style: 'overflow:hidden'
      }
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('v-data-table', _vm._b({
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: `item.information.totalAmount`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-currency-field', _vm._b({
          staticClass: "text-caption text-align-right-field",
          attrs: {
            "readonly": "",
            "suffix": "원"
          },
          model: {
            value: item.information.totalAmount,
            callback: function ($$v) {
              _vm.$set(item.information, "totalAmount", $$v);
            },
            expression: "item.information.totalAmount"
          }
        }, 'v-currency-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.information.cash`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-currency-field', _vm._b({
          staticClass: "text-caption text-align-right-field",
          attrs: {
            "readonly": "",
            "suffix": "원"
          },
          model: {
            value: item.information.cash,
            callback: function ($$v) {
              _vm.$set(item.information, "cash", $$v);
            },
            expression: "item.information.cash"
          }
        }, 'v-currency-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.information.chkBill`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-currency-field', _vm._b({
          staticClass: "text-caption text-align-right-field",
          attrs: {
            "readonly": "",
            "suffix": "원"
          },
          model: {
            value: item.information.chkBill,
            callback: function ($$v) {
              _vm.$set(item.information, "chkBill", $$v);
            },
            expression: "item.information.chkBill"
          }
        }, 'v-currency-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.information.note`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-currency-field', _vm._b({
          staticClass: "text-caption text-align-right-field",
          attrs: {
            "readonly": "",
            "suffix": "원"
          },
          model: {
            value: item.information.note,
            callback: function ($$v) {
              _vm.$set(item.information, "note", $$v);
            },
            expression: "item.information.note"
          }
        }, 'v-currency-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.information.credit`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-currency-field', _vm._b({
          staticClass: "text-caption text-align-right-field",
          attrs: {
            "readonly": "",
            "suffix": "원"
          },
          model: {
            value: item.information.credit,
            callback: function ($$v) {
              _vm.$set(item.information, "credit", $$v);
            },
            expression: "item.information.credit"
          }
        }, 'v-currency-field', _vm.attrs_input__verticalTable, false))];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto",
      "align-self": "stretch"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-3 text-caption text-center",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_vm._v(" 이 금액을 { "), _c('v-select', _vm._b({
    staticClass: "d-inline-block text-caption",
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "items": _vm.purposeTypes
    },
    model: {
      value: _vm.form.base.purposeType,
      callback: function ($$v) {
        _vm.$set(_vm.form.base, "purposeType", $$v);
      },
      expression: "form.base.purposeType"
    }
  }, 'v-select', _vm.attrs_input__verticalTable, false)), _vm._v(" } 함 ")], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }