var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "width": "480"
    }
  }, [_c('v-textarea', {
    staticClass: "mb-3",
    attrs: {
      "label": "title",
      "placeholder": "이미지 위 표시되는 제목",
      "persistent-placeholder": "",
      "hide-details": "",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.value.meta.title,
      callback: function ($$v) {
        _vm.$set(_vm.value.meta, "title", $$v);
      },
      expression: "value.meta.title"
    }
  }), _c('v-text-field', {
    staticClass: "mb-3",
    attrs: {
      "label": "name",
      "placeholder": "통계에서 표시되는 이름",
      "persistent-placeholder": "",
      "hide-details": "",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.value.meta.name,
      callback: function ($$v) {
        _vm.$set(_vm.value.meta, "name", $$v);
      },
      expression: "value.meta.name"
    }
  }), _c('v-text-field', {
    staticClass: "mb-3",
    attrs: {
      "label": "URL",
      "placeholder": "/products/621f2299052d6a062e7400bc",
      "autofocus": "",
      "persistent-placeholder": "",
      "hide-details": "",
      "dense": "",
      "outlined": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.slide.showsModal_url = false;
      }
    },
    model: {
      value: _vm.value.url,
      callback: function ($$v) {
        _vm.$set(_vm.value, "url", $$v);
      },
      expression: "value.url"
    }
  }), _vm.isDescriptionNeeded ? _c('v-text-field', {
    staticClass: "mb-3",
    attrs: {
      "label": "description",
      "persistent-placeholder": "",
      "hide-details": "",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.value.meta.description,
      callback: function ($$v) {
        _vm.$set(_vm.value.meta, "description", $$v);
      },
      expression: "value.meta.description"
    }
  }) : _vm._e(), _vm.isTagNeeded ? _c('v-combobox', {
    attrs: {
      "label": "tag",
      "multiple": "",
      "persistent-placeholder": "",
      "hide-details": "",
      "dense": "",
      "outlined": "",
      "append-icon": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          item = _ref.item,
          parent = _ref.parent;
        return [_c('v-chip', _vm._b({
          attrs: {
            "outlined": "",
            "text": "",
            "label": "",
            "small": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, 'v-chip', attrs, false), [_vm._v(" " + _vm._s(item) + " ")])];
      }
    }], null, false, 2024069107),
    model: {
      value: _vm.value.meta.tag,
      callback: function ($$v) {
        _vm.$set(_vm.value.meta, "tag", $$v);
      },
      expression: "value.meta.tag"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }