<template>
    <v-card width="480" class="pa-3">
        <!-- <v-text-field class="mb-3" v-model="value.meta.title" label="title" persistent-placeholder hide-details dense outlined /> -->
        <v-textarea class="mb-3" v-model="value.meta.title" label="title" placeholder="이미지 위 표시되는 제목" persistent-placeholder hide-details dense outlined />
        <v-text-field class="mb-3" v-model="value.meta.name" label="name" placeholder="통계에서 표시되는 이름" persistent-placeholder hide-details dense outlined/>
        
        <!-- <v-text-field v-if="isUrlNeeded" class="mb-3" v-model="value.url" label="URL" placeholder="/products/621f2299052d6a062e7400bc" autofocus persistent-placeholder hide-details dense outlined @keydown.enter="slide.showsModal_url = false" /> -->
        <v-text-field class="mb-3" v-model="value.url" label="URL" placeholder="/products/621f2299052d6a062e7400bc" autofocus persistent-placeholder hide-details dense outlined @keydown.enter="slide.showsModal_url = false" />

        <v-text-field class="mb-3" v-if="isDescriptionNeeded" v-model="value.meta.description" label="description" persistent-placeholder hide-details dense outlined />

        <v-combobox v-if="isTagNeeded" v-model="value.meta.tag" label="tag" multiple persistent-placeholder hide-details dense outlined append-icon="">
            <template #selection="{ attrs, item, parent }">
                <v-chip v-bind="attrs" outlined text label small close @click:close="parent.selectItem(item)"> {{ item }} </v-chip>
            </template>
        </v-combobox>
    </v-card>
</template>

<script>
import { BANNERS } from "@/assets/variables";

export default {
    props:{
        value: { type: Object, default: () => ({})}
    },
    data: () => ({
    }),
    computed: {
        code () {
            return this.$route.params.code;
        },
        // isUrlNeeded(){
        //     return ![BANNERS.MAIN_2.value].includes(this.code);
        // },
        isDescriptionNeeded(){
            return [BANNERS.MAIN_2.value].includes(this.code);
        },
        isTagNeeded(){
            return [BANNERS.MAIN_2.value].includes(this.code);
        }
    }
}
</script>

<style>

</style>