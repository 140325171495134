<template>
    <view-section-card title="필수옵션(연동)" hideDivider v-bind="{ loading }">
        <template #actions> <v-switch v-model="form.choiceesConf.enabled" v-bind="attrs_switch__console" @change="emit"></v-switch> </template>
        <v-expand-transition>
            <v-sheet v-show="form.choiceesConf.enabled">
                <v-divider />
                <v-card-text class="pa-0">
                    <vertical-table-row term="추가" divider :props="rowProps">
                        <choicee-list-push :product="value" v-on="{ push }" />
                    </vertical-table-row>
                    <vertical-table-row term="목록" divider hideDivider :props="rowProps">
                        <v-data-table :items="form.choicees" v-bind="{ headers }" item-key="name" class="product-choicees-table">
                            <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>

                            <template #[`item.name`]="{ value, item, header }">
                                <item-custom-name-edit :value="item" :placeholder="$decode__productOptionName(header.formatter(value, item))" v-on="{ update }">
                                    <v-card flat tile color="transparent" class="d-flex align-center px-4 py-2 fill-height"> {{ $decode__productOptionName(header.formatter(value, item)) }} </v-card>
                                </item-custom-name-edit>
                            </template>

                            <template #[`item.salePrice`]="{ value, item, header }">
                                <item-custom-sale-edit :value="item" :placeholder="header.formatter(value, item)" v-on="{ update }">
                                    <v-card flat tile color="transparent" class="d-flex align-center px-4 py-2 fill-height"> {{ header.formatter(value, item) }} </v-card>
                                </item-custom-sale-edit>
                            </template>

                            <template #[`item.enabled`]="{ item }">
                                <v-simple-checkbox v-model="item.enabled" dense color="primary" class="d-inline-block mr-n2" @input="emit" />
                            </template>

                            <template #[`item.actions`]="{ item }">
                                <v-btn text tile color="red" height="48" @click="remove(item)">
                                    <v-icon>mdi-minus</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </vertical-table-row>
                </v-card-text>
            </v-sheet>
        </v-expand-transition>
    </view-section-card>
</template>

<script>
import { attrs_input__verticalTable, attrs_switch__console, initDataTableHeaders, initProduct } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import VerticalTableRow from "@/components/console/dumb/vertical-table-row.vue";

import ChoiceeListPush from "./choicee-list-push.vue";
import ItemCustomNameEdit from "@/components/console/dumb/edit/item-custom-name-edit.vue";
import ItemCustomSaleEdit from "@/components/console/dumb/edit/item-custom-sale-edit.vue";

const headers = initDataTableHeaders([
    { text: "이름", value: "name", formatter: (value, item) => item?.custom?.name || item?.supply?.name || item?.option?.name || item?.product?.name, cellClass: "pa-0" },
    { text: "상품코드", value: "code", formatter: (value, item) => item?.custom?.code || item?.supply?.code || item?.option?.code || item?.product?.code, width: 120 },
    {
        text: "재고",
        value: "stock",
        formatter: (value, item) => {
            let stock = item.product?.stock || 0;
            if (item.supply) stock = item.supply?.stock || 0;
            if (item.option) stock = item.option?.stock || 0;
            return `${stock.format()}개`;
        },
        width: +80,
    },
    {
        text: "판매가격",
        value: "salePrice",
        formatter: (value, item) => {
            let salePrice = item.product?.salePrice || 0;
            if (item.custom?.salePrice != null) salePrice = item.custom?.salePrice || 0;
            else salePrice += item.supply?.price || item.option?.price || 0;
            return `${salePrice.format()}원`;
        },
        cellClass: "pa-0",
        width: 120,
    },
    { text: "사용", value: "enabled", align: "center", width: +64, sortable: false, cellClass: "pa-0" },
    { text: "", value: "actions", align: "center", width: +64, sortable: false, cellClass: "pa-0" },
]);

const rowProps = {
    rowProps: { class: "ma-0", align: "center" },
    termProps: { class: "pa-4", cols: 2, md: 1 },
    dataProps: { class: "pa-0" },
};

export default {
    components: {
        ViewSectionCard,
        VerticalTableRow,

        ChoiceeListPush,
        ItemCustomNameEdit,
        ItemCustomSaleEdit,
    },
    props: {
        value: { type: Object, default: initProduct },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initProduct(),

        headers,
        rowProps,
        attrs_switch__console,
    }),
    computed: {
        attrs_autocomplete() {
            const attrs = {
                ...attrs_input__verticalTable,

                multiple: true,
            };

            if (this.value?._id) {
                attrs.params = {
                    _id: { $ne: this.value?._id },
                };
            }

            return attrs;
        },

        products() {
            return this.form.choicees.map(({ product }) => product);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        async sync() {
            this.form = initProduct(this.value);
        },

        emit() {
            if (this.form.choiceesConf.enabled) {
                if (this.form.optionsConf.enabled) {
                    this.form.optionsConf.enabled = false;
                }
            }
            this.$emit("input", this.form);
        },

        update(item) {
            const index = this.form.choicees.findIndex(({ tempId }) => tempId == item.tempId);
            if (-1 < index) {
                this.form.choicees.splice(index, 0, { ...(this.form.choicees.splice(index, 1)[0] || {}), ...item });
            } else {
                this.form.choicees.push(item);
            }
            this.emit();
        },

        remove(item) {
            this.form.choicees = this.form.choicees.filter(({ tempId }) => tempId != item.tempId);
            this.emit();
        },

        push(items) {
            for (const item of items) {
                const sameItem = this.form.choicees.some(({ _product = null, _option = null, _supply = null }) => {
                    const sameProduct = _product == (item._product || null);
                    const sameOption = _option == (item._option || null);
                    const sameSupply = _supply == (item._supply || null);

                    return sameProduct && sameOption && sameSupply;
                });
                if (sameItem) continue;

                this.form.choicees.push(item);
            }

            this.emit();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-input__slot {
        background-color: rgba(255, 255, 255, 0) !important;

        &:hover {
            background: rgba(255, 255, 255, 0.75) !important;
        }
    }
}
.product-choicees-table ::v-deep {
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        display: block;
        height: 100%;
    }
}
</style>
