<template>
    <vertical-table v-if="form" :items="computedItems" v-bind="{ itemsPerRow, hideDivider }">
        <template v-for="(item, i) in computedItems">
            <template v-if="isArray(item)">
                <template v-for="(child, j) in item">
                    <template v-if="$slots[child.term]">
                        <slot :slot="child.term" :name="child.term" :value="findData(child.keys)[child.lastKey]" />
                    </template>
                    <template v-else-if="mode == 'form'">
                        <vertical-form-table-item v-model="$data.form" v-bind="{ mode, item: child }" :key="`item-${i}-${j}`" :slot="child.term" @input="emit" />
                    </template>
                </template>
            </template>
            <template v-else>
                <template v-if="$slots[item.term]">
                    <slot :slot="item.term" :name="item.term" :value="findData(item.keys)[item.lastKey]" />
                </template>
                <template v-else-if="mode == 'form'">
                    <vertical-form-table-item v-model="$data.form" v-bind="{ mode, item }" :key="`item-${i}`" :slot="item.term" @input="emit" />
                </template>
            </template>
        </template>
    </vertical-table>
</template>

<script>
import banks from "@/plugins/shop-banks.json";

import VerticalTable from "@/components/console/dumb/vertical-table.vue";
import VerticalFormTableItem from "./vertical-form-table-item.vue";

export default {
    components: {
        VerticalTable,
        VerticalFormTableItem,
    },
    props: {
        mode: { type: String, default: "form" },

        value: { type: Object, default: () => ({}) },

        items: { type: Array, default: () => [] },
        itemsPerRow: { type: [Number, String], default: 1 },

        hideDivider: { type: Boolean, default: false },
    },
    data: () => ({
        form: undefined,
    }),
    computed: {
        computedItems() {
            return this.$props.items.map(this.mapData);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = { ...this.value };
        },
        emit() {
            this.$emit("input", this.form);
        },
        mapData(item) {
            const isArray = Array.isArray(item);
            if (isArray) return item.map(this.mapData);

            let { cols, data, key, divider, hideDivider, required, term, type, ...props } = item;

            let keys = key?.split?.(".") || [];
            let lastKey = keys.pop();
            data = data ?? this.findData(keys)?.[lastKey];

            if (type == "address") {
                let { postcode, address1, address2 } = data || {};
                data = postcode ? `[${postcode}] ` : "";
                data += address1 || "";
                data += " ";
                data += address2 || "";
                data = data.trim();
            }

            if (type == "bank") {
                let { code, accountNumber, accountHolder } = data || {};
                data = "";
                if (code) data += `${banks.find((item) => item.code == code)?.name || code || ""} / `;
                if (accountNumber) data += `${accountNumber} / `;
                if (accountHolder) data += `${accountHolder}`;
            }

            if (type == "datetime") {
                data = data?.toDateTime?.() || data;
            }

            let computedItem = { cols, data, key, keys, divider, hideDivider, required, lastKey, props, term, type };
            if (this.mode == "form") delete computedItem.data;
            else delete computedItem.required;

            return computedItem;
        },
        findData(keys) {
            return keys.reduce((o, key) => o[key], this.form) || this.form;
        },
        isArray(item) {
            return Array.isArray(item);
        },
    },
};
</script>

<style></style>
