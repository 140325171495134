<template>
    <view-section-card title="링크상품" hideDivider v-bind="{ loading }">
        <template #actions> <v-switch v-model="form.link.enabled" v-bind="attrs_switch__console" @change="emit"></v-switch> </template>
        <v-expand-transition>
            <v-sheet v-show="form.link.enabled">
                <v-divider />
                <v-card-text>
                    <v-row class="ma-n2">
                        <v-col cols="12" md="4" class="pa-2">
                            <v-text-field label="링크이름" placeholder="문의하기" v-model="form.link.name" v-bind="attrs_input__console" @input="emit" />
                        </v-col>
                        <v-col cols="12" md="8" class="pa-2">
                            <v-text-field label="링크주소" v-model="form.link.url" v-bind="attrs_input__console" @input="emit" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-sheet>
        </v-expand-transition>
    </view-section-card>
</template>

<script>
import { attrs_input__console, attrs_switch__console, initProduct } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initProduct },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initProduct(),

        attrs_input__console,
        attrs_switch__console,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        async sync() {
            this.form = initProduct(this.value);
        },

        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
