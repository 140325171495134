<template>
    <view-field-card :containerAttrs="{ class: 'pa-0 rounded-lg', style: 'overflow:hidden' }">
        <v-data-table v-bind="{ headers, items }" hide-default-footer disable-filtering disable-pagination disable-sort>
            <template #[`item.purchaseDT`]="{ item }">
                <v-text-field v-model="item.purchaseDT" v-bind="attrs_input__verticalTable" readonly class="text-caption" />
            </template>
            <template #[`item.itemName`]="{ item }">
                <v-text-field v-model="item.itemName" v-bind="attrs_input__verticalTable" readonly class="text-caption" />
            </template>
            <template #[`item.spec`]="{ item }">
                <v-text-field v-model="item.spec" v-bind="attrs_input__verticalTable" readonly class="text-caption" />
            </template>
            <template #[`item.qty`]="{ item }">
                <v-currency-field v-model="item.qty" v-bind="attrs_input__verticalTable" readonly class="text-caption text-align-right-field" suffix="원" />
            </template>
            <template #[`item.unitCost`]="{ item }">
                <v-currency-field v-model="item.unitCost" v-bind="attrs_input__verticalTable" readonly class="text-caption text-align-right-field" suffix="원" />
            </template>
            <template #[`item.supplyCost`]="{ item }">
                <v-currency-field v-model="item.supplyCost" v-bind="attrs_input__verticalTable" readonly class="text-caption text-align-right-field" suffix="원" />
            </template>
            <template #[`item.tax`]="{ item }">
                <v-currency-field v-model="item.tax" v-bind="attrs_input__verticalTable" readonly class="text-caption text-align-right-field" suffix="원" />
            </template>
            <template #[`item.remark`]="{ item }">
                <v-text-field v-model="item.remark" v-bind="attrs_input__verticalTable" readonly class="text-caption" />
            </template>
        </v-data-table>
    </view-field-card>
</template>

<script>
import { attrs_input__verticalTable } from "@/assets/variables/attrs";
import { initDataTableHeaders, initShopTaxinvoice } from "@/assets/variables/inits";

import ViewFieldCard from "@/components/console/dumb/view-field-card.vue";
import VerticalFormTable from "@/components/console/dumb/vertical-form-table.vue";

const headers = initDataTableHeaders([
    {
        text: "일자",
        value: "purchaseDT",
        cellClass: "pa-0",
        width: 100,
    },
    {
        text: "품목",
        value: "itemName",
        cellClass: "pa-0",
    },
    {
        text: "규격",
        value: "spec",
        cellClass: "pa-0",
        width: 140,
    },
    {
        text: "수량",
        value: "qty",
        cellClass: "pa-0",
        width: 100,
    },
    {
        text: "단가",
        value: "unitCost",
        cellClass: "pa-0",
        width: 100,
    },
    {
        text: "공급가액",
        value: "supplyCost",
        cellClass: "pa-0",
        width: 140,
    },
    {
        text: "세액",
        value: "tax",
        cellClass: "pa-0",
        width: 140,
    },
    {
        text: "비고",
        value: "remark",
        cellClass: "pa-0",
    },
]);

export default {
    components: {
        VerticalFormTable,
        ViewFieldCard,
    },
    props: {
        value: { type: Object, default: initShopTaxinvoice },
    },
    setup: () => ({
        headers,
        attrs_input__verticalTable,
    }),
    data: () => ({
        form: initShopTaxinvoice(),
    }),
    computed: {
        items() {
            return this.form?.detailList || [];
        },
    },
    methods: {
        sync() {
            this.form = initShopTaxinvoice(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>