var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('visits-daily-weekly-view', _vm._b({}, 'visits-daily-weekly-view', {
    type: _vm.ANALYTICS_VISITS_TYPES.DAILY_WEEKLY.value
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('visits-monthly-yearly-view', _vm._b({}, 'visits-monthly-yearly-view', {
    type: _vm.ANALYTICS_VISITS_TYPES.MONTHLY_YEARLY.value
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }