<template>
    <v-card outlined>
        <v-card-text class="white pa-0">
            <v-select v-model="query['sortType']" v-bind="{ ...attrs_input__console, items }" solo flat :outlined="false" class="caption" @change="emit" />
        </v-card-text>
    </v-card>
</template>

<script>
import { attrs_input__console } from "@/assets/variables";

const initQuery = (query = {}) => ({
    ...query,

    sortType: JSON.parse(query.sortType || "-1"),
});

const items = [
    { text: "내림차순", value: -1 },
    { text: "오름차순", value: 1 },
];

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        query: initQuery(),

        items,
        attrs_input__console,
    }),
    methods: {
        sync() {
            this.query = initQuery({ ...this.value });
        },
        emit() {
            const { ...query } = this.query;

            query["page"] = 1;

            this.$emit("input", query);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>