<template>
    <v-row class="ma-0 pa-2">
        <v-col cols="2" class="pa-2">
            <v-select v-model="form.operator" :items="operators" label="연산자" v-bind="attrs_input__console" />
        </v-col>
        <v-col class="pa-2">
            <v-currency-field v-model="form.price" label="옵션가" v-bind="attrs_input__console" />
        </v-col>
        <v-col class="pa-2">
            <v-currency-field v-model="form.stock" label="재고수량" v-bind="attrs_input__console" />
        </v-col>
        <v-col class="pa-2">
            <v-select v-model="form.enabled" :items="enables" label="사용여부" v-bind="attrs_input__console" />
        </v-col>
        <v-col cols="auto" class="pa-2">
            <v-btn text outlined color="secondary" height="40" @click="update(selected)">선택목록 일괄수정</v-btn>
        </v-col>
        <v-col cols="auto" class="pa-2">
            <v-btn text outlined color="red" height="40" @click="init">초기화</v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { attrs_input__console } from "@/assets/variables";

const enables = [
    { text: "사용", value: true },
    { text: "미사용", value: false },
];

const operators = [
    { text: "+", value: 1 },
    { text: "-", value: -1 },
];

const initForm = () => ({
    operator: operators[0].value,
    price: 0,
    stock: 0,
    enabled: true,
});

export default {
    props: {
        value: { type: Array, default: () => [] }, // product.options
        selected: { type: Array, default: () => [] },
    },
    data: () => ({
        form: initForm(),

        enables,
        operators,
        attrs_input__console,
    }),
    methods: {
        init() {
            this.form = initForm();
        },
        update(items = this.value) {
            const { price, stock, operator, enabled } = this.form;

            const items__mapped = items.map((item) => ({
                ...item,
                price: item.price + (+price || 0) * operator,
                stock: item.stock + (+stock || 0) * operator,
                enabled,
            }));

            const value__updated = this.value.map((item) => items__mapped.find(({ tempId }) => tempId == item.tempId) || item);

            this.$emit("input", value__updated);
        },
    },
};
</script>

<style>
</style>