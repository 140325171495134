var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "1280",
      "persistent": "",
      "fullscreen": _vm.$vuetify.breakpoint.mobile
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', _vm._b({
    staticClass: "d-flex flex-column",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "light": "",
      "tile": "",
      "max-height": "90vh"
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-6",
    staticStyle: {
      "flex": "0 1 auto",
      "overflow-y": "auto"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": _vm.hasConsoleScope ? 4 : 3
    }
  }, [_c('form-basics', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": _vm.hasConsoleScope ? 8 : 9
    }
  }, [_c('form-target', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('form-options', {
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-col', {
    staticClass: "py-0",
    staticStyle: {
      "min-width": "140px"
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', _vm._b({
    attrs: {
      "disabled": _vm.form.isDefault
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.isActive,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isActive", $$v);
      },
      expression: "form.isActive"
    }
  }, 'v-switch', _vm.attrs_switch__console, false), [_c('span', {
    staticClass: "subtitle-2",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" " + _vm._s(_vm.form.isActive ? "사용" : "미사용") + " ")])])], 1), _c('v-spacer'), _c('v-btn', _vm._b({
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }