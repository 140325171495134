var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', _vm._b({
    attrs: {
      "label": "상품"
    },
    on: {
      "change": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [_c('draggable', _vm._g(_vm._b({
          attrs: {
            "list": _vm.form._products,
            "group": "form-products-chips"
          }
        }, 'draggable', {
          id: index,
          move: _vm.move
        }, false), {
          change: _vm.change
        }), [_c('v-chip', {
          key: item._id,
          attrs: {
            "draggable": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return _vm.pull(index);
            },
            "mousedown": function ($event) {
              $event.stopPropagation();
            },
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1)];
      }
    }]),
    model: {
      value: _vm.form._products,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_products", $$v);
      },
      expression: "form._products"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input__console, {
    items: _vm.items__products,
    itemText: 'name',
    itemValue: '_id',
    multiple: true,
    smallChips: true,
    deletableChips: true,
    loading: _vm.loading,
    disabled: !_vm.form.board
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }