<template>
    <view-field-card :containerAttrs="{ class: 'pa-0 rounded-lg', style: 'overflow:hidden' }">
        <vertical-form-table v-model="form" v-bind="{ items }" hideDivider @input="emit">
            <template #책번호>
                <v-row no-gutters class align="stretch">
                    <v-col>
                        <v-text-field v-model="form.information.kwon" suffix="권" class="text-caption" v-bind="attrs_input__verticalTable" dense readonly />
                    </v-col>
                    <v-col cols="auto"> <v-divider vertical /> </v-col>
                    <v-col>
                        <v-text-field v-model="form.information.ho" suffix="호" class="text-caption" v-bind="attrs_input__verticalTable" dense readonly />
                    </v-col>
                </v-row>
            </template>
        </vertical-form-table>
    </view-field-card>
</template>

<script>
import { attrs_input__verticalTable, initShopTaxinvoice } from "@/assets/variables";

import ViewFieldCard from "@/components/console/dumb/view-field-card.vue";
import VerticalFormTable from "@/components/console/dumb/vertical-form-table.vue";

const items = [
    {
        term: "책번호",
    },
].map(function mapItem(item, index, array) {
    const hideDivider = item.hideDivider ?? index == array.length - 1;
    if (Array.isArray(item)) return item.map((item, index, array) => mapItem({ ...item, hideDivider }, index, array));

    return {
        ...attrs_input__verticalTable,

        dense: true,
        divider: true,
        hideDivider,

        class: "text-caption",

        ...item,

        rowProps: {
            class: "ma-0 text-caption",
            align: "center",
            noGutters: true,
        },

        termProps: {
            class: "px-3",
            style: "max-width:120px",
            ...(item.termProps || {}),
        },
    };
});

export default {
    components: {
        VerticalFormTable,
        ViewFieldCard,
    },
    props: {
        value: { type: Object, default: initShopTaxinvoice },
    },
    setup: () => ({
        attrs_input__verticalTable,
    }),
    data: () => ({
        form: initShopTaxinvoice(),

        items,
    }),
    methods: {
        sync() {
            this.form = initShopTaxinvoice(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>