var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn-toggle', {
    attrs: {
      "mandatory": "",
      "color": "primary",
      "dense": ""
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.query['mode'],
      callback: function ($$v) {
        _vm.$set(_vm.query, 'mode', $$v);
      },
      expression: "query['mode']"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-btn', {
      key: item.text,
      attrs: {
        "value": item.value,
        "outlined": ""
      }
    }, [_vm._v(_vm._s(item.text))])];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }