<template>
    <view-section-card title="기본정보">
        <v-card-text>
            <v-row class="ma-n2">
                <v-col cols="12" class="pa-2" v-if="hasConsoleScope">
                    <autocomplete-user v-model="form.seller" label="입점사" returnObject v-bind="props__autocompleteUser" :disabled="!!form._id" @input="emit" />
                </v-col>
                <v-col cols="12" class="pa-2">
                    <v-text-field v-model="form.name" label="정책명" v-bind="attrs_input__console" @input="emit" />
                </v-col>
                <v-col cols="12" :md="hasConsoleScope ? 6 : 12" class="pa-2">
                    <v-datetime-field v-model="form.startsAt" label="사용시작" placeholder="시작일시 없음" padding="0" v-bind="attrs_input__console" @input="emit" />
                </v-col>
                <v-col cols="12" :md="hasConsoleScope ? 6 : 12" class="pa-2">
                    <v-datetime-field v-model="form.endsAt" label="사용종료" placeholder="종료일시 없음" padding="0" v-bind="attrs_input__console" @input="emit" />
                </v-col>
            </v-row>
        </v-card-text>
    </view-section-card>
</template>

<script>
import { attrs_input__console } from "@/assets/variables/attrs";
import { USER_TYPES } from "@/assets/variables/constants";
import { initShopShipping } from "@/assets/variables/inits";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import { mapGetters } from "vuex";

const props__autocompleteUser = {
    ...attrs_input__console,

    params: {
        type: USER_TYPES.COMPANY.value,
    },
    itemText: ({ nickname, username }) => `${nickname} (${username})`,
};

export default {
    components: {
        AutocompleteUser,
        VDatetimeField,
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initShopShipping },
    },
    data: () => ({
        form: initShopShipping(),

        attrs_input__console,
        props__autocompleteUser,
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
    },
    methods: {
        sync() {
            this.form = initShopShipping(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>