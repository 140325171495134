<template>
    <v-dialog v-model="shows" width="320">
        <template #activator="{ attrs, on }">
            <v-btn small outlined color="primary" class="mr-2" v-bind="{ ...attrs, loading }" v-on="on">주문취소</v-btn>
        </template>
        <v-card>
            <v-card-title class="subtitle-1">주문취소</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12"> <v-select label="취소사유" v-model="form.claimReason" :items="claimReasonItems" v-bind="attrs_input__console" /> </v-col>
                    <v-col cols="12"> <v-textarea label="취소 상세" v-model="form.claimReasonDetails" v-bind="attrs_input__console" /> </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn text @click="shows = false">취소</v-btn>
                <v-btn color="primary" text @click="put">주문취소</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input__console, PURCHASE_CLAIM_STATUSES, PURCHASE_ORDER_STATUSES, PURCHASE_PURCHASE_STATUSES } from "@/assets/variables";

const claimReasonItems = [
    { text: "기타", value: "기타" }
];
const initForm = () => ({
    claimReason: null,
    claimReasonDetails: null,
});

export default {
    props: {
        loading: { type: Boolean, default: false },
        selected: { type: Array, default: () => [] },
    },
    data: () => ({
        form: initForm(),

        shows: false,
        attrs_input__console,

        claimReasonItems,
    }),
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        init() {
            this.form = initForm();
        },
        async put() {
            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                if (!this.selected.length) throw new Error("취소할 주문건을 선택해주세요");
                if (!this.form.claimReason) throw new Error("취소사유를 선택해주세요");
                if (!this.form.claimReasonDetails) throw new Error("취소 상세를 입력해주세요");

                for (const { _id } of this.selected) {
                    await api.console.shop.purchases.putPurchase({ _id, orderStatus: PURCHASE_ORDER_STATUSES.ORDER_CANCELED.value, claimStatus: PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value, purchaseStatus: PURCHASE_PURCHASE_STATUSES.PURCHASE_CANCELED.value, rejectedAt: null, canceledAt: Date.now(), returnedAt: null, exchangedAt: null, exchangeShippedAt: null, ...this.form });
                }

                let isNonPaymentCancel = this.selected.every(({ orderStatus }) => orderStatus === PURCHASE_ORDER_STATUSES.ORDER_NONPAYMENT.value);

                //미결제의 경우 환불 처리 X
                let orders =[...new Set(this.selected.filter(({ orderStatus }) => orderStatus !== PURCHASE_ORDER_STATUSES.ORDER_NONPAYMENT.value).map(({ _order }) => _order))];

                if (!isNonPaymentCancel) {
                    for (const _id of orders) {
                        await api.console.shop.orders.cancel({ _id });
                    }
                    alert(`취소 처리 되었습니다. (가상계좌 결제의 경우, 수동 환불 처리가 필요합니다.)`);
                } else {
                    alert(`취소 처리 되었습니다.`);
                }

                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.$emit("loading", false);
                this.$emit("search");
            }
        },
    },
};
</script>

<style></style>
