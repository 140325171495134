<template>
    <view-section-card title="알람 발송 목록">
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="alarms" :items-per-page=10 disable-sort class="v-sheet--outlined">
                    <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
                    <template #[`item.nameCode`] = "{ item }">
                        <div v-html = "`${item.name || ''} </br> ${item.code || ''}`" ></div>
                    </template>
                    <template #[`item.actions`]="{ item }">
                        <v-btn small text icon tile>
                            <v-icon small @click="show(item)"> mdi-pencil </v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </view-section-card>
</template>

<script>
import { initDataTableHeaders, ALARM_TYPES, ALARM_CATEGORIES, ALARM_TARGETS } from '@/assets/variables';
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
export default {
    components: {
        ViewSectionCard,
    },
    props: {
        alarms: { type: Array, default: ()=> [] }
    },
    computed: {
        headers(){
            return initDataTableHeaders([
                { text: "유형", value: "type", width: 100, align: "center", formatter: (value)=> ALARM_TYPES[value]?.text || value },
                { text: "구분", value: "category", width: 100, align: "center", formatter: (value)=> ALARM_CATEGORIES[value]?.text || value },
                { text: "대상", value: "target", width: 80, align: "center", formatter: (value)=> ALARM_TARGETS[value]?.text || value },
                { text: "이름", value: "nameCode", align: "center" },
                { text: "", value: "actions", width: 10}
            ]);
        },
    },
    methods:{
        show(item){
            this.$emit("show", item);
        }
    }
}
</script>

<style>

</style>