var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "560",
      "persistent": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "outlined": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v("배송수단 설정")]), _c('v-divider'), _c('form-table', {
    model: {
      value: _vm.methods,
      callback: function ($$v) {
        _vm.methods = $$v;
      },
      expression: "methods"
    }
  }), _c('v-divider'), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "text": "",
      "color": "red"
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("취소하기")]), _c('v-btn', _vm._b({
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("저장하기")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }