<template>
    <v-layout justify-center>
        <v-responsive width="100%">
            <v-row class="ma-n1">
                <v-col cols="12" md="6" lg="7" class="pa-1">
                    <v-row class="ma-n1">
                        <v-col cols="12" sm="6" md="3" class="pa-1">
                            <v-card outlined>
                                <v-card-title class="subtitle-1 pr-2"><v-icon color="teal lighten-3" class="mr-3">mdi-gift</v-icon>신규주문</v-card-title>
                                <v-card-text>
                                    <v-responsive height="64">
                                        <v-row>
                                            <v-col cols="auto" class="py-0">미결제</v-col>
                                            <v-spacer />
                                            <v-col cols="auto" class="py-0">
                                                <v-btn class="teal--text text--lighten-3 font-weight-bold mr-1 pa-0" min-width="auto" height="auto" text @click="$router.push('/console/shop/purchases/nonpayment')">{{ purchaseDashboard?.orderNonpayment?.format() }}</v-btn> 건
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="auto" class="py-0">신규주문</v-col>
                                            <v-spacer />
                                            <v-col cols="auto" class="py-0">
                                                <v-btn class="teal--text text--lighten-3 font-weight-bold mr-1 pa-0" min-width="auto" height="auto" text @click="$router.push('/console/shop/purchases/payment?orderStatus=ORDER_PAYMENT&claimStatus=null&claimStatus=CANCEL_REJECTED&claimStatus=EXCHANGE_REJECTED&claimStatus=RETURN_REJECTED')">{{ purchaseDashboard?.orderPayment?.format() }}</v-btn> 건
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="auto" class="py-0">발주확인완료</v-col>
                                            <v-spacer />
                                            <v-col cols="auto" class="py-0">
                                                <v-btn class="teal--text text--lighten-3 font-weight-bold mr-1 pa-0" min-width="auto" height="auto" text @click="$router.push('/console/shop/purchases/payment?orderStatus=ORDER_CONFIRM')">{{ purchaseDashboard?.orderConfirm?.format() }}</v-btn> 건
                                            </v-col>
                                        </v-row>
                                    </v-responsive>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="pa-1">
                            <v-card outlined>
                                <v-card-title class="subtitle-1 pr-2"><v-icon color="purple lighten-3" class="mr-3">mdi-truck</v-icon>배송관리</v-card-title>
                                <v-card-text>
                                    <v-responsive height="64">
                                        <v-row>
                                            <v-col cols="auto" class="py-0">배송중</v-col>
                                            <v-spacer />
                                            <v-col cols="auto" class="py-0">
                                                <v-btn class="purple--text text--lighten-3 font-weight-bold mr-1 pa-0" min-width="auto" height="auto" text @click="$router.push('/console/shop/purchases/delivery?orderStatus=SHIPPING_CONFIRM&claimStatus=null&claimStatus=CANCEL_REJECTED')">{{ purchaseDashboard?.shippingConfirm?.format() }}</v-btn> 건
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="auto" class="py-0">배송완료</v-col>
                                            <v-spacer />
                                            <v-col cols="auto" class="py-0">
                                                <v-btn class="purple--text text--lighten-3 font-weight-bold mr-1 pa-0" min-width="auto" height="auto" text @click="$router.push('/console/shop/purchases/delivery?orderStatus=DELIVERY_COMPLETE&claimStatus=null&claimStatus=CANCEL_REJECTED&claimStatus=EXCHANGE_REJECTED&claimStatus=RETURN_REJECTED')">{{ purchaseDashboard?.deliveryComplete?.format() }}</v-btn> 건
                                            </v-col>
                                        </v-row>
                                    </v-responsive>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="pa-1">
                            <v-card outlined>
                                <v-card-title class="subtitle-1 pr-2"><v-icon color="blue-grey lighten-1" class="mr-3">mdi-undo-variant</v-icon>취소반품관리</v-card-title>
                                <v-card-text>
                                    <v-responsive height="64">
                                        <v-row>
                                            <v-col cols="auto" class="py-0">취소요청</v-col>
                                            <v-spacer />
                                            <v-col cols="auto" class="py-0">
                                                <v-btn class="blue-grey--text font-weight-bold text--lighten-1 mr-1 pa-0" min-width="auto" height="auto" text @click="$router.push('/console/shop/purchases/cancel?claimStatus=CANCEL_REQUESTED')">{{ purchaseDashboard?.cancelRequest?.format() }}</v-btn> 건
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="auto" class="py-0">교환요청</v-col>
                                            <v-spacer />
                                            <v-col cols="auto" class="py-0">
                                                <v-btn class="blue-grey--text font-weight-bold text--lighten-1 mr-1 pa-0" min-width="auto" height="auto" text @click="$router.push('/console/shop/purchases/exchange?claimStatus=EXCHANGE_REQUESTED')">{{ purchaseDashboard?.exchangeRequest?.format() }}</v-btn> 건
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="auto" class="py-0">반품요청</v-col>
                                            <v-spacer />
                                            <v-col cols="auto" class="py-0">
                                                <v-btn class="blue-grey--text font-weight-bold text--lighten-1 mr-1 pa-0" min-width="auto" height="auto" text @click="$router.push('/console/shop/purchases/exchange?claimStatus=RETURN_REQUESTED')">{{ purchaseDashboard?.returnRequest?.format() }}</v-btn> 건
                                            </v-col>
                                        </v-row>
                                    </v-responsive>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="pa-1">
                            <v-card outlined>
                                <v-card-title class="subtitle-1 pr-2"><v-icon color="light-blue lighten-3" class="mr-3">mdi-bank</v-icon>당일구매확정</v-card-title>
                                <v-card-text>
                                    <v-responsive height="64">
                                        <v-row>
                                            <v-col cols="auto" class="py-0">구매확정</v-col>
                                            <v-spacer />
                                            <v-col cols="auto" class="py-0"> <v-btn class="light-blue--text text--lighten-3 font-weight-bold mr-1 pa-0" min-width="auto" height="auto" text>0</v-btn> 건 </v-col>
                                        </v-row>
                                    </v-responsive>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" lg="6" class="pa-1">
                            <v-card outlined class="fill-height">
                                <v-card-title class="subtitle-1"><v-icon color="red lighten-2" class="mr-3">mdi-help-circle</v-icon>상품문의</v-card-title>

                                <v-card-text class="pa-0">
                                    <v-divider />
                                    <v-data-table :headers="inquiresHeaders" :items="inquires" disable-sort disable-pagination hide-default-footer>
                                        <template #[`item.createdAt`]="{ item }">
                                            {{ item.createdAt.toDate() }}
                                        </template>

                                        <template #[`item.reply`]="{ item }">
                                            <span v-if="item.reply">답변완료</span>
                                            <span v-else>미답변</span>
                                        </template>

                                        <template #[`item.actions`]="{ item }">
                                            <v-icon small @click="editInquire(item)">mdi-pencil</v-icon>
                                        </template>
                                    </v-data-table>

                                    <v-dialog v-model="inquireDialog" max-width="720px">
                                        <v-card v-if="inquireDialog">
                                            <v-card-title>
                                                <span class="subtitle-1">상품문의</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-row v-if="editItem.product" align="center">
                                                    <v-col md="auto" class="pa-1">
                                                        <v-img v-if="editItem.product.thumb" :src="editItem.product.thumb?.url" max-width="56" max-height="56"></v-img>
                                                        <v-responsive v-else width="56" height="56">
                                                            <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                                                <v-icon>mdi-image</v-icon>
                                                            </v-row>
                                                        </v-responsive>
                                                    </v-col>
                                                    <v-col class="pa-1">
                                                        <div class="text-start">{{ editItem.product.name }}</div>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-else align="center">
                                                    <v-col md="auto" class="pa-1">
                                                        <v-responsive width="56" height="56">
                                                            <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                                                <v-icon>mdi-image</v-icon>
                                                            </v-row>
                                                        </v-responsive>
                                                    </v-col>
                                                    <v-col class="pa-1">
                                                        <div class="text-start">삭제된 상품</div>
                                                    </v-col>
                                                </v-row>
                                                <v-text-field v-model="editItem.subject" label="문의 제목" persistent-placeholder readonly dense hide-details class="mt-6"></v-text-field>
                                                <v-text-field label="문의날짜" persistent-placeholder readonly dense hide-details :value="editItem.createdAt.toDate()" class="mt-6"></v-text-field>
                                                <v-text-field label="작성자" persistent-placeholder readonly dense hide-details :value="editItem.user ? `${editItem.user.name}(${editItem.user.username})` : '탈퇴회원'" class="mt-6"></v-text-field>
                                                <v-textarea v-model="editItem.content" label="문의 내용" persistent-placeholder readonly dense hide-details class="mt-6"></v-textarea>
                                                <div class="mt-2" style="font-size: 0.85em">답변</div>
                                                <naver-smarteditor v-model="editItem.reply"></naver-smarteditor>
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer />
                                                <v-btn text @click="closeInquire">취소</v-btn>
                                                <v-btn color="primary" text @click="saveInquire">저장</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" lg="6" class="pa-1" v-if="hasConsoleScope">
                            <v-card outlined class="fill-height">
                                <v-card-title class="subtitle-1"><v-icon color="pink lighten-3" class="mr-3">mdi-comment-question</v-icon>1:1 문의</v-card-title>
                                <v-card-text class="pa-0">
                                    <v-divider />
                                    <v-data-table :headers="questionsHeaders" :items="questions" disable-sort disable-pagination hide-default-footer>
                                        <template #[`item.createdAt`]="{ item }">
                                            {{ item.createdAt.toDate() }}
                                        </template>

                                        <template #[`item.reply`]="{ item }">
                                            <span v-if="item.reply">답변완료</span>
                                            <span v-else>미답변</span>
                                        </template>

                                        <template #[`item.actions`]="{ item }">
                                            <v-icon small @click="editQuestion(item)">mdi-pencil</v-icon>
                                        </template>
                                    </v-data-table>

                                    <v-dialog v-model="questionDialog" max-width="720px">
                                        <v-card v-if="questionDialog">
                                            <v-card-title>
                                                <span class="subtitle-1">상품문의</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-text-field v-model="editItem.subject" label="문의 제목" persistent-placeholder readonly dense hide-details class="mt-3"></v-text-field>
                                                <v-text-field label="문의날짜" persistent-placeholder readonly dense hide-details :value="editItem.createdAt.toDate()" class="mt-6"></v-text-field>
                                                <v-text-field label="작성자" persistent-placeholder readonly dense hide-details :value="editItem.user ? `${editItem.user.name}(${editItem.user.username})` : '탈퇴회원'" class="mt-6"></v-text-field>
                                                <v-textarea v-model="editItem.content" label="문의 내용" persistent-placeholder readonly dense hide-details class="mt-6"></v-textarea>
                                                <v-textarea v-model="editItem.reply" label="답변" persistent-placeholder dense hide-details class="mt-6"></v-textarea>
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer />
                                                <v-btn text @click="closeQuestion">취소</v-btn>
                                                <v-btn color="primary" text @click="saveQuestion">저장</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" lg="6" class="pa-1">
                            <v-card outlined class="fill-height">
                                <v-card-title class="subtitle-1"><v-icon color="orange lighten-2" class="mr-3">mdi-message-draw</v-icon>구매후기</v-card-title>
                                <v-card-text class="pa-0">
                                    <v-divider />
                                    <v-data-table :headers="reviewsHeaders" :items="reviews" disable-sort disable-pagination hide-default-footer>
                                        <template #[`item.createdAt`]="{ item }">
                                            {{ item.createdAt.toDate() }}
                                        </template>
                                        <template #[`item.actions`]="{ item }">
                                            <v-icon small @click="detailsReview(item)">mdi-eye</v-icon>
                                        </template>
                                    </v-data-table>

                                    <v-dialog v-model="reviewDialog" max-width="500px">
                                        <v-card v-if="reviewDialog">
                                            <v-card-title>
                                                <span class="subtitle-1">구매후기</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-row v-if="editItem.product" align="center">
                                                    <v-col md="auto" class="pa-1">
                                                        <v-img v-if="editItem.product.thumb" :src="editItem.product.thumb?.url" max-width="56" max-height="56"></v-img>
                                                        <v-responsive v-else width="56" height="56">
                                                            <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                                                <v-icon>mdi-image</v-icon>
                                                            </v-row>
                                                        </v-responsive>
                                                    </v-col>
                                                    <v-col class="pa-1">
                                                        <div class="text-start">{{ editItem.product.name }}</div>
                                                    </v-col>
                                                </v-row>
                                                <v-text-field v-model="editItem.subject" label="제목" persistent-placeholder hide-details></v-text-field>
                                                <v-text-field label="작성자" persistent-placeholder readonly dense hide-details :value="editItem.user ? `${editItem.user.name}(${editItem.user.username})` : '탈퇴회원'" class="mt-6"></v-text-field>
                                                <v-textarea v-model="editItem.content" label="내용" persistent-placeholder hide-details class="mt-4"></v-textarea>
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer />
                                                <v-btn color="primary" text @click="() => $router.push({ path: `/console/shop/reviews`, query: { _id: editItem._id } })">리뷰보기</v-btn>
                                                <v-btn color="primary" text @click="closeReview">닫기</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" lg="6" class="pa-1" v-if="hasConsoleScope">
                            <v-card outlined class="fill-height">
                                <v-card-title class="subtitle-1">
                                    <v-icon color="amber lighten-2" class="mr-3">mdi-bell-ring</v-icon>공지사항
                                    <v-spacer />
                                    <v-dialog v-model="notificationDialog" max-width="720px">
                                        <template #activator="{ attrs, on }">
                                            <v-btn bottom color="accent" dark small v-bind="attrs" v-on="on" @click="createNotification">
                                                <v-icon small class="mr-2">mdi-plus</v-icon>
                                                글쓰기
                                            </v-btn>
                                        </template>
                                        <v-card v-if="notificationDialog">
                                            <v-card-title>
                                                <span v-if="!editItem._id" class="subtitle-1">공지사항 등록</span>
                                                <span v-else class="subtitle-1">공지사항 수정</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-text-field v-model="editItem.subject" label="제목" persistent-placeholder hide-details></v-text-field>
                                                <!-- <v-textarea v-model="editItem.content" label="내용" persistent-placeholder hide-details class="mt-4"></v-textarea> -->
                                                <naver-smarteditor v-model="editItem.content" label="내용" class="mt-4"/>
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer />
                                                <v-btn text @click="closeNotification">취소</v-btn>
                                                <v-btn color="primary" text @click="saveNotification">저장</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-card-title>
                                <v-card-text class="pa-0">
                                    <v-divider />
                                    <v-data-table :headers="notificationsHeaders" :items="notifications" disable-sort disable-pagination hide-default-footer>
                                        <template #[`item.notification`]="{ item }">
                                            <!-- <v-row @click="$set(item, 'show', !item.show)"> -->
                                            <v-row>
                                                <v-col class="text-start text-truncate pr-0">{{ item.subject }}</v-col>
                                                <!-- <v-col cols="auto">
                                                    <v-icon v-if="!item.show">mdi-chevron-down</v-icon>
                                                    <v-icon v-else>mdi-chevron-up</v-icon>
                                                </v-col> -->
                                            </v-row>
                                            <!-- <v-expand-transition>
                                                <v-row v-show="item.show">
                                                    <v-col>
                                                        <v-textarea v-model="item.content" readonly text hide-details class="text-body-1"></v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-expand-transition> -->
                                        </template>
                                        <template #[`item.createdAt`]="{ item }">
                                            {{ item.createdAt.toDate() }}
                                        </template>
                                        <template #[`item.actions`]="{ item }">
                                            <v-icon small @click="editNotification(item)">mdi-pencil</v-icon>
                                            <v-icon small @click="removeNotification(item)" class="ml-2">mdi-delete</v-icon>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" class="pa-1">
                            <v-card outlined>
                                <v-card-title class="subtitle-1"> <v-icon color="green lighten-2" class="mr-3"> mdi-chart-box-outline </v-icon> 상품별매출통계 </v-card-title>
                                <v-card-text class="pa-0">
                                    <v-divider />
                                    <v-data-table :items="productsSales" :headers="productsSalesHeaders" hide-default-footer>
                                        <template #[`item.product`]="{ item }">
                                            <v-row v-if="item.product" align="center">
                                                <v-col md="auto">
                                                    <v-img v-if="item.thumb" :src="item.thumb?.url" max-width="32" max-height="32"></v-img>
                                                    <v-responsive v-else width="32" height="32">
                                                        <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                                            <v-icon>mdi-image</v-icon>
                                                        </v-row>
                                                    </v-responsive>
                                                </v-col>
                                                <v-col>
                                                    <div class="text-start">{{ item.product.name }}</div>
                                                </v-col>
                                            </v-row>
                                        </template>
                                        <template #[`item.purchasePrice`]="{ item }">
                                            <div class="pr-4">{{ item.purchasePrice.format() }}</div>
                                        </template>
                                        <template #[`item.purchaseAmount`]="{ item }">
                                            <div class="pr-4">{{ item.purchaseAmount.format() }}</div>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6" lg="5" class="pa-1">
                    <v-row class="ma-n1">
                        <v-col cols="12" class="pa-1">
                            <v-card outlined>
                                <v-card-title class="subtitle-1"><v-icon color="indigo lighten-1" class="mr-3">mdi-chart-line</v-icon>월별매출통계</v-card-title>
                                <v-card-text>
                                    <v-responsive width="100%" height="196">
                                        <v-chart :options="monthlySalesOption" />
                                    </v-responsive>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6" class="pa-1">
                            <v-card outlined>
                                <v-card-title class="subtitle-1"> <v-icon color="blue lighten-2" class="mr-3"> mdi-chart-bar </v-icon> 일별결제금액 </v-card-title>
                                <v-card-text>
                                    <v-responsive width="100%" height="196">
                                        <v-chart :options="dailySalesPriceOption" />
                                    </v-responsive>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6" class="pa-1">
                            <v-card outlined>
                                <v-card-title class="subtitle-1"> <v-icon color="blue lighten-2" class="mr-3"> mdi-chart-bar </v-icon> 일별결제건수 </v-card-title>
                                <v-card-text>
                                    <v-responsive width="100%" height="196">
                                        <v-chart :options="dailySalesAmountOption" />
                                    </v-responsive>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6" class="pa-1">
                            <v-card outlined>
                                <v-card-title class="subtitle-1"> <v-icon color="blue lighten-2" class="mr-3"> mdi-chart-bar </v-icon> 일별결제자수 </v-card-title>
                                <v-card-text>
                                    <v-responsive width="100%" height="196">
                                        <v-chart :options="dailySalesUserOption" />
                                    </v-responsive>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6" class="pa-1">
                            <v-card outlined>
                                <v-card-title class="subtitle-1"> <v-icon color="cyan" class="mr-3">mdi-chart-timeline-variant</v-icon> 요일별매출통계 </v-card-title>
                                <v-card-text>
                                    <v-responsive width="100%" height="196">
                                        <v-chart :options="dayOfWeekSalesOption" />
                                    </v-responsive>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>
<script>
import ECharts from "vue-echarts";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import { mapGetters } from "vuex";

export default {
    components: {
        VChart: ECharts,
        NaverSmarteditor,
    },
    data: () => ({
        purchaseDashboard: {},

        ////////////////////////////////////////////////////////////////////
        //
        ////////////////////////////////////////////////////////////////////
        polar: {
            xAxis: {
                type: "category",
                boundaryGap: false,
                data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            },
            yAxis: {
                type: "value",
            },
            series: [
                {
                    type: "line",
                    areaStyle: {},
                    data: [100000, 500000, 1000000, 5000000],
                },
            ],
        },

        // 월별매출통계
        monthlySales: [],
        monthlySalesFilter: { startDate: null, endDate: null },

        // 일별매출통계
        dailySales: [],
        dailySalesFilter: { startDate: null, endDate: null },

        // 요일별매출통계
        dayOfWeekSales: [],
        dayOfWeekSalesFilter: { startDate: null, endDate: null },

        // 상품별매출통계
        productsSales: [],
        productsSalesFilter: { startDate: null, endDate: null },
        productsSalesHeaders: [
            { text: "상품정보", value: "product" },
            { text: "판매금액", value: "purchasePrice", width: 100 },
            { text: "판매수량", value: "purchaseAmount", width: 120 },
        ],

        ////////////////////////////////////////////////////////////////////
        inquireDialog: false,
        inquires: [],
        inquiresHeaders: [
            { text: "문의내용", value: "subject", cellClass: "text-truncate max-width-0" },
            { text: "작성일자", value: "createdAt", width: 110 },
            { text: "", value: "actions", width: 72 },
        ],

        ////////////////////////////////////////////////////////////////////
        questionDialog: false,
        questions: [],
        questionsHeaders: [
            { text: "문의내용", value: "subject", cellClass: "text-truncate max-width-0" },
            { text: "작성일자", value: "createdAt", width: 110 },
            { text: "", value: "actions", width: 72 },
        ],

        ////////////////////////////////////////////////////////////////////
        reviews: [],
        reviewDialog: false,
        reviewsHeaders: [
            { text: "후기내용", value: "content", cellClass: "text-truncate max-width-0" },
            { text: "작성일자", value: "createdAt", width: 110 },
            { text: "", value: "actions", width: 72 },
        ],

        ////////////////////////////////////////////////////////////////////
        notificationDialog: false,
        notifications: [],
        notificationsHeaders: [
            { text: "공지사항", value: "notification", cellClass: "text-truncate max-width-0" },
            { text: "작성일자", value: "createdAt", width: 110 },
            { text: "", value: "actions", width: 72 },
        ],
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
        monthlySalesOption() {
            var xAxisData = [];
            for (var date = new Date(this.monthlySalesFilter.startDate); date <= new Date(this.monthlySalesFilter.endDate); date.addMonth(1)) {
                xAxisData.push(date.getTime().toDate().substr(0, 7));
            }
            return {
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                grid: {
                    width: "100%",
                    left: "0%",
                    top: "5%",
                    bottom: "0%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: true,
                    data: xAxisData,
                },
                yAxis: {
                    type: "value",
                },
                tooltip: {
                    trigger: "axis",
                },
                series: [
                    {
                        data: this.monthlySales.map((statistic) => statistic.totalPrice),
                        type: "line",
                        areaStyle: {},
                    },
                ],
            };
        },
        dailySalesPriceOption() {
            var xAxisData = [];
            for (var date = new Date(this.dailySalesFilter.startDate); date <= new Date(this.dailySalesFilter.endDate); date.addDate(1)) {
                xAxisData.push(date.getTime().toDate());
            }
            return {
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                grid: {
                    width: "100%",
                    left: "0%",
                    top: "5%",
                    bottom: "0%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: true,
                    data: xAxisData,
                },
                yAxis: {
                    type: "value",
                },
                tooltip: {
                    trigger: "axis",
                },
                series: [
                    {
                        data: this.dailySales.map((statistic) => statistic.totalPrice),
                        type: "line",
                        areaStyle: {},
                    },
                ],
            };
        },
        dailySalesAmountOption() {
            var xAxisData = [];
            for (var date = new Date(this.dailySalesFilter.startDate); date <= new Date(this.dailySalesFilter.endDate); date.addDate(1)) {
                xAxisData.push(date.getTime().toDate());
            }
            return {
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                grid: {
                    width: "100%",
                    left: "0%",
                    top: "5%",
                    bottom: "0%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: true,
                    data: xAxisData,
                },
                yAxis: {
                    type: "value",
                    splitNumber: 1,
                },
                tooltip: {
                    trigger: "axis",
                },
                series: [
                    {
                        data: this.dailySales.map((statistic) => statistic.totalCount),
                        type: "line",
                        areaStyle: {},
                    },
                ],
            };
        },
        dailySalesUserOption() {
            var xAxisData = [];
            for (var date = new Date(this.dailySalesFilter.startDate); date <= new Date(this.dailySalesFilter.endDate); date.addDate(1)) {
                xAxisData.push(date.getTime().toDate());
            }
            return {
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                grid: {
                    width: "100%",
                    left: "0%",
                    top: "5%",
                    bottom: "0%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: true,
                    data: xAxisData,
                },
                yAxis: {
                    type: "value",
                    splitNumber: 1,
                },
                tooltip: {
                    trigger: "axis",
                },
                series: [
                    {
                        data: this.dailySales.map((statistic) => statistic.userCount),
                        type: "line",
                        areaStyle: {},
                    },
                ],
            };
        },
        dayOfWeekSalesOption() {
            return {
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                grid: {
                    width: "100%",
                    left: "0%",
                    top: "5%",
                    bottom: "0%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    data: ["월", "화", "수", "목", "금", "토", "일"],
                },
                yAxis: {
                    type: "value",
                },
                tooltip: {
                    trigger: "axis",
                },
                series: [
                    {
                        data: this.dayOfWeekSales.map((statistic) => statistic.totalPrice),
                        type: "bar",
                        areaStyle: {},
                    },
                ],
            };
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getPurchaseDashboard();

                this.monthlySalesFilter.startDate = new Date().addYear(-1).toDate().substr(0, 7);
                this.monthlySalesFilter.endDate = Date.now().toDate().substr(0, 7);

                this.dailySalesFilter.startDate = new Date().addMonth(-1).toDate();
                this.dailySalesFilter.endDate = Date.now().toDate();

                this.dayOfWeekSalesFilter.startDate = new Date().addMonth(-1).toDate();
                this.dayOfWeekSalesFilter.endDate = Date.now().toDate();

                this.productsSalesFilter.startDate = new Date().addMonth(-1).toDate();
                this.productsSalesFilter.endDate = Date.now().toDate();

                await this.getMonthlySales();
                await this.getDailySales();
                await this.getDayOfWeekSales();
                await this.getProductsSales();

                await this.getInquires();
                await this.getQuestions();
                await this.getReviews();
                await this.getNotifications();

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        editInquire(inquire) {
            this.editItem = Object.assign({}, inquire);
            this.inquireDialog = true;
        },

        closeInquire() {
            this.inquireDialog = false;
        },

        async saveInquire() {
            try {
                await this.putInquire(this.editItem);
                await this.getInquires();
                alert("저장되었습니다");
                this.inquireDialog = false;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        editQuestion(question) {
            this.editItem = Object.assign({}, question);
            this.questionDialog = true;
        },

        closeQuestion() {
            this.questionDialog = false;
        },

        async saveQuestion() {
            try {
                await this.putQuestion(this.editItem);
                await this.getQuestions();
                alert("저장되었습니다");
                this.questionDialog = false;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        detailsReview(review) {
            this.editItem = Object.assign({}, review);
            this.reviewDialog = true;
        },

        closeReview() {
            this.reviewDialog = false;
        },

        closeNotification() {
            this.notificationDialog = false;
        },

        createNotification() {
            this.editItem = Object.assign({}, this.defaultItem);
            this.notificationDialog = true;
        },

        editNotification(notification) {
            this.editItem = Object.assign({}, notification);
            this.notificationDialog = true;
        },

        async saveNotification() {
            try {
                this.editItem._id ? await this.putNotification() : await this.postNotification();
                await this.getNotifications();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
            this.notificationDialog = false;
        },

        async removeNotification(notification) {
            try {
                if (confirm("공지사항을 삭제하시겠습니까?")) {
                    await this.deleteNotification(notification);
                    await this.getNotifications();
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        getPurchaseDashboard() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get(`/api/console/shop/purchases/dashboard/all`);

                    this.purchaseDashboard = res.data.dashboard;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        getMonthlySales() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/console/statistics/monthlySales", {
                        params: this.monthlySalesFilter,
                    });

                    this.monthlySales = res.data.monthlySales;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        getDailySales() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/console/statistics/dailySales", {
                        params: this.dailySalesFilter,
                    });

                    this.dailySales = res.data.dailySales;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        getDayOfWeekSales() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/console/statistics/dayOfWeekSales", {
                        params: this.dayOfWeekSalesFilter,
                    });

                    this.dayOfWeekSales = res.data.dayOfWeekSales;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        getProductsSales() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/console/statistics/productsSales", {
                        headers: { limit: 10 },
                        params: this.productsSalesFilter,
                    });

                    this.productsSales = res.data.productsSales;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        getInquires() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/console/shop/inquires", {
                        headers: { limit: 5 },
                    });

                    this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);
                    this.inquires = res.data.inquires;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        putInquire(inquire) {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.put(`/api/console/shop/inquires/${inquire._id}`, inquire);

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        getQuestions() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/console/center/questions", {
                        headers: { limit: 5 },
                    });

                    this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);
                    this.questions = res.data.questions;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        putQuestion(question) {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.put(`/api/console/center/questions/${question._id}`, question);

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        getReviews() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/console/shop/reviews", {
                        headers: { limit: 5 },
                    });

                    this.reviews = res.data.reviews;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        getNotifications() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/console/center/notifications", {
                        headers: { limit: 5 },
                    });

                    this.notifications = res.data.notifications;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        postNotification() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.post("/api/console/center/notifications", this.editItem);

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        putNotification() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.put("/api/console/center/notifications/" + this.editItem._id, this.editItem);

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        deleteNotification(notification) {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.delete("/api/console/center/notifications/" + notification._id);

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0px;
    }
    .echarts {
        width: 100%;
        height: 100%;
    }
}
</style>
