<template>
    <v-layout justify-center>
        <v-responsive width="100%" class="px-3 mx-n3">
            <list-heading title="개인결제 목록" showsFilterButton v-model="showsSearch">
                <template v-if="hasConsoleScope" #add-button>
                    <user-payment-form @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </user-payment-form>
                </template>
            </list-heading>
            <user-payment-search v-bind="{ showsSearch }" />

            <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined mt-3" style="overflow: hidden">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>

                <template #[`item.goods`]="{ item }">
                    <item-product :product="item.product" isLink />
                </template>

                <template v-if="hasConsoleScope" #[`item.actions`]="{ item }">
                    <user-payment-form v-bind="{ _id: item._id }" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile v-bind="attrs" v-on="on" :disabled="disabled('edit',item)">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </user-payment-form>
                    <v-btn text icon tile :disabled="disabled('delete',item)" @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                    <!-- <v-btn text icon tile :disabled="disabled('cancel',item)" @click="cancel(item)">
                        <v-icon small> mdi-currency-usd-off </v-icon>
                    </v-btn> -->
                </template>

            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapGetters } from 'vuex';
import { attrs_input__console, attrs_switch__console, initDataTableHeaders, USER_TYPES, USER_PAYMENT_STATUS } from "@/assets/variables";

import ItemProduct from "@/components/console/dumb/item-product.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import UserPaymentSearch from "@/components/console/users/payments/user-payment-search.vue";
import UserPaymentForm from "@/components/console/users/payments/user-payment-form.vue";

const userTypes = [USER_TYPES.COMPANY.value, USER_TYPES.PERSON.value];

export default {
    components: {
        ListHeading,
        UserPaymentSearch,
        UserPaymentForm,
        ItemProduct
    },
    data: () => ({
        payments: [],

        limit: 10,
        summary: { totalCount: 0 },

        userTypes,
        attrs_input__console,
        attrs_switch__console,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
        headers() {
            return initDataTableHeaders([
                {   text: "결제상태", 
                    value: "status", 
                    width: 90, 
                    formatter: (value) => USER_PAYMENT_STATUS[value].text || "-"
                },
                {   text: "유효기간", 
                    value: "expire", 
                    width: 180,
                    formatter: (value) => value?.isActive ? `${value?.startsAt?.toDate?.()}~${value?.endsAt?.toDate?.()}` : "무기한"
                },
                { text: "결제코드", value: "orderNo" },
                { text: "결제상품", value: "goods" },
                {
                    text: "주문금액",
                    value: "price",
                    width: 140,
                    formatter: (value) => `${value.format()}원`
                },
                {
                    text: "결제자",
                    value: "user",
                    width: 140,
                    formatter: (value) => `${value?.name} (${value?.username})`
                },
                {
                    text: "요청자",
                    value: "seller",
                    width: 140,
                    formatter: (value) => `${value?.name} (${value?.username})`
                },
                { width: 160, text: "결제일시", value: "paymentAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
                { width: 160, text: "생성일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
                { width: 140, text: "", value: "actions", align: "center", test: (value)=> value }
            ]).filter((header)=> header?.test ? header.test(this.hasConsoleScope) : true )
        },
        items() {
            return [...this.payments];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            if(!this.hasConsoleScope) query._seller = this.$store.state.payload._user;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                await this.search(false);
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, payments } = await api.console.users.payments.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.payments = payments;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { payment } = await api.console.users.payments.put(item);
                this.loading = false;
                this.updateItem(payment);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(item) {
            const index = this.payments.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.payments.splice(index, 1, item);
            else {
                this.payments = [item, ...this.payments];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async remove(item) {
            try {
                if (confirm("해당 개인결제를 삭제하시겠습니까?")) {
                    await api.console.users.payments.delete(item);
                    await this.search();
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        async cancel(item){
            try {
                if (confirm("해당 개인결제를 취소시키겠습니까?\r\n취소된 결제는 복구할 수 없습니다.")) {
                    await api.console.users.payments.cancel(item);
                    await this.search();
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        disabled(type, item) {
            switch (type){
                case "edit":
                case "delete":
                    return item.status !== USER_PAYMENT_STATUS.PAY_AWAITING.value;
                case "cancel":
                    return item.status !== USER_PAYMENT_STATUS.PAY_COMPLETED.value;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
