var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2 subtitle-1",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-dialog', {
    attrs: {
      "width": "400"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(" 발급제한 "), _c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-information-outline")])], 1)];
      }
    }])
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("발급제한")]), _c('v-card-text', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    staticClass: "pa-0 my-n4 caption",
    attrs: {
      "dense": ""
    }
  }, [_vm._v(" ※ 값이 0일 때 제한 미적용 ")])], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-currency-field', _vm._b({
    staticClass: "text-align-right-field",
    attrs: {
      "label": "전체발급수량제한",
      "prefix": "최대",
      "suffix": "개"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.issue.limit.shop.count__sum,
      callback: function ($$v) {
        _vm.$set(_vm.form.issue.limit.shop, "count__sum", $$v);
      },
      expression: "form.issue.limit.shop.count__sum"
    }
  }, 'v-currency-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-currency-field', _vm._b({
    staticClass: "text-align-right-field",
    attrs: {
      "label": "전체발급금액제한",
      "prefix": "최대",
      "suffix": "원"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.issue.limit.shop.price__sum,
      callback: function ($$v) {
        _vm.$set(_vm.form.issue.limit.shop, "price__sum", $$v);
      },
      expression: "form.issue.limit.shop.price__sum"
    }
  }, 'v-currency-field', _vm.attrs_input__console, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }