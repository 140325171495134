<template>
    <v-dialog v-model="dialog" width="1280" persistent :fullscreen="$vuetify.breakpoint.mobile">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }" tile class="d-flex flex-column" max-height="90vh" style="overflow: hidden">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-card-text class="pa-6" style="flex: 0 1 auto; overflow-y: auto">
                <v-row class="ma-n2">
                    <v-col cols="12" class="pa-2">
                        <v-row class="ma-n2">
                            <v-col cols="12" :md="hasConsoleScope ? 4 : 3" class="pa-2">
                                <form-basics v-model="form" @input="emit" />
                            </v-col>
                            <v-col cols="12" :md="hasConsoleScope ? 8 : 9" class="pa-2">
                                <form-target v-model="form" @input="emit" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <form-options v-model="form" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-col cols="auto" class="py-0" style="min-width: 140px">
                    <v-switch v-model="form.isActive" @change="emit" v-bind="attrs_switch__console" :disabled="form.isDefault">
                        <span slot="label" class="subtitle-2"> {{ form.isActive ? "사용" : "미사용" }} </span>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn text color="primary" v-bind="{ disabled }" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import { attrs_input__console, attrs_switch__console, initShopShipping, initShopShippingOption } from "@/assets/variables";

import FormBasics from "./form-basics.vue";
import FormTarget from "./form-target.vue";
import FormOptions from "./form-options.vue";

export default {
    components: {
        FormBasics,
        FormTarget,
        FormOptions,
    },
    props: {
        value: { type: Object, default: initShopShipping },
        isClone: { type: Boolean, default: false },
    },
    data: () => ({
        form: initShopShipping(),

        attrs_input__console,
        attrs_switch__console,

        dialog: false,
        loading: false,
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),

        seller() {
            return this.form.seller || (this.hasConsoleScope ? null : this.$store.state?.user);
        },

        isCreate() {
            return !this.value._id;
        },
        title() {
            let title = "배송정책";

            if (this.isClone) title += " 복제";
            else if (this.isCreate) title += " 생성";
            else title += " 수정";

            return title;
        },
        disabled() {
            return this.form.options.some(({ _region, _method }) => !_region || !_method);
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (this.isCreate) {
                    if (this.seller?._id == this.form._seller) throw new Error("stop");

                    const params = { isDefault: true, _seller: this.seller?._id };

                    this.form = initShopShipping({
                        ...this.form,
                        _seller: this.seller?._id,
                        seller: this.seller,
                        options: [
                            initShopShippingOption({
                                _region: (await api.console.shop.shippings.regions.gets({ params }))?.regions?.[0]?._id || undefined,
                                _method: (await api.console.shop.shippings.methods.gets({ params }))?.methods?.[0]?._id || undefined,
                            }),
                        ],
                    });
                } else {
                    const { shipping } = await api.console.shop.shippings.get({ _id: this.value._id });

                    if (this.isClone) {
                        delete shipping._id;
                        delete shipping._options;
                        for (const option of shipping.options) {
                            delete option._id;
                            delete option._shipping;
                        }
                    }

                    this.form = initShopShipping(shipping);

                    if (!this.isClone) this.$emit("input", this.form);
                }
            } finally {
                this.loading = false;
            }
        },
        emit() {
            this.form = initShopShipping(this.form);
        },
        validates() {
            let { name } = this.form;
            if (!name) throw new Error("이름을 입력하셔야 합니다");
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();

                // POST, PUT shop-shippings
                let { post, put } = api.console.shop.shippings;
                let { _options = [], options = [], ...form } = this.form;
                let shipping = (await (this.isCreate || this.isClone ? post : put)(form))?.shipping;

                // POST, PUT shop-shipping-options
                if (options.length) {
                    let { post: postOption, put: putOption } = api.console.shop.shippings.options;
                    options = await Promise.all(options.map(async (item) => (await (item._id ? putOption : postOption)({ ...item, _shipping: shipping._id }))?.option));
                }

                // DELETE shop-shipping-options
                await Promise.all(_options.filter((_option) => !options.some(({ _id }) => _option == _id)).map(async (_id) => await api.console.shop.shippings.options.delete({ _id, _shipping: shipping._id })));

                // PUT shop-shippings
                _options = options.map(({ _id }) => _id);
                shipping = await put({ _id: shipping._id, _options });

                alert(this.isCreate || this.isClone ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", shipping);
                this.dialog = false;
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
        "seller._id"() {
            if (this.isCreate) this.init();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .text-align-right-field {
    & > .v-input__control > .v-input__slot > .v-text-field__slot > input {
        text-align: right !important;
    }
}
::v-deep .amount-field {
    & > .v-input__control > .v-input__slot {
        padding-right: 0 !important;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner {
        margin: 1px 0 0;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner > .v-text-field--solo {
        width: 58px !important;

        .v-input__slot {
            padding-right: 4px;
        }
        .v-select__selections {
            justify-content: center;

            > .v-select__selection {
                margin-right: 0;
            }

            > input {
                display: none;
            }
        }

        .v-input__control,
        .v-input__slot {
            background-color: transparent;
        }
        .v-input__append-inner {
            margin-top: 0;
            padding-left: 0;
        }
    }
}

::v-deep .v-text-field__details {
    margin-bottom: 0 !important;
}

::v-deep .w-100-px {
    width: 100px;
}
</style>
