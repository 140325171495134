<template>
    <v-dialog v-model="shows" width="320">
        <template #activator="{ attrs, on }">
            <v-btn small outlined color="primary" class="mr-2" v-bind="{ ...attrs, loading }" v-on="on">{{ title }}</v-btn>
        </template>
        <v-card>
            <v-card-title class="subtitle-1">{{ title }}</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12"> <v-select label="사유" v-model="form.claimReason" :items="['단순 변심', '상품 불량', '기타']" v-bind="attrs_input__console" /> </v-col>
                    <v-col cols="12"> <v-textarea label="상세" v-model="form.claimReasonDetails" v-bind="attrs_input__console" /> </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn text @click="shows = false">취소</v-btn>
                <v-btn color="primary" text @click="put">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input__console } from "@/assets/variables";

const initForm = () => ({
    claimReason: null,
    claimReasonDetails: null,
});

export default {
    props: {
        title: { type: String, default: "반품사유수정" },
        loading: { type: Boolean, default: false },
        selected: { type: Array, default: () => [] },
    },
    data: () => ({
        form: initForm(),

        shows: false,
        attrs_input__console,
    }),
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        init() {
            this.form = initForm();
        },
        async put() {
            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                if (!this.selected.length) throw new Error(`${this.title} 처리할 구매건을 선택해주세요`);

                const { claimReason, claimReasonDetails } = this.form;
                for (const { _id } of this.selected) {
                    await api.console.shop.purchases.putPurchase({ _id, claimReason, claimReasonDetails });
                }

                alert(`${this.title} 처리 되었습니다`);
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.$emit("loading", false);
                this.$emit("search");
            }
        },
    },
};
</script>

<style></style>
