var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', _vm._b({
    attrs: {
      "title": "상품옵션",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.optionsConf.enabled,
            callback: function ($$v) {
              _vm.$set(_vm.form.optionsConf, "enabled", $$v);
            },
            expression: "form.optionsConf.enabled"
          }
        }, 'v-switch', _vm.attrs_switch__console, false))];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.optionsConf.enabled,
      expression: "form.optionsConf.enabled"
    }]
  }, [_c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('vertical-table-row', {
    attrs: {
      "term": "정렬",
      "divider": "",
      "props": _vm.rowProps
    }
  }, [_c('v-sheet', {
    staticClass: "mx-n4"
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.sortItems
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.optionsConf.sort,
      callback: function ($$v) {
        _vm.$set(_vm.form.optionsConf, "sort", $$v);
      },
      expression: "form.optionsConf.sort"
    }
  }, 'v-select', _vm.attrs_input__verticalTable, false))], 1)], 1), _c('vertical-table-row', {
    attrs: {
      "term": "분류",
      "divider": "",
      "props": _vm.rowProps
    }
  }, [_c('v-sheet', {
    staticClass: "mx-n4"
  }, [_c('options-criteria', _vm._g({
    model: {
      value: _vm.form.optionsConf.criteria,
      callback: function ($$v) {
        _vm.$set(_vm.form.optionsConf, "criteria", $$v);
      },
      expression: "form.optionsConf.criteria"
    }
  }, {
    generate: _vm.generate
  }))], 1)], 1), _c('vertical-table-row', {
    attrs: {
      "term": "목록",
      "divider": "",
      "hideDivider": "",
      "props": _vm.rowProps
    }
  }, [_c('v-sheet', {
    staticClass: "mx-n4"
  }, [_c('v-data-table', _vm._b({
    staticClass: "product-options-table",
    attrs: {
      "items": _vm.form.options,
      "item-key": "name",
      "show-select": ""
    },
    scopedSlots: _vm._u([{
      key: `header.value`,
      fn: function () {
        return [_c('v-row', {
          staticClass: "ma-0"
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_vm._v("옵션명")])], 1), _c('v-expand-transition', [_c('v-sheet', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.form.options[0],
            expression: "form.options[0]"
          }],
          attrs: {
            "color": "transparent"
          }
        }, [_c('v-divider'), _c('v-row', {
          staticClass: "ma-0"
        }, [_vm._l(_vm.criteriaNames, function (name, index) {
          var _name$trim;
          return [_c('v-col', {
            key: index
          }, [_vm._v(" " + _vm._s(decodeURIComponent((name === null || name === void 0 ? void 0 : (_name$trim = name.trim) === null || _name$trim === void 0 ? void 0 : _name$trim.call(name)) || name || "")) + " ")]), index != _vm.criteriaNames.length - 1 ? _c('v-divider', {
            key: `${index}-divider`,
            attrs: {
              "vertical": ""
            }
          }) : _vm._e()];
        })], 2)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "top",
      fn: function () {
        return [_c('options-edit-bulk', _vm._b({
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.form.options,
            callback: function ($$v) {
              _vm.$set(_vm.form, "options", $$v);
            },
            expression: "form.options"
          }
        }, 'options-edit-bulk', {
          selected: _vm.selected
        }, false)), _c('v-divider')];
      },
      proxy: true
    }, {
      key: `item.value`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-row', {
          staticClass: "ma-0"
        }, [_vm._l(item.value.split('/'), function (value, index) {
          return [_c('v-col', {
            key: index,
            staticStyle: {
              "height": "48px"
            }
          }, [_vm._v(_vm._s(decodeURIComponent(value)))]), index != _vm.criteriaNames.length - 1 ? _c('v-divider', {
            key: `${index}-divider`,
            attrs: {
              "vertical": ""
            }
          }) : _vm._e()];
        })], 2)];
      }
    }, {
      key: `item.code`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-text-field', _vm._b({
          staticClass: "caption",
          on: {
            "input": _vm.emit
          },
          model: {
            value: item.code,
            callback: function ($$v) {
              _vm.$set(item, "code", $$v);
            },
            expression: "item.code"
          }
        }, 'v-text-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.price`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-currency-field', _vm._b({
          staticClass: "caption",
          attrs: {
            "suffix": "원"
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: item.price,
            callback: function ($$v) {
              _vm.$set(item, "price", $$v);
            },
            expression: "item.price"
          }
        }, 'v-currency-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.stock`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-currency-field', _vm._b({
          staticClass: "caption",
          on: {
            "input": _vm.emit
          },
          model: {
            value: item.stock,
            callback: function ($$v) {
              _vm.$set(item, "stock", $$v);
            },
            expression: "item.stock"
          }
        }, 'v-currency-field', _vm.attrs_input__verticalTable, false))];
      }
    }, {
      key: `item.enabled`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-simple-checkbox', {
          staticClass: "d-inline-block mr-n2",
          attrs: {
            "dense": "",
            "color": "primary"
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: item.enabled,
            callback: function ($$v) {
              _vm.$set(item, "enabled", $$v);
            },
            expression: "item.enabled"
          }
        })];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "tile": "",
            "color": "red",
            "height": "48"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)];
      }
    }], null, true),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'v-data-table', {
    headers: _vm.headers
  }, false))], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }