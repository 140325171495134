<template>
    <v-layout justify-center>
        <v-responsive :max-width="1080" class="px-3 mx-n3">
            <list-heading :title="category?.name">
                <template #buttons>
                    <v-row class="ma-n2">
                        <v-spacer/>
                        <v-col class="pa-2" cols="auto">
                            <analytics-period-filter v-model="params" @input="sync" />
                        </v-col>
                        <v-col class="pa-2" cols="3">
                            <sales-analytics-sort-field v-model="params" @input="sync" />
                        </v-col>
                        <v-col class="pa-2" cols="3">
                            <sales-analytics-sort v-model="params" @input="sync" />
                        </v-col>
                    </v-row>
                </template>
            </list-heading>
            <sales-analytics-search v-model="params" v-bind="{ showsSearch }" @input="sync" />

            <v-row class="mt-n3"> </v-row>

            <v-data-table v-bind="{ headers, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined" style="overflow: hidden">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
                <template #[`item.product`]="{ item }">
                    <v-row v-if="item" align="center">
                        <v-col md="auto">
                            <!-- <v-img v-if="item.thumb" :src="item.thumb?.url" max-width="56" max-height="56"/> -->
                            <image-popup v-if="item.thumb" :src="item.thumb?.url" :attrs_avatar="{ size: '40', rounded: true }"/>
                            <v-responsive v-else width="56" height="56">
                                <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                    <v-icon>mdi-image</v-icon>
                                </v-row>
                            </v-responsive>
                        </v-col>
                        <v-col>
                            <div class="text-start cursor-pointer" @click="$router.push(`/console/shop/products/${item?.product?._id}`)">{{ item.product.name }}</div>
                        </v-col>
                    </v-row>
                </template>
                <template #[`item.status`]="{ item }">
                    {{ item?.product?.isDeleted || !item?.product?.shows ? "종료" : "판매중" }}
                </template>
            </v-data-table>

            <v-pagination :value="params.page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page)=>params = {...params, page}" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import { initDataTableHeaders } from "@/assets/variables/inits";
import { ANALYTICS_PERIOD_FILTER } from "@/assets/variables/constants";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import SalesAnalyticsSort from "./sales-analytics-sort.vue";
import SalesAnalyticsSearch from "./sales-analytics-search.vue";
import SalesAnalyticsSortField from "./sales-analytics-sort-field.vue";
import AnalyticsPeriodFilter from "@/components/console/analytics/analytics-period-filter.vue";

export default {
    components: {
        ImagePopup,
        ListHeading,
        AnalyticsPeriodFilter,
        SalesAnalyticsSearch,
        SalesAnalyticsSort,
        SalesAnalyticsSortField
    },
    props: {
        category: { type: Object, default: () => ({}) },
    },
    data: () => ({
        loading: false,
        showsSearch: true,

        analytics: [],
        summary: {
            totalCount: 0,
        },
        params: {
            period: ANALYTICS_PERIOD_FILTER.DAILY.value,
            searchKey: "name",
            searchValue: null,
            sortField: "purchasePrice",
            sortType: -1,
            page: 1,
        },

        limit: 5,
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
        items() {
            return [...this.analytics];
        },
        headers() {
            return initDataTableHeaders([
                { text: "상품정보", value: "product" },
                { text: "판매수량", value: "purchaseAmount", width: "140px", align: "center", formatter: (value) => `${value}개` },
                { text: "판매금액", value: "purchasePrice", width: "180px", align: "center", formatter: (value) => `${value.format()}원` },
                { text: "상태", value: "status", width: "100px", align: "center" },
            ]);
        },
        skip() {
            return (this.params.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit);
        },
        sort() {
            const { sortField, sortType } = this.params;
            if(!!sortField && !!sortType) return JSON.stringify({ [sortField]: sortType });
            return null;
        },
        _seller() {
            if(this.hasConsoleScope) return null;
            return this.$store.state.payload._user;
        }
    },
    methods: {
        async search() {
            if (this.loading) return;
            this.loading = true;
            try {
                const { params, skip, limit, sort, _seller } = this;
                const { summary, analytics } = await api.console.analytics.getSales({
                    headers: { skip, limit, sort },
                    params: {
                        ...params,
                        _category: this.category._id,
                        _seller
                    },
                });

                this.summary = summary;
                this.analytics = analytics;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        sync(value) {
            this.params = value;
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        params() {
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
    .cursor-pointer {
        cursor: pointer;
    }
</style>