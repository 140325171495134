var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-currency-field', _vm._b({
    attrs: {
      "label": "판매자 할인부담률"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.share,
      callback: function ($$v) {
        _vm.$set(_vm.form, "share", $$v);
      },
      expression: "form.share"
    }
  }, 'v-currency-field', _vm.props__share, false));

}
var staticRenderFns = []

export { render, staticRenderFns }