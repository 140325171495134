<template>
    <v-data-table v-if="value.length" v-bind="{ items: value, headers }" hide-default-footer disable-filtering disable-pagination disable-sort :items-per-page="-1" class="rounded-0 transparent">
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
        <template #[`item.isActive`]="{ item }">
            <v-switch v-model="item.isActive" v-bind="attrs_switch__console" disabled color="grey darken-1" />
        </template>
    </v-data-table>
    <div v-else class="pa-3 grey--text">기준이 없습니다</div>
</template>

<script>
import { attrs_switch__console, GRADE_CRITERIA_TYPES } from "@/assets/variables";

const headers = [
    { width: 200, value: "type", text: "유형", formatter: (value) => GRADE_CRITERIA_TYPES[value]?.text || value || "-" },
    { width: 100, value: "value", text: "금액", formatter: (value) => `${value?.format?.()}원` || value || "-", align: "right", cellClass: "pl-0" },
    { width: +80, value: "isActive", text: "활성화", align: "center" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-") }));

export default {
    props: {
        value: { type: Array, default: () => [] }, // criteria
    },
    data: () => ({
        headers,
        attrs_switch__console,
    }),
};
</script>

<style></style>
