<template>
    <list-search v-bind="{ showsSearch }" dense hideLabel @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-select v-model="query['searchDateKey']" label="검색기간 기준" :items="searchDateKeys" v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query['searchDateValue'][0]" type="date" label="검색기간(시작일)" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query['searchDateValue'][1]" type="date" label="검색기간(종료일)" clearable v-bind="attrs_input__console" />
            </v-col>
            <template v-if="hasConsoleScope">
                <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                    <autocomplete-user v-model="query['_user']" label="회원" placeholder="전체" clearable v-bind="{ ...attrs_input__console, ...props__autocompleteUser }" v-on="{ emit }" />
                </v-col>
            </template>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-select v-model="query['isIssued']" label="발행여부" :items="isIssuedItems" v-bind="attrs_input__console" v-on="{ emit }" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { mapGetters } from "vuex";
import { USER_TYPES } from "@/assets/variables/constants";
import { attrs_input__console } from "@/assets/variables/attrs";

import ListSearch from "@/components/console/dumb/list-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["searchDateKey"]: query?.["searchDateKey"] || "createdAt",
    ["searchDateValue"]: JSON.parse(query?.["searchDateValue"] || JSON.stringify([null, null])),

    ["isIssued"]: JSON.parse(query?.["isIssued"] ?? "null"),
    ["_user"]: query?.["_user"] ?? null,
});

const searchDateKeys = [
    { text: "작성일", value: "createdAt" },
    { text: "발행일", value: "issuedAt" },
];

const isIssuedItems = [
    { text: "전체", value: null },
    { text: "발행", value: true },
    { text: "미발행", value: false },
];

const props__autocompleteUser = {
    params: {
        type: USER_TYPES.COMPANY.value,
    },
    sort: { companyName: 1, username: 1, _id: 1 },
    itemText: ({ companyName, username }) => `${companyName || "-"} (${username})`,
};

export default {
    components: {
        ListSearch,
        AutocompleteUser,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    setup: () => ({
        searchDateKeys,
        isIssuedItems,

        attrs_input__console,
        props__autocompleteUser,
    }),
    data: () => ({
        query: initQuery(),
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            if (!query["searchDateKey"] || query["searchDateKey"] == "createdAt") delete query["searchDateKey"];

            if (!query["searchDateValue"]) delete query["searchDateValue"];
            if (!query["searchDateValue"]?.[0] && !query?.["searchDateValue"]?.[1]) delete query["searchDateValue"];
            else query["searchDateValue"] = JSON.stringify(query["searchDateValue"]);

            if (query?.["isIssued"] == null) delete query["isIssued"];

            if (!query["_user"]) delete query["_user"];

            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
