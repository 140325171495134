var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "console-notification",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1400px",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "카테고리 공통 이미지 목록"
    },
    scopedSlots: _vm._u([{
      key: "add-button",
      fn: function () {
        return [_c('category-detail-form', {
          on: {
            "input": _vm.search
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "my-n1",
                attrs: {
                  "small": "",
                  "fab": "",
                  "tile": "",
                  "color": "transparent primary--text"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }), _c('v-data-table', _vm._b({
    ref: "data-table",
    staticClass: "v-sheet--outlined",
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value,
            item = _ref2.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.no`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s((_vm.page - 1) * _vm.limit + _vm.categoryDetails.indexOf(item) + 1) + " ")];
      }
    }, {
      key: `item._category`,
      fn: function (_ref4) {
        var _vm$categories$find;
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s((_vm$categories$find = _vm.categories.find(function (category) {
          return category._id === item._category;
        })) === null || _vm$categories$find === void 0 ? void 0 : _vm$categories$find.name) + " ")];
      }
    }, {
      key: `item.user`,
      fn: function (_ref5) {
        var _item$seller;
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(`${(item === null || item === void 0 ? void 0 : (_item$seller = item.seller) === null || _item$seller === void 0 ? void 0 : _item$seller.companyName) || "최고관리자"}`) + " ")];
      }
    }, {
      key: `item.body`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('v-responsive', [_c('v-row', {
          on: {
            "click": function ($event) {
              return _vm.$set(item, 'show', !item.show);
            }
          }
        }, [_c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [!item.show ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _c('v-icon', [_vm._v("mdi-chevron-up")])], 1)], 1), _c('v-expand-transition', [_c('v-row', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: item.show,
            expression: "item.show"
          }]
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('p', {
          staticClass: "category-content",
          domProps: {
            "innerHTML": _vm._s(item.body)
          }
        })])], 1)], 1)], 1)];
      }
    }, {
      key: `item.enabled`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-switch', _vm._b({
          staticClass: "mt-0",
          attrs: {
            "hide-details": ""
          },
          on: {
            "change": function ($event) {
              return _vm.update(item);
            }
          },
          model: {
            value: item.enabled,
            callback: function ($$v) {
              _vm.$set(item, "enabled", $$v);
            },
            expression: "item.enabled"
          }
        }, 'v-switch', Object.assign({}, _vm.attrs_switch__console), false))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('category-detail-form', _vm._b({
          on: {
            "input": _vm.search
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref9) {
              var attrs = _ref9.attrs,
                on = _ref9.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "text": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-pencil")])], 1)];
            }
          }], null, true)
        }, 'category-detail-form', {
          _id: item._id
        }, false)), _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v(" mdi-delete ")])];
      }
    }], null, true)
  }, 'v-data-table', {
    loading: _vm.loading,
    headers: _vm.itemHeaders,
    items: _vm.items
  }, false)), _c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }