<template>
    <v-row no-gutters class="mx-n1">
        <v-col cols="12" v-if="mode == 'form'">
            <v-switch v-model="form.service.isActive" dense inset hide-details class="pa-3 ma-0" @change="emit">
                <span class="caption" slot="label">
                    <template v-if="form.service.isActive"> 사용 </template>
                    <template v-else> 미사용 </template>
                </span>
            </v-switch>
            <v-divider v-if="form.service.isActive" style="margin-bottom: -1px" />
        </v-col>
        <v-expand-transition>
            <v-col cols="12" v-show="form.service.isActive">
                <v-currency-field v-model="form.service.charge.amount" prefix="기본요금" suffix="원" class="caption" v-bind="{ ...attrs_input__verticalTable, readonly }" @input="emit" />
            </v-col>
        </v-expand-transition>
        <template v-if="mode != 'form'">
            <template v-if="!form.service.isActive">
                <v-sheet height="48" color="transparent" class="pa-3 d-flex align-center">
                    <span> - </span>
                </v-sheet>
            </template>
        </template>
    </v-row>
</template>

<script>
import { attrs_input__verticalTable, attrs_switch__console, initShopShippingOption } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initShopShippingOption },

        mode: { type: String, default: "form" },
    },
    data: () => ({
        form: initShopShippingOption(),

        attrs_switch__console,
        attrs_input__verticalTable,
    }),
    computed: {
        readonly() {
            return this.mode != "form";
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopShippingOption(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>