<template>
    <v-dialog v-model="shows" width="720" :persistent="loading">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card outlined>
            <v-card-title>상품전시 그룹 설정</v-card-title>
            <v-divider />
            <display-list-codes-form-table v-model="form.shop.displayCodes" />
            <v-divider />
            <v-card-actions class="justify-end">
                <v-btn text color="red" @click="shows = false">취소하기</v-btn>
                <v-btn text color="primary" @click="save">저장하기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input__console, attrs_switch__console, initSetting, initSettingShopDisplayCode } from "@/assets/variables";

import DisplayListCodesFormTable from "./display-list-codes-form-table.vue";
import { mapGetters } from "vuex";

export default {
    components: {
        DisplayListCodesFormTable,
    },
    props: {
        value: { type: Object, default: initSetting },
    },
    data: () => ({
        form: initSetting(),

        attrs_input__console,
        attrs_switch__console,

        shows: false,
        loading: false,
    }),
    computed: {
        ...mapGetters(["displayCodes"]),
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        shows() {
            this.sync();
        },
    },
    methods: {
        sync() {
            const setting = initSetting(this.value);
            setting.shop.displayCodes = this.displayCodes.map(initSettingShopDisplayCode);
            this.form = setting;
        },

        validate() {},

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.validate();

                const { shop } = this.form;
                const { setting } = await api.console.setting.put({ shop });
                this.shows = false;
                this.$emit("input", setting);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>