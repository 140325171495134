var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": _vm.title
    }
  }, [_vm.isConsole ? _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.memo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "memo", $$v);
      },
      expression: "form.memo"
    }
  })], 1) : _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.sellerMemo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sellerMemo", $$v);
      },
      expression: "form.sellerMemo"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }