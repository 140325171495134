<template>
    <v-dialog v-model="shows" width="1280" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-card-text>
                <v-row class="ma-n2">
                    <v-col class="pa-2" cols="12" sm="6" lg="4">
                        <autocomplete-user v-model="form._user" label="회원" class="text-caption" disabled v-bind="{ ...props__autocompleteUser, loading }" @input="emit" />
                    </v-col>
                    <v-col class="pa-2" cols="12" sm="6" lg="4">
                        <v-text-field v-model="form.information.serialNum" label="일련번호" class="text-caption" v-bind="{ ...attrs_input__console, loading }" @input="emit" />
                    </v-col>
                    <v-col class="pa-2" cols="12" lg="4">
                        <form-bookinfo v-model="form" @input="emit" />
                    </v-col>
                    <v-col class="pa-2" cols="12" lg="6">
                        <form-invoicer v-model="form" @input="emit" />
                    </v-col>
                    <v-col class="pa-2" cols="12" lg="6">
                        <form-invoicee v-model="form" @input="emit" />
                    </v-col>
                    <v-col class="pa-2" cols="12">
                        <form-briefing v-model="form" @input="emit" />
                    </v-col>
                    <v-col class="pa-2" cols="12">
                        <form-itemlist v-model="form" @input="emit" />
                    </v-col>
                    <v-col class="pa-2" cols="12">
                        <form-datafoot v-model="form" @input="emit" />
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- <v-card-actions>
                <v-col class="py-0"> </v-col>
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions> -->
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";

import { attrs_input__console, attrs_switch__console } from "@/assets/variables/attrs";
import { initShopTaxinvoice } from "@/assets/variables/inits";
import { USER_TYPES } from "@/assets/variables/constants";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

import FormBookinfo from "./form/form-bookinfo.vue";
import FormBriefing from "./form/form-briefing.vue";
import FormDatafoot from './form/form-datafoot.vue';
import FormInvoicee from "./form/form-invoicee.vue";
import FormInvoicer from "./form/form-invoicer.vue";
import FormItemlist from "./form/form-itemlist.vue";

const props__autocompleteUser = {
    ...attrs_input__console,
    params: {
        type: USER_TYPES.COMPANY.value,
    },
    itemText: ({ companyName, username } = {}) => `${companyName || "-"} (${username})`,
};

export default {
    components: {
        AutocompleteUser,
        FormBookinfo,
        FormBriefing,
        FormDatafoot,
        FormInvoicee,
        FormInvoicer,
        FormItemlist,
    },
    props: {
        value: { type: Object, default: null },
    },
    data: () => ({
        form: initShopTaxinvoice(),

        shows: false,
        loading: false,

        attrs_input__console,
        attrs_switch__console,
        props__autocompleteUser,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
        title() {
            let title = "세금계산서 상세";
            // title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initShopTaxinvoice({ usage: { isActive: true } });
                } else {
                    const { _id } = this.value;
                    const { taxinvoice } = await api.console.shop.taxinvoices.get({ _id });
                    this.form = initShopTaxinvoice(taxinvoice);
                    this.$emit("input", this.form);
                }
            } finally {
                this.loading = false;
            }
        },

        validates() {
            let { name, amount } = this.form;
            if (!name) throw new Error("이름을 등록하셔야 합니다");
            if (!amount) throw new Error("금액을 등록하셔야 합니다");
        },

        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();

                const { post, put } = api.console.shop.taxinvoices;
                let { ...form } = this.form;
                delete form.type;
                form = initShopTaxinvoice(form);
                const { taxinvoice } = await (this.isCreate ? post : put)(form);
                this.form = initShopTaxinvoice(taxinvoice);

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", this.taxinvoice);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },

        emit() {
            this.form = initShopTaxinvoice(this.form);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .text-align-right-field {
    & > .v-input__control > .v-input__slot > .v-text-field__slot > input {
        text-align: right !important;
    }
}
::v-deep .amount-field {
    & > .v-input__control > .v-input__slot {
        padding-right: 0 !important;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner {
        margin: 1px 0 0;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner > .v-text-field--solo {
        width: 58px !important;

        .v-input__slot {
            padding-right: 4px;
        }
        .v-select__selections {
            justify-content: center;

            > .v-select__selection {
                margin-right: 0;
            }

            > input {
                display: none;
            }
        }

        .v-input__control,
        .v-input__slot {
            background-color: transparent;
        }
        .v-input__append-inner {
            margin-top: 0;
            padding-left: 0;
        }
    }
}

::v-deep .v-text-field__details {
    margin-bottom: 0 !important;
}

::v-deep .w-100-px {
    width: 100px;
}
</style>
