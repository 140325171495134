<template>
    <v-dialog v-model="dialog" width="800" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text>
                <v-row class="ma-n2">
                    <v-col cols="12" md="6" class="pa-2"> <v-text-field v-model="form.index" type="number" label="#" v-bind="{ ...attrs_input__console, loading }" :disabled="isCreate" /> </v-col>
                    <v-col cols="12" md="6" class="pa-2"> <v-text-field v-model="form.title" label="제목" v-bind="{ ...attrs_input__console, loading }" /> </v-col>
                    <v-col cols="12" md="6" class="pa-2"> <v-text-field v-model="form.duration[0]" label="기간(시작)" type="date" v-bind="{ ...attrs_input__console, loading }" clearable /> </v-col>
                    <v-col cols="12" md="6" class="pa-2"> <v-text-field v-model="form.duration[1]" label="기간(종료)" type="date" v-bind="{ ...attrs_input__console, loading }" clearable /> </v-col>
                    <v-col cols="12" class="pa-2 pt-0">
                        <span class="caption">썸네일</span>
                        <v-row align="center">
                            <v-col cols="auto" class="py-0">
                                <image-input v-model="form.thumb" accept="image/*" width="122" height="122" outlined style="overflow: hidden" />
                            </v-col>
                            <v-col class="py-0">
                                <v-file-input v-model="form.thumb" label="썸네일 이미지" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"/>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="pa-2"> 
                        <v-card-text>
                            <naver-smarteditor v-model="form.content" rows="10" />
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-switch v-model="form.shows" dense v-bind="attrs_switch__console">
                        <span slot="label" class="subtitle-2"> {{ form.shows ? "표기" : "미표기" }} </span>
                    </v-switch>
                </v-col>
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input__console, attrs_switch__console } from "@/assets/variables";

import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";
import ImageInput from "../../dumb/image-input.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

const initEvent = (event = {}) => ({
    ...event,

    _thumb: event?._thumb || null,

    index: event?.index || null,
    shows: event?.shows || null,

    title: event?.title || null,
    content: event?.content || null,
    duration: [...(event?.duration || [null, null])].map((item) => item?.toDate?.() || item),

    thumb: event?.thumb || null,
});


export default {
    components: {
        AutocompleteProduct,
        ImageInput,
        NaverSmarteditor
    },
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        form: initEvent(),
        dialog: false,
        loading: false,

        attrs_input__console,
        attrs_switch__console,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = `이벤트`;
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initEvent();
                } else {
                    let { _id } = this;
                    this.form = initEvent((await api.console.shop.events.get({ _id }))?.event);
                    this.$emit("input", { ...this.form });
                }

            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validates() {
            let { title, thumb, content } = this.form;
            if (!title) throw new Error("제목을 등록하셔야 합니다");
            if (!content) throw new Error("내용을 등록하셔야 합니다");
            if (!thumb) throw new Error("썸네일을 등록하셔야 합니다");
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();

                let { post, put } = api.console.shop.events;
                let { _thumb, thumb, ...form } = this.form;
                let event = (await (this.isCreate ? post : put)(form))?.event;

                if (thumb instanceof File) thumb = (await api.console.shop.events.files.post({ _event: event._id, index: "thumb" }, thumb))?.file;
                if (_thumb && _thumb != thumb?._id) await api.console.shop.events.files.delete({ _event: event._id, _id: _thumb });
                _thumb = thumb?._id;

                event = (await put({ _id: event._id, _thumb }))?.event;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", event);
                this.dialog = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        itemText(item) {
            let text = "";

            if (item?.name) text += ` ${item.name}`;
            if (!item?.shows) text += ` (미노출)`;

            return text.trim();
        },
    },
};
</script>
