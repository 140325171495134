<template>
    <v-dialog v-model="dialog" width="400" persistent :fullscreen="$vuetify.breakpoint.mobile">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }" tile class="d-flex flex-column" max-height="90vh" style="overflow: hidden">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-card-text class="py-5" style="flex: 0 1 auto; overflow-y: auto">
                <v-row class="ma-n2">
                    <v-col cols="12" md="6" class="pa-2">
                        <v-currency-field :value="money_withdrawable" label="최대출금가능금액" v-bind="attrs_input__console" disabled class="text-align-right-field" suffix="원" />
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-currency-field v-model="form.amount" label="신청금액" v-bind="attrs_input__console" min="0" :max="money_withdrawable" class="text-align-right-field" suffix="원" />
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <v-text-field v-model="form.remark" label="비고" v-bind="attrs_input__console" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { mapActions, mapState } from "vuex";

import { initUserMoneyWithdrawal } from "@/assets/variables/inits";
import { attrs_input__console, attrs_switch__console } from "@/assets/variables/attrs";

export default {
    props: {
        value: { type: Object, default: initUserMoneyWithdrawal },
    },
    data: () => ({
        form: initUserMoneyWithdrawal(),

        attrs_input__console,
        attrs_switch__console,

        dialog: false,
        loading: false,
    }),
    computed: {
        ...mapState({
            money_withdrawable: (state) => state.user?.money_withdrawable,
        }),
        isCreate() {
            return !this.value._id;
        },
        title() {
            let title = "예치금 출금 신청";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                await this.getUser();

                if (this.isCreate) {
                    this.form = initUserMoneyWithdrawal(this.value);
                } else {
                    let { _id } = this.value;
                    this.form = initUserMoneyWithdrawal((await api.console.users.moneys.withdrawals.get({ _id }))?.money);
                    this.$emit("input", this.form);
                }
            } finally {
                this.loading = false;
            }
        },
        emit() {
            this.form = initUserMoneyWithdrawal(this.form);
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { post, put } = api.console.users.moneys.withdrawals;
                let { ...form } = this.form;
                let withdrawal = initUserMoneyWithdrawal((await (this.isCreate ? post : put)(form))?.withdrawal);

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", withdrawal);
                this.dialog = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .text-align-right-field {
    & > .v-input__control > .v-input__slot > .v-text-field__slot > input {
        text-align: right !important;
    }
}
::v-deep .amount-field {
    & > .v-input__control > .v-input__slot {
        padding-right: 0 !important;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner {
        margin: 1px 0 0;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner > .v-text-field--solo {
        width: 58px !important;

        .v-input__slot {
            padding-right: 4px;
        }
        .v-select__selections {
            justify-content: center;

            > .v-select__selection {
                margin-right: 0;
            }

            > input {
                display: none;
            }
        }

        .v-input__control,
        .v-input__slot {
            background-color: transparent;
        }
        .v-input__append-inner {
            margin-top: 0;
            padding-left: 0;
        }
    }
}

::v-deep .v-text-field__details {
    margin-bottom: 0 !important;
}

::v-deep .w-100-px {
    width: 100px;
}
</style>
