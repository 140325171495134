<template>
    <v-dialog v-model="shows" width="320">
        <template #activator="{ attrs, on }">
            <v-btn small outlined color="primary" class="mr-2" v-bind="{ ...attrs, loading }" v-on="on">송장입력</v-btn>
        </template>
        <v-card>
            <v-card-title class="subtitle-1">송장입력</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12"> <v-select label="배송방법" v-model="form.method" :items="deliveryMethods" v-bind="attrs_input__console" clearable /> </v-col>
                    <v-col cols="12"> <v-select label="택배업체" v-model="form._id" :items="deliveries" item-text="name" item-value="_id" v-bind="attrs_input__console" clearable /> </v-col>
                    <v-col cols="12"> <v-text-field label="운송장번호" v-model="form.number" v-bind="attrs_input__console" clearable /> </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn text @click="shows = false">취소</v-btn>
                <v-btn text @click="put('reset')">초기화</v-btn>
                <v-btn color="primary" text @click="put">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input__console } from "@/assets/variables";

const initForm = () => ({
    _id: null, //택배업체
    method: null, //배송방법
    number: null, //운송장번호
});

const deliveryMethods = ["택배,등기,소포", "퀵서비스", "방문수령", "직접전달"];

export default {
    props: {
        loading: { type: Boolean, default: false },
        selected: { type: Array, default: () => [] },
        deliveries: { type: Array, default: () => [] },
    },
    data: () => ({
        form: initForm(),

        shows: false,
        attrs_input__console,

        deliveryMethods,
    }),
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        init() {
            this.form = initForm();
        },
        async put(type) {
            const isReset = type === "reset";
            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                if (!this.selected.length) throw new Error("송장입력 처리할 구매건을 선택해주세요");
                if (!isReset && !this.validate()) return;
                if (confirm(`해당 송장을 ${isReset ? "초기화" : "저장"}하시겠습니까?`)) {
                    if (isReset) this.form = initForm();

                    for (const { _id } of this.selected) {
                        await api.console.shop.purchases.putPurchase({ _id, delivery: this.form });
                    }
                    alert("송장입력 처리 되었습니다");
                }
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.$emit("loading", false);
                this.$emit("search");
            }
        },
        validate() {
            const { _id, method, number } = this.form;
            const isDelivery = method === "택배,등기,소포";
            if (!method) throw new Error("배송방법을 선택해주세요.");
            if (isDelivery && !_id) throw new Error("택배업체를 선택해주세요.");
            if (isDelivery && !number) throw new Error("송장번호를 입력해주세요.");

            return true;
        },
    },
};
</script>

<style></style>
