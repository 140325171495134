var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', _vm._l(_vm.bannerCodes, function (code, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('banners-banner-view', _vm._b({}, 'banners-banner-view', {
      code
    }, false))], 1);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('banners-story-view')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('banners-popup-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }