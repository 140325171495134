var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "발급시점",
      "placeholder": "없음",
      "items": _vm.eventTypes,
      "clearable": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.issue.event.type,
      callback: function ($$v) {
        _vm.$set(_vm.form.issue.event, "type", $$v);
      },
      expression: "form.issue.event.type"
    }
  }, 'v-select', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "발급시점상세",
      "items": _vm.eventCodes__userSpecialDate,
      "disabled": _vm.eventCodeDisabled
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.issue.event.code,
      callback: function ($$v) {
        _vm.$set(_vm.form.issue.event, "code", $$v);
      },
      expression: "form.issue.event.code"
    }
  }, 'v-select', _vm.attrs_input__console, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }