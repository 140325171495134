<template>
    <v-dialog v-model="shows" width="1440" persistent :fullscreen="$vuetify.breakpoint.mobile">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }" class="d-flex flex-column" :tile="$vuetify.breakpoint.mobile">
            <v-card-title>
                <v-row class="ma-n2" align="center">
                    <v-col cols="auto" class="pa-2"> 주문상세 </v-col>
                    <template v-if="hasConsoleScope && order.isManualMoneyIssueRequired">
                        <v-col cols="auto" class="pa-2">
                            <v-tooltip bottom content-class="rounded-lg pa-0 elevation-24">
                                <template #activator="{ attrs, on }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-btn small color="error" @click="issueMoney"> 예치금 지급하기 </v-btn>
                                    </span>
                                </template>
                                <v-card flat rounded="lg">
                                    <v-card-text style="line-height: 2.4" class="caption">
                                        <ul>
                                            <li>예치금이 발급되지 않은 구매확정 주문입니다.</li>
                                            <li>
                                                <v-chip small outlined class="rounded-sm">
                                                    <span> 정산 </span>
                                                </v-chip>
                                                <v-icon> mdi-chevron-right </v-icon>
                                                <v-chip small outlined class="rounded-sm">
                                                    <span> 수수료 정책 </span>
                                                </v-chip>
                                                메뉴에서 <br />
                                                해당 판매자 수수료 정책을 확인하신 뒤, <br />
                                                <v-chip small outlined class="rounded-sm">
                                                    <span>예치금 지급하기</span>
                                                </v-chip>
                                                버튼을 클릭해주세요.
                                            </li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-tooltip>
                        </v-col>
                    </template>
                    <v-spacer />
                    <v-col cols="auto" class="pa-2">
                        <v-icon @click="shows = false">mdi-close</v-icon>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider />
            <v-card-text class="pt-3" style="flex: 1 0 auto; overflow-y: auto">
                <v-row class="mx-n2">
                    <v-col cols="12" md="4" class="d-flex flex-column pa-2">
                        <order-view-baseinfo v-bind="{ order }" />
                        <order-view-receiver v-bind="{ order }" class="mt-4" />
                    </v-col>
                    <v-col cols="12" md="4" class="pa-2">
                        <order-view-payment v-bind="{ order }" />
                    </v-col>
                    <v-col cols="12" md="4" class="pa-2">
                        <v-textarea v-model="order.memo" label="메모" color="primary" auto-grow rows="9" v-bind="attrs_input__console">
                            <template #append>
                                <v-btn absolute right bottom small text color="primary" class="mr-n2 mb-n2" @click="save"> 저장하기 </v-btn>
                            </template>
                        </v-textarea>
                    </v-col>
                    <v-col cols="12" class="pa-2"> <order-view-purchases v-bind="order" /> </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";

import { initOrder } from "@/assets/variables/inits";
import { attrs_input__console, attrs_switch__console } from "@/assets/variables/attrs";

import OrderViewPayment from "./order-view-payment.vue";
import OrderViewBaseinfo from "./order-view-baseinfo.vue";
import OrderViewReceiver from "./order-view-receiver.vue";
import OrderViewPurchases from "./order-view-purchases.vue";
import { mapGetters } from "vuex";

export default {
    components: {
        OrderViewPayment,
        OrderViewBaseinfo,
        OrderViewReceiver,
        OrderViewPurchases,
    },
    props: {
        _order: { type: String, default: null },
    },
    data: () => ({
        order: initOrder(),

        shows: false,
        loading: false,

        attrs_input__console,
        attrs_switch__console,
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { order } = await api.console.shop.orders.get({ _id: this._order });
                this.order = order;
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { _id, memo } = this.order || {};
                const { order } = await api.console.shop.orders.put({ _id, memo });

                this.order = order;
                alert("저장되었습니다.");
            } finally {
                this.loading = false;
            }
        },
        async issueMoney() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { _id: _order } = this.order || {};
                await api.console.shop.orders.moneys.postIssue({ _order });
            } finally {
                this.loading = false;
                this.shows = false;
                this.$emit("search");
            }
        },
    },
};
</script>
