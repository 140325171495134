<template>
    <v-data-table v-bind="{ items, headers, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" ref="data-table" mobile-breakpoint="610">
        <template #body="props">
            <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" :sort="!loading || items.length < 2" @change="setNewIndex">
                <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
            </draggable>
        </template>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

        <template #[`item.handle`]>
            <v-btn text tile color="grey darken-1" height="48" style="cursor: move" :disabled="items.length < 2">
                <v-icon>mdi-drag</v-icon>
            </v-btn>
        </template>

        <template #[`item.name`]="{ item }">
            <v-text-field v-model="item.name" class="caption" v-bind="attrs_input__verticalTable" @input="emit" />
        </template>

        <template #[`item.code`]="{ item }">
            <v-text-field v-model="item.code" :disabled="item.disabled" class="caption" v-bind="attrs_input__verticalTable" @input="emit" />
        </template>

        <template #[`item.actions`]="{ item, index }">
            <v-btn text tile height="48" color="red" :disabled="item.disabled" @click="pull(index)"> <v-icon>mdi-minus</v-icon> </v-btn>
        </template>

        <template #footer>
            <v-divider />
            <v-row no-gutters align="center">
                <v-spacer />
                <v-divider vertical />
                <v-col cols="auto">
                    <v-btn text tile height="58" color="primary" @click="push"> <v-icon>mdi-plus</v-icon> </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import { attrs_input__verticalTable, attrs_switch__console } from "@/assets/variables/attrs";
import { initDataTableHeaders, initShopShippingMethod } from "@/assets/variables/inits";

import Draggable from "vuedraggable";

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },
        Draggable,
    },
    props: {
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        items: [],

        attrs_switch__console,
        attrs_input__verticalTable,

        loading: false,
    }),
    computed: {
        headers() {
            return initDataTableHeaders(
                [
                    {
                        text: "",
                        value: "handle",
                        width: +64,
                        align: "center",
                    },
                    {
                        text: "이름",
                        value: "name",
                        width: 160,
                    },
                    {
                        text: "코드",
                        value: "code",
                    },
                    {
                        text: "",
                        value: "actions",
                        width: +64,
                    },
                ].map((item) => ({ ...item, cellClass: "pa-0" }))
            );
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.items = [...this.value];
        },
        emit() {
            this.items = this.items.map((item, index) => ({ ...item, index: index + 1 }));
            this.$emit("input", this.items);
        },
        pull(index) {
            this.items.splice(index, 1);
            this.emit();
        },
        push() {
            this.items.push(initShopShippingMethod());
            this.emit();
        },

        updateItem(item) {
            const index = this.items.findIndex(({ tempId }) => tempId == item.tempId);
            if (0 <= index) this.items.splice(index, 1, item);
            else this.items.push(item);
            this.emit();
        },

        async setNewIndex({ moved = {} }) {
            if (this.loading) return;
            this.loading = true;

            try {
                let { oldIndex, newIndex } = moved;
                this.items.splice(newIndex, 0, ...this.items.splice(oldIndex, 1));
                this.emit();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
}
</style>