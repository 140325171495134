<template>
    <view-section-card title="기본정보">
        <v-card flat>
            <v-card-text>
                <v-row class="ma-n2">
                    <v-col cols="12" md="8" class="pa-2"> <v-text-field v-model="form.name" label="이름" v-bind="{ ...attrs_input__console }" @input="emit" /> </v-col>
                    <!-- <v-col cols="12" md="6" class="pa-2"> <v-select v-model="form.type" label="유형" :items="types" v-bind="{ ...attrs_input__console }" @input="emit" /> </v-col> -->
                    <v-col cols="12" md="4" class="pa-2">
                        <v-currency-field v-model="form.value" :decimalLength="disabled_limit ? +0 : +1" class="amount-field text-align-right-field" v-bind="{ ...attrs_input__console, ...attrs_unit }" @input="emit">
                            <template #append>
                                <v-select v-model="form.unit" :items="units" item-text="suffix" dense v-bind="attrs_input__verticalTable" @input="emit" />
                            </template>
                        </v-currency-field>
                    </v-col>
                    <!-- <v-col cols="12" md="6" class="pa-2"> <v-currency-field v-model="form.limit" label="적립금액제한" prefix="최대" suffix="원" messages="※ 값이 0일 때, 적립금액제한 미적용" class="text-align-right-field" :disabled="disabled_limit" v-bind="{ ...attrs_input__console }" @input="emit" /> </v-col> -->
                </v-row>
            </v-card-text>
        </v-card>
    </view-section-card>
</template>

<script>
import { initShopMoney__charge } from "@/assets/variables/inits";
import { SHOP_MONEY_TYPES, SHOP_MONEY_UNITS } from "@/assets/variables/constants";
import { attrs_input__console, attrs_input__verticalTable } from "@/assets/variables/attrs";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

// const types = Object.values(SHOP_MONEY_TYPES);
const units = Object.values(SHOP_MONEY_UNITS);

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initShopMoney__charge },
    },
    data: () => ({
        form: initShopMoney__charge(),

        // types,
        units,

        attrs_input__console,

        attrs_input__verticalTable,
    }),
    computed: {
        attrs_unit() {
            const attrs = { ...(SHOP_MONEY_UNITS[this.form.unit] || {}) };

            attrs.label = SHOP_MONEY_TYPES[this.form.type].text;
            attrs.label += attrs.text || "";

            delete attrs.text;
            delete attrs.value;
            delete attrs.suffix;

            return attrs;
        },
        disabled_limit() {
            return this.form.unit == SHOP_MONEY_UNITS.AMOUNT.value;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        "form.unit"(unit) {
            switch (unit) {
                case SHOP_MONEY_UNITS.AMOUNT.value: {
                    this.form.limit = 0;
                    this.emit();
                    break;
                }
            }
        },
    },
    methods: {
        sync() {
            this.form = initShopMoney__charge(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
