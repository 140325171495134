<template>
    <v-edit-dialog :return-value.sync="form.type" cancel-text="취소" save-text="변경" v-on="{ save }" large eager persistent>
        <slot />
        <template #input>
            <v-select v-model="form.type" v-bind="{ ...attrs_input__console, items }" class="mt-3" />
            <v-fade-transition>
                <v-overlay v-show="loading" absolute light color="white">
                    <v-progress-circular indeterminate size="100" width="5" color="primary" />
                </v-overlay>
            </v-fade-transition>
        </template>
    </v-edit-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input__console, USER_TYPES } from "@/assets/variables";

const items = Object.values(USER_TYPES);

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        loading: false,

        form: {},
        items,
        attrs_input__console,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = JSON.parse(JSON.stringify(this.value));
        },
        async save() {
            if (this.loading) return;
            this.loading = true;

            try {
                let { _id, type } = this.form;
                let form = { _id, type };

                const { user } = await api.console.users.putType(form);

                this.$emit("updateItem", user);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
