<template>
    <v-dialog v-model="shows" width="720" :persistent="loading">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card outlined v-bind="{ loading }">
            <v-card-title>배송그룹 설정</v-card-title>
            <v-divider />
            <form-table v-model="regions" />
            <v-divider />
            <v-card-actions class="justify-end">
                <v-btn text color="red" v-bind="{ loading }" @click="shows = false">취소하기</v-btn>
                <v-btn text color="primary" v-bind="{ loading }" @click="save">저장하기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input__console, attrs_switch__console } from "@/assets/variables/attrs";
import { initShopShippingRegion, initUser } from "@/assets/variables/inits";

import FormTable from "./form-table.vue";

export default {
    components: {
        FormTable,
    },
    props: {
        seller: { type: Object, default: initUser },
    },
    data: () => ({
        regions: [],
        _regions: [],

        attrs_input__console,
        attrs_switch__console,

        shows: false,
        loading: false,
    }),
    methods: {
        async init() {
            const { _id: _seller } = this.seller;
            if (!_seller) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                const { regions } = await api.console.shop.shippings.regions.gets({ params: { _seller } });
                this.regions = regions.map(initShopShippingRegion);
                this._regions = regions.map(({ _id }) => _id);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { _seller } = this;
                const { post, put } = api.console.shop.shippings.regions;

                this.regions = await Promise.all(this.regions.map(async (region) => (await (region._id ? put : post)({ ...region, _seller }))?.region));
                let _regions = this.regions.map(({ _id }) => _id);

                for (const _id of this._regions) {
                    if (_regions.includes(_id)) continue;
                    await api.console.shop.shippings.regions.delete({ _id });
                }

                this.init();
                this.shows = false;
                this.$emit("updated");
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "seller._id"() {
            this.init();
        },
        shows() {
            this.init();
        },
    },
};
</script>

<style>
</style>