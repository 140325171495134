var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip', _vm._g(_vm._b({
    attrs: {
      "outlined": "",
      "label": "",
      "close": "",
      "ripple": false
    },
    on: {
      "click:close": function ($event) {
        return _vm.$emit('remove');
      }
    }
  }, 'v-chip', _vm.attrs, false), _vm.on), [_c('v-card', {
    staticClass: "mr-1",
    staticStyle: {
      "overflow": "hidden",
      "border-color": "#ddd"
    },
    attrs: {
      "flat": "",
      "outlined": "",
      "width": "20",
      "height": "20",
      "rounded": "xl",
      "color": _vm.value.hexa
    }
  }, [_c('v-img', _vm._b({
    attrs: {
      "width": "100%",
      "height": "100%"
    }
  }, 'v-img', {
    src: _vm.src
  }, false))], 1), _vm._v(" " + _vm._s(_vm.value.name) + " ")], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }