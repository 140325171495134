<template>
    <v-card v-bind="$attrs">
        <v-card-text class="caption">
            <v-row class="ma-n4" style="word-break: keep-all">
                <template v-for="({ term, desc }, index) in items">
                    <v-col cols="12" class="py-3 px-4" :class="{ 'pt-0': index }"  :key="index">
                        <div class="font-weight-bold grey--text" v-if="term">
                            {{ term }}
                        </div>
                        {{ desc }}
                    </v-col>
                </template>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { initShopCoupon, SHOP_COUPON_UNITS } from "@/assets/variables";

export default {
    props: {
        coupon: { type: Object, default: initShopCoupon },
    },
    computed: {
        items() {
            let items = [{ term: undefined, desc: this.coupon.name }];

            const { unit, value = 0, limit, count } = this.coupon;
            if (unit) {
                items.push({
                    term: `할인${SHOP_COUPON_UNITS[unit]?.text}`,
                    desc: `${value.format()}${SHOP_COUPON_UNITS[unit]?.suffix}`,
                });
            }
            if (limit) {
                items.push({
                    term: "사용금액제한",
                    desc: `최대 ${limit.format()}원`,
                });
            }
            if (count) {
                items.push({
                    term: "발급수량제한",
                    desc: `${count.format()}개`,
                });
            }

            return items;
        },
    },
};
</script>

<style>
</style>