<template>
    <view-section-card title="할인사용" hideDivider v-bind="{ loading }">
        <template #actions>
            <v-switch v-model="discountEnabled" :value="true" v-bind="attrs_switch__console" @change="input" />
        </template>
        <v-expand-transition>
            <v-sheet v-show="discountEnabled">
                <v-divider />
                <v-card-text>
                    <v-row class="ma-n2">
                        <v-col cols="12" md="6" class="pa-2">
                            <v-currency-field v-model="discount.amount" label="할인금액" v-bind="{ ...attrs_input__console, messages, hideDetails: false }" class="amount-field text-align-right-field" @change="input">
                                <template #append>
                                    <v-select v-model="discount.unit" :items="unitHeaders" item-text="text" item-value="value" dense v-bind="attrs_input__verticalTable" />
                                </template>
                            </v-currency-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pa-2">
                            <v-checkbox v-model="discount.expiration.enabled" label="특정기간만할인" v-bind="attrs_switch__console" class="mt-2 ml-1" />
                        </v-col>
                        <v-expand-transition>
                            <v-col cols="12" class="pa-0" v-show="discount.expiration.enabled">
                                <v-row class="ma-0">
                                    <v-col cols="12" sm="6" class="pa-2">
                                        <v-menu ref="startDateMenu" :close-on-content-click="false" :return-value.sync="discount.expiration.startDate" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="discount.expiration.startDate" label="할인시작일자" readonly prepend-inner-icon="event" v-bind="attrs_input__console" v-on="on" />
                                            </template>
                                            <v-date-picker v-model="discount.expiration.startDate" no-title scrollable @input="$refs.startDateMenu.save(discount.expiration.startDate)"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="pa-2">
                                        <v-menu ref="endDateMenu" :close-on-content-click="false" :return-value.sync="discount.expiration.endDate" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="discount.expiration.endDate" label="할인종료일자" readonly prepend-inner-icon="event" v-bind="attrs_input__console" v-on="on" />
                                            </template>
                                            <v-date-picker v-model="discount.expiration.endDate" no-title scrollable @input="$refs.endDateMenu.save(discount.expiration.endDate)"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-expand-transition>
                    </v-row>
                </v-card-text>
            </v-sheet>
        </v-expand-transition>
    </view-section-card>
</template>

<script>
import { attrs_input__console, attrs_input__verticalTable, attrs_switch__console } from "@/assets/variables/attrs";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: null },
        loading: { type: Boolean, default: false },
    },
    data: () => {
        return {
            discountEnabled: false,

            discount: {
                amount: 0,
                unit: "won",
                expiration: {
                    enabled: false,
                    startDate: "",
                    endDate: "",
                },
            },

            unitHeaders: [
                { text: "%", value: "percent" },
                { text: "원", value: "won" },
            ],

            attrs_input__console,
            attrs_input__verticalTable,
            attrs_switch__console,
        };
    },
    computed: {
        messages() {
            let { price = 0 } = this.value;
            price = +price;

            let { unit, amount = 0 } = this.discount;
            amount = +amount;

            let discount = unit == "won" ? amount : (price * amount) / 100;

            price -= discount;

            return `할인판매가: ${price.format()} 원`;
        },
    },
    watch: {
        value() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            try {
                var { discountEnabled, discount } = this.value;

                if (discountEnabled === undefined) discountEnabled = this.discountEnabled;
                if (discount === undefined) discount = this.discount;

                if (discount.expiration.startDate) discount.expiration.startDate = discount.expiration.startDate.toDate();
                if (discount.expiration.endDate) discount.expiration.endDate = discount.expiration.endDate.toDate();
                Object.assign(this.$data, {
                    discountEnabled,
                    discount,
                });
            } catch (error) {
                console.error(error);
            }
        },

        input() {
            this.$set(this.value, "discountEnabled", this.discountEnabled);
            this.$set(this.value, "discount", this.discount);
            this.$emit("input", this.value);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .text-align-right-field {
    & > .v-input__control > .v-input__slot > .v-text-field__slot > input {
        text-align: right !important;
    }
}
::v-deep .amount-field {
    & > .v-input__control > .v-input__slot {
        padding-right: 0 !important;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner {
        margin: 1px 0 0;
    }
    & > .v-input__control > .v-input__slot > .v-input__append-inner > .v-text-field--solo {
        width: 58px !important;

        .v-input__slot {
            padding-right: 4px;
        }
        .v-select__selections {
            justify-content: center;

            > .v-select__selection {
                margin-right: 0;
            }

            > input {
                display: none;
            }
        }

        .v-input__control,
        .v-input__slot {
            background-color: transparent;
        }
        .v-input__append-inner {
            margin-top: 0;
            padding-left: 0;
        }
    }
}

::v-deep .v-text-field__details {
    margin-bottom: 0 !important;
}

::v-deep .w-100-px {
    width: 100px;
}
</style>
