<template>
    <view-section-card title="유효기간 설정">
        <template #actions> <v-switch v-model="form.expire.isActive" :disabled="disabled" v-bind="attrs_switch__console" @change="emit" /> </template>
        <v-card :disabled="!form.expire.isActive" flat>
            <v-card-text>
                <v-row class="ma-n2">
                    <v-col cols="12" md="12" class="pa-2">
                        <v-row class="ma-n2">
                            <v-col cols="12" md="6" class="pa-2">
                                <v-text-field v-model="form.expire.startsAt" label="결제기간(시작)" clearable v-bind="attrs_input__date__console" @input="emit" />
                            </v-col>
                            <v-col cols="12" md="6" class="pa-2">
                                <v-text-field v-model="form.expire.endsAt" label="결제기간(종료)" clearable v-bind="attrs_input__date__console" messages="※ 미입력 시 상시 사용" @input="emit" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </view-section-card>
</template>

<script>
import { attrs_switch__console, attrs_input__date__console, initUserPayment } from "@/assets/variables";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initUserPayment },
        disabled: { type: Boolean, default: false }
    },
    data: () => ({
        form: initUserPayment(),
        attrs_input__date__console,
        attrs_switch__console,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initUserPayment(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
