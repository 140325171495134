var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', _vm._b({}, 'list-heading', {
    title: _vm.title
  }, false)), _c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('view-section-card', {
    attrs: {
      "title": "썸네일"
    }
  }, [_c('v-card-text', [_c('v-img', {
    staticClass: "grey lighten-2",
    attrs: {
      "src": _vm.board.storyThumbSrc,
      "aspect-ratio": 1 / 1,
      "contain": ""
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "color": "transparent"
          }
        }, [_c('v-icon', [_vm._v(" mdi-image-broken-variant ")])], 1)];
      },
      proxy: true
    }], null, false, 268261350)
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "lg": "9"
    }
  }, [_c('view-section-card', {
    staticClass: "fill-height",
    attrs: {
      "title": "기본정보"
    }
  }, [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "value": (_vm.board.writer || {}).nickname,
      "label": "작성자",
      "readonly": "",
      "disabled": ""
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-datetime-field', _vm._b({
    attrs: {
      "value": _vm.board.createdAt ? _vm.board.createdAt.toDateTime() : undefined,
      "label": "작성일"
    },
    on: {
      "input": function (value) {
        return _vm.board.createdAt = value;
      }
    }
  }, 'v-datetime-field', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "카테고리",
      "items": _vm.codes
    },
    model: {
      value: _vm.board.code,
      callback: function ($$v) {
        _vm.$set(_vm.board, "code", $$v);
      },
      expression: "board.code"
    }
  }, 'v-select', _vm.attrs_input__console, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', {
    attrs: {
      "title": "내용"
    }
  }, [_c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "bSkipXssFilter": "",
      "id": "content",
      "rows": "10"
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)], 1)], 1)], 1), _vm.board._id ? _c('v-btn', _vm._b({
    staticClass: "mb-16",
    attrs: {
      "fixed": "",
      "right": "",
      "bottom": "",
      "fab": "",
      "color": "white",
      "href": `/story/${_vm.board.code}/${_vm.board._id}`,
      "target": "blank"
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('v-icon', [_vm._v("mdi-link-variant")])], 1) : _vm._e(), _c('v-btn', _vm._b({
    attrs: {
      "fixed": "",
      "right": "",
      "bottom": "",
      "fab": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('v-icon', [_vm._v("mdi-content-save")])], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }