<template>
    <v-container>
        <v-row v-for="(category, index) in categories" :key=index>
            <v-col>
                <sales-analytics-view v-bind="{ category }"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import api from "@/api";
import SalesAnalyticsView from "@/components/console/analytics/sales/sales-analytics-view.vue";
export default {
    components: {
        SalesAnalyticsView
    },
    data: () => ({
        categories: [],
        summary: {
            totalCount: 0
        }
    }),
    methods: {
        async search() {
            try{
                const { summary, categories } = await api.console.shop.categories.gets({
                    params: { depth: 1 }
                })

                this.categories = categories;
                this.summary = summary;
            } catch(error) {
                this.$handleError(error);
            }
        }
    },
    mounted() {
        this.search();
    }
}
</script>

<style>

</style>