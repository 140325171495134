<template>
    <v-btn-toggle v-model="query['period']" color="primary" dense @change="emit">
        <template v-for="item in items">
            <v-btn :value="item.value" outlined :key="item.text">{{ item.text }}</v-btn>
        </template>
    </v-btn-toggle>
</template>

<script>
import { ANALYTICS_PERIOD_FILTER } from "@/assets/variables";

const initQuery = (query = {}) => ({
    ...query,

    period: query.period || null,
});

const items = Object.values(ANALYTICS_PERIOD_FILTER);

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        query: initQuery(),
        items,
    }),
    methods: {
        sync() {
            this.query = initQuery({ ...this.value });
        },
        emit() {
            const { ...query } = this.query;

            query.page = 1;

            if (!query["period"]) delete query["period"];
            query['searchDateValue'] = JSON.stringify([null, null]);

            this.$emit("input", query);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>