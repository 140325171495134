<template>
    <list-search v-bind="{ loading, showsSearch }" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="2" class="pa-2">
                <v-select v-model="query['searchKey']" label="검색유형" :items="searchKeyItems" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="3" class="pa-2">
                <v-text-field v-model="query['searchValue']" label="검색어" clearable v-bind="attrs_input__console" @keydown.enter="emit" @click:clear="clearSearchValue"/>
            </v-col>
            <v-col cols="3" class="pa-2" v-if="hasConsoleScope">
                <autocomplete-user v-model="query['_seller']" label="입점사" clearable v-bind="props__autocompleteUser" @input="emit" />
            </v-col>
            <v-col cols="2" class="pa-2">
                <v-select v-model="query['shows']" label="전시여부" :items="showsItems" v-bind="attrs_input__console" @input="emit"/>
            </v-col>
            <v-col cols="2" class="pa-2">
                <v-select v-model="query['sells']" label="판매여부" :items="sellsItems" v-bind="attrs_input__console" @input="emit"/>
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import { attrs_input__console, DISPLAY_CODES, USER_TYPES } from "@/assets/variables";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

import ListSearch from "@/components/console/dumb/list-search.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["_seller"]: query?.["_seller"] || null,

    ["searchKey"]: query?.["searchKey"] || null,
    ["searchValue"]: query?.["searchValue"] || null,

    ["shows"]: JSON.parse(query?.["shows"] || "null"),
    ["sells"]: JSON.parse(query?.["sells"] || "null"),
});

const showsItems = [
    { text: "전체", value: null },
    { text: "전시", value: true },
    { text: "숨김 ", value: false },
];

const sellsItems = [
    { text: "전체", value: null },
    { text: "판매 ", value: true },
    { text: "품절", value: false },
];

const props__autocompleteUser = {
    ...attrs_input__console,
    params: {
        type: USER_TYPES.COMPANY.value,
    },
    itemText: ({ nickname, username }) => `${nickname} (${username})`,
};

export default {
    components: {
        ListSearch,
        AutocompleteUser
    },
    props: {
        loading: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        props__autocompleteUser,
        query: initQuery(),

        categories: [],

        showsItems,
        sellsItems,
        attrs_input__console,
        DISPLAY_CODES,
        searchKeyItems: [
            { text: "서비스명", value: "name" },
            { text: "서비스 설명", value: "description" },
        ]
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
    },
    mounted() {
        this.init().then(this.sync);
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        async init() {
            const { categories } = await api.console.shop.categories.gets({ params: { depth: 1 } });
            this.categories = categories;
        },
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            delete query.page;

            if (!query?.["_seller"]) delete query["_seller"];
            if (!query?.["searchKey"]) delete query["searchKey"];
            if (!query?.["searchValue"]) delete query["searchValue"];
            if (query?.["shows"] == null) delete query["shows"];
            if (query?.["sells"] == null) delete query["sells"];

            this.$router.push({ query });
        },
        clearSearchValue(){
            this.query['searchKey'] = null;
            this.query['searchValue'] = null;
            this.emit();
        }
    },
};
</script>

<style></style>
