<template>
  <v-dialog v-model="shows" max-width="800" persistent>
    <template v-slot:activator="{ attrs, on }">
        <slot name="activator" v-bind="{ attrs, on }" />
    </template>
    <v-card v-bind="loading" tile class="d-flex flex-column" max-height="90vh" style="overflow: hidden">
        <v-card-title>
            <template>운영시간 설정</template>
            <v-spacer />
            <v-btn absolute right text icon @click="shows = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0" style="flex: 0 1 auto; overflow-y: auto">
            <v-row class="mx-0 form-table-row"> 
                <v-col cols="3" md="2" class="subtitle-2 font-weight-bold">영업시간</v-col>
                <v-col cols="auto" class="pa-0" align-self="stretch"><v-divider vertical/></v-col>
                <v-col  class="py-0" align-self="center">
                    <v-row>
                        <v-col cols="2" lg="1" class="txt--xs text-center text-lg-right">시작</v-col>
                        <v-col cols="10" lg="auto">
                            <v-time-field v-model="schedule.open.startsAt"/>
                        </v-col>
                        <v-col cols="2" lg="1" class="txt--xs text-center text-lg-right">종료</v-col>
                        <v-col cols="10" lg="auto">
                            <v-time-field v-model="schedule.open.endsAt"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mx-0 form-table-row">
                <v-col cols="3" md="2" class="subtitle-2 font-weight-bold">예약시간
                    <v-btn small fab color="transparent primary--text" @click="add('reservationTime')">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="auto" class="pa-0" align-self="stretch"><v-divider vertical/></v-col>
                <v-col  class="py-0" align-self="center">
                    <v-row v-for="(item, index) in schedule.reservationTime" :key="index">
                        <v-col cols="2" lg="1" class="txt--xs text-center text-lg-right">시작</v-col>
                        <v-col cols="10" lg="auto">
                            <v-time-field v-model="item.startsAt"/>
                        </v-col>
                        <v-col cols="2" lg="1" class="txt--xs text-center text-lg-right">종료</v-col>
                        <v-col cols="10" lg="auto">
                            <v-time-field v-model="item.endsAt"/>
                        </v-col>
                        <v-col class="d-flex justify-end">
                            <v-btn v-if="schedule.reservationTime.length > 1" small fab color="transparent primary--text" @click="remove({index, type: 'reservationTime'})">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mx-0 form-table-row">
                <v-col cols="3" md="2" class="subtitle-2 font-weight-bold">휴게시간
                    <v-btn small fab color="transparent primary--text" @click="add('breaks')">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="auto" class="pa-0" align-self="stretch"><v-divider vertical/></v-col>
                <v-col  class="py-0" align-self="center">
                    <v-row v-for="(item, index) in schedule.breaks" :key="index">
                        <v-col cols="2" lg="1" class="txt--xs text-center text-lg-right">시작</v-col>
                        <v-col cols="10" lg="auto">
                            <v-time-field v-model="item.startsAt"/>
                        </v-col>
                        <v-col cols="2" lg="1" class="txt--xs text-center text-lg-right">종료</v-col>
                        <v-col cols="10" lg="auto">
                            <v-time-field v-model="item.endsAt"/>
                        </v-col>
                        <v-col class="d-flex justify-end">
                            <v-btn small fab color="transparent primary--text" @click="remove({index, type: 'breaks'})">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mx-0 form-table-row">
                <v-col cols="3" md="2" class="subtitle-2 font-weight-bold">정기휴무일</v-col>
                <v-col cols="auto" class="pa-0" align-self="stretch"><v-divider vertical/></v-col>
                <v-col  class="py-0" align-self="center">
                    <service-schedule-days v-model="schedule.holidays"/>
                </v-col>
            </v-row>
            <v-row class="mx-0 form-table-row">
                <v-col cols="3" md="2" class="subtitle-2 font-weight-bold">휴무일 등록</v-col>
                <v-col cols="auto" class="pa-0" align-self="stretch"><v-divider vertical/></v-col>
                <v-col  class="py-0" align-self="center">
                    <v-chip-group column>
                        <v-chip v-for="(date, index) in schedule.excludedDates" :key="index" close @click:close="schedule.excludedDates.splice(index,1)">{{ date }}</v-chip>
                        <v-btn text icon class="form-holidays-picker"> <v-icon> mdi-plus-circle </v-icon> </v-btn>
                    </v-chip-group>
                    <v-dialog width="300" activator=".form-holidays-picker">
                        <v-date-picker v-model="schedule.excludedDates" multiple class="v-sheet--outlined" />
                    </v-dialog>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
            <v-spacer />
            <v-btn large color="primary" v-bind="{ loading }" @click="save"> 저장하기 </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api";
import VTimeField from "@/components/plugins/vuetify/v-time-field.vue";
import ServiceScheduleDays from "./service-schedule-days.vue"

export const initScheduleTime = (time = {})=> ({
    startsAt: time.startsAt || "09:00",
    endsAt: time.endsAt || "10:00",
})

export const initSchedule = (schedule = {})=> ({
    ...schedule,

    open: schedule.open || initScheduleTime(),
    breaks: schedule.breaks || [],
    reservationTime: schedule.reservationTime || [ initScheduleTime() ],
    holidays: schedule.holidays || [],
    excludedDates: schedule.excludedDates || [],
});

export default {
    components:{
        VTimeField,
        ServiceScheduleDays
    },
    data: ()=>({
        shows: false,
        loading: false,

        schedule: initSchedule()
    }),
    methods:{
        async search(){
            try{
                let schedule = (await api.console.shop.schedules.gets({}))?.schedules?.[0] || {};
                this.schedule = initSchedule(schedule);

            } catch(error){
                this.$handleError(error);
            }
        },
        async save(){
            if(this.loading) return
            else this.loading = true;
            try{
                let { put, post } = api.console.shop.schedules;
                let { schedule } = await (this.schedule._id ? put(this.schedule) : post(this.schedule));

                this.schedule = initSchedule(schedule);
                this.loading = false;
                this.shows = false;
            } catch(error){
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        add(type) {
            let previousTime = initScheduleTime();

            // 추가할 때마다, 이전 시간 + 1시간
            if(this.schedule[type].length){
                previousTime = this.schedule[type][this.schedule[type].length - 1];
                previousTime = {
                    startsAt: this.afterAnHour(previousTime.startsAt),
                    endsAt: this.afterAnHour(previousTime.endsAt)
                };
            }

            this.schedule[type].push(initScheduleTime(previousTime));
        },
        remove({ index, type}){
            this.schedule[type].splice(index, 1);
        },
        afterAnHour(prev){
            let [hour, minute] = prev.split(":");
            hour = (+hour + 1) % 24;
            return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
        },
    },
    mounted(){
        this.search();
    },
    watch: {
        shows() {
            this.search();
        }
    }
}
</script>

<style lang="scss" scoped>
.form-table-row {
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>