<template>
    <v-select v-model="form.areas__available" placeholder="전체" multiple class="caption" v-bind="{ ...attrs_input__verticalTable, items }" @input="emit" />
</template>

<script>
import sidoGugun from "@/assets/data/sido-gugun.json";
import { attrs_input__verticalTable } from "@/assets/variables/attrs";
import { initShopShippingMethod } from "@/assets/variables/inits";

const sidos = sidoGugun.map(({ name }) => name);

export default {
    props: {
        value: { type: Object, default: initShopShippingMethod },
    },
    data: () => ({
        form: initShopShippingMethod(),

        items: sidos,
        attrs_input__verticalTable,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopShippingMethod(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style>
</style>