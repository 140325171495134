<template>
    <v-card outlined>
        <v-card-text class="white pa-0">
            <v-select v-model="query['sort']" v-bind="{ ...attrs_input__console, items }" solo flat :outlined="false" class="caption" @change="emit" />
        </v-card-text>
    </v-card>
</template>

<script>
import { attrs_input__console } from "@/assets/variables";

const initQuery = (query = {}) => ({
    ...query,

    sort: JSON.parse(query.sort || "-1"),
});

const items = [
    { text: "오름차순", value: 1 },
    { text: "내림차순", value: -1 },
];

export default {
    data: () => ({
        query: initQuery(),

        items,
        attrs_input__console,
    }),
    methods: {
        sync() {
            this.query = initQuery({ ...this.$route.query });
        },
        emit() {
            const { ...query } = this.query;

            delete query["page"];

            if (!query["mode"]) delete query["mode"];

            this.$router.push({ query });
        },
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>