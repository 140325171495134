<template>
    <v-card>
        <v-img :src="value.thumbSrc" />
        <v-card-title class="font-weight-bold">{{ value?.subject || value?.board?.subject }}</v-card-title>
        <v-card-text> {{ value?.computedContent }} </v-card-text>
        <v-divider />
        <template v-if="0 < value?.products.length">
            <v-list>
                <template v-for="product in value?.products">
                    <v-list-item :key="product._id">
                        <v-list-item-avatar>
                            <v-img :src="product?.thumb?.src">
                                <template #placeholder>
                                    <v-icon> mdi-image-broken </v-icon>
                                </template>
                            </v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-bold"> {{ product?.name }} </v-list-item-title>
                            <span class="font-weight-bold red--text text--darken-4"> {{ product?.salePrice?.format?.() }}원 </span>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </template>
    </v-card>
</template>

<script>
import { initStoryBoardDisplay } from "@/store/story";

export default {
    props: {
        value: { type: Object, default: initStoryBoardDisplay },
    },
};
</script>

<style>
</style>