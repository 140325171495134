<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%">
            <list-heading title="서비스 목록" v-model="showsSearch" showsFilterButton />

            <service-list-search v-bind="{ loading, showsSearch }" />

            <v-row class="mt-n3">
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white" class="caption" height="46" v-bind="{ loading }">
                            <v-layout align-baseline>
                                <span> 검색결과: </span>
                                <b class="mx-1"> {{ summary.totalCount?.format?.() }} </b> 건
                            </v-layout>
                        </v-btn>
                    </v-card>
                </v-col>
                <v-col cols="auto">
                    <service-list-types />
                </v-col>
            </v-row>

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.sells`]="{ item }">
                    <v-switch v-model="item.sells" v-bind="attrs_switch__console" @change="(sells) => update({ _id: item._id, sells })" />
                </template>
                <template #[`item.shows`]="{ item }">
                    <v-switch v-model="item.shows" v-bind="attrs_switch__console" @change="(shows) => update({ _id: item._id, shows })" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn small text icon tile :to="`${$route.path}/${item._id}`">
                        <v-icon small title="수정">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn small text icon tile :to="`${$route.path}/${item._id}?mode=copy`">
                        <v-icon small title="복사">mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small title="삭제">mdi-delete</v-icon>
                    </v-btn>
                </template>

                <template #footer>
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>

            <service-schedule-dialog>
                <template #activator="{ attrs, on }">
                    <v-btn fixed right bottom fab color="white" elevation="1" class="mb-16" v-bind="{ loading, attrs }" v-on="on">
                        <v-icon>mdi-calendar-edit</v-icon>
                    </v-btn>
                </template>
            </service-schedule-dialog>
            <v-btn bottom color="accent" fab fixed right :to="`${$route.path}/create`">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapActions } from "vuex";
import { attrs_switch__console, COMPANY_CATEGORY_TYPES, DISPLAY_CODES } from "@/assets/variables";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ItemProduct from "@/components/console/dumb/item-product.vue";
import ServiceListTypes from "@/components/console/shop/services/list/service-list-types.vue";
import ServiceListSearch from "@/components/console/shop/services/list/service-list-search.vue";
import ShopProductGroups from "@/components/console/shop/products/shop-product-groups.vue";
import ServiceScheduleDialog from "@/components/console/shop/services/service-schedule-dialog.vue";
const headers = [
    { width: +85, text: "진열순서", value: "sequence", formatter: (value) => value?.format?.() || value || 0, align: "end" },
    {
        text: "입점사",
        value: "user",
        formatter: ({ nickname, username } = {}) => `${nickname} (${username})`,
        width: 140,
        test: (scope) => scope.includes("console"),
    },
    { width: 160, text: "서비스 유형", value: "type", formatter: (value) => COMPANY_CATEGORY_TYPES[value].text },
    { width: 240, text: "서비스명", value: "name" },
    { text: "서비스 설명", value: "product", value: "name" },
    { width: 160, text: "판매가", value: "price", formatter: (value) => (value?.format?.() || value || 0) + "원" },
    { width: +90, text: "판매상태", value: "sells" },
    { width: +90, text: "전시상태", value: "shows" },
    { width: 120, text: "Actions", value: "actions" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));
export default {
    components: {
        ListHeading,
        ItemProduct,
        ServiceListTypes,
        ServiceListSearch,
        ShopProductGroups,
        ServiceScheduleDialog,
    },
    data: () => ({
        services: [],

        limit: 20,
        summary: { totalCount: 0 },

        attrs_switch__console,
        DISPLAY_CODES,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        headers() {
            return headers.filter((header) => (header?.test ? header.test(this.$store.state.scope) : true));
        },
        items() {
            return this.services.map((item, index) => ({ ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            delete query.page;
            // query._user = this.$store.state.payload._user;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        ...mapActions(["getSetting__console"]),

        async init() {
            this.services = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.getSetting__console();
            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, services } = await api.console.shop.services.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.services = services;
            } finally {
                this.loading = false;
            }
        },

        async update(service) {
            if (this.loading) return;
            else this.loading = true;
            try {
                service = (await api.console.shop.services.put(service))?.service;
                this.updateItem(service);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(service) {
            const index = this.services.findIndex(({ _id }) => _id == service._id);
            if (0 <= index) this.services.splice(index, 1, service);
            else {
                this.services = [service, ...this.services];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async remove(service) {
            try {
                if (confirm("해당 상품을 삭제하시겠습니까?")) {
                    await api.console.shop.services.delete(service);
                    await this.search();

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
