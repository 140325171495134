<template>
    <v-card outlined flat v-bind="{ loading }">
        <v-card-title class="subtitle-1">카테고리 {{ isCreate ? "생성하기" : "수정하기" }}</v-card-title>
        <v-card-text>
            <v-row class="ma-n2">
                <v-col cols="12" class="pa-2">
                    <story-category-select v-if="categories" v-model="form._parent" v-bind="{ ...attrs_input__console, categories, _disableds: [form._id] }" dense hideDetails />
                </v-col>
                <v-col cols="12" class="pa-2"> <v-text-field v-model="form.name" label="카테고리 이름" v-bind="attrs_input__console" /> </v-col>
                <v-col cols="12" class="pa-2"> <v-text-field v-model="form.code" label="카테고리 코드" v-bind="attrs_input__console" /> </v-col>
                <v-col cols="12" class="pa-2"> <v-select v-model="form.mainType" label="카테고리 메인유형" :items="mainTypes" v-bind="attrs_input__console" /> </v-col>
                <template v-if="hasItemType">
                    <v-col cols="12" class="pa-2"> <v-select v-model="form.itemType" label="상위 카테고리 메인에서의 목록유형" :items="itemTypes" v-bind="attrs_input__console" /> </v-col>
                </template>
                <v-col cols="12" class="pa-2">
                    <view-field-card label="쇼케이스 기능 가부">
                        <v-switch v-model="form.showcase" v-bind="attrs_switch__console" class="my-n1">
                            <template #label>
                                <span class="caption">
                                    <template v-if="form.showcase">쇼케이스 기능 가능</template>
                                    <template v-else>쇼케이스 기능 불가</template>
                                </span>
                            </template>
                        </v-switch>
                    </view-field-card>
                </v-col>
                <v-col cols="12" class="pa-2">
                    <v-select v-model="form.scope__main" label="목록권한" :items="scopes" multiple persistent-placeholder placeholder="전체" v-bind="attrs_input__console" />
                </v-col>
                <v-col cols="12" class="pa-2">
                    <v-select v-model="form.scope__view" label="열람권한" :items="scopes" multiple persistent-placeholder placeholder="전체" v-bind="attrs_input__console" />
                </v-col>
                <v-col cols="12" class="pa-2">
                    <v-select v-model="form.scope__form" label="작성권한" :items="scopes" multiple persistent-placeholder placeholder="전체" v-bind="attrs_input__console" />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-fade-transition>
                <v-btn v-show="!isCreate" :disabled="!form._id || form.root ? true : false" color="grey darken-1" class="white--text" @click="remove">삭제</v-btn>
            </v-fade-transition>
            <v-spacer />
            <v-btn color="primary" @click="save">저장</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import { attrs_input__console, attrs_switch__console } from "@/assets/variables";
import { initStoryCategory, STORY_MAIN_TYPES } from "@/store/story";

import StoryCategorySelect from "./story-category-select.vue";

import ImageInput from "@/components/console/dumb/image-input.vue";
import ViewFieldCard from "@/components/console/dumb/view-field-card.vue";

export const scopes = [
    { text: "관리자", value: "console" },
    { text: "기업회원", value: "company" },
    { text: "소식정보 작성회원", value: "article_user" },
];

export default {
    components: {
        StoryCategorySelect,

        ImageInput,
        ViewFieldCard,
    },
    props: {
        value: { type: Object }, // category
        categories: { type: Array, default: null },

        loading: { type: Boolean, default: false },
    },
    data: (v) => ({
        form: initStoryCategory(),

        scopes,
        attrs_input__console,
        attrs_switch__console,
    }),

    computed: {
        ...mapGetters("story", ["itemTypes", "mainTypes", "getCategoryWithId"]),
        _category() {
            return this.form._id;
        },
        isCreate() {
            return !this._category;
        },
        parentCategory() {
            return this.getCategoryWithId(this.form?._parent);
        },
        hasItemType() {
            return this.parentCategory?.mainType == STORY_MAIN_TYPES.ARTICLE.value;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initStoryCategory(this.value);
        },
        async save() {
            if (this.loading) return;
            else this.$emit("loading", true);

            const { post, put } = api.console.story.categories;
            try {
                let { _visual, visual, ...form } = this.form;

                delete form._brands;
                delete form.brands;
                delete form.children;

                delete form.index;
                delete form.createdAt;

                form = (await (this.isCreate ? post : put)(form))?.category;

                this.$emit("loading", false);
                this.$emit("search");
                alert("저장되었습니다.");
            } finally {
                if (this.loading) this.$emit("loading", false);
            }
        },
        async remove() {
            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                await api.console.story.categories.delete(this.form);
                this.$emit("loading", false);
                this.$emit("search");
                alert("삭제되었습니다.");
            } finally {
                if (this.loading) this.$emit("loading", false);
            }
        },
    },
};
</script>

<style></style>
