<template>
    <v-btn small outlined color="primary" class="mr-2" v-bind="{ loading }" @click="setOrderStatusPayment">발송처리</v-btn>
</template>

<script>
import api from "@/api";
import { PURCHASE_ORDER_STATUSES, PURCHASE_PURCHASE_STATUSES } from "@/assets/variables";

export default {
    props: {
        loading: { type: Boolean, default: false },
        selected: { type: Array, default: () => [] },
    },
    methods: {
        async setOrderStatusPayment() {
            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                if (!this.selected.length) throw new Error("발송 처리할 구매건을 선택해주세요");

                let emptyMethodSeleced = [];

                this.selected.forEach((item) => {
                    if(!item.delivery.method) emptyMethodSeleced.push(item);
                })

                if(emptyMethodSeleced.length) {
                    let message = "송장을 입력해주세요";
                    emptyMethodSeleced.forEach((item) => message += `\n상품주문번호: ${item?.purchaseNo || ""}`);
                    alert(message);
                    return;
                }

                for (const { _id } of this.selected) {
                    await api.console.shop.purchases.putPurchase({ _id, orderStatus: PURCHASE_ORDER_STATUSES.SHIPPING_CONFIRM.value, purchaseStatus: PURCHASE_PURCHASE_STATUSES.PURCHASE_AWAITING.value, shippedAt: Date.now() });
                }

                alert("발송 처리 되었습니다");
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.$emit("loading", false);
                this.$emit("search");
            }
        },
    },
};
</script>

<style></style>
