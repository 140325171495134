<template>
    <v-currency-field v-model="form.value" v-bind="props__value" @input="emit">
        <template #append>
            <v-select v-model="form.unit" dense v-bind="props__unit" @input="emit" />
        </template>
    </v-currency-field>
</template>

<script>
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

import { attrs_input__console, attrs_input__verticalTable } from "@/assets/variables/attrs";
import { initShopCoupon } from "@/assets/variables/inits";
import { SHOP_COUPON_UNITS } from "@/assets/variables/constants";

export default {
    components: {
        AutocompleteUser,
    },
    props: {
        value: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        form: initShopCoupon(),
    }),
    computed: {
        props__unit() {
            return {
                ...attrs_input__verticalTable,

                items: Object.values(SHOP_COUPON_UNITS),
                itemText: "suffix",

                dense: true,
            };
        },
        props__value() {
            const attrs = {
                ...attrs_input__console,
                ...(SHOP_COUPON_UNITS[this.form.unit] || {}),
                class: "amount-field text-align-right-field",
            };

            attrs.label = "할인" + (attrs.text || "");

            delete attrs.text;
            delete attrs.value;
            delete attrs.suffix;

            return attrs;
        },
    },
    methods: {
        sync() {
            this.form = initShopCoupon(this.value);
        },
        emit() {
            if (this.form.unit == SHOP_COUPON_UNITS.AMOUNT.value) {
                this.form.limit = 0;
            }
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>