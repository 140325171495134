<template>
    <v-layout justify-center>
        <v-responsive width="100%">
            <list-heading v-bind="{ title }" v-model="showsSearch" showsFilterButton />
            <comment-list-search v-bind="{ showsSearch, loading, boardCode, boardCategory }" />

            <template v-if="codes.length">
                <v-row class="mt-n3">
                    <v-col cols="auto">
                        <board-list-codes v-bind="{ codes }" />
                    </v-col>
                </v-row>
            </template>

            <v-data-table v-bind="{ items, headers }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>

                <template #[`item.isDeleted`]="{ item }">
                    <v-switch v-model="item.isDeleted" v-bind="attrs_switch__console" @change="(isDeleted) => update({ _id: item._id, isDeleted })" />
                </template>

                <template #[`item.board`]="{ value }">
                    <v-row>
                        <v-col class="py-0 text-truncate" v-if="value">
                            {{ value?.subject }}
                        </v-col>
                        <v-col class="py-0 text--secondary" v-else> 삭제된 게시글입니다 </v-col>
                        <v-col cols="auto" class="px-1 py-0">
                            <v-btn x-small icon text :to="{ path: `/console/boards/${value?._id}`, query: { code: value.code, category: value.category } }">
                                <v-icon>mdi-link</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto" class="pl-1 py-0">
                            <v-btn x-small icon text :to="{ query: { ...$route.query, _board: value?._id, page: null } }">
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>

                <template #[`item.writer`]="{ value }">
                    <v-row>
                        <v-col class="py-0" v-if="value">
                            {{ value?.nickname || "-" }}
                            ({{ value?.username }})
                        </v-col>
                        <v-col class="py-0 text--secondary" v-else> 삭제된 회원입니다 </v-col>
                        <v-col cols="auto" class="px-1 py-0">
                            <v-btn x-small icon text :to="`/console/users/${value?._id}`">
                                <v-icon>mdi-link</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto" class="pl-1 py-0">
                            <v-btn x-small icon text :to="{ query: { ...$route.query, 'writer._id': value?._id, page: null } }">
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />

            <v-btn bottom color="accent" fab fixed right @click="create()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapGetters, mapState } from "vuex";
import { attrs_switch__console, initDataTableHeaders } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import CommentListSearch from "@/components/console/boards/comments/comment-list-search.vue";
import BoardListCodes from "@/components/console/boards/board-list-codes.vue";

export default {
    components: {
        ListHeading,
        CommentListSearch,
        BoardListCodes,
    },
    data: () => ({
        comments: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,

        attrs_switch__console,
    }),
    computed: {
        ...mapState({
            codes: (state) =>
                state.story.categories.map((item) => ({
                    ...item,

                    text: item.name,
                    value: item.code,
                })),
        }),
        ...mapGetters("story", ["getCategory"]),
        boardCode() {
            return this.$route.query.boardCode;
        },
        boardCategory() {
            return this.$route.query.boardCategory;
        },
        title() {
            const title = "댓글 관리";
            switch (this.boardCode) {
                default: {
                    return title;
                }
            }
        },
        headers() {
            return initDataTableHeaders([
                { text: "#", value: "index", align: "right", width: 40 },
                { text: "게시글", value: "board", width: 160, withTitle: true, cellClass: "caption text-truncate max-width-0" },
                { text: "댓글", value: "content", cellClass: "caption text-truncate max-width-0" },
                { width: 240, text: "작성자", value: "writer" },
                { width: 100, text: "블라인드", value: "isDeleted" },
                { width: 100, text: "작성일", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
                { width: 100, text: "수정일", value: "updatedAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
            ]);
        },
        items() {
            return this.comments.map((item, index) => ({ ...item, index: this.summary.totalCount - index - (this.page - 1) * this.limit }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            if (query.code) {
                query.boardCode = this.getCategory(query.code)?.codes || query.code;
                delete query.code;
            }

            if (query.searchDateValue) query.searchDateKey = "createdAt";

            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.init().then(this.search);
        },
    },
    methods: {
        async init() {
            this.comments = [];
            this.summary = { totalCount: 0 };
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                const { summary, comments } = await api.console.boards.comments.gets({ headers: { skip, limit }, params });

                this.comments = comments;
                this.summary = summary;
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { comment } = await api.console.boards.comments.put(item);
                this.loading = false;
                this.updateItem(comment);
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        updateItem(item) {
            const index = this.comments.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.comments.splice(index, 1, item);
            else {
                this.comments = [item, ...this.comments];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
