var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    class: _vm.attachClass,
    staticStyle: {
      "position": "relative"
    }
  }, [_c('v-menu', {
    ref: "menu",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.time,
      "transition": "scale-transition",
      "min-width": "290px",
      "max-width": "290px",
      "offset-y": "",
      "attach": _vm.attach ? `.${_vm.attachClass}` : false
    },
    on: {
      "update:returnValue": function ($event) {
        _vm.time = $event;
      },
      "update:return-value": function ($event) {
        _vm.time = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "pt-0",
          attrs: {
            "value": _vm.time,
            "append-icon": "mdi-clock-outline",
            "readonly": ""
          },
          on: {
            "click:append": on.click
          }
        }, 'v-text-field', _vm.$attrs, false), on))];
      }
    }])
  }, [_c('v-time-picker', {
    attrs: {
      "full-width": ""
    },
    on: {
      "click:minute": function ($event) {
        return _vm.$refs.menu.save(_vm.time);
      }
    },
    model: {
      value: _vm.time,
      callback: function ($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }