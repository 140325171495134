<template>
    <v-dialog v-model="shows" max-width="480" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-0">
                <v-row class="mx-n2">
                    <v-col cols="12" sm="6" class="pa-2">
                        <v-text-field v-model="form.name" label="명칭" v-bind="attrs_input__console" />
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                        <v-text-field v-model="form.code" label="코드" v-bind="attrs_input__console" />
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <v-text-field v-model="form.href" label="URL" v-bind="attrs_input__console" />
                    </v-col>
                    <v-col cols="6" class="pa-2">
                        <v-switch v-model="form.display.enabled" label="노출" dense color="secondary" v-bind="attrs_switch__console" />
                    </v-col>
                    <v-col cols="6" class="pa-2">
                        <v-switch v-model="form.display.hasPeriod" label="기간" dense color="secondary" v-bind="attrs_switch__console" />
                    </v-col>
                </v-row>
                <v-expand-transition v-model="form.display.hasPeriod">
                    <v-row v-show="form.display.hasPeriod" class="mx-n2">
                        <v-col cols="12" class="pa-2">
                            <v-datetime-field v-model="form.display.startedAt" label="시작일" v-bind="attrs_input__console" padding="0" />
                        </v-col>
                        <v-col cols="12" class="pa-2">
                            <v-datetime-field v-model="form.display.endedAt" label="종료일" v-bind="attrs_input__console" padding="0" />
                        </v-col>
                    </v-row>
                </v-expand-transition>
                <v-row class="mx-n2">
                    <v-col cols="12" sm="6" class="pa-2">
                        <span class="caption">이미지(PC)</span>
                        <image-input v-model="form.imagePc" outlined flat width="100%" :aspect-ratio="1 / 1" />
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                        <span class="caption">이미지(모바일)</span>
                        <image-input v-model="form.imageMb" outlined flat width="100%" :aspect-ratio="1 / 1" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = false">취소</v-btn>
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input__console, attrs_switch__console, initBandBanner } from "@/assets/variables";

import ImageInput from "@/components/console/dumb/image-input.vue";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";

export default {
    components: {
        ImageInput,
        VDatetimeField,
    },
    props: {
        value: { type: Object, default: initBandBanner },
    },
    data: () => ({
        form: initBandBanner(),

        attrs_input__console,
        attrs_switch__console,

        shows: false,
        loading: false,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
        title() {
            let title = "띠배너";
            if (this.isCreate) title += " 생성";
            else title += " 수정";
            return title;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.isCreate) this.form = initBandBanner(this.value);
            else {
                const { bandBanner } = await api.console.bandBanners.get({ _id: this.value?._id });
                this.form = initBandBanner(bandBanner);
            }
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { isCreate } = this;
                let { _imagePc, _imageMb, imagePc, imageMb, ...form } = this.form;

                // Document POST/PUT
                const { post, put } = api.console.bandBanners;
                let { bandBanner } = await (this.isCreate ? post : put)(form);
                const { _id: _bandBanner } = bandBanner;

                // Resource POST/DELETE
                if (imagePc instanceof File) {
                    imagePc = (await api.console.files.post({ _bandBanner, index: "pc" }, imagePc))?.file;
                }
                if (imageMb instanceof File) {
                    imageMb = (await api.console.files.post({ _bandBanner, index: "mb" }, imageMb))?.file;
                }

                if (_imagePc && _imagePc != imagePc?._id) {
                    await api.console.files.delete({ _id: _imagePc });
                }
                if (_imageMb && _imageMb != imageMb?._id) {
                    await api.console.files.delete({ _id: _imageMb });
                }

                _imagePc = imagePc?._id ?? null;
                _imageMb = imageMb?._id ?? null;

                bandBanner = (await put({ _id: _bandBanner, _imagePc, _imageMb }))?.bandBanner;

                this.$emit("input", bandBanner);
                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
            } finally {
                this.loading = false;
                this.shows = false;
            }
        },
    },
};
</script>

<style></style>
