<template>
    <v-row>
        <v-col cols="12">
            <v-row>
                <v-col v-for="(code, index) in bannerCodes" :key=index cols="12">
                    <banners-banner-view v-bind="{ code }"/>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <banners-story-view />
        </v-col>
        <v-col cols="12">
            <banners-popup-view />
        </v-col>
    </v-row>
</template>

<script>
import { BANNERS } from "@/assets/variables";
import BannersBannerView from "@/components/console/analytics/banners/banners-banner-view.vue";
import BannersStoryView from "@/components/console/analytics/banners/banners-story-view.vue";
import BannersPopupView from '@/components/console/analytics/banners/banners-popup-view.vue';

const bannerCodes = Object.keys(BANNERS);

export default {
    components: {
        BannersBannerView,
        BannersStoryView,
        BannersPopupView
    },
    data: () => ({
        bannerCodes,
    }),
};
</script>

<style>
</style>