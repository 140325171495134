<template>
    <v-btn-toggle v-model="value" color="primary">
        <v-btn :value="null" color="white">전체</v-btn>
        <v-btn v-for="{ text, value } in types" v-bind="{ value }" :key="value" color="white"> {{ text }} </v-btn>
    </v-btn-toggle>
</template>

<script>
import { ALARM_TYPES } from "@/assets/variables";

const types = Object.values(ALARM_TYPES);
export default {
    data: () => ({
        value: null,
        types,
    }),
    computed: {
        type() {
            return this.$route.query.type || null;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        type() {
            if (this.value !== this.type) this.init();
        },
        value() {
            if (this.value !== this.type) {
                this.$router.push({ query: { ...this.$route.query, type: this.value || undefined } });
            }
        },
    },
    methods: {
        async init() {
            this.value = this.type;
        },
    },
};
</script>

<style></style>
