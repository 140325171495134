<template>
    <view-section-card title="등급조건" :hideDivider="!form.isActive">
        <template #actions>
            <v-switch v-model="form.isActive" v-bind="attrs_switch__console" @input="emit" />
        </template>
        <v-expand-transition>
            <v-sheet v-show="form.isActive">
                <v-card-text>
                    <v-row class="ma-n2">
                        <v-col cols="12" sm="" class="pa-2">
                            <v-currency-field v-model="form.value" suffix="원 이상" v-bind="{ ...attrs_input__console, label }" @input="emit" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-sheet>
        </v-expand-transition>
    </view-section-card>
</template>

<script>
import { attrs_input__console, attrs_switch__console, initUserLevelCriterion } from "@/assets/variables";
import { USER_LEVEL_CRITERIA_TYPES } from "@/assets/variables/constants";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initUserLevelCriterion },
    },
    data: () => ({
        form: initUserLevelCriterion(),

        attrs_input__console,
        attrs_switch__console,
    }),
    computed: {
        label() {
            return USER_LEVEL_CRITERIA_TYPES[this.form.type]?.text;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initUserLevelCriterion(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style>
</style>