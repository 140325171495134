<template>
    <list-search v-bind="{ loading, showsSearch }" @search="emit">
        <v-row align="center">
            <v-col cols="12" sm="6" lg="3">
                <v-text-field v-model="query['searchDateValue'][0]" label="작성일(시작)" clearable v-bind="attrs_input__date__console" @input="emit" />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-text-field v-model="query['searchDateValue'][1]" label="작성일(종료)" clearable v-bind="attrs_input__date__console" @input="emit" />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <autocomplete-company label="기업회원" v-model="query['_user']" placeholder="전체" clearable v-bind="{ ...attrs_input__console, ...props__autocompleteUser }" @input="emit" />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-text-field v-model="query['searchValue']" label="검색어" clearable v-bind="attrs_input__console" @keydown.enter="emit" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { USER_TYPES } from "@/assets/variables/constants";
import { attrs_input__console, attrs_input__date__console } from "@/assets/variables/attrs";

import ListSearch from "@/components/console/dumb/list-search.vue";
// import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteCompany from "@/components/dumb/autocomplete-company.vue";
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["searchDateValue"]: [...(query?.["searchDateValue"] || [null, null])],

    ["_user"]: query?.["_user"] || null,
    ["searchValue"]: query?.["searchValue"] || null,
});

const props__autocompleteUser = {
    params: {
        type: USER_TYPES.COMPANY.value,
    },
    itemText: ({ nickname, username }) => `${nickname} (${username})`,
};

export default {
    components: {
        ListSearch,
        AutocompleteCompany,
        AutocompleteProduct,
    },
    props: {
        loading: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        attrs_input__console,
        attrs_input__date__console,

        props__autocompleteUser,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            delete query.page;

            if (!query?.["searchDateValue"]) delete query["searchDateValue"];
            if (!query?.["searchDateValue"]?.[0] && !query?.["searchDateValue"]?.[1]) delete query["searchDateValue"];

            if (!query?.["_user"]) delete query["_user"];
            if (!query?.["searchValue"]) delete query["searchValue"];

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
