<template>
    <autocomplete-product
        v-model="form.usage.target._products"
        v-bind="{
            ...attrs_input__console,
            dense: false,

            label: '사용대상 ' + USAGE_TARGET_TYPES.PRODUCTS.text,
            multiple: true,

            params: { _seller: { $in: _seller } },
        }"
        ref="autocomplete-product"
        @input="emit"
    >
        <template #selection="{ parent, item, attrs, itemText }">
            <v-chip outlined label close v-bind="attrs" @click:close="parent.selectItem(item)" :key="item._id">{{ itemText(item) }}</v-chip>
        </template>
    </autocomplete-product>
</template>

<script>
import { attrs_input__console } from "@/assets/variables/attrs";
import { initShopCoupon } from "@/assets/variables/inits";
import { USAGE_TARGET_TYPES } from "@/assets/variables/constants";

import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";

export default {
    components: {
        AutocompleteProduct,
    },
    props: {
        value: { type: Object, default: initShopCoupon },
    },
    setup: () => ({
        attrs_input__console,
        USAGE_TARGET_TYPES,
    }),
    data: () => ({
        form: initShopCoupon(),
    }),
    computed: {
        _seller() {
            return this.form._seller;
        },
    },
    methods: {
        sync() {
            let form = { ...this.value };

            let needsEmit = false;

            if (this.$refs["autocomplete-product"]) {
                if (form?.usage?.target?._products?.length) {
                    let { _products = [] } = form?.usage?.target || {};

                    let products = this.$refs["autocomplete-product"]?.items;
                    if (0 < products.length) {
                        products = _products.map((_id) => products.find((item) => item._id == _id));
                        products = products.filter(({ _seller } = {}) => _seller == this.value?._seller);

                        _products = products.map(({ _id } = {}) => _id);
                        _products = _products.filter((item) => !!item);

                        needsEmit = JSON.stringify([..._products].sort()) != JSON.stringify([...(form?.usage?.target?._products || [])].sort());

                        form = {
                            ...form,
                            usage: {
                                ...form.usage,
                                target: {
                                    ...form.usage.target,
                                    _products,
                                },
                            },
                        };
                    }
                }
            }

            this.form = initShopCoupon(form);

            if (needsEmit) this.emit();
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>