<template>
    <v-text-field v-model="form.name" v-bind="{ ...attrs_input__verticalTable, disabled }" @input="emit">
        <template #append v-if="!isColor && hasError">
            <v-tooltip right>
                <template #activator="{ attrs, on }">
                    <v-icon size="20" color="error" v-bind="attrs" v-on="on"> mdi-alert-circle-outline </v-icon>
                </template>

                <ul>
                    <template v-if="hasNoName">
                        <li>옵션명은 필수 입력사항입니다.</li>
                    </template>
                    <template v-if="hasSameName">
                        <li>동일한 이름의 옵션이 있습니다.</li>
                    </template>
                </ul>
            </v-tooltip>
        </template>
    </v-text-field>
</template>

<script>
import { attrs_input__verticalTable } from "@/assets/variables/attrs";
import { PRODUCT_OPTION_CRITERIA_TYPES } from "@/assets/variables/constants";
import { initProductOptionCriterion } from "@/assets/variables/inits";

export default {
    props: {
        value: { type: Object, default: initProductOptionCriterion },
        items: { type: Array, default: () => [] }, // criteria
    },
    setup: () => ({
        attrs_input__verticalTable,
    }),
    data: () => ({
        form: initProductOptionCriterion(),
    }),
    computed: {
        isColor() {
            return this.form.type == PRODUCT_OPTION_CRITERIA_TYPES.COLOR.value;
        },
        disabled() {
            return this.isColor;
        },

        hasError() {
            return this.hasNoName || this.hasSameName;
        },
        hasNoName() {
            return !this.form.name;
        },
        hasSameName() {
            return 1 < this.items.filter(({ name }) => name == this.form.name)?.length;
        },
    },
    methods: {
        sync() {
            this.form = initProductOptionCriterion(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style></style>
