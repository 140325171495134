<template>
    <v-layout justify-center fill-height>
        <v-responsive max-width="1160" width="100%" height="100%">
            <list-heading :title="isCreate ? '서비스 등록' : '서비스 상세'" />

            <v-row class="mx-n2 my-2">
                <v-col cols="12" md="7" class="pa-2">
                    <view-section-card title="서비스정보" v-bind="{ loading }">
                        <v-card-text>
                            <v-row class="mx-n2">
                                <v-col cols="12" md="3" class="pa-2">
                                    <v-select v-model="service.type" label="서비스 유형" :items="serviceTypes" v-bind="attrs_input__console" :disabled="!isCreate" />
                                </v-col>
                                <v-col cols="12" md="9" class="pa-2">
                                    <v-text-field v-model="service.name" label="서비스명" v-bind="attrs_input__console" />
                                </v-col>
                                <v-col cols="12" md="12" class="pa-2">
                                    <v-text-field v-model="service.description" label="서비스 설명"  v-bind="attrs_input__console" />
                                </v-col>
                                <v-col cols="12" md="6" class="pa-2">
                                    <v-currency-field v-model="service.price" label="판매가" suffix="원" v-bind="attrs_input__console" />
                                </v-col>
                                <v-col auto class="pa-2">
                                    <v-text-field v-model="service.sequence" label="진열순서" type="number" v-bind="attrs_input__console" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </view-section-card>
                </v-col>
                <v-col cols="12" md="5" class="pa-2">
                    <v-row class="ma-n2">
                        <v-col cols="12" class="pa-2">
                            <view-section-card hideTitle hideDivider v-bind="{ loading }">
                                <v-row no-gutters>
                                    <v-col cols="12" md="6">
                                        <view-section-title title="전시여부">
                                            <template #actions> <v-switch v-model="service.shows" v-bind="attrs_switch__console" /> </template>
                                        </view-section-title>
                                        <v-divider style="margin-bottom: -1px" />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <view-section-title title="판매여부">
                                            <template #actions> <v-switch v-model="service.sells" v-bind="attrs_switch__console" /> </template>
                                        </view-section-title>
                                        <v-divider style="margin-bottom: -1px" />
                                    </v-col>
                                </v-row>
                            </view-section-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <!-- <v-btn fixed right bottom fab color="white" elevation="1" class="mb-16" v-bind="{ loading }" :href="`/minishop/`">
                <v-icon>mdi-link-variant</v-icon>
            </v-btn> -->
            <v-btn fixed right bottom fab color="primary" elevation="1" v-bind="{ loading }" @click="save">
                <v-icon>mdi-content-save</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input__console, attrs_switch__console, initService, COMPANY_CATEGORY_TYPES } from "@/assets/variables";

import Draggable from "vuedraggable";
import ImageInput from "@/components/console/dumb/image-input.vue";
import ImagesInput from "@/components/console/dumb/images-input.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import ViewSectionTitle from "@/components/console/dumb/view-section-title.vue";
import ShopProductLink from "@/components/console/shop/products/link/shop-product-link.vue";

const serviceTypes = Object.values(COMPANY_CATEGORY_TYPES);

export default {
    components: {
        Draggable,
        ImageInput,
        ImagesInput,
        ListHeading,
        ViewSectionCard,
        ViewSectionTitle,
        ShopProductLink,
    },
    props: {
        _service: { type: String, default: null },
    },
    data: () => ({
        service: initService(),

        brands: [],

        serviceTypes,

        attrs_input__console,
        attrs_switch__console,

        loading: false,
    }),
    computed: {
        isCreate() {
            return !this._service;
        },
        isCopy() {
            return this.$route.query.mode == "copy";
        },
        _user(){
            return this.$store.state.payload._user;
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        _service() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (!this.isCreate) {
                    var { service } = await api.console.shop.services.get({ _id: this._service });

                    if (this.isCopy) {
                        service._id = undefined;
                    }

                    this.service = service;
                }

                if (this.isCreate || this.isCopy) {
                    var { summary } = await api.console.shop.services.gets({ headers: { limit: 1 }, params: { _user: this._user } });
                    this.service.sequence = summary.totalCount;
                }
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if(!this.validates()) return;
                let { ...form } = this.service;
                const { post, put } = api.console.shop.services;
                let { service } = await (this.isCreate || this.isCopy ? post : put)({ _user: this._user, ...form });

                alert(this.isCreate || this.isCopy ? "등록 되었습니다" : "수정 되었습니다");
                this.$router.go(-1);
            } catch(error){
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validates(){
            if(!this.service.type) throw new Error("서비스 유형을 선택해주세요");
            if(!this.service.name) throw new Error("서비스명을 입력해주세요");
            if(!this.service.price) throw new Error("판매가를 입력해주세요");
    
            return true;
        }
    },
};
</script>
