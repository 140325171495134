<template>
    <v-btn-toggle v-model="query['mode']" mandatory color="primary" dense @change="emit">
        <template v-for="item in items">
            <v-btn :value="item.value" outlined :key="item.text">{{ item.text }}</v-btn>
        </template>
    </v-btn-toggle>
</template>

<script>
import { USER_MONEY_STATISTICS_MODES } from "@/assets/variables";

const initQuery = (query = {}) => ({
    ...query,

    mode: query.mode || USER_MONEY_STATISTICS_MODES.DAILY.value,
});

const items = Object.values(USER_MONEY_STATISTICS_MODES);

export default {
    data: () => ({
        query: initQuery(),
        items,
    }),
    methods: {
        sync() {
            this.query = initQuery({ ...this.$route.query });
        },
        emit() {
            const { ...query } = this.query;

            delete query["page"];

            if (!query["mode"]) delete query["mode"];

            this.$router.push({ query });
        },
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>