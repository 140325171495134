var render = function render(){
  var _vm$itemText;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.readonly ? _c('span', [_vm.loading ? [_c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  })] : _vm.multiple ? [_vm._v(" " + _vm._s(_vm.selected.map(_vm.itemText).join(", ") || _vm.placeholder) + " ")] : [_vm._v(" " + _vm._s((_vm$itemText = _vm.itemText(_vm.selected)) !== null && _vm$itemText !== void 0 ? _vm$itemText : _vm.placeholder) + " ")]], 2) : _c('v-autocomplete', _vm._b({
    attrs: {
      "search-input": _vm.searchValue
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.searchValue = $event;
      },
      "update:search-input": function ($event) {
        _vm.searchValue = $event;
      },
      "click": _vm.alert,
      "keyup": [_vm.onKeyup, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }],
      "change": _vm.onChange
    },
    scopedSlots: _vm._u([_vm.$scopedSlots['item'] ? {
      key: "item",
      fn: function (_ref) {
        var parent = _ref.parent,
          item = _ref.item,
          on = _ref.on,
          attrs = _ref.attrs;
        return [_vm._t("item", null, null, {
          parent,
          item,
          on,
          attrs,
          itemText: _vm.itemText
        })];
      }
    } : null, _vm.$scopedSlots['selection'] ? {
      key: "selection",
      fn: function (_ref2) {
        var parent = _ref2.parent,
          item = _ref2.item,
          attrs = _ref2.attrs;
        return [_vm._t("selection", null, null, {
          parent,
          item,
          attrs,
          itemText: _vm.itemText
        })];
      }
    } : null, _vm.$scopedSlots['append'] ? {
      key: "append",
      fn: function () {
        return [_vm._t("append")];
      },
      proxy: true
    } : null, _vm.$scopedSlots['append-outer'] ? {
      key: "append-outer",
      fn: function () {
        return [_vm._t("append-outer")];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.item,
      callback: function ($$v) {
        _vm.item = $$v;
      },
      expression: "item"
    }
  }, 'v-autocomplete', Object.assign({}, _vm.$attrs, {
    items: _vm.items,
    itemText: _vm.itemText,
    itemValue: '_id',
    loading: _vm.loading,
    multiple: _vm.multiple,
    placeholder: _vm.placeholder,
    returnObject: _vm.returnObject
  }), false), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }