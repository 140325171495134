<template>
    <view-section-card title="검색엔진최적화(SEO)" hideDivider v-bind="{ loading }">
        <template #actions>
            <v-switch v-model="form.seoEnabled" v-bind="attrs_switch__console" @change="emit" />
        </template>
        <v-expand-transition>
            <v-sheet v-show="form.seoEnabled">
                <v-divider />
                <v-card-text>
                    <v-row class="ma-n2">
                        <v-col cols="12" md="6" class="pa-2">
                            <v-text-field v-model="form.seo.title" label="타이틀: Title" v-bind="attrs_input__console" @input="emit" />
                        </v-col>
                        <v-col cols="12" md="6" class="pa-2">
                            <v-text-field v-model="author.content" label="메타태그: Author" v-bind="attrs_input__console" @input="emit" />
                        </v-col>
                        <v-col cols="12" class="pa-2">
                            <v-textarea v-model="decription.content" label="메타태그: Description" v-bind="attrs_input__console" @input="emit" />
                        </v-col>
                        <v-col cols="12" class="pa-2">
                            <v-textarea v-model="keywords.content" label="메타태그: Keywords" v-bind="attrs_input__console" @input="emit" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-sheet>
        </v-expand-transition>
    </view-section-card>
</template>

<script>
import { attrs_input__console, attrs_switch__console, initProduct } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initProduct },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initProduct(),

        attrs_input__console,
        attrs_switch__console,
    }),
    computed: {
        author() {
            let author = this.form.seo.meta.find((meta) => meta.name == "author");
            if (!author) {
                author = { name: "author", content: "" };
                this.form.seo.meta.push(author);
            }
            return author;
        },
        decription() {
            let decription = this.form.seo.meta.find((meta) => meta.name == "decription");
            if (!decription) {
                decription = { name: "decription", content: "" };
                this.form.seo.meta.push(decription);
            }
            return decription;
        },
        keywords() {
            let keywords = this.form.seo.meta.find((meta) => meta.name == "keywords");
            if (!keywords) {
                keywords = { name: "keywords", content: "" };
                this.form.seo.meta.push(keywords);
            }
            return keywords;
        },
    },
    methods: {
        async sync() {
            this.form = initProduct(this.value);
        },
        async emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>
