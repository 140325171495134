var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-currency-field', _vm._b({
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-select', _vm._b({
          attrs: {
            "dense": ""
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.form.unit,
            callback: function ($$v) {
              _vm.$set(_vm.form, "unit", $$v);
            },
            expression: "form.unit"
          }
        }, 'v-select', _vm.props__unit, false))];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.value,
      callback: function ($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }, 'v-currency-field', _vm.props__value, false));

}
var staticRenderFns = []

export { render, staticRenderFns }