var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": 1440
    }
  }, [_c('list-heading', {
    attrs: {
      "title": _vm.title
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('v-row', {
          staticClass: "ma-n2"
        }, [_c('v-spacer'), _c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "auto"
          }
        }, [_c('analytics-period-filter', {
          on: {
            "input": _vm.sync
          },
          model: {
            value: _vm.params,
            callback: function ($$v) {
              _vm.params = $$v;
            },
            expression: "params"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('banners-analytics-search', _vm._b({
    on: {
      "input": _vm.sync
    },
    model: {
      value: _vm.params,
      callback: function ($$v) {
        _vm.params = $$v;
      },
      expression: "params"
    }
  }, 'banners-analytics-search', {
    showsSearch: _vm.showsSearch
  }, false)), _c('v-row', {
    staticClass: "mt-n3"
  }), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    attrs: {
      "items-per-page": -1,
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value, item))
            }
          })];
        }
      };
    }), {
      key: `item.banner`,
      fn: function (_ref2) {
        var _item$meta;
        var item = _ref2.item;
        return [item ? _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [item.image ? _c('image-popup', {
          attrs: {
            "src": item.image,
            "attrs_avatar": {
              size: '40',
              rounded: true
            }
          }
        }) : _c('v-responsive', {
          attrs: {
            "width": "40",
            "height": "40"
          }
        }, [_c('v-row', {
          staticClass: "fill-height ma-0 grey lighten-3",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)], 1)], 1), _c('v-col', [_c('div', {
          staticClass: "white-space-pre-line cursor-pointer",
          on: {
            "click": function ($event) {
              return _vm.$router.push(`/console/main/banner/${_vm.code}`);
            }
          }
        }, [_vm._v(" " + _vm._s(`${(item === null || item === void 0 ? void 0 : (_item$meta = item.meta) === null || _item$meta === void 0 ? void 0 : _item$meta.name) || "-"}\r\n${(item === null || item === void 0 ? void 0 : item.image.split("/").pop()) || "-"}`) + " ")])])], 1) : _vm._e()];
      }
    }, {
      key: `item.link`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('a', {
          attrs: {
            "href": item.url,
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(item.url))])];
      }
    }, {
      key: `item.status`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.isDeleted ? "종료" : "게시중") + " ")];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false)), _c('v-pagination', {
    staticClass: "my-2",
    attrs: {
      "value": _vm.params.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.params = Object.assign({}, _vm.params, {
          page
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }