<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%">
            <list-heading title="상품 목록" v-model="showsSearch" showsFilterButton />

            <product-list-search v-bind="{ loading, showsSearch }" />

            <v-row class="mt-n3">
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white" class="caption" height="46" v-bind="{ loading }">
                            <v-layout align-baseline>
                                <span> 검색결과: </span>
                                <b class="mx-1"> {{ summary.totalCount?.format?.() }} </b> 건
                            </v-layout>
                        </v-btn>
                    </v-card>
                </v-col>
                <!-- <v-col cols="auto">
                    <product-list-types />
                </v-col> -->
            </v-row>

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.meta.sellCount`]="{ item, value }">
                    <shop-product-edit v-bind="{ value: item }" @update="update">
                        {{ value }}
                    </shop-product-edit>
                </template>

                <template #[`item.goods`]="{ item }">
                    <item-product :product="item" isLink />
                </template>
                <template #[`item.POPULAR`]="{ item }">
                    <shop-product-groups v-model="item.groups" :code="DISPLAY_CODES.POPULAR.value" v-bind="attrs_switch__console" @input="(groups) => update({ _id: item._id, groups })" />
                </template>
                <template #[`item.RECOMMENDATION`]="{ item }">
                    <shop-product-groups v-model="item.groups" :code="DISPLAY_CODES.RECOMMENDATION.value" readonly v-bind="attrs_switch__console" @input="(groups) => update({ _id: item._id, groups })" />
                </template>
                <template #[`item.BEST_SELLER`]="{ item }">
                    <shop-product-groups v-model="item.groups" :code="DISPLAY_CODES.BEST_SELLER.value" v-bind="attrs_switch__console" @input="(groups) => update({ _id: item._id, groups })" />
                </template>
                <template #[`item.sells`]="{ item }">
                    <v-switch v-model="item.sells" v-bind="attrs_switch__console" @change="(sells) => update({ _id: item._id, sells })" />
                </template>
                <template #[`item.shows`]="{ item }">
                    <v-switch v-model="item.shows" v-bind="attrs_switch__console" @change="(shows) => update({ _id: item._id, shows })" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn small text icon tile :to="`${$route.path}/${item._id}`">
                        <v-icon small title="수정">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn small text icon tile :to="`${$route.path}/${item._id}?mode=copy`">
                        <v-icon small title="복사">mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small title="삭제">mdi-delete</v-icon>
                    </v-btn>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>

            <template v-if="hasCompanyScope">
                <v-btn bottom color="accent" fab fixed right :to="`${$route.path}/create`">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </template>
        </v-responsive>
    </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import api from "@/api";

import { attrs_switch__console } from "@/assets/variables/attrs";
import { DISPLAY_CODES, PRODUCT_TYPES } from "@/assets/variables/constants";
import { initDataTableHeaders, initProduct } from "@/assets/variables/inits";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import ItemProduct from "@/components/console/dumb/item-product.vue";
import ProductListTypes from "@/components/console/shop/products/list/product-list-types.vue";
import ProductListSearch from "@/components/console/shop/products/list/product-list-search.vue";
import ShopProductGroups from "@/components/console/shop/products/shop-product-groups.vue";
import ShopProductEdit from '@/components/console/shop/products/shop-product-edit.vue';

export default {
    components: {
        ListHeading,
        ItemProduct,
        ProductListTypes,
        ProductListSearch,
        ShopProductGroups,
        ShopProductEdit
    },
    data: () => ({
        products: [],

        limit: 20,
        summary: { totalCount: 0 },

        attrs_switch__console,
        DISPLAY_CODES,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        ...mapGetters(["displayCodes", "hasCompanyScope"]),

        headers() {
            const headers = initDataTableHeaders([
                {
                    text: "입점사",
                    value: "seller",
                    formatter: ({ nickname, username } = {}) => `${nickname} (${username})`,
                    width: 140,
                    test: (scope) => scope.includes("console"),
                    cellClass: "overflow-wrap-break-word word-break-break-all",
                },
                {
                    text: "우선\r\n순서",
                    value: "index",
                    formatter: (value) => value?.format?.() || value || "-",
                    width: +60,
                    align: "end",
                    test: (scope) => scope.includes("console"),
                },
                {
                    text: "진열\r\n순서",
                    value: "sequence",
                    formatter: (value) => value?.format?.() || value || 0,
                    width: +60,
                    align: "end",
                },
                {
                    text: "판매량",
                    value: "meta.sellCount",
                    formatter: (value) => value?.format?.() || value || 0,
                    cellClass: "py-4 max-width-0 text-truncate",
                    width: +70,
                    align: "center",
                    test: (scope) => scope.includes("console"),
                },
                {
                    text: "상품유형",
                    value: "type",
                    formatter: (value) => PRODUCT_TYPES[value]?.text || "-",
                    cellClass: "max-width-0 py-3 text-truncate",
                    width: +85,
                },
                // {
                //     text: "상품코드",
                //     value: "code",
                //     width: 110,
                //     cellClass: "max-width-0 py-3 text-truncate",
                // },
                // {
                //     text: "묶음상품코드",
                //     value: "variation.code",
                //     cellClass: "max-width-0 py-3 text-truncate",
                //     width: 110,
                // },
                // {
                //     text: "연관상품코드",
                //     value: "relatedGroupCode",
                //     cellClass: "max-width-0 py-3 text-truncate",
                //     width: 110,
                // },
                {
                    text: "상품명",
                    value: "goods",
                    cellClass: "max-width-0 pa-0",
                },
                {
                    text: "판매가",
                    value: "price",
                    formatter: (value) => (value?.format?.() || value || 0) + "원",
                    width: 120,
                    align: "end",
                },
                {
                    text: "할인판매가",
                    value: "salePrice",
                    formatter: (value) => (value?.format?.() || value || 0) + "원",
                    width: 120,
                    align: "end",
                },
                {
                    text: "재고",
                    value: "stock",
                    formatter: (value) => (value?.format?.() || value || 0) + "개",
                    cellClass: "py-3 max-width-0 text-truncate",
                    width: +80,
                    align: "end",
                },
                // {
                //     text: DISPLAY_CODES.POPULAR.text,
                //     value: DISPLAY_CODES.POPULAR.value,
                //     class: "word-break-keep-all",
                //     width: +80,
                //     align: "center",
                // },
                // {
                //     text: DISPLAY_CODES.BEST_SELLER.text,
                //     value: DISPLAY_CODES.BEST_SELLER.value,
                //     class: "word-break-keep-all",
                //     width: +80,
                //     align: "center",
                // },
                {
                    text: DISPLAY_CODES.RECOMMENDATION.text,
                    value: DISPLAY_CODES.RECOMMENDATION.value,
                    class: "word-break-keep-all",
                    width: 130,
                    align: "center",
                },
                {
                    text: "판매\r\n상태",
                    value: "sells",
                    width: +80,
                    align: "center",
                },
                {
                    text: "전시\r\n상태",
                    value: "shows",
                    width: +80,
                    align: "center",
                },
                {
                    text: "Actions",
                    value: "actions",
                    width: 120,
                },
            ]);

            return headers.map((item) => ({ ...item, text: this.displayCodes.find(({ value }) => value == item.value)?.text || item.text })).filter((header) => (header?.test ? header.test(this.$store.state.scope) : true));
        },
        items() {
            return this.products.map((item, index) => initProduct({ ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            for (const key of Object.keys(DISPLAY_CODES)) {
                if (query[key]) {
                    // 파싱
                    try {
                        query[key] = JSON.parse(query[key]);
                    } catch {
                        query[key] = query[key];
                    }

                    if (query[key] == true) {
                        query.groups = { ...(query.groups || []) };
                        query.groups.$in = [...(query.groups.$in || []), key];
                    }
                    if (query[key] == false) {
                        query.groups = { ...(query.groups || []) };
                        query.groups.$nin = [...(query.groups.$nin || []), key];
                    }
                }
            }

            query['populate'] = JSON.stringify(["options"]);
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        ...mapActions(["getSetting__console"]),

        async init() {
            this.products = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.getSetting__console();
            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, products } = await api.console.shop.products.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.products = products;
            } finally {
                this.loading = false;
            }
        },

        async update(product) {
            if (this.loading) return;
            else this.loading = true;
            try {
                product = (await api.console.shop.products.put(product))?.product;
                this.updateItem(product);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(product) {
            const index = this.products.findIndex(({ _id }) => _id == product._id);
            if (0 <= index) this.products.splice(index, 1, product);
            else {
                this.products = [product, ...this.products];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async remove(product) {
            try {
                if (confirm("해당 상품을 삭제하시겠습니까?")) {
                    await api.console.shop.products.delete(product);
                    await this.search();

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    // common
    .cursor-pointer {
        cursor: pointer;
    }
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .overflow-wrap-break-word {
        overflow-wrap: break-word;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .word-break-break-all {
        word-break: break-all;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }

    // pagination
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }

    // edit-dialog
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    // v-data-table
    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }

    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
