<template>
    <v-dialog v-model="dialog" width="560" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }}  <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-5">
                <v-row>
                    <v-col cols="8" class="py-2">
                        <autocomplete-user v-model="form._user" label="차단회원" :itemText="({ name, username, nickname }) => `${name} (${username} / ${nickname})`" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                    <v-col cols="12" md="4" class="pa-2">
                        <v-text-field v-model="form.days" label="차단일" type= number v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                    <v-col cols="12" md="12" class="pa-2">
                        <v-textarea v-model="form.memo" label="메모" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                    <v-col cols="12" md="12" class="pa-2">
                        <v-card class="pa-3" v-bind="{ ...attrs_input__console, loading }">
                            {{ blockMsg }}
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-switch v-model="form.enabled" dense v-bind="attrs_switch__console">
                        <span slot="label" class="subtitle-2"> {{ form.enabled ? "활성화" : "비활성화" }} </span>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import dayjs from "@/plugins/vue-dayjs";
import { attrs_input__console, attrs_switch__console } from "@/assets/variables";

import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

const initBlockedUser = (user = {}) => ({
    ...user,

    _user: user?._user || null,

    days: user?.days || 0,
    memo: user?.memo || "",
    enabled: user?.enabled || false,

    blockedAt: user?.blockedAt || dayjs(),
    unblockedAt: user?.unblockedAt || dayjs(),
});

export default {
    components: {
        VDateField,
        AutocompleteUser,
    },
    props: {
        _id: { type: String, default: "" }
    },
    data: () => ({
        form: initBlockedUser(),
        today: dayjs(),

        attrs_input__console,
        attrs_switch__console,

        dialog: false,
        loading: false,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "차단회원";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
        blockMsg() {
            return `해당 회원은 ${dayjs(this.form.unblockedAt).format("YYYY-MM-DD")} 까지 차단됩니다.`;
        }
    },
    watch: {
        "form.days"() {
            this.form.unblockedAt = this.today.add(this.form.days, "days").startOf("day");
        },
        dialog() {
            this.init();
        }
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initBlockedUser();
                } else {
                    let { _id } = this;
                    this.form = (await api.console.users.blocks.get({ _id }))?.user;
                    this.form.blockedAt = dayjs(this.form.blockedAt).format("YYYY-MM-DD");
                    this.form.unblockedAt = dayjs(this.form.unblockedAt).format("YYYY-MM-DD");
                }
            } finally {
                this.loading = false;
            }
        },
        validates() {
            try {
                let { _user, days } = this.form;
                if (!_user) throw new Error("회원을 선택해주세요.");
                if (!days) throw new Error("차단일을 입력해주세요.");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (!this.validates()) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                let { post, put } = api.console.users.blocks;

                let user = (await (this.isCreate ? post : put)(this.form))?.user;
                
                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input");
                this.dialog = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>