<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%">
            <list-heading title="수수료 정책" v-model="showsSearch" showsFilterButton>
                <template #add-button>
                    <shop-money-form @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </shop-money-form>
                </template>
            </list-heading>

            <shop-money-search v-bind="{ showsSearch, loading }" />

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value, item)" /> </template>

                <template #[`item.money`]="{ item }">
                    <item-money :money="item" flat tile color="transparent" />
                </template>
                <template #[`item.usage.datetime`]="{ item }">
                    <item-usage-datetime :usage="item.usage" flat tile color="transparent" />
                </template>

                <template #[`item.issue`]="{ item }">
                    <item-issue :issue="item.issue" flat tile color="transparent" />
                </template>
                <template #[`item.issue.target`]="{ item }">
                    <item-issue-target :issue="item.issue" flat tile color="transparent" />
                </template>
                <template #[`item.issue.limit`]="{ item }">
                    <item-issue-limit :issue="item.issue" flat tile color="transparent" />
                </template>
                <template #[`item.issue.datetime`]="{ item }">
                    <item-issue-datetime :issue="item.issue" flat tile color="transparent" />
                </template>
                <template #[`item.meta.count`]="{ value }">
                    {{ value.used.format() }} /
                    {{ value.total.format() }}
                </template>

                <template #[`item.issue.isActive`]="{ item }">
                    <v-switch v-model="item.issue.isActive" v-bind="attrs_switch__console" @change="(isActive) => update({ _id: item._id, issue: { ...item.issue, isActive } })" />
                </template>

                <template #[`item.usage.isActive`]="{ item }">
                    <v-switch v-model="item.usage.isActive" v-bind="attrs_switch__console" @change="(isActive) => update({ _id: item._id, usage: { ...item.usage, isActive } })" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-layout justify-center>
                        <shop-money-form :value="item" @input="updateItem">
                            <template #activator="{ attrs, on }">
                                <v-btn small text icon tile v-bind="attrs" v-on="on">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                        </shop-money-form>
                        <v-btn small text icon tile title="발급내역" :to="{ path: '/console/user-moneys/', query: { _shopMoney: item._id } }">
                            <v-icon small>mdi-cash-multiple</v-icon>
                        </v-btn>
                        <v-btn small text icon tile title="정책삭제" @click="remove(item)">
                            <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                    </v-layout>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { initDataTableHeaders, initShopMoney__charge } from "@/assets/variables/inits";
import { attrs_switch__console } from "@/assets/variables/attrs";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import ItemMoney from "@/components/console/shop/moneys/list/item-money.vue";

import ItemUsageDatetime from "@/components/console/shop/moneys/list/item-usage-datetime.vue";

import ItemIssue from "@/components/console/shop/moneys/list/item-issue.vue";
import ItemIssueLimit from "@/components/console/shop/moneys/list/item-issue-limit.vue";
import ItemIssueTarget from "@/components/console/shop/moneys/list/item-issue-target.vue";
import ItemIssueDatetime from "@/components/console/shop/moneys/list/item-issue-datetime.vue";

import ShopMoneyForm from "@/components/console/shop/moneys/form/shop-money-form.vue";
import ShopMoneySearch from "@/components/console/shop/moneys/list/shop-money-search.vue";
import { ISSUE_TARGET_TYPES, SHOP_MONEY_UNITS } from "@/assets/variables";

const headers = initDataTableHeaders([
    { width: 160, text: "생성일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-", cellClass: "py-3" },
    { width: 160, text: "관리코드", value: "code" },
    { width: 160, text: "관리명칭", value: "name" },
    { width: 160, text: "수수료", value: "value", formatter: (value, item) => `${(value || 0)?.format(1)}${SHOP_MONEY_UNITS[item?.unit]?.suffix}` },
    // { width: 140, text: "사용기간", value: "usage.datetime" },
    // { width: 140, text: "발급형태", value: "issue" },
    {
        text: "발급대상",
        value: "issue.target.type",
        formatter: (value, item) => {
            let text = ISSUE_TARGET_TYPES[value]?.text;
            if (value == ISSUE_TARGET_TYPES.USERS.value) {
                text += ` / ${(item?.issue?.target?.users || []).map(({ name, username }) => `${name}(${username})`).join(", ")}`;
            }
            return text.trim();
        },
    },
    // { width: 140, text: "발급제한", value: "issue.limit" },
    {
        width: 240,
        text: "발급기간",
        value: "issue.datetime",
        cellClass: "pa-0",
    },
    {
        width: 160,
        text: "출금액 / 발급액",
        value: "meta.count",
        cellClass: "py-3",
        align: "center",
    },
    { width: +80, text: "발급", value: "issue.isActive", cellClass: "py-3", align: "center" },
    // { width: +80, text: "사용", value: "usage.isActive", cellClass: "py-3", align: "center" },
    { width: 100, text: "", value: "actions", cellClass: "py-3" },
]);

export default {
    components: {
        ListHeading,

        ItemMoney,

        ItemUsageDatetime,

        ItemIssue,
        ItemIssueLimit,
        ItemIssueTarget,
        ItemIssueDatetime,

        ShopMoneyForm,
        ShopMoneySearch,
    },
    data: () => ({
        moneys: [],

        limit: 20,
        summary: { totalCount: 0 },

        headers,
        attrs_switch__console,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        items() {
            return [...this.moneys].map(initShopMoney__charge);
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            if (query["_user"]) {
                query["issue.target._users"] = query["_user"];
                delete query["_user"];
            }

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.moneys = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, moneys } = await api.console.shop.moneys.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.moneys = moneys;
            } finally {
                this.loading = false;
            }
        },

        async update(money) {
            if (this.loading) return;
            else this.loading = true;
            try {
                money = (await api.console.shop.moneys.put(money))?.money;
                this.updateItem(money);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(money) {
            const index = this.moneys.findIndex(({ _id }) => _id == money._id);
            if (0 <= index) this.moneys.splice(index, 1, money);
            else {
                this.moneys = [initShopMoney__charge(money), ...this.moneys];
                this.summary.totalCount += 1;
            }
            this.search();
        },
        async remove(item) {
            if (this.loading) return;
            else this.loading = true;
            try {
                (await api.console.shop.moneys.delete(item))?.money;
            } finally {
                this.loading = false;
                this.search();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
