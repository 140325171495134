<template>
    <v-dialog v-model="dialog" width="560" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }" class="d-flex flex-column" max-height="90vh" style="overflow: hidden">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-5" style="flex: 0 1 auto; overflow-y: auto">
                <v-row class="ma-n2">
                    <v-col cols="12" md="10" class="pa-2">
                        <v-select v-model="form.code" label="카테고리" :items="codes" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                    <v-col cols="12" md="2" class="pa-2">
                        <v-text-field v-model="form.index" label="#" v-bind="{ ...attrs_input__console, loading }" :disabled="isCreate" />
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-datetime-field v-model="form.startsAt" label="전시시작" class="pa-0" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-datetime-field v-model="form.endsAt" label="전시종료" class="pa-0" v-bind="{ ...attrs_input__console, loading }" />
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <showroom v-model="form" v-bind="{ attrs_input__console }" editable editable_image ref="showroom" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-switch v-model="form.shows" dense v-bind="attrs_switch__console">
                        <span slot="label" class="subtitle-2"> {{ form.shows ? "노출" : "미노출" }} </span>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { attrs_input__console, attrs_switch__console, initShowroom } from "@/assets/variables";

import Showroom from "@/components/dumb/showroom/showroom.vue";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";

export default {
    components: {
        Showroom,
        VDatetimeField,
        AutocompleteProduct,
    },
    props: {
        _id: { type: String, default: null },
        code: { type: String, default: null },
    },
    data: () => ({
        form: initShowroom(),

        attrs_input__console,
        attrs_switch__console,

        dialog: false,
        loading: false,
    }),
    computed: {
        ...mapState({ codes: (state) => state?.setting?.shop?.showroomCodes || [] }),

        isCreate() {
            return !this._id;
        },
        title() {
            let title = "쇼룸";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initShowroom({ code: this.code });
                } else {
                    let { _id } = this;
                    this.form = initShowroom((await api.console.shop.showrooms.get({ _id }))?.showroom);
                    this.$emit("input", this.form);
                }
            } finally {
                this.loading = false;
            }
        },
        validates() {
            try {
                let { code, image } = this.form;

                if (!code) throw new Error("위치를 선택하세요");
                if (!image) throw new Error("이미지를 등록하세요");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (!this.validates()) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                let { post, put } = api.console.shop.showrooms;
                let { _image, image, ...form } = this.form;
                let showroom = (await (this.isCreate ? post : put)(form))?.showroom;

                if (image instanceof File) image = (await api.console.files.post({ _showroom: showroom._id, index: "image" }, image))?.file;
                if (_image && _image != image?._id) await api.console.files.delete({ _showroom: showroom._id, _id: _image });
                _image = image?._id || null;

                showroom = (await put({ _id: showroom._id, _image }))?.showroom;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", showroom);
                this.dialog = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
