<template>
    <v-layout justify-center>
        <v-responsive :max-width="1440" class="px-3 mx-n3">
            <list-heading title="추천태그">
                <template #buttons>
                    <v-row class="ma-n2">
                        <v-spacer />
                        <v-col class="pa-2" cols="auto">
                            <analytics-period-filter v-model="params" @input="sync" />
                        </v-col>
                    </v-row>
                </template>
            </list-heading>
            <tags-analytics-search v-model="params" v-bind="{ showsSearch }" @input="sync" />
            <v-row class="mt-n3"> </v-row>

            <v-row>
                <v-col cols="6">
                    <v-data-table v-bind="{ headers, items: leftItems, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined" style="overflow: hidden">
                        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
                    </v-data-table>
                </v-col>
                <v-col cols="6">
                    <v-data-table v-bind="{ headers, items: rightItems, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined" style="overflow: hidden">
                        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <v-pagination :value="params.page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page)=>params = {...params, page}" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import { initDataTableHeaders } from "@/assets/variables/inits";
import { ANALYTICS_PERIOD_FILTER } from "@/assets/variables/constants";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import AnalyticsPeriodFilter from "../analytics-period-filter.vue";
import TagsAnalyticsSearch from "./tags-analytics-search.vue";

export default {
    components: {
        ListHeading,
        AnalyticsPeriodFilter,
        TagsAnalyticsSearch
    },
    props: {
        category: { type: Object, default: () => ({}) },
    },
    data: () => ({
        loading: false,
        showsSearch: true,

        analytics: [],
        summary: {
            totalCount: 0,
        },
        params: {
            period: ANALYTICS_PERIOD_FILTER.DAILY.value,
            searchKey: "name",
            searchValue: null,
            sortField: "viewCount",
            sortType: -1,
            page: 1,
        },

        limit: 20,
    }),
    computed: {
        ...mapGetters(["hasConsoleScope"]),
        leftItems() {
            return [...this.analytics].splice(0, this.limit/2);
        },
        rightItems() {
            return [...this.analytics].splice(this.limit/2, this.limit);
        },
        headers() {
            return initDataTableHeaders([
                { text: "제목", value: "value", width: "240px", align: "center" },
                { text: "등록수", value: "totalTagCount", width: "80px", align: "center" },
                { text: "조회수", value: "viewCount", width: "80px", align: "center" },
                { text: "전체 조회수", value: "totalViewCount", width: "80px", align: "center" },
            ]);
        },
        skip() {
            return (this.params.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit);
        },
        sort() {
            const { sortField, sortType } = this.params;
            if (!!sortField && !!sortType) return JSON.stringify({ [sortField]: sortType });
            return null;
        },
        _seller() {
            if (this.hasConsoleScope) return null;
            return this.$store.state.payload._user;
        },
    },
    methods: {
        async search() {
            if (this.loading) return;
            this.loading = true;
            try {
                const { params, skip, limit, sort } = this;
                const { summary, analytics } = await api.console.analytics.getReommendationTags({
                    headers: { skip, limit, sort },
                    params: {
                        ...params,
                    },
                });

                this.summary = summary;
                this.analytics = analytics;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        sync(value) {
            this.params = value;
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        params() {
            this.search();
        },
    },
};
</script>
