var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "발급주기",
      "items": _vm.roundTypes
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.issue.round.type,
      callback: function ($$v) {
        _vm.$set(_vm.form.issue.round, "type", $$v);
      },
      expression: "form.issue.round.type"
    }
  }, 'v-select', _vm.attrs_input__console, false))], 1), _c('v-expand-transition', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsField,
      expression: "showsField"
    }],
    staticClass: "pa-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "pa-2"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "value": _vm.showsField,
      "label": "발급회차 입력방법",
      "readonly": "",
      "disabled": ""
    }
  }, 'v-text-field', _vm.attrs_input__console, false))], 1)])], 1), _c('v-expand-transition', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsField == '진행회차',
      expression: "showsField == '진행회차'"
    }],
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-2"
  }, [_c('view-chips-field', _vm._b({
    attrs: {
      "label": "발급진행회차",
      "disabled": _vm.disabled__includeds
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "chip-area",
      fn: function () {
        return [_vm.showsField == '진행회차' && _vm.disabled__includeds ? _c('v-overlay', {
          attrs: {
            "absolute": "",
            "color": "transparent"
          }
        }, [_c('span', {
          staticClass: "black--text"
        }, [_vm._v(" 발급제외회차 항목을 모두 삭제 후 입력해주세요. ")])]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.issue.round.includeds,
      callback: function ($$v) {
        _vm.$set(_vm.form.issue.round, "includeds", $$v);
      },
      expression: "form.issue.round.includeds"
    }
  }, 'view-chips-field', _vm.attrs_chipsField, false))], 1)], 1)], 1), _c('v-expand-transition', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsField == '제외회차',
      expression: "showsField == '제외회차'"
    }],
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-2"
  }, [_c('view-chips-field', _vm._b({
    attrs: {
      "label": "발급제외회차",
      "disabled": _vm.disabled__excludeds
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "chip-area",
      fn: function () {
        return [_vm.showsField == '제외회차' && _vm.disabled__excludeds ? _c('v-overlay', {
          attrs: {
            "absolute": "",
            "color": "transparent"
          }
        }, [_c('span', {
          staticClass: "black--text"
        }, [_vm._v(" 발급진행회차 항목을 모두 삭제 후 입력해주세요. ")])]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.issue.round.excludeds,
      callback: function ($$v) {
        _vm.$set(_vm.form.issue.round, "excludeds", $$v);
      },
      expression: "form.issue.round.excludeds"
    }
  }, 'view-chips-field', _vm.attrs_chipsField, false))], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }